<div class="app-content main-content">
  <div class="side-app main-container">
    <div class="container-fluid p-0 dashboard">

      <div class="grid_hold px-2">
        <div class="grid_1 h200">
          <div class="card dashboard-carousel">
            <h4 class="card-title mt-2 sticky-title" style="margin-bottom: 10px; padding-left: 20px;">
              Notice Board
            </h4>
            <div class="notice-tiles p-0 m-0">
              <!-- Existing Birthday Wishes Tile -->
              <div class="notice-tile" (click)="openNoticeCategory('Birthday Wishes')" data-bs-toggle="modal"
                data-bs-target="#noticeModal">
                <div class="tile-header">
                  <h4 class="tile-title">Birthday Wishes</h4>
                </div>
                <div class="badge-container">
                  <span class="badge">{{ brithdayFilteredList.length }}</span>
                </div>
                <img src="../../assets/img/vecteezy_birthday-cake.png" alt="Cake" width="70%" height="100%">
              </div>
      
              <!-- Existing Work Anniversary Tile -->
              <div class="notice-tile" (click)="openNoticeCategory('Work Anniversary Wishes')" data-bs-toggle="modal"
                data-bs-target="#noticeModal">
                <div class="tile-header">
                  <h4 class="tile-title">Work Anniversary Wishes</h4>
                </div>
                <div class="badge-container">
                  <span class="badge">{{ workAnniversarFilteredList.length }}</span>
                </div>
                <img src="../../assets/img/anniversery.png.png" alt="Anniversary" width="70%" height="100%">
              </div>
      
              <!-- Existing Others Tile -->
              <div class="notice-tile" (click)="openNoticeCategory('Others')" data-bs-toggle="modal"
                data-bs-target="#noticeModal">
                <div class="tile-header">
                  <h4 class="tile-title">Notices</h4>
                </div>
                <div class="badge-container">
                  <span class="badge">{{ othersTitleFilteredList.length }}</span>
                </div>
                <img src="../../assets/img/notices6.png" alt="announcement" width="70%" height="90%">
              </div>
            </div>
          </div>
        </div>
     
      
        <!-- Modal -->
        <div class="modal fade" id="noticeModal" tabindex="-1" role="dialog" aria-labelledby="modalLabel"
          aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ selectedNoticeCategory }}</h4>
                <button type="button" class="btn-close close-btn" data-bs-dismiss="modal" aria-label="Close">X</button>
              </div>
              <div class="modal-body scroll" >
                <ng-container *ngIf="filteredNotices.length > 0; else noNotices">
                  <ul>
                    <li *ngFor="let notice of filteredNotices" style="padding: 5px 0px 5px 0px;">
                      <ng-container
                        *ngIf="selectedNoticeCategory !== 'Birthday Wishes' && selectedNoticeCategory !== 'Work Anniversary Wishes'">
                        <div class="row pt-4 pb-4" style="font-size: 14px;border: 1px solid black;border-radius: 8px;">
                          {{ notice.description }}
                        </div>
                      </ng-container>
                      <ng-container
                        *ngIf="selectedNoticeCategory === 'Birthday Wishes' || selectedNoticeCategory === 'Work Anniversary Wishes'">
                        <div class="column d-flex pt-4 pb-4" style="border: 1px solid black;border-radius: 8px;">
                          <img class="avatar avatar-xxl brround rounded-circle" alt="img"
                            [src]="notice.image || './../../../assets/template_assests/images/users/1.jpg'">
                          {{ notice.description }}
                        </div>

                      </ng-container>
                    </li>
                  </ul>
                </ng-container>
                <ng-template #noNotices>
                  <p style="text-align: center; font-weight: bold; margin-top: 20px;border: 1px solid black;border-radius: 8px;margin-left: 120px;margin-right: 120px;">Currently No Notices to Display
                  </p>
                </ng-template>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>

       
        <!-- HR related things -->
        <div class="grid_2" *ngIf="!hasNotices">
          <div class="card mb-3 ">
            <div class="card-content padd-15 py-2">
              <div
                class="col-md-12 d-md-flex flex-sm-column flex-xs-column flex-md-row justify-content-center align-items-center ps-0">
                <p class="card-text col-md-6  fs-6 lh-base  d-md-flex  "
                  style="font-weight: 500; margin: 0; padding-left: 0; padding-right: 0;">
                  Manage your HR related things such as Attendance, Leave, Payslips,
                  Documents and many more, all from a single dashboard.
                </p>
                <div class="col-md-6 text-center" style="padding: 0; height: 140px; overflow: hidden;">
                  <img src="../../assets/img/welcome-PhotoRoom.png" style=" height: inherit;" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- highlights of my Team -->
        <div class="grid_3 h200 p-0" *ngIf="noOfemployeeabsent!==0">
          <!-- <div class="grid_3  p-0" *ngIf="teamMemberOnLeave.length"> -->
          <div class="card ">

            <div class="card-content padd-15">
              <h4 class="card-title mb-3 fontSize-20">A Few highlights of my Team</h4>
              <div class="row">
                <div class="col-12 mb-3 mb-md-0" data-bs-toggle="modal" data-bs-target="#exampleModal">
                  <div class="app p-2 text-center" id="appInform">
                    <p class="h3">{{noOfemployeeabsent}}</p>
                    <h6 class="mx-auto max-w">Employees not working on {{currentdate| date: 'mediumDate'}}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid_sm " *ngIf="leaveDetailFlow.length">
          <app-show-all-leave-for-approval [ngClass]="{'mobileP0': teamMemberOnLeave.length==0,
          'p-0  mobileP0': teamMemberOnLeave.length!=0 && leaveDetailFlow.length!=0 }" *ngIf="leaveDetailFlow.length"
            [showLeaveForApproval]="leaveDetailFlow"></app-show-all-leave-for-approval>
        </div>
        <div class="grid_sm " *ngIf="teamMemberOnLeave.length">
          <app-team-member-on-leave [ngClass]="{'mobileP0': leaveDetailFlow.length==0,
          'p-0 mobileP0': teamMemberOnLeave.length!=0 && leaveDetailFlow.length!=0 }" *ngIf="teamMemberOnLeave.length"
            [teamMemberOnLeave]="teamMemberOnLeave"></app-team-member-on-leave>
        </div>
        <!-- <div class="grid_sm h200 p-0">
          <daily-tasks style="height:100%"
            [ngClass]="{' mobileP0': (teamMemberOnLeave.length==0 && leaveDetailFlow.length!=0) || (leaveDetailFlow.length==0 && teamMemberOnLeave.length!=0 ),
            'p-0 pe-2 mobileP0': (leaveDetailFlow.length==0 && teamMemberOnLeave.length==0) || (teamMemberOnLeave.length!=0 && leaveDetailFlow.length!=0) }"></daily-tasks>
        </div> -->
        <div class="grid_sm  p2" *ngIf="annualhoildaylist.length">
          <annualholiday
            [ngClass]="{'mobileP0': (teamMemberOnLeave.length==0 && leaveDetailFlow.length!=0) || (leaveDetailFlow.length==0 && teamMemberOnLeave.length!=0 ),
                      'p-0 mobileP0': (leaveDetailFlow.length==0 && teamMemberOnLeave.length==0) || (teamMemberOnLeave.length!=0 && leaveDetailFlow.length!=0) }"
            [annualhoildaylist]="annualhoildaylist">
          </annualholiday>
        </div>
        <div class="grid_sm " *ngIf="eventDayList.length">
          <app-dashboard-event-calendar
            [ngClass]="{'mobileP0': (teamMemberOnLeave.length==0 && leaveDetailFlow.length!=0) || (leaveDetailFlow.length==0 && teamMemberOnLeave.length!=0 ),
            'p-0 mobileP0': (leaveDetailFlow.length==0 && teamMemberOnLeave.length==0) || (teamMemberOnLeave.length!=0 && leaveDetailFlow.length!=0) }"
            [eventDayList]="eventDayList"></app-dashboard-event-calendar>
        </div>
      </div>
      <div class="side_hold px-2">
        <div class="side_1">
          <div>
            <calender [annualhoildaylist]="annualhoildaylist"></calender>
          </div>
          <div>
            <rulesAndPolicy></rulesAndPolicy>
          </div>
        </div>
        <div class="side_2">
          <daily-tasks style="height:100%"
            [ngClass]="{' mobileP0': (teamMemberOnLeave.length==0 && leaveDetailFlow.length!=0) || (leaveDetailFlow.length==0 && teamMemberOnLeave.length!=0 ),
          'p-0 pe-2 mobileP0': (leaveDetailFlow.length==0 && teamMemberOnLeave.length==0) || (teamMemberOnLeave.length!=0 && leaveDetailFlow.length!=0) }"></daily-tasks>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog ">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Employees On Leave</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
        </div>
        <div class="modal-body">
          <employeeonleave [empabsencelist]="empabsencelist"></employeeonleave>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>