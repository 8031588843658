<div class="app-content main-content">
    <div class="side-app main-container ">
        <div class="alertcomp"></div>

        <!-- <div class="card-body px-0">
            <div class="page-leftheader my-3">
                <div class="page-title">Shift Master</div>
            </div>
        </div> -->

        <div class="row">
            <div class="col-md-12">
                <div class="card-content">
                    <form #shiftform="ngForm">
                        <section>

                            <div class="card">
                                <div class="card-body">
                                    <div class="row col-md-12 col-sm-12 col-xs-12 px-0">
                                        <div class="col-md-6 col-sm-6 col-xs-6 px-5">
                                            <div class="form-group label-floating px-0">
                                                <label class="form-label">Add Shift Name </label>

                                                <div>
                                                    <input type="text" name="shift_Name"
                                                        class="form-control col-md-12 col-xs-12 field_space"
                                                        autocomplete="off" placeholder="Enter Shift Name"
                                                        autofocus="true"
                                                        [(ngModel)]="insertShiftMasterDetails.shiftName" required
                                                        #shift_Name="ngModel"
                                                        [ngClass]="(!shift_Name.valid && shift_Name.touched) ? 'is-invalid' : ''" />
                                                </div>
                                                <span class="help-text text-danger"
                                                    *ngIf="(!shift_Name.valid && shift_Name.touched)">Enter shift
                                                    Name</span>
                                            </div>
                                        </div>
                                        <div
                                            class="col-md-6 col-sm-6 col-xs-6 justify-content-between align-items-start px-0">
                                            <div class="form-group label-floating">
                                                <label class="form-label">&nbsp;</label>
                                                <button *ngIf="addOrUpdate; else updateButton" type="button"
                                                    class="btn btn-primary w-40" id="navigatetarget"
                                                    style="margin-right: 3vw;margin-left: 2vw;" (click)="addShift()">
                                                    Add
                                                </button>
                                                <ng-template #updateButton>
                                                    <button type="button" id="navigatetarget"
                                                        class="btn btn-primary w-40"
                                                        style="margin-right: 3vw;margin-left: 2vw;"
                                                        (click)="updateShift(insertShiftMasterDetails)">
                                                        Update
                                                    </button>
                                                </ng-template>
                                                <button type="button" class="btn btn-danger w-40" (click)="viewShift()">
                                                    View
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="clearfix"> </div>
                                </div>
                            </div>

                            <div class="empty-div-one" style="height: 10px;" id="navigatedest"></div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card"   style="padding-bottom:60px"  *ngIf="add_view_toggle == 'addShift' ">
                                        <div class="card-header border-bottom-0">
                                            <h3 class="card-title ">Shift Master Configuration </h3>
                                        </div>
                                        <div class="card-body">
                                            <div class="panel-group1" id="accordion1">
                                                <div class="panel panel-default mb-4 overflow-hidden br-7">
                                                    <div class="panel-heading1">
                                                        <h4 class="panel-title1">
                                                            <a class="accordion-toggle collapsed  "
                                                                data-bs-toggle="collapse" data-parent="#accordion"
                                                                href="#collapseFour" aria-expanded="false">Shift
                                                                Name</a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapseFour" class="panel-collapse collapse"
                                                        role="tabpanel" aria-expanded="false">
                                                        <div class="panel-body m-0 p-0">
                                                            <div class="card-body">
                                                                <div class="row col-md-12 col-sm-12 col-xs-12">
                                                                    <div class="row col-md-12 col-sm-12 col-xs-12">
                                                                        <div class="col-md-3 col-sm-3 col-xs-3">
                                                                            <div class="form-group label-floating">
                                                                                <label class="form-label">Shift Name
                                                                                    <span
                                                                                        style="color: rgb(241, 111, 111);">&nbsp;*</span></label>
                                                                                <div>
                                                                                    <input type="text" name="shift_Name"
                                                                                        class="form-control col-md-12 col-xs-12"
                                                                                        autocomplete="off"
                                                                                        placeholder="Enter Shift Name"
                                                                                        autofocus="true"
                                                                                        [(ngModel)]="insertShiftMasterDetails.shiftName"
                                                                                        #shift_Name="ngModel"
                                                                                        [ngClass]="(!shift_Name.valid && shift_Name.touched) ? 'is-invalid' : ''" />
                                                                                </div>
                                                                                <span class="help-text text-danger"
                                                                                    *ngIf="(!shift_Name.valid && shift_Name.touched)">Enter
                                                                                    shift Name</span>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-md-3 col-sm-3 col-xs-3">
                                                                            <div class="form-group label-floating">
                                                                                <label class="form-label">Shift From
                                                                                    <span
                                                                                        style="color: rgb(241, 111, 111);">&nbsp;*</span></label>
                                                                                <div>
                                                                                    <input type="time"
                                                                                        name="fromTimeCtrl"
                                                                                        class="form-control col-md-12 col-xs-12"
                                                                                        autocomplete="off"
                                                                                        placeholder="Enter From Time"
                                                                                        [(ngModel)]="insertShiftMasterDetails.fromTimings"
                                                                                        #fromTimeCtrl="ngModel"
                                                                                        [ngClass]="(!fromTimeCtrl.valid && fromTimeCtrl.touched) ? 'is-invalid' : ''" />
                                                                                </div>
                                                                                <span class="help-text text-danger"
                                                                                    *ngIf="(!fromTimeCtrl.valid && fromTimeCtrl.touched)">Enter
                                                                                    Shift From Time</span>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-md-3 col-sm-3 col-xs-3">
                                                                            <div class="form-group label-floating">
                                                                                <label class="form-label">Shift To <span
                                                                                        style="color: rgb(241, 111, 111);">&nbsp;*</span></label>
                                                                                <div>
                                                                                    <input type="time" name="toTimeCtrl"
                                                                                        class="form-control col-md-12 col-xs-12"
                                                                                        autocomplete="off"
                                                                                        placeholder="Enter To Time"
                                                                                        [(ngModel)]="insertShiftMasterDetails.toTimings"
                                                                                        #toTimeCtrl="ngModel"
                                                                                        [ngClass]="(!toTimeCtrl.valid && toTimeCtrl.touched) ? 'is-invalid' : ''" />
                                                                                </div>
                                                                                <span class="help-text text-danger"
                                                                                    *ngIf="(!toTimeCtrl.valid && toTimeCtrl.touched)">Enter
                                                                                    Shift To</span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-3 col-sm-3 col-xs-3">
                                                                            <div class="form-group label-floating">
                                                                                <label class="form-label">Flexible
                                                                                    Shift</label>
                                                                                <div class="d-flex align-items-center">
                                                                                    <label
                                                                                        class="form-label p-0 m-0 me-2">No</label>
                                                                                    <label class="custom-switch">
                                                                                        <input type="checkbox"
                                                                                            name="custom-switch-checkbox Flexibleshift"
                                                                                            class="custom-switch-input"
                                                                                            [(ngModel)]="insertShiftMasterDetails.flexibleShift"
                                                                                            (change)="onFlexibleShiftChange()">
                                                                                        <span
                                                                                            class="custom-switch-indicator"></span>
                                                                                    </label>
                                                                                    <label
                                                                                        class="form-label ms-2">Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="clearfix"></div>
                                                                    <div class="row col-md-12 col-sm-12 col-xs-12">
                                                                        <div class="col-md-3 col-sm-3 col-xs-3"
                                                                            *ngIf="insertShiftMasterDetails.flexibleShift">
                                                                            <div class="form-group label-floating">
                                                                                <label class="form-label">
                                                                                    In Time Cutoff <span
                                                                                        style="color: rgb(241, 111, 111);">&nbsp;*</span>
                                                                                    <i class="fa fa-info-circle"
                                                                                        data-bs-toggle="tooltip-primary"
                                                                                        title="Coming after the designated time will be marked as COMING IN LATE."></i>
                                                                                </label>
                                                                                <div>
                                                                                    <input type="time"
                                                                                        name="lateComingTime"
                                                                                        class="form-control col-md-12 col-xs-12"
                                                                                        autocomplete="off"
                                                                                        placeholder="Late Coming Time"
                                                                                        [(ngModel)]="insertShiftMasterDetails.lateComing"
                                                                                        #lateComingCtrl="ngModel"
                                                                                        [ngClass]="(!lateComingCtrl.valid && lateComingCtrl.touched) ? 'is-invalid' : ''" />
                                                                                </div>
                                                                                <span class="help-text text-danger"
                                                                                    *ngIf="(!lateComingCtrl.valid && lateComingCtrl.touched)">Enter
                                                                                    shift Name</span>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-md-3 col-sm-3 col-xs-3"
                                                                            *ngIf="insertShiftMasterDetails.flexibleShift">
                                                                            <div class="form-group label-floating">
                                                                                <label class="form-label">Out Time
                                                                                    Cutoff <span
                                                                                        style="color: rgb(241, 111, 111);">&nbsp;*</span>
                                                                                    <i class="fa fa-info-circle"
                                                                                        data-bs-toggle="tooltip-primary"
                                                                                        title="Leaving before the designated time will be marked as LEAVING EARLY ."></i>
                                                                                </label>
                                                                                <div>
                                                                                    <input type="time"
                                                                                        name="earlyLeavingTime"
                                                                                        class="form-control col-md-12 col-xs-12"
                                                                                        autocomplete="off"
                                                                                        placeholder="Early Leaving Time"
                                                                                        [(ngModel)]="insertShiftMasterDetails.earlyLeaving"
                                                                                        #earlyLeavingCtrl="ngModel"
                                                                                        [ngClass]="(!earlyLeavingCtrl.valid && earlyLeavingCtrl.touched) ? 'is-invalid' : ''" />
                                                                                </div>
                                                                                <span class="help-text text-danger"
                                                                                    *ngIf="(!earlyLeavingCtrl.valid && earlyLeavingCtrl.touched)">Enter
                                                                                    shift Name</span>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-md-3 col-sm-3 col-xs-3">
                                                                            <div class="form-group label-floating">
                                                                                <label class="form-label">Shift
                                                                                    Allowance Amount </label>
                                                                                <div>
                                                                                    <input type="number"
                                                                                        name="shiftAllowance"
                                                                                        class="form-control col-md-12 col-xs-12"
                                                                                        autocomplete="off"
                                                                                        placeholder="Shift Allowance"
                                                                                        [(ngModel)]="insertShiftMasterDetails.shiftAllowance"
                                                                                        #shiftAllowanceCtrl="ngModel"
                                                                                        [ngClass]="(!shiftAllowanceCtrl.valid && shiftAllowanceCtrl.touched) ? 'is-invalid' : ''" />
                                                                                </div>
                                                                                <span class="help-text text-danger"
                                                                                    *ngIf="(!shiftAllowanceCtrl.valid && shiftAllowanceCtrl.touched)">Enter
                                                                                    Shift Allowance</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>



                                                                </div>
                                                                <div class="clearfix"> </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="panel panel-default  mb-4 overflow-hidden br-7">
                                                    <div class="panel-heading1 ">
                                                        <h4 class="panel-title1">
                                                            <a class="accordion-toggle collapsed "
                                                                data-bs-toggle="collapse" data-parent="#accordion"
                                                                href="#collapseSix" aria-expanded="false">Over Time
                                                                Rules</a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapseSix" class="panel-collapse collapse"
                                                        role="tabpanel" aria-expanded="false">
                                                        <div class="panel-body m-0 p-0">
                                                            <div class="card-body">

                                                                <div class="card-body">
                                                                    <form>
                                                                        <div
                                                                            class="d-flex align-items-center gap-2 mb-3">
                                                                            <label
                                                                                class="form-check-label d-flex align-items-center gap-2">
                                                                                <input type="radio" name="otOption"
                                                                                    [(ngModel)]="otOption" value="time"
                                                                                    class="form-check-input"
                                                                                    (change)="resetValues()">
                                                                                OT paid if works beyond this time (in
                                                                                HH:MM)
                                                                            </label>
                                                                            <div class="col-auto p-0">
                                                                                <!-- Use col-auto for flexible width -->
                                                                                <input type="time"
                                                                                    [(ngModel)]="insertShiftMasterDetails.otStartTime"
                                                                                    [attr.disabled]="otOption !== 'time' ? true : null"
                                                                                    class="form-control"
                                                                                    style="width: 195px;"
                                                                                    placeholder="HH:MM">
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                    <form>

                                                                        <!-- OT Hours Input (HH:MM Format) -->
                                                                        <div
                                                                            class="d-flex align-items-center gap-2 mb-3">
                                                                            <label
                                                                                class="form-check-label d-flex align-items-center gap-2">
                                                                                <input type="radio" name="otOption"
                                                                                    [(ngModel)]="otOption" value="hours"
                                                                                    class="form-check-input"
                                                                                    (change)="resetValues()">
                                                                                OT paid if works beyond these many hours
                                                                            </label>
                                                                            <div class="col-auto p-0"
                                                                                style="margin-left: 17px;">
                                                                                <input type="text"
                                                                                    [attr.disabled]="otOption !== 'hours' ? true : null"
                                                                                    class="form-control" maxlength="5"
                                                                                    placeholder="00:00" required
                                                                                    pattern="^([01]\d|2[0-3]):([0-5]\d)$"
                                                                                    #overtimeHoursModel="ngModel"
                                                                                    [ngClass]="(!overtimeHoursModel.valid &&
                                                                                overtimeHoursModel.dirty &&
                                                                                overtimeHoursModel.value) ? 'is-invalid'
                                                                                : ''"
                                                                                    [(ngModel)]="insertShiftMasterDetails.otStartMins"
                                                                                    (input)="formatTimeInput($event, 'otStartMins')" />
                                                                                <div *ngIf="overtimeHoursModel.errors?.pattern && overtimeHoursModel.dirty && overtimeHoursModel.value"
                                                                                    class="invalid-feedback">
                                                                                    Please enter a valid time in HH:MM
                                                                                    format.
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                    <!-- Daily Working Hours Input (HH:MM Format) -->
                                                                    <div class="d-flex align-items-center gap-2 mb-3">
                                                                        <label class="form-label">Daily Working
                                                                            Hours (in HH:MM) </label>
                                                                        <div class="col-auto p-0"
                                                                            style="margin-left: 100px;">
                                                                            <input type="text" class="form-control"
                                                                                placeholder="00:00" required
                                                                                maxlength="5"
                                                                                pattern="^([01]\d|2[0-3]):([0-5]\d)$"
                                                                                #dailyHoursModel="ngModel" [ngClass]="(!dailyHoursModel.valid &&
                                                                          dailyHoursModel.dirty &&
                                                                          dailyHoursModel.value) ? 'is-invalid' :
                                                                          ''" [(ngModel)]="insertShiftMasterDetails.dailyWorkHrs"
                                                                                (input)="formatTimeInput($event, 'dailyWorkHrs')" />
                                                                            <div *ngIf="dailyHoursModel.errors?.pattern && dailyHoursModel.dirty && dailyHoursModel.value"
                                                                                class="invalid-feedback">
                                                                                Please enter a valid time in HH:MM
                                                                                format.
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="panel panel-default mb-4 overflow-hidden br-7">
                                                    <div class="panel-heading1 ">
                                                        <h4 class="panel-title1">
                                                            <a class="accordion-toggle collapsed "
                                                                data-bs-toggle="collapse" data-parent="#accordion"
                                                                href="#collapseThree" aria-expanded="false">Breaks</a>
                                                        </h4>
                                                    </div>

                                                    <div id="collapseThree" class="panel-collapse collapse"
                                                        role="tabpanel" aria-expanded="false">
                                                        <div class="panel-body  m-0 p-0">
                                                            <div class="card-body  m-0 p-0 ">
                                                                <div class="row col-md-12 col-sm-12 col-xs-12 m-0 p-0">
                                                                    <!-- w-100 d-flex -->
                                                                    <div class="showGrid mt-2 ms-2 mx-2">
                                                                        <div class="">
                                                                            <label for="Break Name"
                                                                                class="label_css form-label">Break
                                                                                Name</label>
                                                                        </div>
                                                                        <div class="">
                                                                            <label for="Break Window Start"
                                                                                class="label_css form-label">Break
                                                                                Window Start</label>
                                                                        </div>
                                                                        <div class="">
                                                                            <label for="Break Window End"
                                                                                class="label_css form-label">Break
                                                                                Window End</label>
                                                                        </div>
                                                                        <div class="">
                                                                            <label for="Max.Break Duration (in Mins)"
                                                                                class="label_css form-label">Max.Break
                                                                                Duration (in Mins)</label>
                                                                        </div>

                                                                        <div class="d-flex align-items-end">
                                                                            <div class="col-md-12 col-xs-12">&nbsp;
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <form #breakForm="ngForm" class="m-0 p-0">
                                                                        <section class="showGrid m-2 p-0">
                                                                            <div class="">
                                                                                <input type="text" name="Break_Name_ied"
                                                                                    class="form-control col-md-12 col-xs-12"
                                                                                    autocomplete="off"
                                                                                    placeholder="Enter Break Name"
                                                                                    autofocus="true"
                                                                                    [(ngModel)]="insertBreakDetail.breakName"
                                                                                    #breakName_ied="ngModel"
                                                                                    [ngClass]="(!breakName_ied.valid && breakName_ied?.touched) ? 'is-invalid' : ''" />
                                                                            </div>
                                                                            <div class="">
                                                                                <input type="time"
                                                                                    name="Break Window Start"
                                                                                    class="form-control col-md-12 col-xs-12"
                                                                                    autocomplete="off"
                                                                                    placeholder="Enter Break Window Start"
                                                                                    autofocus="true"
                                                                                    [(ngModel)]="insertBreakDetail.fromTimings"
                                                                                    #breakStart_ied="ngModel"
                                                                                    [ngClass]="(!breakStart_ied.valid && breakStart_ied?.touched) ? 'is-invalid' : ''" />
                                                                            </div>
                                                                            <div class="">
                                                                                <input type="time"
                                                                                    name="Break Window End"
                                                                                    class="form-control col-md-12 col-xs-12"
                                                                                    autocomplete="off"
                                                                                    placeholder="Enter Break Window End"
                                                                                    autofocus="true"
                                                                                    [(ngModel)]="insertBreakDetail.toTimings"
                                                                                    #breakEnd_ied="ngModel"
                                                                                    [ngClass]="(!breakEnd_ied.valid && breakEnd_ied?.touched) ? 'is-invalid' : ''" />
                                                                            </div>
                                                                            <div class="">
                                                                                <input type="number"
                                                                                    name="Max.Break Duration (in Mins)"
                                                                                    class="form-control col-md-12 col-xs-12"
                                                                                    autocomplete="off"
                                                                                    placeholder="Enter Max. Break Duration (in Mins)"
                                                                                    autofocus="true"
                                                                                    [(ngModel)]="insertBreakDetail.breakMins"
                                                                                    #maxBreakDur_ied="ngModel"
                                                                                    [ngClass]="(!maxBreakDur_ied.valid && maxBreakDur_ied?.touched) ? 'is-invalid' : ''"
                                                                                    min="0" />
                                                                            </div>

                                                                            <div class="d-flex align-items-end">
                                                                                <button type="button"
                                                                                    (click)="addBreak()"
                                                                                    class="btn btn_fit mb-2"><span><svg
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                            width="16" height="16"
                                                                                            fill="currentColor"
                                                                                            class="bi bi-plus-lg"
                                                                                            viewBox="0 0 16 16">
                                                                                            <path fill-rule="evenodd"
                                                                                                d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2" />
                                                                                        </svg>
                                                                                    </span>
                                                                                </button>
                                                                            </div>
                                                                        </section>
                                                                    </form>

                                                                    <ng-container
                                                                        *ngFor="let ed of shiftRuleDetail;let i = index;">
                                                                        <ng-container *ngIf="ed.userId!='Del'">
                                                                            <div class="showGrid m-0 p-0">
                                                                                <div class="ms-2 disable w-100">
                                                                                    <input type="text"
                                                                                        name="Break_Name_erd{{i}}"
                                                                                        class="form-control col-md-12 col-xs-12"
                                                                                        autocomplete="off"
                                                                                        placeholder="Enter Break Name"
                                                                                        autofocus="true"
                                                                                        [(ngModel)]="ed.breakName" />
                                                                                </div>
                                                                                <div class="ms-2 disable w-100">
                                                                                    <input type="time"
                                                                                        name="Break_Window_Start_erd{{i}}"
                                                                                        class="form-control col-md-12 col-xs-12"
                                                                                        autocomplete="off"
                                                                                        placeholder="Enter Break Window Start"
                                                                                        autofocus="true"
                                                                                        [(ngModel)]="ed.fromTimings"
                                                                                        required />
                                                                                </div>
                                                                                <div class="ms-2 disable w-100">
                                                                                    <input type="time"
                                                                                        name="Break_Window_End_erd{{i}}"
                                                                                        class="form-control col-md-12 col-xs-12"
                                                                                        autocomplete="off"
                                                                                        placeholder="Enter Break Window End"
                                                                                        autofocus="true"
                                                                                        [(ngModel)]="ed.toTimings"
                                                                                        required />
                                                                                </div>
                                                                                <div class="ms-2 disable w-100">
                                                                                    <input type="number"
                                                                                        name="Max_Break_Duration_erd{{i}}"
                                                                                        class="form-control col-md-12 col-xs-12"
                                                                                        autocomplete="off"
                                                                                        placeholder="Enter Max Break Duration (in Mins)"
                                                                                        autofocus="true"
                                                                                        [(ngModel)]="ed.breakMins"
                                                                                        required min="0" />
                                                                                </div>

                                                                                <div
                                                                                    class="ms-2 d-flex align-items-end">
                                                                                    <button type="button"
                                                                                        (click)="deleteBreak(i)"
                                                                                        class="btn btn_fit mb-2"><span><svg
                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                                width="16" height="16"
                                                                                                fill="red"
                                                                                                class="bi bi-trash"
                                                                                                viewBox="0 0 16 16">
                                                                                                <path
                                                                                                    d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                                                                                                <path
                                                                                                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                                                                                            </svg>
                                                                                        </span>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </div>

                                                                <div class="clearfix"> </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="panel panel-default mb-4 overflow-hidden br-7">
                                                    <div class="panel-heading1">
                                                        <h4 class="panel-title1">
                                                            <a class="accordion-toggle collapsed "
                                                                data-bs-toggle="collapse" data-parent="#accordion"
                                                                href="#collapseTwo" aria-expanded="false">Days</a>
                                                        </h4>
                                                    </div>

                                                    <div id="collapseTwo" class="panel-collapse collapse"
                                                        role="tabpanel" aria-expanded="false">
                                                        <div class="panel-body m-0 p-0">
                                                            <div class="card-body ">
                                                                <form #daysForm="ngForm" class="m-0 p-0">
                                                                    <table
                                                                        class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                                                        id="hr-table">
                                                                        <thead class="tablerow">
                                                                            <tr class="rowcolors stickyatTop">
                                                                                <th>Day</th>
                                                                                <th>Half Day (HH:MM)</th>
                                                                                <th>Full Day (HH:MM)</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    <label for="sunday"
                                                                                        class="form-label">Sunday</label>
                                                                                </td>

                                                                                <!-- Half Day Input -->
                                                                                <td>
                                                                                    <input type="text" name="sunHalfDay"
                                                                                        maxlength="5"
                                                                                        class="form-control"
                                                                                        autocomplete="off"
                                                                                        placeholder="00:00"
                                                                                        [(ngModel)]="insertShiftMasterDetails.sunHalfDayMins"
                                                                                        required
                                                                                        #sunHalfDayCtrl="ngModel"
                                                                                        [ngClass]="(!sunHalfDayCtrl.valid && sunHalfDayCtrl.touched) ? 'is-invalid' : ''"
                                                                                        pattern="^[0-2][0-9]:[0-5][0-9]$" />
                                                                                    <span
                                                                                        *ngIf="!sunHalfDayCtrl.valid && sunHalfDayCtrl.touched"
                                                                                        class="invalid-feedback">
                                                                                        Enter time in HH:MM format
                                                                                    </span>
                                                                                </td>

                                                                                <!-- Full Day Input -->
                                                                                <td>
                                                                                    <input type="text" name="sunFullDay"
                                                                                        maxlength="5"
                                                                                        class="form-control"
                                                                                        autocomplete="off"
                                                                                        placeholder="00:00"
                                                                                        [(ngModel)]="insertShiftMasterDetails.sunFullDayMins"
                                                                                        required
                                                                                        #sunFullDayCtrl="ngModel"
                                                                                        [ngClass]="(!sunFullDayCtrl.valid && sunFullDayCtrl.touched) ? 'is-invalid' : ''"
                                                                                        pattern="^[0-2][0-9]:[0-5][0-9]$" />
                                                                                    <span
                                                                                        *ngIf="!sunFullDayCtrl.valid && sunFullDayCtrl.touched"
                                                                                        class="invalid-feedback">
                                                                                        Enter time in HH:MM format
                                                                                    </span>
                                                                                </td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td>
                                                                                    <label for="monday"
                                                                                        class="form-label">Monday</label>
                                                                                </td>

                                                                                <!-- Half Day Input -->
                                                                                <td>
                                                                                    <input type="text" name="monHalfDay"
                                                                                        maxlength="5"
                                                                                        class="form-control"
                                                                                        autocomplete="off"
                                                                                        placeholder="00:00"
                                                                                        [(ngModel)]="insertShiftMasterDetails.monHalfDayMins"
                                                                                        required
                                                                                        #monHalfDayCtrl="ngModel"
                                                                                        [ngClass]="(!monHalfDayCtrl.valid && monHalfDayCtrl.touched) ? 'is-invalid' : ''"
                                                                                        pattern="^[0-2][0-9]:[0-5][0-9]$" />
                                                                                    <span
                                                                                        *ngIf="!monHalfDayCtrl.valid && monHalfDayCtrl.touched"
                                                                                        class="invalid-feedback">
                                                                                        Enter time in HH:MM format
                                                                                    </span>
                                                                                </td>

                                                                                <!-- Full Day Input -->
                                                                                <td>
                                                                                    <input type="text" name="monFullDay"
                                                                                        maxlength="5"
                                                                                        class="form-control"
                                                                                        autocomplete="off"
                                                                                        placeholder="00:00"
                                                                                        [(ngModel)]="insertShiftMasterDetails.monFullDayMins"
                                                                                        required
                                                                                        #monFullDayCtrl="ngModel"
                                                                                        [ngClass]="(!monFullDayCtrl.valid && monFullDayCtrl.touched) ? 'is-invalid' : ''"
                                                                                        pattern="^[0-2][0-9]:[0-5][0-9]$" />
                                                                                    <span
                                                                                        *ngIf="!monFullDayCtrl.valid && monFullDayCtrl.touched"
                                                                                        class="invalid-feedback">
                                                                                        Enter time in HH:MM format
                                                                                    </span>
                                                                                </td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td>
                                                                                    <label for="tuesday"
                                                                                        class="form-label">Tuesday</label>
                                                                                </td>

                                                                                <!-- Half Day Input -->
                                                                                <td>
                                                                                    <input type="text" name="tueHalfDay"
                                                                                        maxlength="5"
                                                                                        class="form-control"
                                                                                        autocomplete="off"
                                                                                        placeholder="00:00"
                                                                                        [(ngModel)]="insertShiftMasterDetails.tueHalfDayMins"
                                                                                        required
                                                                                        #tueHalfDayCtrl="ngModel"
                                                                                        [ngClass]="(!tueHalfDayCtrl.valid && tueHalfDayCtrl.touched) ? 'is-invalid' : ''"
                                                                                        pattern="^[0-2][0-9]:[0-5][0-9]$" />
                                                                                    <span
                                                                                        *ngIf="!tueHalfDayCtrl.valid && tueHalfDayCtrl.touched"
                                                                                        class="invalid-feedback">
                                                                                        Enter time in HH:MM format
                                                                                    </span>
                                                                                </td>

                                                                                <!-- Full Day Input -->
                                                                                <td>
                                                                                    <input type="text"
                                                                                        name="tueHalfDayCtrl"
                                                                                        maxlength="5"
                                                                                        class="form-control"
                                                                                        autocomplete="off"
                                                                                        placeholder="00:00"
                                                                                        [(ngModel)]="insertShiftMasterDetails.tueFullDayMins"
                                                                                        required
                                                                                        #tueFullDayCtrl="ngModel"
                                                                                        [ngClass]="(!tueFullDayCtrl.valid && tueFullDayCtrl.touched) ? 'is-invalid' : ''"
                                                                                        pattern="^[0-2][0-9]:[0-5][0-9]$" />
                                                                                    <span
                                                                                        *ngIf="!tueFullDayCtrl.valid && tueFullDayCtrl.touched"
                                                                                        class="invalid-feedback">
                                                                                        Enter time in HH:MM format
                                                                                    </span>
                                                                                </td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td>
                                                                                    <label for="wednesday"
                                                                                        class="form-label">Wednesday</label>
                                                                                </td>

                                                                                <!-- Half Day Input -->
                                                                                <td>
                                                                                    <input type="text" name="wedHalfDay"
                                                                                        maxlength="5"
                                                                                        class="form-control"
                                                                                        autocomplete="off"
                                                                                        placeholder="00:00"
                                                                                        [(ngModel)]="insertShiftMasterDetails.wedHalfDayMins"
                                                                                        required
                                                                                        #wedHalfDayCtrl="ngModel"
                                                                                        [ngClass]="(!wedHalfDayCtrl.valid && wedHalfDayCtrl.touched) ? 'is-invalid' : ''"
                                                                                        pattern="^[0-2][0-9]:[0-5][0-9]$" />
                                                                                    <span
                                                                                        *ngIf="!wedHalfDayCtrl.valid && wedHalfDayCtrl.touched"
                                                                                        class="invalid-feedback">
                                                                                        Enter time in HH:MM format
                                                                                    </span>
                                                                                </td>

                                                                                <!-- Full Day Input -->
                                                                                <td>
                                                                                    <input type="text" name="wedFullDay"
                                                                                        maxlength="5"
                                                                                        class="form-control"
                                                                                        autocomplete="off"
                                                                                        placeholder="00:00"
                                                                                        [(ngModel)]="insertShiftMasterDetails.wedFullDayMins"
                                                                                        required
                                                                                        #wedFullDayCtrl="ngModel"
                                                                                        [ngClass]="(!wedFullDayCtrl.valid && wedFullDayCtrl.touched) ? 'is-invalid' : ''"
                                                                                        pattern="^[0-2][0-9]:[0-5][0-9]$" />
                                                                                    <span
                                                                                        *ngIf="!wedFullDayCtrl.valid && wedFullDayCtrl.touched"
                                                                                        class="invalid-feedback">
                                                                                        Enter time in HH:MM format
                                                                                    </span>
                                                                                </td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td>
                                                                                    <label for="thursday"
                                                                                        class="form-label">Thursday</label>
                                                                                </td>

                                                                                <!-- Half Day Input -->
                                                                                <td>
                                                                                    <input type="text" name="thuHalfDay"
                                                                                        maxlength="5"
                                                                                        class="form-control"
                                                                                        autocomplete="off"
                                                                                        placeholder="00:00"
                                                                                        [(ngModel)]="insertShiftMasterDetails.thuHalfDayMins"
                                                                                        required
                                                                                        #thuHalfDayCtrl="ngModel"
                                                                                        [ngClass]="(!thuHalfDayCtrl.valid && thuHalfDayCtrl.touched) ? 'is-invalid' : ''"
                                                                                        pattern="^[0-2][0-9]:[0-5][0-9]$" />
                                                                                    <span
                                                                                        *ngIf="!thuHalfDayCtrl.valid && thuHalfDayCtrl.touched"
                                                                                        class="invalid-feedback">
                                                                                        Enter time in HH:MM format
                                                                                    </span>
                                                                                </td>

                                                                                <!-- Full Day Input -->
                                                                                <td>
                                                                                    <input type="text" name="thuFullDay"
                                                                                        maxlength="5"
                                                                                        class="form-control"
                                                                                        autocomplete="off"
                                                                                        placeholder="00:00"
                                                                                        [(ngModel)]="insertShiftMasterDetails.thuFullDayMins"
                                                                                        required
                                                                                        #thuFullDayCtrl="ngModel"
                                                                                        [ngClass]="(!thuFullDayCtrl.valid && thuFullDayCtrl.touched) ? 'is-invalid' : ''"
                                                                                        pattern="^[0-2][0-9]:[0-5][0-9]$" />
                                                                                    <span
                                                                                        *ngIf="!thuFullDayCtrl.valid && thuFullDayCtrl.touched"
                                                                                        class="invalid-feedback">
                                                                                        Enter time in HH:MM format
                                                                                    </span>
                                                                                </td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td>
                                                                                    <label for="friday"
                                                                                        class="form-label">Friday</label>
                                                                                </td>

                                                                                <!-- Half Day Input -->
                                                                                <td>
                                                                                    <input type="text" name="friHalfDay"
                                                                                        maxlength="5"
                                                                                        class="form-control"
                                                                                        autocomplete="off"
                                                                                        placeholder="00:00"
                                                                                        [(ngModel)]="insertShiftMasterDetails.friHalfDayMins"
                                                                                        required
                                                                                        #friHalfDayCtrl="ngModel"
                                                                                        [ngClass]="(!friHalfDayCtrl.valid && friHalfDayCtrl.touched) ? 'is-invalid' : ''"
                                                                                        pattern="^[0-2][0-9]:[0-5][0-9]$" />
                                                                                    <span
                                                                                        *ngIf="!friHalfDayCtrl.valid && friHalfDayCtrl.touched"
                                                                                        class="invalid-feedback">
                                                                                        Enter time in HH:MM format
                                                                                    </span>
                                                                                </td>

                                                                                <!-- Full Day Input -->
                                                                                <td>
                                                                                    <input type="text" name="friFullDay"
                                                                                        maxlength="5"
                                                                                        class="form-control"
                                                                                        autocomplete="off"
                                                                                        placeholder="00:00"
                                                                                        [(ngModel)]="insertShiftMasterDetails.friFullDayMins"
                                                                                        required
                                                                                        #friFullDayCtrl="ngModel"
                                                                                        [ngClass]="(!friFullDayCtrl.valid && friFullDayCtrl.touched) ? 'is-invalid' : ''"
                                                                                        pattern="^[0-2][0-9]:[0-5][0-9]$" />
                                                                                    <span
                                                                                        *ngIf="!friFullDayCtrl.valid && friFullDayCtrl.touched"
                                                                                        class="invalid-feedback">
                                                                                        Enter time in HH:MM format
                                                                                    </span>
                                                                                </td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td>
                                                                                    <label for="saturday"
                                                                                        class="form-label">Saturday</label>
                                                                                </td>

                                                                                <!-- Half Day Input -->
                                                                                <td>
                                                                                    <input type="text" name="satHalfDay"
                                                                                        maxlength="5"
                                                                                        class="form-control"
                                                                                        autocomplete="off"
                                                                                        placeholder="00:00"
                                                                                        [(ngModel)]="insertShiftMasterDetails.satHalfDayMins"
                                                                                        required
                                                                                        #satHalfDayCtrl="ngModel"
                                                                                        [ngClass]="(!satHalfDayCtrl.valid && satHalfDayCtrl.touched) ? 'is-invalid' : ''"
                                                                                        pattern="^[0-2][0-9]:[0-5][0-9]$" />
                                                                                    <span
                                                                                        *ngIf="!satHalfDayCtrl.valid && satHalfDayCtrl.touched"
                                                                                        class="invalid-feedback">
                                                                                        Enter time in HH:MM format
                                                                                    </span>
                                                                                </td>

                                                                                <!-- Full Day Input -->
                                                                                <td>
                                                                                    <input type="text" name="satFullDay"
                                                                                        maxlength="5"
                                                                                        class="form-control"
                                                                                        autocomplete="off"
                                                                                        placeholder="00:00"
                                                                                        [(ngModel)]="insertShiftMasterDetails.satFullDayMins"
                                                                                        required
                                                                                        #satFullDayCtrl="ngModel"
                                                                                        [ngClass]="(!satFullDayCtrl.valid && satFullDayCtrl.touched) ? 'is-invalid' : ''"
                                                                                        pattern="^[0-2][0-9]:[0-5][0-9]$" />
                                                                                    <span
                                                                                        *ngIf="!satFullDayCtrl.valid && satFullDayCtrl.touched"
                                                                                        class="invalid-feedback">
                                                                                        Enter time in HH:MM format
                                                                                    </span>
                                                                                </td>
                                                                            </tr>


                                                                        </tbody>
                                                                    </table>
                                                                </form>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>


                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="actionBar pe-4 ps-4" *ngIf="addOrUpdate && add_view_toggle === 'addShift'">
                                <div class="clearfix"> </div>
                                <button class="btn btn-success" type="submit" (click)="submit()">Save</button>
                                <button class="btn btn-danger ms-2" type="button" (click)="clear()">Clear</button>
                            </div>


                            <div class="row card-list-margin">
                                <div class="col-md-12 card-list1-margin" style="padding-bottom: 60px">
                                    <div class="card col-md-12 col-sm-12 col-xs-12"
                                        *ngIf="add_view_toggle == 'viewShift' ">
                                        <div class="card-header border-bottom-0">
                                            <h3 class="card-title">List Of Shift Type</h3>
                                        </div>
                                        <div class="card-body card1S p-0">
                                            <div class="table-responsive">
                                                <div class="scrollsearchresults" id="scroll" infiniteScroll
                                                    [infiniteScrollDistance]="2" [infiniteScrollUpDistance]="1.5"
                                                    [infiniteScrollThrottle]="100" [scrollWindow]="false"
                                                    id="scrolllength">
                                                    <table
                                                        class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                                        id="hr-table">
                                                        <thead class="tablerow">
                                                            <tr class="rowcolors stickyatTop">
                                                                <th class="border-bottom-0 w-5 fs-6 ">Sl.No</th>
                                                                <th class="border-bottom-0 w-5 fs-6 ">shift Name</th>
                                                                <th class="border-bottom-0 w-5 fs-6 ">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody style="max-height: 50vh; overflow: scroll;">
                                                            <tr
                                                                *ngFor="let shiftlist of ShiftMasterData; let i = index">
                                                                <td>{{i+1}}</td>
                                                                <td>{{shiftlist.shiftName}}</td>
                                                                <td>
                                                                    <button class="btn btn-primary btn-icon btn-sm me-2"
                                                                        data-method="edit" title="Edit"
                                                                        (click)="editShift(shiftlist.shiftid)">
                                                                        <i class="fa fa-edit" data-bs-toggle="tooltip"
                                                                            data-original-title="edit"></i>
                                                                    </button>
                                                                    <button class="btn btn-danger btn-icon btn-sm me-2"
                                                                        data-method="delete" title="Delete"
                                                                        (click)="onDeleteHandler(shiftlist)"
                                                                        data-bs-target="#showAlertWarning">
                                                                        <i class="fa fa-trash" data-bs-toggle="tooltip"
                                                                            data-original-title="delete"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </tbody>

                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </section>
                    </form>
                </div>
            </div>
        </div>

        <!-- make change here  -->
    </div>
</div>
<div id="showAlertVer" class="modal" style="top: 40px;">
    <div class="modal-dialog">
        <div class="modal-content animate">
            <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">×</button>
                <h4 class="modal-title">Warning</h4>
            </div>

            <div class="modal-body" style="max-height: calc(100vh - 210px);overflow-y: auto;">
                <!-- auto scroll -->
                <span><b> Shift Master data will be deleted permanently. Are you sure you want to continue?</b></span>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-danger medium" (click)="deleteShift()">OK</button>
                <button type="button" class="btn btn-primary" (click)="closeMRQModal()">Cancel</button>
            </div>

        </div>
    </div>
</div>