import { Component, Input, OnInit } from '@angular/core';
import { EventCalendar } from 'src/app/models/EventCalendar';
import { ConstantService, SessionService } from 'src/app/services';
import { DashboardService } from 'src/app/services/dashboard.service';
import { Menu } from 'src/app/services/menu.Service';


@Component({
  selector: 'app-dashboard-event-calendar',
  templateUrl: './dashboard-event-calendar.component.html',
  styleUrls: ['./dashboard-event-calendar.component.css']
})
export class DashboardEventCalendarComponent implements OnInit {
  @Input() eventDayList:EventCalendar[] = [];
  constructor(private dashboardService: DashboardService, private menuService: Menu ,  private sessionService: SessionService,
      private constantService: ConstantService
  ){}

  ngOnInit(): void {
  }
  setMenuName(name: string) {
    this.menuService.setMenuName(name);
  }
  getUserRole() {
   return this.sessionService.getLoggedUserRole();
  }
  navigateEventCalendar() {
    localStorage.setItem('navigateDashboard', 'true')
  }

  isPresent(permissionKey:string){
    debugger;
    var rolePermission: any[] = this.constantService.getRolePermissions();
    var allowedPermissionList: any[] = this.sessionService.getRolePermissionList();
    
    if (!allowedPermissionList.includes(rolePermission[`${permissionKey}`])) {
       return false
    } else {
         return true
    }
}
}


