import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-resignation',
  templateUrl: './resignation.component.html',
  styleUrls: ['./resignation.component.css']
})
export class ResignationComponent implements OnInit {
  noticePeriod: number = 0;

  constructor() { }

  ngOnInit(): void {
  }
  calculateNoticePeriod(event: Event): void {
    const input = event.target as HTMLInputElement;
    const selectedDate = new Date(input.value); // Get the selected date
    const currentDate = new Date(); // Get the current date
    if (selectedDate > currentDate) {
      // Calculate the difference in days
      const differenceInTime = selectedDate.getTime() - currentDate.getTime();
      this.noticePeriod = Math.ceil(differenceInTime / (1000 * 3600 * 24)); // Convert milliseconds to days
    } else {
      this.noticePeriod = 0; // Reset if the selected date is in the past or today
    }
  }



}
