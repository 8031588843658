import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { SalaryAdvanceService } from '../services/salary-advance.service';
import { SessionService } from '../services';
import { NgForm } from '@angular/forms';
import { salaryAdvance } from '../models/salaryAdvance';
import { Menu } from '../services/menu.Service';
import { EmployeeMaster } from '../models/employeeMasterts';
import { EmployeeLeaveService } from '../services/employee-leave.service';

declare var $: any;

@Component({
  selector: 'app-salary-advance-approve',
  templateUrl: './salary-advance-approve.component.html',
  styleUrls: ['./salary-advance-approve.component.css']
})
export class SalaryAdvanceApproveComponent implements AfterViewInit {

  permissionList: any[] = [];
  allowToViewReport: boolean = false;
  constructor(private empLeaveService: EmployeeLeaveService,private salaryAdvanceService: SalaryAdvanceService, private sessionservice: SessionService, private menuService: Menu) { }

  @ViewChild('salaryadvanceform') salaryAdvanceForm: NgForm;
  @ViewChild('salaryadvanceListform') salaryadvanceListform: NgForm;
  ngOnInit(): void {

    this.getSalaryLoanSettings();
    this.getSalaryAdvanceSettings();
    this.getSalaryMaster();
    this.isSearch = false;
    this.statusFilter = "PENDING";
    this.typeFilter = "A";
    this.userId = Number(this.sessionservice.getempid());
    // this.getSalaryAdvanceInterestRate();

    const maxString = sessionStorage.getItem('permission').replace('[', '');
    const maxStringBox = maxString.replace(']', '');
    const maxStringAgain = maxStringBox.replace(/"/g, '');
    this.permissionList = maxStringAgain.split(',');
    this.checkAccessForUser();

    this.limitPara = 12;
    this.offsetPara = 0;
  }

  ngAfterViewInit() {
    this.clearForm();
  }


  searchattendance: string;
  salaryAdvanceList: any[] = [];
  filteredSalaryAdvanceList: any[] = [];
  tempsalaryAdvanceList: any[] = [];
  selectedList: any[] = [];
  salaryMaster: any[] = [];
  // installmentComp:installmentComp[] = [];
  installmentComp = [];

  amount: number;
  totalAmount: number;
  totalAmountToPay: number;
  noOfInstallment: number;

  expenseType: any[] = [];

  // salaryAdvance = salaryAdvance;

  date_of_loan: any;
  Ins_StartDate: any;
  model: any = {};
  isEdit: boolean = false;
  submitted: boolean;
  salaryAdvanceData = new salaryAdvance();
  salaryHeadId: any;
  salaryDetailId: any;
  interestRate: number;
  detailMasterSection: any;
  query: any;
  checkheader: boolean = false;
  checkDetail: boolean = false;
  dateError: boolean = false;
  isOnlyView: boolean = false;
  isVerify: boolean = false;
  fieldChanged: boolean = false;
  isSearch: boolean = false;
  reason: String;
  statusFilter: any;
  typeFilter: any;
  currentStatus: any;
  currentType: any;

  //Salary Loan Setting
  interestRateForLoan: any;
  maxAmtForLoan: number;
  salaryDetailIdForloan: any;
  salaryHeadIdForloan: any;


  //Salary Advance Setting
  interestRateForAdvance: any;
  maxAmtForAdvance: number;
  salaryDetailIdForAdvance: any;
  salaryHeadIdForAdvance: any;
  ActiveInativeEmpStatusFilter: string = 'Active';
  leavingdate: any;
  uniqueemployeeid: String;
  employeeMasterLucene: EmployeeMaster[];
  selectedEmpList: EmployeeMaster[];
  showBasedOnPermissionEmployeeStatus: boolean = false;
  userId: any;
  employeeid: string;
  employeename: string;
  limitPara: any;
  offsetPara: any;



  getEmployeeByNameOrId(value:any) {
    if(value && value != null){
      const [name, id] = value.split('/');
      this.employeename = name;

      if (this.employeename && this.employeeMasterLucene) {
        this.selectedEmpList = this.employeeMasterLucene.filter((dName) => {
          if(dName.name === this.employeename){
            console.log("dName ",dName);
            return dName;
          }
        });
        if(this.selectedEmpList[0]){
          this.employeeid = this.selectedEmpList[0].uniqueemployeeid.toString();
        }
      
      }
  
    }else{
      this.employeeid = "All";
    }

 
  
    // Ensure the query length is valid (either <= 2 or >= 3)
    if (this.query?.length <= 2 || this.query?.length >= 3) {
      this.empLeaveService.getEmployeeByNameOrUniqueId(this.query).subscribe(
        data => {
  
          // Check if data.employeeMasterData has any entries
          if (data.employeeMasterData.length > 0) {
            let filteredEmployees = data.employeeMasterData;
  
            // Get the current date for comparison
            const currentDate = new Date();
  
            // Apply filtering logic based on the selected employee status
            if (this.ActiveInativeEmpStatusFilter === 'Active') {
              filteredEmployees = filteredEmployees.filter(emp => {
                const leavingDate = emp.leavingdateString ? new Date(emp.leavingdateString) : null;
                console.log("Leaving Date for " + emp.name + ": ", leavingDate);  // Log leaving date for debugging
                // Active employees should have leavingdate in the future or null
                return leavingDate === null || leavingDate > currentDate;
              });
            } else if (this.ActiveInativeEmpStatusFilter === 'Inactive') {
              filteredEmployees = filteredEmployees.filter(emp => {
                const leavingDate = emp.leavingdateString ? new Date(emp.leavingdateString) : null;
                console.log("Leaving Date for " + emp.name + ": ", leavingDate);  // Log leaving date for debugging
                // Inactive employees should have leavingdate in the past or null
                return leavingDate && leavingDate < currentDate;
              });
            }
  
            // If the status is 'All', no filter is applied
            if (this.ActiveInativeEmpStatusFilter === 'All') {
              filteredEmployees = data.employeeMasterData;
            }
  
            // Assign filtered data to the employeeMasterLucene
            this.employeeMasterLucene = filteredEmployees;
  
            // Log the data and leaving date of the first employee in the filtered list
            if (this.employeeMasterLucene.length > 0) {
              this.leavingdate = this.employeeMasterLucene[0].leavingdateString; // Extract the leaving date
            } else {
              console.log("No employee data found.");
            }
          } else {
            console.log("No employee data found.");
          }
        },
        error => {
          console.log("Error ", error);
        }
      );
    }
  
    // This line will still be executed before the async request completes,
    // so you may not have a value for 'this.leavingdate' here.
  }
  
  onluceneStatusChange() {
    this.getEmployeeByNameOrId(this.query);
  }
  getAllAdvApplies() {
    let uniqueEmpId = this.sessionservice.getempid();
    this.salaryAdvanceService.getAllAdvApplies(this.typeFilter, this.statusFilter,this.employeeid,this.limitPara , this.offsetPara).subscribe(
      data => {
        // this.salaryAdvanceList = data.salaryAdvanceList;
        this.tempsalaryAdvanceList = data.salaryAdvanceList;
        console.log("this.salaryAdvanceList 222 : ", this.salaryAdvanceList);
        console.log("this.tempsalaryAdvanceList 222 : ", this.tempsalaryAdvanceList);
        if (this.salaryAdvanceList.length > 0) {
          this.salaryAdvanceList = this.salaryAdvanceList.concat(this.tempsalaryAdvanceList);
        } else {
          this.salaryAdvanceList = this.tempsalaryAdvanceList;
        }

        this.filterByName();
        console.log("this.salaryAdvanceList 3333 : ", this.salaryAdvanceList);
      }, error => {

      });
  }

  getSalaryLoanSettings() {
    this.salaryAdvanceService.getSalaryLoanSettings().subscribe(
      data => {
        this.interestRateForLoan = data.interestRateForLoan;
        this.salaryHeadIdForloan = data.salaryHeadIdForloan;
        this.salaryDetailIdForloan = data.salaryDetailIdForloan;
      }, error => {
        console.log(error);
      }
    );
  }

  getSalaryAdvanceSettings() {
    this.salaryAdvanceService.getSalaryAdvanceSettings().subscribe(
      data => {
        this.interestRateForAdvance = data.interestRateForAdvance;
        this.salaryHeadIdForAdvance = data.salaryHeadIdForAdvance;
        this.salaryDetailIdForAdvance = data.salaryDetailIdForAdvance;
      }, error => {
        console.log(error);
      }
    );

  }
  onStatusChange(event: any) {
    this.salaryAdvanceList = [];
    if (this.typeFilter === "L") {
      this.isVerify = true;
    } else {
      this.isVerify = false;
    }
  }


  search() {
    this.offsetPara = 0;
    this.currentType = this.typeFilter;
    this.salaryAdvanceList = [];
    this.getAllAdvApplies();
    console.log("this.isSearch",this.isSearch)

    $('html,body').animate({
      scrollTop: $(`#navigatedest`).offset().top - 90
    },
      'slow');

  }

  filterByName() {
   
    if (this.salaryAdvanceList.length > 0) {
      this.isSearch = true;
    } else {
      this.isSearch = false;
    }
  }

  validateDates() {
    if (this.date_of_loan && this.Ins_StartDate) {
      const loanDate = new Date(this.date_of_loan);
      const startDate = new Date(this.Ins_StartDate);

      if (loanDate >= startDate) {
        this.dateError = true; // Flag for error handling
      } else {
        this.dateError = false;
      }
    }
    if (this.dateError) {
      return false;
    }
    return true;
  }


  checkallbox(event: any) {
    this.salaryAdvanceList.forEach((exp) => {
      if (exp.status === 'PENDING') {
        exp.checked = event.target.checked;

        // Add or remove from selectedList based on checked status
        if (exp.checked) {
          this.addToSelectedList(exp);
        } else {
          this.removeFromSelectedList(exp);
        }
      }

    });
  }

  check_info(sal: any, event: any): void {
    sal.checked = event.target.checked;
    if (sal.checked) {
      this.addToSelectedList(sal);
    } else {
      this.removeFromSelectedList(sal);
    }
  }

  addToSelectedList(exp: any): void {
    // Check if the item is not already in the selectedList before adding
    if (!this.selectedList.includes(exp)) {
      this.selectedList.push(exp);
    }
  }

  removeFromSelectedList(exp: any): void {
    // Remove the item from the selectedList if it exists
    this.selectedList = this.selectedList.filter((item) => item !== exp);
  }

  approvalhandler() {
    
    let flag = true;
    this.checkheader = false;
    if (this.selectedList.length === 0) {
      this.alertmessages("Please select record for Approval.", "danger")

      flag = false
      return;
    }

    this.selectedList.forEach(t => {

    })
 
    this.offsetPara = 0;
    this.salaryAdvanceService.approveList(this.selectedList, this.typeFilter, this.statusFilter, this.userId,this.limitPara , this.offsetPara).subscribe(
      data => {
        console.log("Saved data : ", data)
        this.tempsalaryAdvanceList = data.salaryAdvanceList;
        this.salaryAdvanceList = data.salaryAdvanceList;
        // this.search();
        this.selectedList = [];
        // const scrollContainer = document.getElementById('scrolllength');
        // if (scrollContainer) {
        //   scrollContainer.scrollTop = 0; // Moves the scroll thumb to the top
        // }
        // this.clear();
        // this.clearForm();
        this.alertmessages("Successfully Approved ", "success");
      }, error => {
        console.log(error);
        this.alertmessages("Please try again later", "danger");

      }

    );

    // this.getAllAdvApplies();

  }

  rejecthandler() {
    let flag = false;
    if (this.selectedList.length === 0) {
      this.alertmessages("Please select record for Approval.", "danger");

      flag = true;
      return;
    }

    this.selectedList.forEach(t => {
      if (t.remarks === undefined || t.remarks === "") {
        flag = true;

      }
    })

    if (flag) {
      this.alertmessages("Comments for rejection is mandatory.", "danger");
      return;
    }

    this.offsetPara = 0;
    this.salaryAdvanceService.rejectList(this.selectedList, this.typeFilter, this.statusFilter, this.userId,this.limitPara , this.offsetPara).subscribe(
      data => {
        this.tempsalaryAdvanceList = data.salaryAdvanceList;
        // this.search();
        this.salaryAdvanceList = data.salaryAdvanceList;
        this.selectedList = [];
        this.alertmessages("Successfully Rejected ", "success");
      }, error => {
        console.log(error);
        this.alertmessages("Please try again later", "danger");

      }
    );
  }

  MarkAsPaidhandler() {
    let flag = false;
    if (this.selectedList.length === 0) {
      this.alertmessages("Please select record for Disbursment.", "danger");

      flag = true;
      return;
    }

    this.offsetPara = 0;
    this.salaryAdvanceService.PaidList(this.selectedList, this.typeFilter, this.statusFilter, this.userId,this.limitPara , this.offsetPara).subscribe(
      data => {
        this.tempsalaryAdvanceList = data.salaryAdvanceList;
        this.salaryAdvanceList = data.salaryAdvanceList;
        this.selectedList = [];
        this.alertmessages("Marked as Disbursed successfully ", "success");
      }, error => {
        console.log(error);
        this.alertmessages("Please try again later", "danger");

      }
    );
  }

  editcalcInstallment() {
    this.submitted = true;


    if (this.noOfInstallment > 0 && this.totalAmount > 0 && this.date_of_loan && this.Ins_StartDate) {

      this.setSalaryAdvanceModel();
      this.calcTotalAmountPay();
      this.installmentComp = this.model.installmentComp;
    }
    if (this.fieldChanged) {
      this.setInstallmentComp();
    }
    for (let i = 0; i < this.installmentComp.length; i++) {
      const installment = this.installmentComp[i];
      installment.isUpdated = false;
    }
  }

  calcTotalAmountPay() {
    let totalInstallments = this.noOfInstallment;
    // Calculate installment amount
    // let installAmt = (this.totalAmount / totalInstallments).toFixed(2);
    let interest: number = (this.totalAmount * totalInstallments * this.interestRate) / (100 * 12);
    this.totalAmountToPay = Number(this.totalAmount) + Number(interest);
  }

  onfieldChange() {

    if (this.isEdit && this.noOfInstallment > 0 && this.totalAmount > 0 && this.date_of_loan && this.Ins_StartDate) {
      this.fieldChanged = true;
    }

  }

  updateInstallmentCompBasedOnPaidAmount(index: number, instAmt: number, paidAmt: number) {
    if (!this.validateDigit(paidAmt)) {
      console.error("Invalid paid amount. It should be a positive number.");
      // Reset the paid amount if validation fails
      this.installmentComp[index].paidAmount = 0;
      return;
    } else {
      for (let i = 0; i < this.installmentComp.length; i++) {
        const installment = this.installmentComp[i];
        installment.isUpdated = false;
      }
    }

    let currentDate = new Date(this.Ins_StartDate);
    let year = currentDate.getFullYear();
    let totalInstallments = this.noOfInstallment;
    // Calculate installment amount
    let totalPaid: number = 0;
    let pendingAmount: number = 0;
    let pendingInstallments: number = 0;
    let salGeneratedCount: number = 0;

    for (let i = 0; i < this.installmentComp.length; i++) {
      const installment = this.installmentComp[i];

      if (installment.paidAmount != null && installment.paidAmount > 0) {
        totalPaid = totalPaid + Number(installment.paidAmount);
      }
      if (installment.isSalaryGenerated) {
        salGeneratedCount++;
        totalPaid = totalPaid + Number(installment.instAmount);
      }

    }
    pendingAmount = this.totalAmountToPay - totalPaid; // remaining amount to be paid
    pendingInstallments = totalInstallments - salGeneratedCount;// remaining installments to be paid

    let installAmt = 0.00;
    if (pendingAmount > 0) {
      installAmt = Number((pendingAmount / pendingInstallments).toFixed(2));
    }

    for (let j = salGeneratedCount; j < this.installmentComp.length; j++) {
      this.installmentComp[j].instAmount = installAmt;
    }

    console.log("installmentComp ", this.installmentComp);
  }

  updateInstallmentCompBasedOnInstallmentAmount(index: number, item) {
    if (item.instAmt == null) {
      item.instAmt == 0;
    }
    let totalInstallments = this.noOfInstallment;
    this.installmentComp[index].isUpdated = true;
    // Calculate installment amount
    let totalPaid: number = 0;
    let pendingAmount: number = 0;
    let pendingInstallments: number = 0;
    let salGeneratedCount: number = 0;
    let skipCount: number = 0;

    for (let i = 0; i < this.installmentComp.length; i++) {
      const installment = this.installmentComp[i];

      if (installment.paidAmount != null && installment.paidAmount > 0) {
        totalPaid = totalPaid + Number(installment.paidAmount);

      }
      if (installment.isSalaryGenerated || installment.isUpdated) {
        if (installment.isSalaryGenerated) {
          salGeneratedCount++;
        }
        skipCount++;
        totalPaid = totalPaid + Number(installment.instAmount);
      }

    }

    pendingAmount = this.totalAmountToPay - totalPaid; // remaining amount to be paid
    pendingInstallments = totalInstallments - skipCount;// remaining installments to be paid

    let installAmt = 0.00;
    if (pendingAmount > 0 && pendingInstallments > 0) {
      installAmt = Number((pendingAmount / pendingInstallments).toFixed(2));
    } else {
      return;
    }

    for (let j = salGeneratedCount; j < this.installmentComp.length; j++) {
      if (j != index && !this.installmentComp[j].isUpdated) {
        this.installmentComp[j].instAmount = installAmt;
      }
    }
  }

  changeAmount(e, item, i) {
    if (item.instAmount == null) {
      item.instAmount = 0;
    }
    let amount = e.target.value;


  }

  update() {

    if (this.fieldChanged) {
      this.alertmessages("Please verify the installments.", "danger");
      return false;
    }

    if (this.validate()) {
      if (this.noOfInstallment > 0 && this.totalAmount > 0 && this.date_of_loan && this.Ins_StartDate) {
        this.setSalaryAdvanceModel();
      }
      this.salaryAdvanceService.updateSalaryAdvanceApprove(this.model, this.typeFilter, this.statusFilter,this.limitPara , this.offsetPara).subscribe(
        data => {

          this.tempsalaryAdvanceList = data.salaryAdvanceList;
          this.clear();
          this.clearForm();
          this.salaryAdvanceList = [];
          this.search();
          this.alertmessages("Updated successfully.", "success");
        }, error => {
          this.alertmessages("Error occured . Please try again Later.", "danger");
        });
    }
  }

  validate(): any {
    if (this.totalAmount == null || this.totalAmount == undefined) {
      return false;
    }
    if (this.noOfInstallment == null || this.noOfInstallment == undefined) {
      return false;
    }
    if (this.date_of_loan == null || this.date_of_loan == undefined) {
      return false;
    }
    if (this.Ins_StartDate == null || this.Ins_StartDate == undefined) {
      return false;
    }
    if (!this.validateDates()) {
      return false;
    }
    if (this.model.installmentComp == null || this.model.installmentComp == undefined) {
      return false;
    }
    if (this.model.installmentComp && this.model.installmentComp.length === 0) {
      return false;
    }

    return true;
  }

  validateDigit(amount: any): boolean {
    // Convert the amount to a number
    const num = parseFloat(amount);

    // Check if it's not a number (NaN) or negative
    if (isNaN(num) || num < 0) {
      return false;  // Invalid if it's not a number or is negative
    }

    return true;  // Valid if it's a non-negative number
  }

  setSalaryAdvanceModel() {

    this.model.totalAmount = this.totalAmount;
    this.model.noOfInstallment = this.noOfInstallment;
    this.model.reason = this.reason;

    let currentDate = new Date(this.Ins_StartDate);
    let year = currentDate.getFullYear();
    let month = (currentDate.getMonth() + 1).toString().padStart(2, '0');  // Months are 0-based, so add 1
    let day = currentDate.getDate().toString().padStart(2, '0');

    this.model.installStartDate = this.Ins_StartDate;
    // this.model.installStartMonth = this.Ins_StartDate;

    let currentDate2 = new Date(this.date_of_loan);
    let year2 = currentDate2.getFullYear();
    let month2 = (currentDate2.getMonth() + 1).toString().padStart(2, '0');  // Months are 0-based, so add 1
    let day2 = currentDate2.getDate().toString().padStart(2, '0');

    this.model.dateOfLoan = this.date_of_loan;

    this.model.salaryHeadId = this.salaryHeadId;
    this.model.salaryDetailId = this.salaryDetailId;
    this.model.interestRate = this.interestRate;
    this.model.uniqueEmpId = this.sessionservice.getempid();
    this.model.userId = this.sessionservice.getempid();
    this.model.status = this.salaryAdvanceData.status;

  }

  setInstallmentComp() {
    let currentDate = new Date(this.Ins_StartDate);
    let year = currentDate.getFullYear();
    let totalInstallments = this.noOfInstallment;
    // Calculate installment amount
    let totalPaid: number = 0;
    let pendingAmount: number = 0;
    let pendingInstallments: number = 0;
    let salGeneratedCount: number = 0;

    for (let i = 0; i < this.installmentComp.length; i++) {
      const installment = this.installmentComp[i];

      if (installment.paidAmount != null && installment.paidAmount > 0) {
        totalPaid = totalPaid + Number(installment.paidAmount);
      }
      if (installment.isSalaryGenerated) {
        salGeneratedCount++;
        totalPaid = totalPaid + Number(installment.instAmount);
      }

    }

    pendingAmount = this.totalAmountToPay - totalPaid; // remaining amount to be paid
    pendingInstallments = totalInstallments - salGeneratedCount;// remaining installments to be paid

    let installAmt = 0.00;
    if (pendingAmount > 0) {
      installAmt = Number((pendingAmount / pendingInstallments).toFixed(2));
    }

    // Clear previous installments if needed
    // this.model.installmentComp = [];
    // this.installmentComp = [];

    for (let i = salGeneratedCount; i < totalInstallments; i++) {
      // Calculate new month and year
      let installmentDate = new Date(currentDate);
      installmentDate.setMonth(currentDate.getMonth() + i); // Increment month for each installment

      // Extract year, month, and day from the updated date
      year = installmentDate.getFullYear();
      let month = (installmentDate.getMonth() + 1).toString().padStart(2, '0');
      let day = installmentDate.getDate().toString().padStart(2, '0');

      // Format month name if needed (assuming `getMonthName` is defined elsewhere)
      let monthName = this.getMonthName(installmentDate.getMonth());

      // Check if the installment already exists
      if (this.installmentComp[i]) {
        // Modify the existing installment entry
        this.installmentComp[i].monthName = monthName;
        this.installmentComp[i].instAmount = installAmt;
        this.installmentComp[i].instDate = `${year}-${month}-${day}`;
        // If paidAmount should remain unchanged, no need to modify it
      } else {
        // Add new installment to the list
        this.installmentComp.push({
          monthName: monthName, // or `${year}-${month}` if you need 'YYYY-MM'
          instAmount: installAmt,
          paidAmount: '',
          instDate: `${year}-${month}-${day}`,
          isUpdated: false,
          isSalaryGenerated: false // Format: 'YYYY-MM-DD'
        });
        console.error(`Installment at index ${i} does not exist!`);
      }
    }
  }

  getMonthName(monthNumber: number): string {
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    // // Validate monthNumber to be within the valid range (1-12)
    // if (monthNumber < 1 || monthNumber > 12) {
    //   throw new Error("Invalid month number. It must be between 1 and 12.");
    // }

    return monthNames[monthNumber]; // Subtract 1 because array is 0-based
  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    // (<HTMLButtonElement>document.getElementById('btndisabled')).disabled = true;
    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/employeemaster']);
      });


    }, 5000);
  }

  clearForm() {
    if (this.salaryAdvanceForm) {
      this.salaryAdvanceForm.reset(); // Reset form only after view initialization
    } else {
      console.log("Form is not initialized yet.");
    }
  }

  clear() {
    this.totalAmount = null;
    this.noOfInstallment = null;
    this.date_of_loan = null;
    this.Ins_StartDate = null;
    this.model.installmentComp = [];
    this.isEdit = false;
    this.submitted = false;
    this.clearForm();
    this.checkheader = false;
    this.isOnlyView = false;
    // this.salaryAdvanceForm.reset();
  }

  editSalaryAdvance(salAdvobj: any) {
    // this.clear();
    this.isEdit = true;
    this.totalAmount = salAdvobj.totalAmount;
    this.noOfInstallment = salAdvobj.noOfInstallment;
    this.Ins_StartDate = salAdvobj.installStartDate

    this.date_of_loan = salAdvobj.dateOfLoan;

    this.salaryHeadId = salAdvobj.salaryHeadId;
    this.headChange(this.salaryHeadId);
    this.salaryDetailId = salAdvobj.salaryDetailId;
    this.interestRate = salAdvobj.interestRate;
    this.currentStatus = salAdvobj.status;

    this.salaryAdvanceService.getapplyAdvInfoForApprover(salAdvobj.interMasterId).subscribe(
      data => {

        this.salaryAdvanceData = data.salaryAdvance;
        this.reason = data.salaryAdvance.reason;
        this.model.interMasterId = data.salaryAdvance.interMasterId;
        this.model.empInstMasterId = data.salaryAdvance.empInstMasterId;
        this.model.installmentComp = this.salaryAdvanceData.installmentComp;
        this.interestRate = data.salaryAdvance.interestRate;
        // if( this.salaryAdvanceData.installmentComp.length > 0){
        //     this.isVerify = true
        // } else{
        //   this.isVerify = false;
        // }
        if (this.salaryAdvanceData.status != "APPROVED" && this.salaryAdvanceData.status != "PAID") {
          this.isOnlyView = true;
        } else {
          this.isOnlyView = false;
        }

      },
      error => {
        const response = error.error;
        console.log(error);
      }
    );
    // window.scrollTo({ top: -100, behavior: 'smooth' });
    $('html,body').animate({
      scrollTop: $(`#alertcomp`).offset().top - 1
    },
      'slow');
    console.log("this.interestRate =>", this.interestRate);
  }

  extractYearMonth(dateString: string): string {
    const date = new Date(dateString); // Convert to a Date object
    const year = date.getFullYear();   // Get the year
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get the month (zero-based)

    return `${year}-${month}`; // Return in 'YYYY-MM' format
  }

  getSalaryMaster() {
    this.salaryAdvanceService.getSalaryDetails().subscribe((data) => {
      console.log(data);
      this.salaryMaster = data.salaryHeadMaster;
    }, (error) => {
      console.log(error);
    });
  }

  headChange(info: any) {
    if (info > 0) {
      this.detailMasterSection = this.salaryMaster.find(head => head.salaryHeadId == info).salaryDetailMaster;
    }
  }

  ListheadChange(info: any, sal: any) {
    if (info > 0) {
      sal.detailMasterSection = this.salaryMaster.find(head => head.salaryHeadId == info).salaryDetailMaster;
    }
    console.log(this.detailMasterSection);
  }

  chkInput() {
    if (this.query.length > 3) {
      this.salaryAdvanceList = this.salaryAdvanceList.filter(item =>
        item.employeeName.toLowerCase().includes(this.query.toLowerCase()) && item.status.toLowerCase().includes(this.statusFilter.toLowerCase()));
    }
    else {
      this.salaryAdvanceList = this.tempsalaryAdvanceList.filter(item =>
        item.status.toLowerCase().includes(this.statusFilter.toLowerCase()));
      // this.salaryAdvanceList = this.tempsalaryAdvanceList;
    }
  }

  onScroll() {
    this.offsetPara = this.offsetPara + 12;
    this.getAllAdvApplies();
    
  }

  ok() {
    console.log("this.installmentComp", this.installmentComp);
    const tolerance = 0.10; // Allow a small margin for rounding errors
    const totalInstallmentAmount: number = this.installmentComp.reduce((sum: number, comp) => {
      if (comp.paidAmount !== undefined && comp.paidAmount !== null && comp.paidAmount > 0) {
        return sum + parseFloat(comp.paidAmount) + parseFloat(comp.instAmount);
      } else {
        return sum + parseFloat(comp.instAmount);
      }
      // Ensuring instAmount is a number
    }, 0);
    console.log("totalAmount", this.totalAmount);
    console.log("totalInstallmentAmount", totalInstallmentAmount);
    if (Math.abs(this.totalAmountToPay - totalInstallmentAmount) < tolerance) {
      console.log("The sum of installment amounts is approximately equal to the total installments.");
      this.model.installmentComp = this.installmentComp;
      $('#calInstallmentModel').modal('hide');
    } else {
      this.customalertmessages("The sum of installment amounts does NOT match the total amount.", "danger", '.alertmodel');
      console.log("The sum of installment amounts does NOT match the total installments.");
    }
    this.fieldChanged = false;
  }
  customalertmessages(message: string, sign: string, alertcomponent: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    let alertcomp = document.querySelector(alertcomponent);
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    // (<HTMLButtonElement>document.getElementById('btndisabled')).disabled = true;
    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/employeemaster']);
      });


    }, 5000);
  }


  navigateSalaryStatus() {
    localStorage.setItem('navigateDashboard', 'true')
  }

  setMenuName(name: string) {
    this.menuService.setMenuName(name);
  }

  checkAccessForUser() {
    console.log("checkAccessForUser here");
    this.allowToViewReport = this.permissionList.includes('ng.salary.edit.salaryadvance.report.permission');
    this.showBasedOnPermissionEmployeeStatus = this.permissionList.includes('ng.employee.search.show.employeestatus');
  }

  isAppoveAllowed(): boolean {
    return ['PENDING', 'CP'].includes(this.statusFilter);
  }

  isCheckBoxDisabled(): boolean {
    return !['PENDING', 'APPROVED', 'CP'].includes(this.statusFilter);
  }

}
