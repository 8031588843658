import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { EmployeeMasterservice } from '../services/employeemaster.service';
import { CommonService } from '../services/common.service';
import { annualHolidays } from '../models/annualHoliday';
import { AnnualHoliday } from '../models/AnnualHolidayInfo';
import { AlertService } from '../services';
import { DatePipe } from '@angular/common';
declare var $:any

@Component({
  selector: 'app-annual-holiday-master',
  templateUrl: './annual-holiday-master.component.html',
  styleUrls: ['./annual-holiday-master.component.css']
})
export class AnnualHolidayMasterComponent implements OnInit {

  // add_view_toggle: any;
  add_view_toggle = "viewAnnualHoliday";
  update_view_toggle: any;

  Date: String;
  annualName: String;
  creditedDay: String = "F";
  colorCode: String;
  // holiday:any;

  annualHolidayId: number;
  annualhoildaylist: AnnualHoliday[];


  addOrUpdate: boolean = true;
  saveOrUpdate: boolean = true;

  info: any;
  DeleteAnnualHoliday:AnnualHoliday ;
  constructor(private employeeMasterservice: EmployeeMasterservice, private commonService: CommonService,
    private alertservice: AlertService, private datePipe: DatePipe) { }
  @ViewChild('annualform') annual: NgForm;
  @ViewChild('annual_name') annualcontrol: NgModel

  ngOnInit(): void {
    this.getannualHoildayList();
    this.viewAnnualHolidayButton();


  }

  getannualHoildayList(): void {
    this.commonService.getAnnualHolidays().subscribe(data => {
      // console.log(data, "annual holiday list")
      this.annualhoildaylist = data.annuallist;
      console.log("annual holiday list", this.annualhoildaylist);
      this.annual.reset();

    });
  }


  addAnnualHoliday() {
    console.log("here");

    //this.annual.form.markAllAsTouched();

    if ((this.annualName != null || this.annualName != undefined) && (this.Date != null || this.Date != undefined)) {
      console.warn(this.creditedDay);

      this.employeeMasterservice.insert_annual_holiday(this.Date, this.annualName, this.creditedDay, this.colorCode).subscribe(
        data => {
          console.log("added annual holiday ", data);
          this.alertmessages("Successfully Saved ", "success");
          this.annual.reset();
          this.annualcontrol.reset()
          this.clear();
          this.viewAnnualHolidayButton();

        }, error => {

          console.log("Error Hey Bro Error", error);
          const formattedDate = this.formatDate(this.Date as string);
          this.alertmessages(`Holiday already saved for ${formattedDate}`, "danger");
        }
      );
    } else {
      console.log("err");
      this.alertmessages("Please Fill all the required field", "danger");
    }
  }

  formatDate(dateString: string): string {
    const dateParts: string[] = dateString.split("-");
    const formattedDate: string = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
    return formattedDate;
  }

  clear() {
    this.annualName = undefined;
    this.addOrUpdate = true;
    this.Date = undefined;
    // this.branch.reset();
  }


  // updateAnnualHoliday() {
  //   this.annualName = this.annualName.trim();
  //  //console.log("After trim Branch: ", this.branchName, " :  length : ", this.branchName.length)
  //   if (this.annualName != undefined && this.annualName.length > 0) {

  //     const updatedField = {
  //        annualHolidayId: this.annualHolidayId,
  //       name: this.annualName
  //     };

  //     this.employeeMasterservice.editUpdate_branch(updatedField).subscribe(
  //       data => {
  //         console.log("added branch ", data)
  //         this.alertmessages("Successfully Updated ", "success")
  //         this.clear();
  //         //this.getBranch();
  //       }, error => {
  //        this.alertmessages("Please try again later", "danger")
  //         console.log(error);
  //       }
  //     );

  //     this.clear();
  //   }
  // }


  addAnnualHolidayButton() {
    this.add_view_toggle = "addAnnualHoliday";
    this.addOrUpdate = true;
    this.creditedDay = "F";
    //this.annual.reset();


  }

  viewAnnualHolidayButton() {
    this.addOrUpdate = true;
    this.add_view_toggle = "viewAnnualHoliday";
    this.annual.reset();

    // this.annualName = undefined;
    this.getannualHoildayList();

    this.annualcontrol.reset();

  }
  deleteAnnualHoliday() {
    const deleteId = this.DeleteAnnualHoliday. annualHolidayID;
    console.log("deleteId", deleteId);
    this.employeeMasterservice.delete_annual_holiday(deleteId).subscribe(
      (data) => {
        this.getannualHoildayList();
        $('#showAlertVer').modal('toggle');
        this.alertmessages(data.successMessages[0]['key'], "success")


      }, (error) => {
        console.log(error)
        $('#showAlertVer').modal('toggle');
        this.alertmessages("Annual Holiday cannot be deleted as it's in use", "danger");

      }
    );


  }


  onDeleteHandler(info: any) {
    console.log("delete : ", info);
    this.DeleteAnnualHoliday = info;
    $('#showAlertVer').modal('toggle');
  }

  closeMRQModal() {
    $('#showAlertVer').modal('toggle');

  }

  editAnnualHoliday(info) {
    console.log("edit annual holiday : ", info)
    this.addOrUpdate = false;

    // this.annualHolidayId = id;

    this.add_view_toggle = "addAnnualHoliday";
    //this.updateAnnualHoliday();

    const formattedDate = this.datePipe.transform(info?.date, 'yyyy-MM-dd');
    console.log(formattedDate, "update date");

    this.Date = formattedDate;
    this.annualName = info.reason;
    this.creditedDay ='F';
    this.colorCode = info.colorcode;
    this.annualHolidayId = info.annualHolidayID;
  }

  updateAnnualHoliday() {
    this.addAnnualHolidayButton();

    this.saveOrUpdate = false;

    this.annualName = this.annualName.trim();
    console.log("After trim annual holiday: ", this.annualName, " :  length : ", this.annualName.length)
    if (this.annualName != undefined && this.annualName.length > 0) {

      // const updatedField = {
      //   annualHolidayId: this.annualHolidayId,
      //   name: this.annualName
      // };

      this.employeeMasterservice.editUpdate_annual_holiday(this.annualName, this.annualHolidayId, this.Date,
        this.creditedDay, this.colorCode).subscribe(
          data => {
            console.log("added annual holiday ", data, this.annualName)
            this.alertmessages("Successfully Updated ", "success")
            this.annual.reset();
            this.annualName = ''
            this.clear();
            this.annualcontrol.reset();
            this.viewAnnualHolidayButton();
            this.getannualHoildayList();

            console.warn(this.annual, this.annualcontrol, this.annualcontrol.reset());


          }, error => {
            this.alertmessages("Holiday with the same name or date already exists", "danger")
            this.viewAnnualHolidayButton();
            console.log(error);
          }
        );

      // this.clear();
    }
  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }
}
