<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp"></div>
        <!-- <div class="page-header d-xl-flex d-block">
            <div class="page-leftheader">
                <div class="page-title">OverTime Report</div>
            </div>
        </div> -->
        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <form id="fcForm" class="form-horizontal form-label-left">
                            <div class="row col-md-12 col-sm-12 col-xs-12">
                                <!-- report from   (keyup.enter)="limitpara();searchOTReport()"-->
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label class="form-label">Report From</label>
                                        <div>
                                            <input class="form-control col-md-12 col-xs-12" type="date" name="fromDate"
                                                autocomplete="off" autofocus="true" [disabled]="false"
                                                [(ngModel)]="fromDate" (ngModelChange)="checkFromAndToDate()" required>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label class="form-label">Report To</label>
                                        <div>
                                            <input class="form-control col-md-12 col-xs-12" type="date" name="toDate"
                                                autocomplete="off" autofocus="true" [disabled]="false"
                                                [(ngModel)]="toDate" (ngModelChange)="checkFromAndToDate()" required>
                                            <p *ngIf="endDate" class="help-block " style="color: #ff5757;">
                                                ⓘ End date should be greater than start date
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="row"> -->
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating"> <label class="form-label">Employee Name / ID
                                        </label>
                                        <div class="form-group m-0">
                                            <div>
                                                <input type="search" class="form-control col-md-12 col-xs-12"
                                                    name="employeename" id="employeename"
                                                    placeholder="Enter Employee Name / ID" [(ngModel)]="employeename"
                                                    autocomplete="off" (input)="getEmployeeByNameOrId()"
                                                    list="employeeMasterLucene">
                                                <datalist id="employeeMasterLucene">
                                                    <option data-value={{emp.name}}
                                                        *ngFor="let emp of employeeMasterLucene">
                                                        {{emp.employeeid}}
                                                    </option>
                                                </datalist>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <label class="form-label">Employee Status</label>
                                    <select class="form-control" type="text" style="width: 100%;" required
                                        [(ngModel)]="selectedValueForLucene" name="selVal"(change)="onStatusChange()"
                                        [disabled]="!showBasedOnPermissionEmployeeStatus">
                                        <option value="All">All</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>
                                </div>
                                <!-- dep. dropdown -->
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label class="form-label"> Department </label>
                                        <div>
                                            <select class="form-control col-md-12 col-xs-12"
                                                style="appearance: auto !important;"
                                                data-style="btn btn-drpdwn btn-round" id="dGroup" name="department"
                                                [(ngModel)]="department" (ngModelChange)="modelDepartment($event)">
                                                <option [ngValue]="0">All</option>
                                                <option [value]="item.departmentId" *ngFor="let item of departments">
                                                    {{item.name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-md-3 col-sm-3 col-xs-3 mb-4" style="margin-top:-4px;">
                                    <!-- <label class="form-label col-md-12 col-sm-4 col-xs-4 mb-1">&nbsp;</label> -->
                                    <div class="form-group m-0  d-flex justify-content-between">
                                        <!-- <button type="button" id="navigatetarget" class="btn btn-primary w-10"
                                    style="margin-right:auto" (click)="limitpara(); searchOTReport()">
                                        Search
                                    </button> -->
                                        <button type="button" id="navigateclient" class="btn btn-primary w-45"
                                            style="margin-right: 1vw"
                                            (click)="limitpara(); searchOTReport(); scrollToBottom()"> Search
                                        </button>
                                        <button type="button" class="btn btn-danger w-45"
                                            (click)="clear()">Clear</button>
                                    </div>
                                    <!-- </div> -->
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="empty-div-one" id="navigatedest"></div>
        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <!-- <div class="row">
            <div class="col-md-12"> -->
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title "> Overtime Report </h4>
                        <button type="button" class="btn btn-primary ms-auto" (click)="overtimereportCSVDownload()">
                            <i class="fa fa-download" data-bs-toggle="tooltip" data-original-title="download"></i>
                        </button>
                    </div>

                    <!-- <div class="empty-div-one" style="height: 10px;" id="navigatedest"></div> -->
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xs-12  mobile view">

                                <div class="scrollsearchresults" infiniteScroll [infiniteScrollDistance]="1"
                                    [infiniteScrollUpDistance]="1.5" [infiniteScrollThrottle]="100"
                                    [scrollWindow]="false" (scrolled)="onScroll()" id="scrolllength">
                                    <table
                                        class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                        id="hr-table">
                                        <thead class="tablerow">
                                            <tr class=" stickyatTop rowcolors">

                                                <th class="border-bottom-0 w-10 fs-7">Employee ID</th>
                                                <th class="border-bottom-0 w-10 fs-7">Employee Name</th>
                                                <th class="border-bottom-0 w-10 fs-7">Applied Date</th>
                                                <th class="border-bottom-0 w-10 fs-7">OT Minutes</th>
                                                <th class="border-bottom-0 w-10 fs-7">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let otInfo of otreportinfo ">
                                                <td class="fixedcolumn backgroundFW" data-th="Employee Id">
                                                    {{otInfo.empId}}</td>
                                                <td class="fixedcolumn secondcolumn  backgroundFW backgroundSW"
                                                    data-th="Employee Name"> {{otInfo.empName}}</td>
                                                <td data-th="Overtime"> {{otInfo.monthStart| date: 'mediumDate'}}</td>
                                                <td data-th="Overtime"> {{otInfo.min }}</td>
                                                <!-- <td data-th="Overtime">{{otInfo.otDifference | slice:0:5 }}</td> -->
                                                <!-- <td data-th="Overtime"> {{otInfo.status}}</td> -->
                                                <td>
                                                    <span style="font-size: 13px; font-weight: 500;" [ngClass]="
                                                    otInfo.status === 'Extra Time Out Cancel Pending' ? 'bg-custom-warning2 text-custom-dark' :
                                                    otInfo.status === 'Approved' ? 'bg-custom-green text-custom-dark' :
                                                    otInfo.status === 'Rejected' ? 'bg-custom-red text-custom-dark' :
                                                    otInfo.status === 'Partial Approve 1' ? 'bg-custom-orange1 text-custom-dark' :
                                                    otInfo.status === 'Partial Approve 2' ? 'bg-custom-orange2 text-custom-dark' :
                                                    otInfo.status === 'Over Time Cancel Approved' ? 'bg-custom-red text-custom-dark' :
                                                    'bg-custom-warning text-custom-dark'
                                                  " class="badge">
                                                        {{ otInfo.status }}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>