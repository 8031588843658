<div class="app-content main-content">
    <div class="side-app main-container">


        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <form id="fcForm" class="form-horizontal form-label-left">
                            <div class="x_content row px-4">
                                <div class="col-md-3 col-sm-3 col-xs-3">
                                    <div class="form-group label-floating">
                                        <label class="form-label"> From Date: </label>
                                        <div>
                                            <input class="form-control" type="date" name="fromdate" id="fromdate"
                                                [(ngModel)]="fromDate">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-3 col-xs-3">
                                    <div class="form-group label-floating">
                                        <label class="form-label"> To Date: </label>
                                        <div>
                                            <input class="form-control" type="date" name="todate" id="todate"
                                                [(ngModel)]="toDate">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-3 col-xs-3">
                                    <div class="form-group label-floating">
                                        <label class="form-label">Select Status </label>
                                        <div>
                                            <div>
                                                <select id="SuspensionStatusFilters" class="form-control"
                                                    [(ngModel)]="statusValue"
                                                    (change)="onSuspensionStatusChange($event.target.value)"
                                                    name="dateFilter">
                                                    <option value="All">All</option>
                                                    <option value="Approved">Approved</option>
                                                    <option value="Pending">Pending</option>
                                                    <option value="Rejected">Rejected</option>

                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-3 col-sm-3 col-xs-3">
                                    <div class="form-group label-floating">
                                        <label class="form-label">Select Department</label>
                                        <div>
                                            <select id="leaveStatusFilters" class="form-control" name="department"
                                                id="department" [(ngModel)]="selecteddepartment" autocomplete="off"
                                                autofocus="autofocus">
                                                <option value="0" selected="selected">All</option>
                                                <option *ngFor="let dep of department; let i = index"
                                                    [value]=dep.departmentId>{{
                                                    dep.name }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12 col-sm-12 col-xs-12 mt-3" style="padding: 0px 0px">
                                    <form class="form-horizontal" id="fcForm">
                                        <div class="form-group row">

                                            <div class="col-md-6 col-sm-6 col-xs-6 mb-4">
                                                <label class="form-label w-100 mb-1">Employee Name / ID </label>
                                                <div class="form-group m-0">
                                                    <div>
                                                        <input type="search" class="form-control " name="employeename"
                                                            id="employeename" [(ngModel)]="query" autocomplete="off"
                                                            (input)="getEmployeeByNameOrId()" list="employeeMaster"
                                                            autofocus="autofocus"
                                                            placeholder="Search Employee Name / ID">

                                                        <datalist id="employeeMaster">
                                                            <option [value]="emp.name + ' / ' + emp.employeeid"
                                                                *ngFor="let emp of employeeMasterLucene">
                                                                <!-- {{emp.name}} -->
                                                            </option>
                                                        </datalist>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group col-md-3 col-sm-3 col-xs-3 px-2">
                                                <label class="form-label">Employee Status</label>
                                                <select class="form-control" type="text" style="width: 100%;" required
                                                    [(ngModel)]="selectedValue" name="selVal"
                                                    (change)="onStatusChange()"
                                                    [disabled]="!showBasedOnPermissionEmployeeStatus">
                                                    <option value="All">All</option>
                                                    <option value="Active">Active</option>
                                                    <option value="Inactive">Inactive</option>
                                                </select>
                                            </div>

                                            <!-- Search Button -->
                                            <div class="col-md-3 col-sm-3 col-xs-3 mb-4">
                                                <label
                                                    class="form-label col-md-12 col-sm-4 col-xs-4 mb-1">&nbsp;</label>
                                                <div class="form-group m-0">
                                                    <button type="button" id="navigatetarget"
                                                        class="btn btn-primary w-45 me-2"
                                                        (click)="searchSuspensionReport()">
                                                        Search
                                                    </button>
                                                    <button type="button" class="btn btn-danger w-45" (click)="clear()">
                                                        Clear
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>


        <div class="empty-div-one" id="add-update-client"></div>
        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-header  border-0">
                        <h4 class="card-title">Suspension Summary</h4>
                    </div>
                    <div class="card-body">
                        <div class="col-xs-12 mobile view">
                            <div>
                                <div class="scrollsearchresults" infiniteScroll [infiniteScrollDistance]="2"
                                    [infiniteScrollUpDistance]="1.5" [infiniteScrollThrottle]="100"
                                    [scrollWindow]="false" (scrolled)="onScroll()" id="scrolllength">
                                    <table
                                        class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                        id="hr-table">
                                        <thead class="tablerow">
                                            <tr class=" stickyatTop rowcolors">
                                                <th class="border-bottom-0 w-5 fixedcolumn">Employee ID</th>
                                                <th class="border-bottom-0 w-5 secondcolumn">Name</th>
                                                <th class="border-bottom-0 w-5 secondcolumn">Department</th>
                                                <th class="border-bottom-0">From Date</th>
                                                <th class="border-bottom-0">To Date</th>
                                                <th class="border-bottom-0">Reason</th>
                                                <th class="border-bottom-0">Applied By</th>
                                                <th class="border-bottom-0">Approved/Rejected By</th>
                                                <th class="border-bottom-0">Rejected Reason</th>
                                                <th class="border-bottom-0">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let s of suspenseReport">
                                                <td>{{s.empid}}</td>
                                                <td>{{s.empname}}</td>
                                                <td>{{s.departname}}</td>
                                                <td>{{s.fromdate | date: 'mediumDate'}} </td>
                                                <td>{{s.todate | date: 'mediumDate'}} </td>
                                                <td>{{s.reason}}</td>
                                                <td>{{s.updatedBy}}</td>
                                                <td>{{s.approvedBy}}</td>
                                                <td>{{s.remark}}</td>

                                                <td>
                                                    <span style="font-size: 13px;font-weight: 500;"
                                                        [ngClass]="s.status === 'Approved' ? 'badge-success' : s.status  === 'Rejected' ? 'badge-danger' : s.status  === 'Leave Cancel Rejected' ? 'badge-danger':s.status  === 'Leave Cancel Pending' ? 'badge-warning text-custom-dark' : 'bg-custom-warning text-custom-dark'"
                                                        class="badge">{{s.status}}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>