import { Component, OnInit } from '@angular/core';
import { TaskDetailsForLeave } from '../models/task-details-for-leave.model';
import { ApproveLeavePipe } from '../pipes/approve-leave.pipe';
import { EmployeeLeaveService } from '../services/employee-leave.service';
import { LeaveWorkFlow } from '../models/leaveWorkflow';
import { TitleCasePipe } from '@angular/common';
import { Menu } from '../services/menu.Service';
import { EmployeeMaster } from '../models/employeeMasterts';

declare var $: any;
@Component({
	selector: 'app-employee-leave-approval',
	templateUrl: './employee-leave-approval.component.html',
	styleUrls: ['./employee-leave-approval.component.css']
})
export class EmployeeLeaveApprovalComponent implements OnInit {
	private APPROVED: string = "APPROVED";
	private REJECTED: string = "REJECTED";
	private LEAVE_APPROVE_PENDING: string = "Leave Approve Pending"
	private LEAVE_CANCEL_PENDING: string = "Leave Cancel Pending"
	private LEAVE_CANCEL_APPROVED: string = "Leave Cancel Approved"
	private LEAVE_CANCEL_REJECTED: string = "Leave Cancel Rejected"
	searchleave: any;

	// employeeLeaveApplied:EmployeeLeaveApplied[]= []
	// taskDetailsForLeave:TaskDetailsForLeave[]=[]

	taskDetailsForLeave: TaskDetailsForLeave[] = [];
	leaveDetailFlow: LeaveWorkFlow[] = []
	leaveTypesList = [
		{ leaveType: "", leaveName: "" }
	]
	leaveTypeMap = new Map();
	approvalLeaves: TaskDetailsForLeave[] = [];
	leavesApproval: LeaveWorkFlow[] = [];
	templeaveDetailFlow: LeaveWorkFlow[] = [];
	statusFilter: any = 'all';
	limitPara: any;
	offsetPara: any;
	isFetching:boolean = false;
	permissionList: any[] = [];
  allowToViewReport: boolean = false;
	 ActiveInativeEmpStatusFilter : string='Active';
	  leavingdate: any;
	  query: any;
	  uniqueemployeeid: String;
	  employeeMasterLucene: EmployeeMaster[];
	  showBasedOnPermissionEmployeeStatus: boolean = false;

	constructor(private empLeaveService: EmployeeLeaveService, private approve: ApproveLeavePipe, private titleCase: TitleCasePipe,private menuService: Menu) { }

	ngOnInit(): void {
		this.limitPara = 15;
		this.offsetPara = 0;
		if (localStorage.getItem('navigateDashboard') == 'true') {
			this.search();
			localStorage.setItem('navigateDashboard', 'false');
		}
		const maxString = sessionStorage.getItem('permission').replace('[', '');
		const maxStringBox = maxString.replace(']', '');
		const maxStringAgain = maxStringBox.replace(/"/g, '');
		this.permissionList = maxStringAgain.split(',');
		this.checkAccessForUser();
	}

	search() {
		this.offsetPara = 0;
		this.leaveDetailFlow = [];
		this.isFetching = true;
		this.getLeaves();
		this.getLeaveForApproval();
		$('html,body').animate({
			scrollTop: $(`#navigatedest`).offset().top - 90
		},
			'slow');
	}

	onScroll() {
		if(this.isFetching == true){
		  this.offsetPara=  this.offsetPara + 15;
		  console.log("offset=============>",this.offsetPara);
		  this.getLeaveForApproval();
		}
	  }

	getLeaves() {
		this.empLeaveService.getAllLeaves().subscribe(
			data => {
				this.leaveTypesList = data.leaveMasters
				this.setLeaveMap();
			},
			error => {
				console.log("Error ", error);
			}
		)
	}

	showTable = false
	getLeaveForApproval() {
		// this.leaveDetailFlow = [];
		this.templeaveDetailFlow = [];
		this.empLeaveService.getLeaveForApproval(this.limitPara,this.offsetPara).subscribe(
			data => {
				if(data.leaveWorkflow.length<15){
					this.isFetching = false;
				  }
				console.log("get Leave for Approval ");
				if (this.statusFilter == 'all') {
					console.log("this.statusFilter ", this.statusFilter);
					// this.leaveDetailFlow = data.leaveWorkflow;
					if(this.offsetPara == 0){
						this.leaveDetailFlow = data.leaveWorkflow;
					  }else{
						this.leaveDetailFlow = this.leaveDetailFlow.concat(data.leaveWorkflow);
					}
					console.log("when statusFilter is All +++++++++++>", this.leaveDetailFlow);
					this.leaveDetailFlow.forEach(item => {
						item.employeeLeaveApplied.leaveStatus = this.titleCase.transform(item.employeeLeaveApplied.leaveStatus);
					})
					this.templeaveDetailFlow = this.leaveDetailFlow;
				} else {
					console.log("this.statusFilter ", this.statusFilter);
					// this.leaveDetailFlow = data.leaveWorkflow;
					if(this.offsetPara == 0){
						this.leaveDetailFlow = data.leaveWorkflow;
					  }else{
						this.leaveDetailFlow = this.leaveDetailFlow.concat(data.leaveWorkflow);
					}
					console.log("when statusFilter is NOT All +++++++++++>", this.leaveDetailFlow);
					this.leaveDetailFlow.forEach(item => {
						item.employeeLeaveApplied.leaveStatus = this.titleCase.transform(item.employeeLeaveApplied.leaveStatus);
					})
					this.leaveDetailFlow = this.leaveDetailFlow.filter(
						ele => ele.employeeLeaveApplied.leaveStatus == this.statusFilter
					);
					this.templeaveDetailFlow = this.leaveDetailFlow;
				}
				if (this.leaveDetailFlow.length > 0) {
					this.showTable = true
				}
				console.log("leave response ", this.leaveDetailFlow);

			},
			error => {
				console.error("Error while getting Leave For Approval ", error);
			}
		)
	}

	setLeaveMap() {
		this.leaveTypesList.forEach(type => {
			this.leaveTypeMap.set(type.leaveType, type.leaveName)
		})
	}

	getLeaveName(leaveType: any) {
		return this.leaveTypeMap.get(leaveType)
	}

	checkAllSelected(e: any) {
		console.log("checkAllSelected ");

		const checkboxes = document.querySelectorAll('input');
		if (e.target.checked) {
			for (let i = 0; i < checkboxes.length; i++) {
				if (checkboxes[i].type == 'checkbox') {
					checkboxes[i].checked = true;
				}
			}
			this.leavesApproval.splice(0, this.leavesApproval.length)
			console.log(" select all ", this.leavesApproval);

			if (this.searchleave) {
				this.searchLeaves.forEach(task => {
					this.leavesApproval.push()
				});
			} else {
				this.leaveDetailFlow.forEach(task => {
					this.leavesApproval.push(task)
				});
			}
			console.log(" check  all ", this.leavesApproval.length);
		} else {
			for (let i = 0; i < checkboxes.length; i++) {
				if (checkboxes[i].type == 'checkbox') {
					checkboxes[i].checked = false;
				}
			}
			this.leavesApproval.splice(0, this.leavesApproval.length);
			console.log(" end  ", this.leavesApproval.length);
		}
	}

	checkSelected(leave: any, e: any) {
		console.log("check selected ");

		if (e.target.checked) {
			console.log(" before ", this.leavesApproval.length);
			console.log(this.leavesApproval);
			this.leavesApproval.push(leave);
			console.log("after ", this.leavesApproval.length);
			console.log(this.leavesApproval);
		} else {
			console.log("else part before ", this.leavesApproval.length);
			console.log(this.leavesApproval);
			this.leavesApproval = this.leavesApproval.filter(item => item.employeeLeaveApplied.employeeLeaveAppliedId !== leave.employeeLeaveApplied.employeeLeaveAppliedId);
			console.log("else part after ", this.leavesApproval.length);
			console.log(this.leavesApproval);
		}
		console.log("check selected done ");
	}

	onApproveHandler() {
		let approve_reject = true;
		let flag = true
		if (this.leavesApproval.length === 0) {
			this.alertmessages("Please select item for Approval.", "danger")
			flag = false
			return;
		}

		if (flag) {
			this.empLeaveService.leaveForApproval(this.leavesApproval, approve_reject).subscribe(
				data => {
					this.alertmessages("Successfully Approved.", "success")
					this.leavesApproval.splice(0, this.leavesApproval.length)
					this.searchleave = ""
					this.resetAllCheckBoxes()
					this.getLeaveForApproval()
				},
				error => {
					this.searchleave = ""
					console.error("Error ", error);
					const response = error.error;
					if (response.errorMessages != null && response.errorMessages.length !== 0) {
						let err: string = response.errorMessages[0]['key'];
						if (err.toLocaleLowerCase() === "FlowableObjectNotFoundExceptionSingle".toLocaleLowerCase()) {
							this.alertmessages("A requests have already been processed.", "danger")
						} else if (err.toLocaleLowerCase() === "FlowableObjectNotFoundException".toLocaleLowerCase()) {
							this.alertmessages("One or more requests have already been processed.", "danger")
						} else {
							this.alertmessages("Not Approved.", "danger")
						}
					} else {
						this.alertmessages("Not Approved.", "danger")
					}
					this.resetAllCheckBoxes()
					this.leavesApproval.splice(0, this.leavesApproval.length)
					this.getLeaveForApproval()
				}
			)
		}
	}

	onRejectHandler() {
		let approve_reject = false;
		let flag = true
		if (this.leavesApproval.length === 0) {
			this.alertmessages("Please select item for Rejection.", "danger")

			flag = false
			return;
		}

		let isCommentAbsent = false;
		this.leavesApproval.forEach(rejectLeave => {

			if (rejectLeave.employeeLeaveApplied.remark === undefined) {
				isCommentAbsent = true;
				flag = false
				return;
			}
		})

		if (isCommentAbsent) {
			this.alertmessages("Comments are mandatory for Rejection.", "danger")
			return;
		}

		if (flag) {
			this.empLeaveService.leaveForApproval(this.leavesApproval, approve_reject).subscribe(
				data => {
					this.alertmessages("Successfully Rejected.", "success")
					this.leavesApproval.splice(0, this.leavesApproval.length)
					this.searchleave = ""
					this.resetAllCheckBoxes()
					this.getLeaveForApproval()
				},
				error => {
					this.searchleave = ""
					console.error("Error ", error);
					const response = error.error;
					if (response.errorMessages != null && response.errorMessages.length !== 0) {
						let err: string = response.errorMessages[0]['key'];
						if (err.toLocaleLowerCase() === "FlowableObjectNotFoundExceptionSingle".toLocaleLowerCase()) {
							this.alertmessages("A requests have already been processed.", "danger")
						} else if (err.toLocaleLowerCase() === "FlowableObjectNotFoundException".toLocaleLowerCase()) {
							this.alertmessages("One or more requests have already been processed.", "danger")
						} else {
							this.alertmessages("Not Rejected.", "danger")
						}
					} else {
						this.alertmessages("Not Rejected.", "danger")
					}

					this.leavesApproval.splice(0, this.leavesApproval.length)
					this.resetAllCheckBoxes()
					this.getLeaveForApproval()
				}
			)

		}
	}

	searchLeaves: LeaveWorkFlow[] = [];
	chkInput(e: any) {
		console.log("chkinput ", this.query);
		if (this.query.length > 3) {
			console.log("call filter function ");
			this.leaveDetailFlow = this.leaveDetailFlow.filter(item =>
				item.name.toLowerCase().includes(this.query.toLowerCase())
			);
			console.log(this.leaveDetailFlow);
		}
		else {
			this.leaveDetailFlow = this.templeaveDetailFlow;
		}
	}

	resetAllCheckBoxes() {

		const checkboxes = document.querySelectorAll('input');
		for (let i = 0; i < checkboxes.length; i++) {
			if (checkboxes[i].type == 'checkbox') {
				checkboxes[i].checked = false;
			}
		}
		this.searchleave = ""
		this.leavesApproval.splice(0, this.leavesApproval.length)
	}

	alertmessages(message: string, sign: string): void {
		let alertDiv = document.createElement('div');
		alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
		<i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message}</div>`
		let alertcomp = document.querySelector('.alertcomp');
		alertcomp.append(alertDiv);
		document.body.scrollTop = document.documentElement.scrollTop = 0;

		setTimeout(function () {
			alertcomp.removeChild(alertDiv);
		}, 5000);
	}

	navigateLeaveStatus() {
		localStorage.setItem('navigateDashboard', 'true')
	  }

	  setMenuName(name: string) {
		this.menuService.setMenuName(name);
	  }
	  checkAccessForUser() {
		console.log("checkAccessForUser here");
		this.allowToViewReport =this.permissionList.includes('ng.leave.edit.leavestatus.report.permission');
		this.showBasedOnPermissionEmployeeStatus = this.permissionList.includes('ng.employee.search.show.employeestatus');
	  }

	  getEmployeeByNameOrId() {
        console.log("Len ", this.query);
      
        // Ensure the query length is valid (either <= 2 or >= 3)
        if (this.query?.length <= 2 || this.query?.length >= 3) {
          this.empLeaveService.getEmployeeByNameOrUniqueId(this.query).subscribe(
            data => {
              console.log("Emp Data ", data);
      
              // Check if data.employeeMasterData has any entries
              if (data.employeeMasterData.length > 0) {
                let filteredEmployees = data.employeeMasterData;
      
                // Get the current date for comparison
                const currentDate = new Date();
                console.log("Current Date: ", currentDate);  // Log current date for debugging
      
                // Apply filtering logic based on the selected employee status
                if (this.ActiveInativeEmpStatusFilter === 'Active') {
                  filteredEmployees = filteredEmployees.filter(emp => {
                    const leavingDate = emp.leavingdateString ? new Date(emp.leavingdateString) : null;
                    console.log("Leaving Date for " + emp.name + ": ", leavingDate);  // Log leaving date for debugging
                    // Active employees should have leavingdate in the future or null
                    return leavingDate === null || leavingDate > currentDate;
                  });
                } else if (this.ActiveInativeEmpStatusFilter === 'Inactive') {
                  filteredEmployees = filteredEmployees.filter(emp => {
                    const leavingDate = emp.leavingdateString ? new Date(emp.leavingdateString) : null;
                    console.log("Leaving Date for " + emp.name + ": ", leavingDate);  // Log leaving date for debugging
                    // Inactive employees should have leavingdate in the past or null
                    return leavingDate && leavingDate < currentDate;
                  });
                }
      
                // If the status is 'All', no filter is applied
                if (this.ActiveInativeEmpStatusFilter === 'All') {
                  filteredEmployees = data.employeeMasterData;
                }
      
                // Assign filtered data to the employeeMasterLucene
                this.employeeMasterLucene = filteredEmployees;
      
                // Log the data and leaving date of the first employee in the filtered list
                if (this.employeeMasterLucene.length > 0) {
                  this.leavingdate = this.employeeMasterLucene[0].leavingdateString; // Extract the leaving date
                  console.log("Filtered Employee Data: ", this.employeeMasterLucene);
                  console.log("leavingdate", this.leavingdate);
                } else {
                  console.log("No employee data found.");
                }
              } else {
                console.log("No employee data found.");
              }
            },
            error => {
              console.log("Error ", error);
            }
          );
        }
      
        // This line will still be executed before the async request completes,
        // so you may not have a value for 'this.leavingdate' here.
        console.log("Unique Id : " + this.uniqueemployeeid);
      }
      
      onStatusChange() {
        this.getEmployeeByNameOrId();
      }
}
