import { Component, OnInit } from '@angular/core';
import { EmployeeLeaveService } from '../services/employee-leave.service';
import { EmployeeDesignation, EmployeeMaster } from '../models/employeeMasterts';
import { EmployeeMasterservice } from '../services/employeemaster.service';
import { CommonService } from '../services/common.service';
import { DesignationInfo } from '../models/designationInfo';
declare var $: any;
@Component({
  selector: 'app-employee-designation',
  templateUrl: './employee-designation.component.html',
  styleUrls: ['./employee-designation.component.css']
})
export class EmployeeDesignationComponent implements OnInit {
  designation: DesignationInfo;
  query: any;
  limitPara: any;
  offsetPara: any;
  queryName: any;
  id: any;
  employeeMasterLucene: EmployeeMaster[];
  employeedesignationList: EmployeeDesignation[];
  employeeDesgnation: EmployeeDesignation;
  DeleteEmployeeDesgnation: EmployeeDesignation;
  uniqueemployeeid: String;
  allowBlankSearch: boolean = false;
  insertdesignationDetails;
  searchedEmployeeName: string = '';
  selecteduniqueemployeeid: number;
  isToDateReadOnly: boolean = true;
  saveOrUpdate: boolean = true;
  isSearchClicked: boolean;
  isEmployeeFound: boolean;
  DeleteEmployeeDesignation: EmployeeDesignation;
   selectedValue : string='Active';
   leavingdate: any;
   permissionList: any[] = [];
   showBasedOnPermissionEmployeeStatus: boolean = false;

  constructor(private empLeaveService: EmployeeLeaveService, private employeeMasterservice: EmployeeMasterservice) { }

  ngOnInit(): void {
    this.insertdesignationDetails = new EmployeeDesignation();
    this.getdesignations();
    this.limitPara = 0;
    this.offsetPara = 13;
    const maxString = sessionStorage.getItem('permission').replace('[', '');
    const maxStringBox = maxString.replace(']', '');
    const maxStringAgain = maxStringBox.replace(/"/g, '');
    this.permissionList = maxStringAgain.split(',');
    this.checkAccessForUser();
  }

  getdesignations() {
    this.employeeMasterservice.getdesignations().subscribe({
      next: (res) => {
        this.designation = res.designation
      },
      error: (e) => {
        console.log("error occured while getting all the designation ")
      }
    })
  }
  getEmployeeByNameOrId() {
    const [name, id] = this.query.split(' / ');
    this.queryName = name;
    this.uniqueemployeeid = id; // Store the ID if it's available

    console.log("Len ", this.query);

    if (this.queryName.length <= 2) {
      this.employeeMasterLucene = [];
    }

    if (this.queryName.length >= 3) {
      this.empLeaveService.getEmployeeByNameOrUniqueId(this.queryName).subscribe(
        data => {
          console.log("Emp Data ", data);
  
          // Check if data.employeeMasterData has any entries
          if (data.employeeMasterData.length > 0) {
            let filteredEmployees = data.employeeMasterData;
  
            // Get the current date for comparison
            const currentDate = new Date();
            console.log("Current Date: ", currentDate);  // Log current date for debugging
  
            // Apply filtering logic based on the selected employee status
            if (this.selectedValue === 'Active') {
              filteredEmployees = filteredEmployees.filter(emp => {
                const leavingDate = emp.leavingdateString ? new Date(emp.leavingdateString) : null;
                console.log("Leaving Date for " + emp.name + ": ", leavingDate);  // Log leaving date for debugging
                // Active employees should have leavingdate in the future or null
                return leavingDate === null || leavingDate > currentDate;
              });
            } else if (this.selectedValue === 'Inactive') {
              filteredEmployees = filteredEmployees.filter(emp => {
                const leavingDate = emp.leavingdateString ? new Date(emp.leavingdateString) : null;
                console.log("Leaving Date for " + emp.name + ": ", leavingDate);  // Log leaving date for debugging
                // Inactive employees should have leavingdate in the past or null
                return leavingDate && leavingDate < currentDate;
              });
            }
  
            // If the status is 'All', no filter is applied
            if (this.selectedValue === 'All') {
              filteredEmployees = data.employeeMasterData;
            }
  
            // Assign filtered data to the employeeMasterLucene
            this.employeeMasterLucene = filteredEmployees;
  
            // Log the data and leaving date of the first employee in the filtered list
            if (this.employeeMasterLucene.length > 0) {
              this.leavingdate = this.employeeMasterLucene[0].leavingdateString; // Extract the leaving date
              console.log("Filtered Employee Data: ", this.employeeMasterLucene);
              console.log("leavingdate", this.leavingdate);
            } else {
              console.log("No employee data found.");
            }
          } else {
            console.log("No employee data found.");
          }
        },
        error => {
          console.log("Error ", error);
        }
      );
    }
  }

  onStatusChange() {
    this.getEmployeeByNameOrId();
  }

  limitpara() {
    this.limitPara = 0;
  }
  onScroll() {
    this.limitPara = this.limitPara + 1;
    this.searchEmployee();
  }

  searchEmployee() {
    console.log("check1111111111 : ", this.employeedesignationList);
    this.isSearchClicked = true;
    console.log("check : ", typeof this.query);

    // Retrieve the employee name from input field
    let employeename = (<HTMLInputElement>document.getElementById('employeename')).value?.trim() || null;

    // If the unique employee ID is undefined, set it to null
    this.uniqueemployeeid = this.uniqueemployeeid || null;

    // Log the current state for debugging
    console.log("permission : ", this.allowBlankSearch);
    console.log("Unique Id : " + this.uniqueemployeeid);
    console.log("Employee Name : " + employeename);

    // Store the employee name in searchedEmployeeName if it is found
    if (employeename && !Number(employeename)) {
      this.searchedEmployeeName = employeename;
    } else if (this.uniqueemployeeid) {
      this.searchedEmployeeName = `ID: ${this.uniqueemployeeid}`; // If searching by ID, show the ID
    }

    // Check if the search is valid (either allowBlankSearch is true or there is an employee name or unique ID)
    if (!this.allowBlankSearch && !this.uniqueemployeeid && !employeename) {
      // If no valid search criteria, show an error message
      this.alertmessages("Please Enter Employee Name or Unique Employee ID", "danger");
      return;  // Stop further execution
    }

    // If blank search is allowed or if there is an employee name or unique ID, perform the search
    if (this.allowBlankSearch || this.uniqueemployeeid || employeename) {
      // If the employee name is a number, treat it as the unique employee ID
      if (Number(employeename)) {
        console.log("nametosearch : " + employeename);
        this.uniqueemployeeid = employeename;
        employeename = null; // Reset the employee name as it's treated as a unique ID
      }

      // Call the service to get employee designations based on the unique ID or name
      this.employeeMasterservice.getEmployeeDesgignation(this.uniqueemployeeid, employeename, this.limitPara, this.offsetPara).subscribe(
        data => {
          this.insertdesignationDetails.desgname = '';
          this.insertdesignationDetails.fromDate = '';
          this.insertdesignationDetails.toDate = '';

          // If no employee data is returned, display an error message and set flag to false
          if (!data.employeeDesigationData || data.employeeDesigationData.length === 0) {
            this.isEmployeeFound = false;  // Flag indicating employee is not found
            this.alertmessages("No employee found with the provided ID or Name.", "danger");
            return;  // Stop further execution
          }

          // If employee data is returned, set flag to true
          this.isEmployeeFound = true;

          data.employeeDesigationData.forEach(employee => {
            const leavingDate = new Date(employee.leavingdate);
            const currentDate = new Date();

            // If the leaving date is less than the current date, disallow adding new designation
            if (leavingDate < currentDate) {
              // this.alertmessages(`Employee ${employee.name} has left on ${employee.leavingdate}. Cannot assign new designation.`, "danger");
              this.alertmessages("Employee  has left. Cannot assign new designation.", "danger");
              this.isEmployeeFound = false;
              this.employeedesignationList = [];
              return;
            }

          // If it's the first load (limitPara is 0), replace the list; otherwise, append the new data
          if (this.limitPara === 0) {
            this.employeedesignationList = data.employeeDesigationData;
          } else {
            this.employeedesignationList = this.employeedesignationList.concat(data.employeeDesigationData);
          }

          this.employeedesignationList.forEach(data => {
            this.selecteduniqueemployeeid = data.uniqueemployeeid;
          });
        });
        },
        error => {
          // Handle any errors from the service call
          console.log(error);
          this.alertmessages("An error occurred while searching for the employee. Please try again later.", "danger");
        }
      );
    } else {
      // If neither allowBlankSearch is true nor any search criteria are provided, still perform a blank search
      this.employeeMasterservice.getEmployeeDesgignation(null, null, this.limitPara, this.offsetPara).subscribe(
        data => {
          this.insertdesignationDetails.desgname = '';
          this.insertdesignationDetails.fromDate = '';
          this.insertdesignationDetails.toDate = '';
          if (this.limitPara === 0) {
            this.employeedesignationList = data.employeeDesigationData;
          } else {
            this.employeedesignationList = this.employeedesignationList.concat(data.employeeDesigationData);
          }
        },
        error => {
          console.log(error);
          this.alertmessages("An error occurred while searching for the employee. Please try again later.", "danger");
        }
      );
    }
  }






  // Helper method to clear the employee name input
  clearEmployeeNameInput() {
    this.uniqueemployeeid = null;
    this.employeeDesgnation = new EmployeeDesignation();
    (<HTMLInputElement>document.getElementById('employeename')).value = "";
  }



  submit() {
    this.isEditMode = false;
    // Validation for desgname and fromDate
    if (!this.insertdesignationDetails.desgname) {
      this.alertmessages("Designation name is required", "danger");
      return;
    }

    if (!this.insertdesignationDetails.fromDate) {
      this.alertmessages("From date is required", "danger");
      return;
    }

    // Check if the same designation already exists in the list
    const isDuplicateDesignation = this.employeedesignationList.some(
      (designation) => designation.desgname === this.insertdesignationDetails.desgname
    );
    if (isDuplicateDesignation) {
      this.alertmessages("This designation already exists.", "danger");
      return;
    }


    // Get the most recent previous designation from the list (assuming employeedesignationList is sorted by date)
    const previousDesignation = this.employeedesignationList[0]; // Assuming 0th index is the latest entry

    // Check if the designation is a duplicate of the latest one

    // if (previousDesignation && this.insertdesignationDetails.desgname === previousDesignation.desgname) {
    //   this.alertmessages("Continuous entries with the same designation are not allowed", "danger");
    //   return;
    // }

    if (previousDesignation && new Date(this.insertdesignationDetails.fromDate) <= new Date(previousDesignation.fromDate)) {
      // Clear the toDate for the first designation in the list
      if (this.employeedesignationList.length > 0) {
        this.employeedesignationList[0].toDate = null;  // Clear the 'toDate'
      }
      // Display the error message
      this.alertmessages("From date cannot be earlier than the previous From date", "danger");
      return;
    }

    // Validation for toDate being lesser than fromDate
    if (this.insertdesignationDetails.toDate && new Date(this.insertdesignationDetails.toDate) < new Date(this.insertdesignationDetails.fromDate)) {
      this.alertmessages("To date cannot be earlier than From date", "danger");
      return;
    }

    console.log(" save ", this.insertdesignationDetails);
    console.log("Desg Name:", this.insertdesignationDetails.desgname);
    console.log("Desg ID:", this.insertdesignationDetails.designationid);
    console.log("Emp id:", this.insertdesignationDetails.uniqueemployeeid);
    console.log("From date:", this.insertdesignationDetails.fromDate);
    console.log("To date:", this.insertdesignationDetails.toDate);

    this.insertdesignationDetails.uniqueemployeeid = this.selecteduniqueemployeeid;

    this.employeeMasterservice.saveEmployeeDesignation(this.insertdesignationDetails).subscribe(
      (data) => {
        console.log(data);
        this.searchEmployee();
        this.clear();
        this.alertmessages("Successfully saved Employee Designation", "success");
      }, (error) => {
        console.log(error);
        this.alertmessages("Please Try Again", "danger");
      }
    );
  }


  clear() {

    // this.query = '';
    this.insertdesignationDetails.desgname = '';
    // this.insertdesignationDetails.uniqueemployeeid = '';
    this.insertdesignationDetails.fromDate = '';
    this.insertdesignationDetails.toDate = '';

  }


  editdesignation(editId: any) {
    if (this.employeedesignationList.length > 0) {
      this.employeedesignationList[0].toDate = null;  // Clear the 'toDate'
    }
    console.log("edited function ", editId);
    this.isEditMode = true;
    this.employeeMasterservice.getEditEmployeeDesignation(editId).subscribe(
      (data) => {
        console.log(data);
        this.insertdesignationDetails = data.employeeDesigationData[0];
        console.log("this.insertdesignationDetails", this.insertdesignationDetails);

        console.log("this.insertdesignationDetails", this.insertdesignationDetails);
        this.saveOrUpdate = false;


      }, (error) => {
        console.log(error)
        this.alertmessages("Please Try Again", "danger");
      });
  }

  updatedesignation() {
    // Validation for desgname and fromDate
    if (!this.insertdesignationDetails.desgname) {
      this.alertmessages("Designation name is required", "danger");
      return;
    }

    if (!this.insertdesignationDetails.fromDate) {
      this.alertmessages("From date is required", "danger");
      return;
    }

    const isDuplicateDesignation = this.employeedesignationList.some(
      (designation) => 
        designation.desgname === this.insertdesignationDetails.desgname &&
        designation.designationid !== this.insertdesignationDetails.designationid
    );
    
    if (isDuplicateDesignation) {
      this.alertmessages("This designation already exists.", "danger");
      return;
    }

    // Get the most recent previous designation from the list (assuming employeedesignationList is sorted by date)
    const previousDesignation = this.employeedesignationList[1]; // Assuming 0th index is the latest entry

    // if (previousDesignation && this.insertdesignationDetails.desgname === previousDesignation.desgname) {
    //   this.alertmessages("Continuous entries with the same designation are not allowed", "danger");
    //   return;
    // }
    if (previousDesignation && new Date(this.insertdesignationDetails.fromDate) < new Date(previousDesignation.fromDate)) {
      this.alertmessages("From date cannot be earlier than the previous From date", "danger");
      return;
    }

    // Validation for toDate being lesser than fromDate
    if (this.insertdesignationDetails.toDate && new Date(this.insertdesignationDetails.toDate) < new Date(this.insertdesignationDetails.fromDate)) {
      this.alertmessages("To date cannot be earlier than From date", "danger");
      return;
    }
    console.log(" save ", this.insertdesignationDetails);
    console.log("Desg Name:", this.insertdesignationDetails.desgname);
    console.log("Desg ID:", this.insertdesignationDetails.designationid);
    console.log("Emp id:", this.insertdesignationDetails.uniqueemployeeid);
    console.log(" totime ", this.insertdesignationDetails.fromDate);
    console.log(" fromtime ", this.insertdesignationDetails.toDate);
    this.insertdesignationDetails.uniqueemployeeid = this.selecteduniqueemployeeid;
    this.employeeMasterservice.getupdateEmployeeDesignation(this.insertdesignationDetails).subscribe(
      (data) => {
        console.log(data);
        this.searchEmployee();
        this.clear();
        this.isEditMode = false;
        this.saveOrUpdate = true;
        this.alertmessages("Successfully Updated Employee Designation", "success");

      }, (error) => {
        console.log(error)
        this.alertmessages("Please Try Again", "danger");
      });
  }
  onDeleteHandler(info: any) {
    console.log("delete : ", info);
    this.DeleteEmployeeDesignation = info;
    $('#showAlertVer').modal('toggle');
  }

  closeMRQModal() {
    $('#showAlertVer').modal('toggle');

  }

  deletemployeedesignation() {
    const deleteId = this.DeleteEmployeeDesignation.id;
    console.log("deleteId", deleteId);
    this.employeeMasterservice.deleteEmployeeDesgnation(deleteId).subscribe(
      (data) => {
        this.searchEmployee();
        this.isEditMode = false;
        this.saveOrUpdate = true;
        $('#showAlertVer').modal('toggle');
        this.alertmessages(data.successMessages[0]['key'], "success")


      }, (error) => {
        console.log(error)
        $('#showAlertVer').modal('toggle');
        this.alertmessages(" Please Try Again ", "danger")

      }
    );


  }





  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  };

  isEditMode: boolean = false;

  todatechange(from_date: any) {
    console.log("From Date change -- ", from_date);

    // Ensure from_date and employeedesignationList are valid
    if (from_date && this.employeedesignationList.length > 0) {
      const fromDateObj = new Date(from_date);

      // Subtract one day to calculate the "To Date"
      fromDateObj.setDate(fromDateObj.getDate() - 1);
      const toDate = fromDateObj.toISOString().split('T')[0];

      if (!this.isEditMode) {
        // Insert Mode: Set "To Date" for the 0th index
        const hasValidFirstFromDate =
          this.employeedesignationList[0]?.fromDate &&
          new Date(this.employeedesignationList[0].fromDate).toString() !== "Invalid Date";

        if (hasValidFirstFromDate) {
          this.employeedesignationList[0].toDate = toDate;
          console.log(`To Date set for 0th index (Insert Mode): ${toDate}`);
        } else {
          console.log("No valid 'From Date' found at index 0. 'To Date' not set.");
        }
      } else {
        // Edit Mode: Set "To Date" for the 1st index
        if (this.employeedesignationList.length > 1) {
          this.employeedesignationList[1].toDate = toDate;
          console.log(`To Date set for 1st index (Edit Mode): ${toDate}`);
        } else {
          console.log("No 1st index found in the list. 'To Date' not set.");
        }
      }
    } else {
      console.log("From Date is invalid or no designations in the list.");
    }

    console.log("Updated Employee Designation List: ", this.employeedesignationList);
  }

  checkAccessForUser() {
    console.log("checkAccessForUser here")
    this.showBasedOnPermissionEmployeeStatus = this.permissionList.includes('ng.employee.search.show.employeestatus');
  }
}
