import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { LeaveMaster } from '../models/leaveMaster';
import { ApplicationSettingsService } from '../services/application-settings.service';
import { EmployeeMasterservice } from '../services/employeemaster.service';
import { Level } from '../models/Level';
import { AdminConfig } from '../models/AdminConfig';
import { LeaveAuthority } from '../models/LeaveAuthority';
declare var $: any

@Component({
  selector: 'app-leave-master',
  templateUrl: './leave-master.component.html',
  styleUrls: ['./leave-master.component.css']
})
export class LeaveMasterComponent implements OnInit {
  DeleteLeaveMaster:any;


  constructor(private applicationSettingsService: ApplicationSettingsService, private render: Renderer2,
    private employeeMasterservice: EmployeeMasterservice) {
    this.initialize();
  }
  @ViewChild('leaveform', { static: false }) leave: NgForm;
  @ViewChild('leave_Name') leavecontol: NgModel

  leaveMaster: LeaveMaster = new LeaveMaster();
  basedOn: any; // option
  carryForward: boolean; // caryfoward option t/f
  compensatoryOFf: boolean;  // option y n , 
  creditRule: any; // field set here , radio 4 + text 
  colorCode: string = '#ff0000';
  enacashSeperation: boolean; // option y n ,
  encashable: boolean; // option
  frequency: any; // option
  interveneHoliday: boolean = false; // option y n , intervening
  leaveCredited: any; // text 
  leaveName: any; // leave name 
  leaveOption: any; // option paid/unpaid
  leaveType: any; // leavetype
  noOfDaysWorked: any; //text 
  statutoryCode: any; // option
  totalPercenatage: any; // caryforward 2 radio + 2 text 
  type: any; // option
  validDays: any; // text 
  dayAfterJoin: any; // text 
  flatRate: any;  // text 
  add_view_toggle: any;
  LeaveTypeList: [];
  maxLeaveCarryForward: number;
  accrual: any = false;
  addOrUpdate: boolean = true;
  totalencashmentpercent: any;
  maxleaveencashable: number;

  // here
  errorMessages: any;
  approveLevel: number = 1;   // single variable is enough
  approvalLimit: number = 1;  // single variable is enough
  leaveAuthorities: LeaveAuthority[] = [];
  JobList: [];
  dpartmentList: [];

  ngOnInit(): void {

    console.log(" INITIAL ")
    // initilize and push
    let obj = new LeaveAuthority();
    this.leaveAuthorities.push(obj);

    this.getJobList()
    this.viewLeaveTypeButton();
    this.getDepartment();

    // on click search or add , uto scroll down 
    this.render.listen(document.getElementById('navigatetarget'), 'click', () => {
      $('html,body').animate({
        scrollTop: $(`#navigatedest`).offset().top - 90
      },
        'slow');
    })
  }

  initialize() {
    this.basedOn = 'Frequency';
    this.carryForward = true;
    this.leaveOption = 'Paid Leave';
    this.encashable = true;
    this.type = 'F';
    this.totalPercenatage = 'P';
    this.interveneHoliday = false;
    this.compensatoryOFf = false;
    this.enacashSeperation = false;
    this.flatRate = 0;
    this.frequency = 'YR';
    this.add_view_toggle = 'viewLeaveType';
    this.totalencashmentpercent = 0;
    this.maxleaveencashable = 0;
  }

  ModelToClassValidator() {
    console.warn("ModelToClassValidator ");
    this.leaveMaster.leaveName = this.leaveName;
    this.leaveMaster.leaveType = this.leaveType;
    this.leaveMaster.basedOn = this.basedOn;
    this.leaveMaster.frequency = this.frequency;
    this.leaveMaster.noOfDaysWorked = this.noOfDaysWorked;
    this.leaveMaster.type = this.type;
    this.leaveMaster.leaveCredited = this.leaveCredited;
    this.leaveMaster.creditRule = this.creditRule;
    this.leaveMaster.dayAfterJoin = this.dayAfterJoin;
    this.leaveMaster.carryForward = this.carryForward;
    this.leaveMaster.interveneHoliday = this.interveneHoliday;
    this.leaveMaster.leaveOption = this.leaveOption;
    this.leaveMaster.statutoryCode = this.statutoryCode;
    this.leaveMaster.encashable = this.encashable;
    this.leaveMaster.compensatoryOFf = this.compensatoryOFf;
    this.leaveMaster.enacashSeperation = this.enacashSeperation;
    this.leaveMaster.validDays = this.validDays;
    this.leaveMaster.totalPercenatage = this.totalPercenatage;
    this.leaveMaster.flatRate = this.flatRate;
    this.leaveMaster.colorCode = this.colorCode;
    this.leaveMaster.accrual = this.accrual;
    this.leaveMaster.maxLeaveCarryForward = this.maxLeaveCarryForward;
    this.leaveMaster.totalencashmentpercent = this.totalencashmentpercent;
    this.leaveMaster.maxleaveencashable = this.maxleaveencashable;
    if (this.leaveMaster.basedOn === 'Frequency') { // check if selected is frequency 
      this.leaveMaster.noOfDaysWorked = null;
      this.leaveMaster.leaveCredited = null;

      if (this.leaveMaster.type === 'F') { // check if selected is frequency and type is F
        this.leaveMaster.creditRule = null;
        this.leaveMaster.dayAfterJoin = null;
      } else if (this.leaveMaster.type === 'V') { // check if selected is frequency and type is V and option is not JD
        if (this.leaveMaster.creditRule != 'JD') {
          this.leaveMaster.dayAfterJoin = null;
        }
      }
    } else if (this.leaveMaster.basedOn === 'Number_of_Days_Worked') { // check if selected is Number_of_Days_Worked 
      this.leaveMaster.frequency = null;
      this.leaveMaster.type = null;
      this.leaveMaster.creditRule = null;
      this.leaveMaster.dayAfterJoin = null;
    }
    if (this.leaveMaster.carryForward === false) {
      this.leaveMaster.totalPercenatage = null;
      this.leaveMaster.flatRate = 0;
      this.leaveMaster.maxLeaveCarryForward = 0;
    }
    if (this.leaveMaster.compensatoryOFf === false) {
      this.leaveMaster.validDays = 0;
    }
    if (this.leaveMaster.encashable === false) {
      this.leaveMaster.totalencashmentpercent = null;
      this.leaveMaster.maxleaveencashable = 0;

    }
  }

  leaveAuthorityValidation(): boolean {
    console.log("leaveAuthorityValidation ");
    const hasUndefinedJobRole = this.leaveAuthorities.some(item => item.jobRole === undefined);
    return hasUndefinedJobRole;
  }

  submit() {
    console.warn("submit ");

    if (this.leaveAuthorityValidation()) {
      console.log("there is undefined job role in list ");
      this.alertmessages("Please Check Leave Approval", "danger");
      return;
    }
    if (this.authorityValidation()) {
      return;
    }

    this.leave.form.markAllAsTouched()
    if (this.leave.invalid) {
      // Handle invalid form submission
      return;
    }
    // console.log("color code ", this.colorCode)
    this.ModelToClassValidator()
    // validation to check if the _ = _ before sending res 
    if ((this.leaveMaster.leaveName != null || this.leaveMaster.leaveName != undefined) && (this.leaveMaster.leaveType != null || this.leaveMaster.leaveType != undefined) && (this.leaveMaster.leaveName.length > 0 && this.leaveMaster.leaveType.length > 0)) {

      const obj = {
        authoritylimit: this.approvalLimit,
        authorityList: this.leaveAuthorities,
        info: this.leaveMaster
      }

      // response / request 
      this.applicationSettingsService.saveLeaveMaster(obj).subscribe(res => {
        console.log("response", res)
        this.alertmessages("Successfully Saved ", "success")
        this.leaveName = "";
        this.leaveType = "";
        this.initialize();
        this.viewLeaveTypeButton();
      }, error => {
        console.log(error)
        this.alertmessages("Please try again later", "danger")
      })

    } else {
      this.alertmessages("Please Fill all the required field", "danger")
    }
    this.leave.reset();
  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }

  getLeaveType() {
    console.warn("getLeaveType ");

    this.employeeMasterservice.getLeaveType().subscribe(
      data => {
        this.LeaveTypeList = data;
        console.log("List of all the leave type available  :", this.LeaveTypeList);
      }, error => {
        console.log(error);
      }
    );

  }

  addLeaveTypeButton() {
    console.warn("addLeaveTypeButton ");
    this.approveLevel = undefined;
    this.approvalLimit = 1;
    this.add_view_toggle = "addLeaveType";
    // console.warn("add view toggle to add");
  }

  viewLeaveTypeButton() {
    console.warn("viewLeaveTypeButton ");
    // this.clear();
    // console.warn("clear");
    this.getLeaveType();
    // console.warn("get");
    this.addOrUpdate = true;
    // console.warn("add true");
    this.add_view_toggle = "viewLeaveType";
    // console.warn("view ");

    // console.warn("leave name = ' ' ");
    this.approveLevel = 1;
    this.approvalLimit = 1;
    this.clear();
    console.log("viewLeaveTypeButton approval and limit ", this.approveLevel, " ", this.approvalLimit);

  }

  updateLeave() {
    console.warn("updateLeave ");
    if (this.leaveAuthorityValidation()) {
      console.log("there is undefined job role in list ");
      this.alertmessages("Please Fill all Job Role ", "danger");
      return;
    }
    if (this.authorityValidation()) {
      return;
    }
    // console.warn("toggle updateLeave");
    this.addOrUpdate = true;
    // console.warn("add true ");
    this.add_view_toggle = "addLeaveType";
    // console.warn("add view toggle to add");
    this.addLeaveTypeButton();
    this.updateBranch();
    // console.warn("update branch");
  }

  editBranch(leaveobj) {

    this.approveLevel = undefined;
    this.approvalLimit = 1;


    console.warn("edit function ");
    console.log("edit leave ", this.approveLevel, "   limit ", this.approvalLimit);
    // console.warn(leaveobj);
    this.addOrUpdate = false;  // on clik edit make add button to update button
    // ------------------------------------------------------- 
    // leave name and type 
    this.leaveName = leaveobj.info.leaveName;  //Leave Name 1
    this.leaveType = leaveobj.info.leaveType; //Leave Type1
    //based 
    this.basedOn = leaveobj.info.basedOn; //Based On , Frequency / Number_of_Days_Worked
    //based > frequency 
    this.frequency = leaveobj.info.frequency;  // F , Monthly / Quarterly /Half Year / Yearly
    // based > no of days 
    this.noOfDaysWorked = leaveobj.info.noOfdaysworked; //Number of Days Worked
    // type | fixed 
    this.type = leaveobj.info.type; // type , Fixed/Variable
    // type > variable 
    this.leaveCredited = leaveobj.info.noOfLeaveCredit;  //>Number of Leave to be Credited
    // variable >leave credit rule 
    this.creditRule = leaveobj.info.leaveCreditRule; // Leave Credit Rule  , Date of Joining/Date of Confirmation/Days after Joining/Date after Confirmation
    // variable > leave credit rule > Days after Joining
    this.dayAfterJoin = leaveobj.info.leaveCreditRuleDays; // Days after Joining
    this.carryForward = leaveobj.info.carryForward; // Carry Forward , Yes / No
    this.interveneHoliday = leaveobj.info.interveneHoliday;  // Consider Intervening Holiday , Yes / No 
    this.leaveOption = leaveobj.info.paidUnpaidLeave; // paid / unpaid  
    this.encashable = leaveobj.info.encashable; // Encashable , YEs /No
    this.statutoryCode = leaveobj.info.statutoryCode;  // Statutory Code
    this.compensatoryOFf = leaveobj.info.compOff; // Compensatory Off
    this.enacashSeperation = leaveobj.info.seperationEncash; // Encashment @ separation
    this.validDays = leaveobj.info.compOffValidityDays; //Validity Days
    // Carry Forward Rules > 
    this.totalPercenatage = leaveobj.info.totalPercenatage; // totalPercenatage , P/T
    //save both Flat / Rate & totalPercenatage
    this.flatRate = leaveobj.info.flatRate; //Flat / Rate & totalPercenatage
    // console.warn(this.flatRate, "flat rate")
    this.colorCode = leaveobj.info.colorcode; // Color Code
    this.accrual = leaveobj.info.accrual;
    this.maxLeaveCarryForward = leaveobj.info.maxLeaveCarryForward;
  
    this.totalencashmentpercent = leaveobj.info.totalencashmentpercent; // leave encashment
    this.maxleaveencashable = leaveobj.info.maxleaveencashable; // leave encashment

    // edit empty the list reson is when newly add levave is not c;eared then the exisitng data is shown 
    this.leaveAuthorities = [];
    if (leaveobj.authorityList.length != 0) {
      this.approveLevel = leaveobj.authorityList.length;
      this.approvalLimit = leaveobj.authorityList[0].approvalLimit;
      console.log("this approval leave inside if ", this.approveLevel, "   ", this.approvalLimit);
      this.leaveAuthorities = leaveobj.authorityList;
    }

    this.ModelToClassValidator();
    this.add_view_toggle = "addLeaveType"; // on change update button and open detail for editing 
    console.warn("values leavemaster inside edit : ", leaveobj);
  }

  clear() {
    console.warn("clear funtion ")

    this.leaveName = "";
    this.leaveType = "";
    this.accrual = false;
    this.statutoryCode = null;
    this.noOfDaysWorked = null;
    this.leaveCredited = null;
    this.creditRule = null;
    this.dayAfterJoin = null;
    this.maxLeaveCarryForward = 0;
    this.basedOn = 'Frequency';
    this.carryForward = true;
    this.leaveOption = 'Paid Leave';
    this.encashable = true;
    this.type = 'F';
    this.totalPercenatage = 'P';
    this.interveneHoliday = false;
    this.compensatoryOFf = false;
    this.enacashSeperation = false;
    this.flatRate = 0;
    this.frequency = 'YR';
    this.add_view_toggle = 'viewLeaveType';
    this.approveLevel = undefined;
    this.approvalLimit = 1;
    this.totalencashmentpercent = 0;
    this.maxleaveencashable = 0;

  }

  debugger
  updateBranch() {
    console.warn("updateBranch ");

    // console.log("update clicked ");
    this.ModelToClassValidator()
    console.warn("values chekc this  123456 : ", this.leaveMaster);

    const obj = {
      authoritylimit: this.approvalLimit,
      authorityList: this.leaveAuthorities,
      info: this.leaveMaster,
    }

    this.employeeMasterservice.UpdateLeaveType(obj).subscribe(
      data => {
        console.log("Update Leave", data);
        this.alertmessages("Successfully Updated ", "success");
        this.leavecontol.reset();
        this.viewLeaveTypeButton() // clear and redirect to view 
      }, error => {
        console.log("Error Occured While Updateing", error)
        this.clear(); // simple clear 
      }
    );
  }

  
  deleteLeave() {
    const deleteId = this.DeleteLeaveMaster.info.leaveType;
    console.log("deleteId", deleteId);
    console.log(this.DeleteLeaveMaster)
    this.employeeMasterservice.deleteLeaveType(deleteId).subscribe(
      (data) => {
        this.getLeaveType();
        $('#showAlertVer').modal('toggle');
        this.alertmessages("Successfully deleted ", "success");

      }, (error) => {
        console.log(error)
        $('#showAlertVer').modal('toggle');
        this.alertmessages("Leave type cannot be deleted as it's in use", "danger");

      }
    );


  }


  onDeleteHandler(info: any) {
    console.log("delete : ", info);
    this.DeleteLeaveMaster = info;
    $('#showAlertVer').modal('toggle');
  }

  closeMRQModal() {
    $('#showAlertVer').modal('toggle');

  }


  checkApprovalLevel() {
    console.log("check appoval level ");
    console.log("leave before ", this.leaveAuthorities)

    if (this.approveLevel > this.leaveAuthorities.length) {
      console.log("add ");
      let obj = new LeaveAuthority();

      this.leaveAuthorities.push(obj);
      // this.leaveAuthorities.reverse();

      console.log("leave ", this.leaveAuthorities)
      console.log("leave ", this.leaveAuthorities.length)

    } else {


      // this.LeaveTypeList.reverse();
      this.leaveAuthorities.pop();
      // this.LeaveTypeList.reverse();

      console.log("leave ", this.leaveAuthorities)
      console.log("leave ", this.leaveAuthorities.length)
    }
    console.log("leave after ", this.leaveAuthorities)

  }

  getJobList() {
    console.warn("getJobList ");
    this.employeeMasterservice.getJobType().subscribe(
      data => {
        this.JobList = data;
        console.log(this.JobList);

      }, error => {
        console.log(error);
      }
    );
  }

  getDepartment() {
    this.applicationSettingsService.getDepartmentType().subscribe(
      (data) => {
        console.log("Data available :", data);
        this.dpartmentList = data.departmentDetail;
        console.log("List of all the branch available :", this.dpartmentList);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  authorityValidation() {
    console.log("inside authority validation ");
    debugger
    let ele1, ele2, ele3;

    console.log("leaveAuthorities ", this.leaveAuthorities);


    this.leaveAuthorities.forEach((item, index) => {
      if (index === 0) {
        ele1 = item.jobRole;
      } else if (index === 1) {
        ele2 = item.jobRole;
      } else if (index === 2) {
        ele3 = item.jobRole;
      }
    });
    console.log("value of ele1  ", ele1, "ele2  ", ele2, "ele3  ", ele3);


    // Comparison logic
    if (ele1 !== undefined && ele2 !== undefined && ele3 !== undefined) {
      console.log("3 level approval ");

      if (ele2 > ele1) {
        console.log("JobRole is higher than Approval level 2");
        this.updateLabel('JobRole1', "JobRole is higher than Approval level 1");
        return true;
      }

      if (ele2 == ele1) {
        console.log("JobRole is equal to Approval level 2");
        this.updateLabel('JobRole1', "JobRole is equal to Approval level 1");
        return true;
      }

      if (ele3 > ele2) {
        console.log("JobRole is higher than Approval level 2");
        this.updateLabel('JobRole2', "JobRole is higher than Approval level 2");
        return true;
      }

      if (ele3 == ele2) {
        console.log("JobRole is equal to Approval level 2");
        this.updateLabel('JobRole2', "JobRole is equal to Approval level 2");
        return true;
      }
      if (ele1 > ele2 && ele2 > ele3) {

        console.log("proper ele1  ", ele1, "ele2  ", ele2, "ele3  ", ele3);

        this.hideLabel('JobRole0');
        this.hideLabel('JobRole1');
        this.hideLabel('JobRole2');
      }

    } else if (ele1 !== undefined && ele2 !== undefined) {
      console.log("two level approval ");

      if (ele2 > ele1) {
        console.log("JobRole is higher than Approval level 1");
        this.updateLabel('JobRole1', "JobRole is higher than Approval level 1");
        return true;
      }

      if (ele2 == ele1) {
        console.log("JobRole is equal to Approval level 1");
        this.updateLabel('JobRole1', "JobRole is equal to Approval level 1");
        return true;
      }
      if (ele1 > ele2) {
        this.hideLabel('JobRole1');
      }
    }
  }

  updateLabel(jobRoleName: string, message: string) {
    console.log("update Label ", jobRoleName);
    const label = document.getElementById(`${jobRoleName}-label`);
    if (label) {
      label.textContent = message;
      label.style.display = 'block'; // Ensure the label is displayed
    }
  }

  hideLabel(jobRoleName: string) {
    const label = document.getElementById(`${jobRoleName}-label`);
    if (label) {
      label.style.display = 'none'; // Hide the label
    }
  }

}

