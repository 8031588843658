<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp"></div>
        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
              <div class="card">
                <div class="card-body">
                        <form id="fcForm" class="form-horizontal form-label-left">
                            <div class="row col-md-12 col-sm-12 col-xs-12">
                                <!-- report from   (keyup.enter)="limitpara();searchOTReport()"-->
                                <div *ngIf="displayDateField" class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label class="form-label">{{dateFromLable}}</label>
                                        <div>
                                            <input class="form-control col-md-12 col-xs-12" type="date" name="fromDate"
                                                autocomplete="off" autofocus="true" [disabled]="false"
                                                [(ngModel)]="fromDate" (ngModelChange)="checkFromAndToDate()" required>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="displayDateField" class="col-md-4 col-sm-4 col-xs-4">
                                    <div  class="form-group label-floating">
                                        <label class="form-label">{{dateToLable}}</label>
                                        <div>
                                            <input class="form-control col-md-12 col-xs-12" type="date" name="toDate"
                                                autocomplete="off" autofocus="true" [disabled]="false"
                                                [(ngModel)]="toDate" (ngModelChange)="checkFromAndToDate()" required>
                                            <p *ngIf="endDate" class="help-block " style="color: #ff5757;">
                                                ⓘ End date should be greater than start date
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="row"> -->
                                        <div [ngClass]="displayDateField ? 'col-md-4 col-sm-4 col-xs-4' : 'col-md-3 col-sm-3 col-xs-3'">
                                            <div class="form-group label-floating"> <label class="form-label">Employee Name / ID </label>
                                                <div class="form-group m-0">
                                                    <div>
                                                        <input type="search" class="form-control col-md-12 col-xs-12" name="employeename" id="employeename"
                                                            placeholder="Enter Employee Name / ID" [(ngModel)]="employeename" autocomplete="off"
                                                            (input)="getEmployeeByNameOrId()" list="employeeMasterLucene">
                                                        <datalist id="employeeMasterLucene">
                                                            <option [value]="emp.name + '/' + emp.employeeid" *ngFor="let emp of employeeMasterLucene">
                                                                {{emp.employeeid}}
                                                            </option>
                                                        </datalist>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                <div class="col-md-3 col-sm-4 col-xs-4">
                                    <div class="form-group">
                                      <label class="form-label">Type </label>
                                  
                                      <select id="TypeFilters" class="form-control" [(ngModel)]="typeFilter"
                                         name="TypeFilters"  (change)=" onStatusChange()" >
                                        <option value="A">Advance</option>
                                        <option value="L">Loan</option>
                                      </select>
                                  
                                    </div>
                                </div>

                                <div class="col-md-3 col-sm-4 col-xs-4">
                                    <div class="form-group">
                                      <label class="form-label">Select Status </label>
                                  
                                      <select id="leaveStatusFilters" class="form-control" [(ngModel)]="statusFilter"
                                         name="dateFilter" (change)=" onStatusChange()">                                      
                                        <option value="PENDING" selected>Pending</option>
                                        <option value="APPROVED">Approved</option>
                                        <option value="REJECTED">Rejected</option>
                                        <option value="PAID">Disbursed</option>
                                        <option value="RECOVERD">Recovered</option>
                                      </select>
                                  
                                    </div>
                                </div>
                                <!-- dep. dropdown -->
                                <div class="col-md-3 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                      <label class="form-label"> Department </label>
                                      <div>               
                                        <select class="form-control col-md-12 col-xs-12" style="appearance: auto !important;"
                                          data-style="btn btn-drpdwn btn-round" id="dGroup" name="department" 
                                          [(ngModel)]="department" (ngModelChange)="modelDepartment($event)">
                                          <option [value]="0" selected>All</option>
                                          <option [value]="item.departmentId" *ngFor="let item of departments">{{item.name}}</option> 
                                        </select>                                       
                                      </div>
                                    </div>
                                </div>  
                                                              
                            
                            <div  class="col-md-3 col-sm-3 col-xs-3 mb-4" >  
                                <label class="form-label col-md-12 col-sm-4 col-xs-4 mb-1">&nbsp;</label>
                                <div class="form-group m-0  d-flex justify-content-between">
                                    <button type="button" id="navigateclient" class="btn btn-primary w-45"
                                    style="margin-right: 1vw" (click)="search();limitpara();scrollToBottom()"> Search
                                                </button>
                                     <button type="button" class="btn btn-danger w-45" (click)="clear()">Clear</button>
                                </div>
                          </div>  
                    </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="empty-div-one" id="navigatedest"></div>


        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
         <!-- <div class="row">
            <div class="col-md-12"> -->
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title ">Salary Advance/Loan Report  </h4>
 
                        <button type="button" class="btn btn-primary ms-auto" (click)="salaryAdvanceReportDownload()">
                            <i class="fa fa-download" data-bs-toggle="tooltip" data-original-title="download"></i>
                        </button>
                    </div>
                    <!-- <div class="empty-div-one" style="height: 10px;" id="navigatedest"></div> -->
                    <div class="card-body">
                        <div class="row">
                        <div class="col-xs-12  mobile view">

                            <div class="scrollsearchresults" 
                                        infiniteScroll
                                         [infiniteScrollDistance]="1" 
                                         [infiniteScrollUpDistance]="1.5" 
                                         [infiniteScrollThrottle]="100"
                                         [scrollWindow]="false" 
                                         (scrolled)="onScroll()" 
                                         id="scrolllength">
                                <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                    id="hr-table">
                                    <thead class="tablerow">
                                        <tr class=" stickyatTop rowcolors ">

                                            <th class="border-bottom-0 fs-7 ">Employee ID</th>
                                            <th class="border-bottom-0 fs-7 ">Employee Name</th>
                                            <th class="border-bottom-0 fs-7 ">
                                                <span *ngIf="typeFilter === 'L'">Loan</span><span *ngIf="typeFilter === 'A'">Advance</span> Amount
                                            </th>
                                            <th class="border-bottom-0  fs-7 " *ngIf="typeFilter === 'L'">Disbursed Amount</th>
                                            <th class="border-bottom-0  fs-7 " *ngIf="typeFilter != 'A'">Recovered Amount</th>
                                            <th class="border-bottom-0  fs-7 " *ngIf="typeFilter != 'A'">Remaining Amount</th>
                                            <th class="border-bottom-0  fs-7 " *ngIf="typeFilter != 'A'">Pending Installments</th>
                                            <th class="border-bottom-0  fs-7 ">Status</th>
                                            <th class="border-bottom-0 fs-7 " >
                                                <span *ngIf="statusFilter === 'PENDING'" >Applied Date</span>
                                                <span *ngIf="statusFilter === 'APPROVED'" >Approved Date</span>
                                                <span *ngIf="statusFilter === 'PAID' || statusFilter === 'RECOVERD' "  >Disbursed Date</span>
                                                <span *ngIf="statusFilter === 'REJECTED'"  >Rejected Date</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let sal of salaryAdvanceList ; let i = index">
                                            <td>{{ sal.employeeid }}</td>
                                            <td>{{ sal.employeeName }}</td>
                                            <td class="text-right" style="width: 100px;">{{ sal.totalAmount | currency : "INR" : "symbol" : "1.2-2"}}</td>
                                            <td *ngIf="typeFilter === 'L'">
                                                <span *ngIf="statusFilter === 'PAID'">{{ sal.totalAmount | currency : "INR" : "symbol" : "1.2-2"}}</span>
                                                <span *ngIf="statusFilter != 'PAID'">0.00</span>   
                                            </td>
                                            <td class="text-right" *ngIf="typeFilter != 'A'">{{ sal.recoveredAmount | currency : "INR" : "symbol" : "1.2-2"}}</td>
                                            <td class="text-right" *ngIf="typeFilter != 'A'">{{ sal.pendingAmount | currency : "INR" : "symbol" : "1.2-2"}}</td>
                                            <td  *ngIf="typeFilter != 'A'">{{ sal.pendingInstallments}}</td>
                                            <td style="width: 70px;">
                                                <span *ngIf="sal.status === 'PENDING'" class="badge badge-warning" style="width: 67px;">{{sal.status  | titlecase }}</span>
                                                <span *ngIf="sal.status === 'APPROVED'" class="badge badge-success" style="width: 67px;">{{sal.status  | titlecase }}</span>
                                                <span *ngIf="sal.status === 'REJECTED'" class="badge badge-danger" style="width: 67px;">{{sal.status  | titlecase }}</span>
                                                <span *ngIf="sal.status === 'PAID'" class="badge badge-primary" style="width: 67px;">Disbursed</span>
                                                <span *ngIf="sal.status === 'RECOVERD'" class="badge badge-success" style="width: 67px;">{{sal.status  | titlecase }}</span>
                                            </td>
                                            <td  *ngIf="statusFilter != 'RECOVERD'">
                                                <span *ngIf="sal.status === 'PENDING'"  >{{sal.appliedDate | date: 'mediumDate'}}</span>
                                                <span *ngIf="sal.status === 'APPROVED'"  >{{sal.approvedDate | date: 'mediumDate'}}</span>
                                                <span *ngIf="sal.status === 'PAID'"  >{{sal.paidDate | date: 'mediumDate'}}</span>
                                                <span *ngIf="sal.status === 'REJECTED'">{{sal.rejectedDate | date: 'mediumDate'}}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 

    </div>
</div>