<div class="app-content main-content">
    <div class="side-app main-container ">
        <div class="alertcomp"></div>

        <!-- <div class="card-body px-0 custom-card-margin">
            <div class="page-leftheader my-3">
                <div class="page-title">Leave Master</div>
            </div>
        </div> -->

        <div class="row">
            <!-- <div class="col-md-12"> -->
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card-content">
                    <form #leaveform="ngForm">
                        <section>

                            <div class="card card-add-leave-margin">
                                <div class="card-body row">

                                    <!-- leave type and leave name  -->

                                    <div class=" row col-md-6 col-sm-6 col-xs-6">
                                        <div class="form-group label-floating px-5">
                                            <label class="form-label">Add Leave Name </label>
                                            <!-- <div>
                                                    <input type="text" class="form-control col-md-12 col-xs-12"
                                                        autocomplete="off" placeholder="Enter Leave Name"
                                                        name="leaveName" [(ngModel)]="leaveName" autofocus="true" />
                                                </div> -->
                                            <div>
                                                <input type="text" name="leave_Name"
                                                    class="form-control col-md-12 col-xs-12" autocomplete="off"
                                                    placeholder="Enter Leave Name" autofocus="true"
                                                    [(ngModel)]="leaveName" required #leave_Name="ngModel"
                                                    [ngClass]="(!leave_Name.valid && leave_Name.touched) ? 'is-invalid' : ''" />
                                            </div>
                                            <span class="help-text text-danger"
                                                *ngIf="(!leave_Name.valid && leave_Name.touched)">Enter Leave
                                                Name</span>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                        <div
                                            class="form-group label-floating justify-content-between align-items-center">
                                            <label class="form-label">&nbsp;</label>
                                            <button *ngIf="addOrUpdate; else updateButton" type="button"
                                                class="btn btn-primary w-40" id="navigatetarget"
                                                style="margin-right: 3vw;margin-left: 2vw;"
                                                (click)="addLeaveTypeButton()">
                                                Add
                                            </button>
                                            <ng-template #updateButton>
                                                <button type="button" id="navigatetarget" class="btn btn-primary w-40"
                                                    style="margin-right: 3vw;margin-left: 2vw;" (click)="updateLeave()">
                                                    Update
                                                </button>
                                            </ng-template>
                                            <button type="button" class="btn btn-danger w-40"
                                                (click)="viewLeaveTypeButton()">
                                                View
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <!-- </div> -->
                                <div class="clearfix"> </div>
                            </div>
                            <!-- </div> -->

                            <div class="empty-div-one" style="height: 10px;" id="navigatedest"></div>

                            <div class="card" style="padding-bottom:60px" *ngIf="add_view_toggle == 'addLeaveType' ">
                                <div class="card-header border-bottom-0">
                                    <h3 class="card-title ">Leave Master Configuration</h3>
                                </div>
                                <div class="card-body ">
                                    <div class="panel-group1" id="accordion1">
                                        <div class="panel panel-default mb-4 overflow-hidden br-7">
                                            <div class="panel-heading1">
                                                <h4 class="panel-title1">
                                                    <a class="accordion-toggle collapsed  " data-bs-toggle="collapse"
                                                        data-parent="#accordion" href="#collapseFour"
                                                        aria-expanded="false">Leave
                                                        Name</a>
                                                </h4>
                                            </div>
                                            <div id="collapseFour" class="panel-collapse collapse" role="tabpanel"
                                                aria-expanded="false">
                                                <div class="panel-body">
                                                    <div class="card-body">
                                                        <div class="row col-md-12 col-sm-12 col-xs-12">
                                                            <!-- leave type and leave name  -->
                                                            <div class=" row col-md-12 col-sm-12 col-xs-12">
                                                                <div class="col-md-4 col-sm-4 col-xs-4">
                                                                    <div class="form-group label-floating">
                                                                        <label class="form-label">Leave
                                                                            Name <span
                                                                                style="color: rgb(241, 111, 111);">
                                                                                &nbsp;*</span></label>
                                                                        <!-- <div>
                                                                                    <input type="text" class="form-control col-md-12 col-xs-12"
                                                                                        autocomplete="off" placeholder="Enter Leave Name"
                                                                                        name="leaveName" [(ngModel)]="leaveName" autofocus="true"
                                                                                        required />
                                                                                </div> -->
                                                                        <div>
                                                                            <input type="text" name="leave_Name"
                                                                                class="form-control col-md-12 col-xs-12"
                                                                                autocomplete="off"
                                                                                placeholder="Enter Leave Name"
                                                                                autofocus="true" [(ngModel)]="leaveName"
                                                                                #leave_Name="ngModel"
                                                                                [ngClass]="(!leave_Name.valid && leave_Name.touched) ? 'is-invalid' : ''" />
                                                                        </div>
                                                                        <span class="help-text text-danger"
                                                                            *ngIf="(!leave_Name.valid && leave_Name.touched)">Enter
                                                                            Leave
                                                                            Name</span>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-4 col-sm-4 col-xs-4"
                                                                    *ngIf="addOrUpdate">
                                                                    <div class="form-group label-floating">
                                                                        <label class="form-label">Leave
                                                                            Code<span
                                                                                style="color: rgb(241, 111, 111);">
                                                                                &nbsp;*</span></label>
                                                                        <div>
                                                                            <input type="text" name="leave_Type"
                                                                                maxlength="2"
                                                                                class="form-control col-md-12 col-xs-12"
                                                                                autocomplete="off"
                                                                                placeholder="Enter Leave Type"
                                                                                autofocus="true" [(ngModel)]="leaveType"
                                                                                required #leave_Type="ngModel"
                                                                                [ngClass]="(!leave_Type.valid && leave_Type.touched) ? 'is-invalid' : ''" />
                                                                        </div>
                                                                        <span class="help-text text-danger"
                                                                            *ngIf="(!leave_Type.valid && leave_Type.touched)">Enter
                                                                            Leave
                                                                            Type</span>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-4 col-sm-4 col-xs-4">
                                                                    <div class="form-group label-floating">
                                                                        <label class="form-label">Accrual</label>
                                                                        <div class="w-100">
                                                                            <select
                                                                                class="form-control col-md-12 col-xs-12"
                                                                                style="height: 38px;"
                                                                                data-style="btn btn-drpdwn btn-round"
                                                                                [(ngModel)]="accrual" id="accrual"
                                                                                name="accrual">
                                                                                <option value="True">Yes
                                                                                </option>
                                                                                <option value="false">No
                                                                                </option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div class="clearfix"> </div>
                                                            <!-- Based On , paid , satutory code -->
                                                            <div class="col-md-4 col-sm-4 col-xs-4">
                                                                <div class="form-group label-floating">
                                                                    <label class="form-label">Based On</label>
                                                                    <div class="w-100">
                                                                        <select class="form-control col-md-12 col-xs-12"
                                                                            style="height: 38px;"
                                                                            data-style="btn btn-drpdwn btn-round"
                                                                            [(ngModel)]="basedOn" id="basedOn"
                                                                            name="basedOn">
                                                                            <option value="Frequency">Frequency
                                                                            </option>
                                                                            <option value="Number_of_Days_Worked">
                                                                                Number of Days Worked
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4 col-sm-4 col-xs-4">
                                                                <div class="form-group label-floating">
                                                                    <label class="form-label">Statutory
                                                                        Code</label>
                                                                    <div class="w-100">
                                                                        <select class="form-control col-md-12 col-xs-12"
                                                                            style="height: 38px;"
                                                                            data-style="btn btn-drpdwn btn-round"
                                                                            [(ngModel)]="statutoryCode"
                                                                            id="statutoryCode" name="statutoryCode">
                                                                            <option value="AA1">ESI</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4 col-sm-4 col-xs-4">
                                                                <div class="w-100">
                                                                    <label class="form-label  mb-0 mt-2">&nbsp;</label>
                                                                </div>
                                                                <select class="form-control col-md-12 col-xs-12"
                                                                    style="height: 38px;"
                                                                    data-style="btn btn-drpdwn btn-round"
                                                                    id="leaveOption" name="leaveOption"
                                                                    [(ngModel)]="leaveOption">
                                                                    <option value="Paid Leave">Paid Leave
                                                                    </option>
                                                                    <option value="Unpaid Leave">Unpaid Leave
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="clearfix"> </div>
                                                        <!-- frequency and type -->
                                                        <div
                                                            class="row col-md-12 col-sm-12 col-xs-12 {{basedOn === 'Number_of_Days_Worked' ? 'display-none' : ''}}">
                                                            <div class="col-md-6 col-sm-6 col-xs-6">
                                                                <div class="form-group label-floating">
                                                                    <label class="form-label">Frequency</label>
                                                                    <div class="w-100">
                                                                        <select class="form-control col-md-12 col-xs-12"
                                                                            style="height: 38px;"
                                                                            data-style="btn btn-drpdwn btn-round"
                                                                            [(ngModel)]="frequency" id="frequency"
                                                                            name="frequency">
                                                                            <option value="MO">Monthly</option>
                                                                            <option value="QT">Quarterly
                                                                            </option>
                                                                            <option value="HY">Half Year
                                                                            </option>
                                                                            <option value="YR">Yearly</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-sm-6 col-xs-6">
                                                                <div class="form-group label-floating">
                                                                    <label class="form-label">Type</label>
                                                                    <div class="w-100">
                                                                        <select class="form-control col-md-12 col-xs-12"
                                                                            style="height: 38px;"
                                                                            data-style="btn btn-drpdwn btn-round"
                                                                            [(ngModel)]="type" id="type" name="type">
                                                                            <option value="F">Fixed</option>
                                                                            <option value="V">Variable</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="clearfix"> </div>
                                                        <!-- Number of Days Worked and leave to credit -->
                                                        <div
                                                            class="row col-md-12 col-sm-12 col-xs-12 {{basedOn === 'Frequency' ? 'display-none' : ''}}">
                                                            <div class="col-md-6 col-sm-6 col-xs-6">
                                                                <div class="form-group label-floating">
                                                                    <label class="form-label">Number of Days
                                                                        Worked</label>
                                                                    <div class="w-100">
                                                                        <input type="number"
                                                                            class="form-control col-md-12 col-xs-12"
                                                                            [(ngModel)]="noOfDaysWorked"
                                                                            id="noOfDaysWorked" name="noOfDaysWorked">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-sm-6 col-xs-6">
                                                                <div class="form-group label-floating">
                                                                    <label class="form-label">Number of Leave to
                                                                        be Credited</label>
                                                                    <div class="w-100">
                                                                        <input type="number"
                                                                            class="form-control col-md-12 col-xs-12"
                                                                            [(ngModel)]="leaveCredited"
                                                                            id="leaveCredited" name="leaveCredited">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="clearfix"> </div>
                                                        <!-- Leave Credit Rule , drop dow with text -->
                                                        <div
                                                            class="row col-md-12 col-sm-12 col-xs-12  {{type ==='F' || basedOn === 'Number_of_Days_Worked' ? 'display-none' : '' }}">
                                                            <div class="col-md-6 col-sm-6 col-xs-6">
                                                                <div class="form-group label-floating">
                                                                    <label class="form-label">Leave Credit
                                                                        Rule</label>
                                                                    <div class="w-100">
                                                                        <select class="form-control col-md-12 col-xs-12"
                                                                            style="height: 38px;"
                                                                            data-style="btn btn-drpdwn btn-round"
                                                                            [(ngModel)]="creditRule" id="creditRule"
                                                                            name="creditRule">
                                                                            <option value="JO">Date of Joining
                                                                            </option>
                                                                            <option value="CO">Date of
                                                                                Confirmation</option>
                                                                            <option value="JD">Days after
                                                                                Joining</option>
                                                                            <option value="CD">Date after
                                                                                Confirmation</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-md-6   {{creditRule ==='JD'? '' : 'display-none'}}  ">
                                                                <div class="form-group label-floating">
                                                                    <label class="form-label">Days after
                                                                        Joining</label>
                                                                    <div class="w-100">
                                                                        <input type="number"
                                                                            class="form-control col-md-12 col-xs-12"
                                                                            [(ngModel)]="dayAfterJoin">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="clearfix"> </div>
                                                        <!-- Compensatory Off and validity days and encaps-->
                                                        <div class="row col-md-12 col-sm-12 col-xs-12">
                                                            <div class="col-md-3 col-sm-3 col-xs-3">
                                                                <div class="form-group label-floating">
                                                                    <label class="form-label">Compensatory
                                                                        Off</label>
                                                                    <div class="w-100">
                                                                        <select class="form-control col-md-12 col-xs-12"
                                                                            style="height: 38px;"
                                                                            data-style="btn btn-drpdwn btn-round"
                                                                            id="compensatoryOFf"
                                                                            [(ngModel)]="compensatoryOFf"
                                                                            name="compensatoryOFf">
                                                                            <option value="true">Yes</option>
                                                                            <option value="false">No</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-md-3 col-sm-3 col-xs-3"
                                                                [class.display-none]="compensatoryOFf === 'false'">
                                                                <div class="form-group label-floating">
                                                                    <label class="form-label">Validity
                                                                        Days</label>
                                                                    <div class="w-100">
                                                                        <input type="number"
                                                                            class="form-control col-md-12 col-xs-12"
                                                                            [(ngModel)]="validDays" id="validDays"
                                                                            name="validDays">
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-md-6 col-sm-6 col-xs-6">
                                                                <div class="form-group label-floating">
                                                                    <label class="form-label">Consider
                                                                        Interleaved Holiday as Leave
                                                                        <i class="fa fa-info-circle"
                                                                            data-bs-toggle="tooltip-primary"
                                                                            title="If Yes, the Org or Weekly holiday in between 2 leave dates is considered as leave"></i>
                                                                    </label>
                                                                    <div class="w-100">
                                                                        <select class="form-control col-md-12 col-xs-12"
                                                                            style="height: 38px;"
                                                                            data-style="btn btn-drpdwn btn-round"
                                                                            id="interveneHoliday"
                                                                            [(ngModel)]="interveneHoliday"
                                                                            name="interveneHoliday">
                                                                            <option value="true">Yes</option>
                                                                            <option value="false">No</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-md-3 col-sm-3 col-xs-3">
                                                                <div class="form-group label-floating">
                                                                    <label class="form-label">Color Code</label>
                                                                    <div class="w-100">
                                                                        <input type="color" [(ngModel)]="colorCode"
                                                                            name="colorSetter" />
                                                                        <div class="selected-color"
                                                                            [style.background]="colorCode">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="clearfix"> </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="panel panel-default  mb-4 overflow-hidden br-7">
                                            <div class="panel-heading1">
                                                <h4 class="panel-title1">
                                                    <a class="accordion-toggle collapsed  " data-bs-toggle="collapse"
                                                        data-parent="#accordion" href="#collapseFive"
                                                        aria-expanded="false">Leave
                                                        Rules</a>
                                                </h4>
                                            </div>
                                            <div id="collapseFive" class="panel-collapse collapse" role="tabpanel"
                                                aria-expanded="false">
                                                <div class="panel-body">
                                                    <div class="card-body">
                                                        <!-- carry forward  and intervening holiday -->
                                                        <div class="row col-md-12 col-sm-12 col-xs-12">
                                                            <div class="col-md-3 col-sm-3 col-xs-3">
                                                                <div class="form-group label-floating">
                                                                    <label class="form-label">Carry
                                                                        Forward</label>
                                                                    <div class="w-100">
                                                                        <select class="form-control col-md-12 col-xs-12"
                                                                            style="height: 38px;"
                                                                            data-style="btn btn-drpdwn btn-round"
                                                                            id="carryForward" [(ngModel)]="carryForward"
                                                                            name="carryForward">
                                                                            <option value="true">Yes</option>
                                                                            <option value="false">No</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- Carry Forward is true -->
                                                            <div class="col-md-3 col-sm-3 col-xs-3"
                                                                [class.display-none]="carryForward === 'false'">
                                                                <div class="form-group label-floating">
                                                                    <label class="form-label">Carry Forward
                                                                        Rules</label>
                                                                    <div class="w-100">
                                                                        <select class="form-control col-md-12 col-xs-12"
                                                                            style="height: 38px;"
                                                                            data-style="btn btn-drpdwn btn-round"
                                                                            [(ngModel)]="totalPercenatage"
                                                                            id="totalPercenatage"
                                                                            name="totalPercenatage">
                                                                            <option value="P">Total Percentage
                                                                            </option>
                                                                            <option value="T">Flat / Rate
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3 col-sm-3 col-xs-3"
                                                                [class.display-none]="carryForward === 'false' ">
                                                                <div class="form-group label-floating"
                                                                    *ngIf="totalPercenatage === 'P'">
                                                                    <label class="form-label">Total
                                                                        Percentage</label>
                                                                    <div class="w-100">
                                                                        <input type="number"
                                                                            class="form-control col-md-12 col-xs-12"
                                                                            [(ngModel)]="flatRate">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3 col-sm-3 col-xs-3"
                                                                [class.display-none]="carryForward === 'false'">
                                                                <div class="form-group label-floating"
                                                                    *ngIf="totalPercenatage === 'P'">
                                                                    <label class="form-label">Max Leave
                                                                        CarryForward</label>
                                                                    <div class="w-100">
                                                                        <input type="number"
                                                                            class="form-control col-md-12 col-xs-12"
                                                                            [(ngModel)]="maxLeaveCarryForward"
                                                                            id="maxLeaveCarryForward"
                                                                            name="maxLeaveCarryForward">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-sm-6 col-xs-6"
                                                                [class.display-none]="carryForward === 'false'">
                                                                <div class="form-group label-floating"
                                                                    *ngIf="totalPercenatage === 'T'">
                                                                    <label class="form-label">Flat /
                                                                        Rate</label>
                                                                    <div class="w-100">
                                                                        <input type="number"
                                                                            class="form-control col-md-12 col-xs-12"
                                                                            [(ngModel)]="flatRate" name="flatRate">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="clearfix"> </div>
                                                        <!-- Encashable  -->
                                                        <div class="row col-md-12 col-sm-12 col-xs-12">
                                                            <div class="col-md-6 col-sm-6 col-xs-6">
                                                                <div class="form-group label-floating">
                                                                    <label class="form-label">Encashable</label>
                                                                    <div>
                                                                        <div class="w-100">
                                                                            <select
                                                                                class="form-control col-md-12 col-xs-12"
                                                                                style="height: 38px;"
                                                                                data-style="btn btn-drpdwn btn-round"
                                                                                id="encashable" [(ngModel)]="encashable"
                                                                                name="encashable">
                                                                                <option value="true">Yes
                                                                                </option>
                                                                                <option value="false">No
                                                                                </option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-md-6 col-sm-6 col-xs-6">
                                                                <div class="form-group label-floating">
                                                                    <label class="form-label">Encashment "&#64;"
                                                                        separation</label>
                                                                    <div class="w-100">
                                                                        <select class="form-control col-md-12 col-xs-12"
                                                                            style="height: 38px;"
                                                                            data-style="btn btn-drpdwn btn-round"
                                                                            id="enacashSeperation"
                                                                            [(ngModel)]="enacashSeperation"
                                                                            name="enacashSeperation">
                                                                            <option value="true">Yes</option>
                                                                            <option value="false">No</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-md-3 col-sm-3 col-xs-3"
                                                                [class.display-none]="encashable === 'false' ">
                                                                <div class="form-group label-floating">

                                                                    <label class="form-label">Total Encashable
                                                                        Percentage</label>
                                                                    <div class="w-100">
                                                                        <input type="number"
                                                                            class="form-control col-md-12 col-xs-12"
                                                                            name="totalencashmentpercent"
                                                                            [disabled]="false"
                                                                            [(ngModel)]="totalencashmentpercent"
                                                                            id="totalencashmentpercent">
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div class="col-md-3 col-sm-3 col-xs-3"
                                                                [class.display-none]="encashable === 'false'">
                                                                <div class="form-group label-floating">
                                                                    <label class="form-label">Max Encashable
                                                                        Leave
                                                                    </label>
                                                                    <div class="w-100">
                                                                        <input type="number"
                                                                            class="form-control col-md-12 col-xs-12"
                                                                            [(ngModel)]="maxleaveencashable"
                                                                            id="maxleaveencashable"
                                                                            name="maxleaveencashable">
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>
                                                        <!-- leave Encashment -->



                                                    </div>
                                                    <div class="clearfix"> </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="panel panel-default overflow-hidden br-7">
                                        <div class="panel-heading1">
                                            <h4 class="panel-title1">
                                                <a class="accordion-toggle collapsed" data-bs-toggle="collapse"
                                                    data-parent="#accordion" href="#collapseSix"
                                                    aria-expanded="false">Leave
                                                    Approval</a>
                                            </h4>
                                        </div>
                                        <div id="collapseSix" class="panel-collapse collapse" role="tabpanel"
                                            aria-expanded="false">
                                            <div class="panel-body">
                                                <!-- approve leave and approve limit  -->
                                                <div class=" row col-md-12 col-sm-12 col-xs-12 mb-2">
                                                    <div *ngIf="errorMessages?.length > 0" class="text-danger">
                                                        <ul>
                                                            <li *ngFor="let message of errorMessages">{{
                                                                message }}</li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                                        <div class="form-group label-floating">
                                                            <label class="form-label">Authority
                                                                Levels</label>
                                                            <div>
                                                                <!-- (ngModelChange)="checkApprovalLevelDesignationValidation();" -->
                                                                <input type="number"
                                                                    class="form-control col-md-12 col-xs-12"
                                                                    autocomplete="off"
                                                                    placeholder="Enter Number Of Level"
                                                                    [(ngModel)]="approveLevel" min="1" max="3"
                                                                    name="ApproveLevel"
                                                                    (ngModelChange)="checkApprovalLevel();"
                                                                    autofocus="true" required #LApprove_Level="ngModel"
                                                                    [ngClass]="(!LApprove_Level.valid && LApprove_Level.touched) ? 'is-invalid' : ''" />
                                                                <div *ngIf="(!LApprove_Level.valid && LApprove_Level.touched)"
                                                                    class="text-danger">
                                                                    Please Enter a valid Leave Approval
                                                                    Levels
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div *ngFor="let obj of leaveAuthorities;let i = index">
                                                    <!-- select level one two three four five six ... -->
                                                    <div class="row col-md-12 col-sm-12 col-xs-12 borderTop"
                                                        *ngIf="approveLevel >= 1 && approveLevel <= 3">
                                                        <div class="col-md-6 col-sm-6 col-xs-6">
                                                            <div class="form-group label-floating">
                                                                <label class="form-label">Approval level
                                                                    {{leaveAuthorities.length-i}}</label>
                                                                <div class="w-100">
                                                                    <!-- (change)="onApprovelLevelDesChange()" -->
                                                                    <select class="form-control col-md-12 col-xs-12"
                                                                        style="height: 38px;"
                                                                        data-style="btn btn-drpdwn btn-round"
                                                                        id="selectedJobLevel{{i}}"
                                                                        name="selectedJobLevel{{i}}"
                                                                        [(ngModel)]="obj.selectedJobLevel">
                                                                        <option value="GTE">Greater than
                                                                            equal to Job Level</option>
                                                                        <option value="EEE">Same Job Level
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 col-sm-6 col-xs-6"
                                                            *ngIf="obj.selectedJobLevel !=undefined">
                                                            <div class="form-group label-floating">
                                                                <label class="form-label">Job Level<span
                                                                        style="color: rgb(248, 111, 111);">
                                                                        &nbsp;*</span></label>
                                                                <div class="w-100">
                                                                    <select class="form-control col-md-12 col-xs-12"
                                                                        style="height: 38px;"
                                                                        data-style="btn btn-drpdwn btn-round"
                                                                        id="JobRole{{i}}" name="JobRole{{i}}"
                                                                        [(ngModel)]="obj.jobRole"
                                                                        (ngModelChange)="authorityValidation()"
                                                                        required>
                                                                        <option
                                                                            *ngFor="let des of JobList; let i = index"
                                                                            [value]="des.joblevelid">{{
                                                                            des.joblevel }}
                                                                        </option>
                                                                    </select>
                                                                    <label class="form-label" id="JobRole{{i}}-label"
                                                                        style="color: rgb(248, 111, 111);"></label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row col-md-12 col-sm-12 col-xs-12">
                                                        <div class="col-md-6 col-sm-6 col-xs-6"
                                                            *ngIf="approveLevel >= 1  && approveLevel <= 3 &&  (obj.selectedJobLevel !=undefined )">
                                                            <div class="form-group label-floating">
                                                                <label class="form-label">Authority Belongs
                                                                    to</label>
                                                                <div class="w-100">
                                                                    <select class="form-control col-md-12 col-xs-12"
                                                                        style="height: 38px;"
                                                                        data-style="btn btn-drpdwn btn-round"
                                                                        id="Department{{i}}" name="Department{{i}}"
                                                                        [(ngModel)]="obj.selectedDepartment" required>
                                                                        <option value="DEP">Same Department
                                                                        </option>
                                                                        <option value="OTH">Other Department
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 col-sm-6 col-xs-6"
                                                            *ngIf="approveLevel >= 1  && approveLevel <= 3 && obj.selectedDepartment === 'OTH'">
                                                            <div class="form-group label-floating">
                                                                <label class="form-label">Other<span
                                                                        style="color: rgb(248, 111, 111);">&nbsp;*</span></label>
                                                                <div class="w-100">
                                                                    <select class="form-control col-md-12 col-xs-12"
                                                                        style="height: 38px;"
                                                                        data-style="btn btn-drpdwn btn-round"
                                                                        id="otherDepartment{{i}}"
                                                                        name="otherDepartment{{i}}"
                                                                        [(ngModel)]="obj.otherDepartment">
                                                                        <option
                                                                            *ngFor="let dep of dpartmentList; let i = index"
                                                                            [value]=dep.departmentId>{{
                                                                            dep.name
                                                                            }}</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="row card-list-margin" *ngIf="add_view_toggle == 'viewLeaveType' ">
                                <div class="col-md-12 card-list1-margin" style="padding-bottom: 160px">
                                    <div class="card col-md-12 col-sm-12 col-xs-12">
                                        <div class="card-header border-bottom-0">
                                            <h3 class="card-title">List Of Leave Type</h3>
                                        </div>
                                        <div class="card-body card1S p-0">
                                            <div class="table-responsive">
                                                <div class="scrollsearchresults" id="scroll" infiniteScroll
                                                    [infiniteScrollDistance]="2" [infiniteScrollUpDistance]="1.5"
                                                    [infiniteScrollThrottle]="100" [scrollWindow]="false"
                                                    id="scrolllength">
                                                    <table
                                                        class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                                        id="hr-table">
                                                        <thead class="tablerow">
                                                            <tr class="rowcolors stickyatTop">
                                                                <th class="border-bottom-0 w-5 fs-6 ">Sl.No</th>
                                                                <th class="border-bottom-0 w-5 fs-6 ">Leave Name</th>
                                                                <th class="border-bottom-0 w-5 fs-6 ">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody style="max-height: 50vh; overflow: scroll;">
                                                            <tr *ngFor="let leavelist of LeaveTypeList; let i = index">
                                                                <td>{{i+1}}</td>
                                                                <td>{{leavelist.info.leaveName}}</td>
                                                                <td>
                                                                    <button class="btn btn-primary btn-icon btn-sm me-2"
                                                                        data-method="edit" title="Edit"
                                                                        (click)="editBranch(leavelist)">
                                                                        <i class="fa fa-edit" data-bs-toggle="tooltip"
                                                                            data-original-title="edit"></i>
                                                                    </button>


                                                                    <button class="btn btn-danger btn-icon btn-sm me-2"
                                                                        data-method="delete" title="Delete"
                                                                        (click)="onDeleteHandler(leavelist)"
                                                                        data-bs-target="#showAlertWarning">
                                                                        <i class="fa fa-trash" data-bs-toggle="tooltip"
                                                                            data-original-title="delete"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </tbody>

                                                    </table>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <!-- </section> -->
                            </div>

                            <div class="actionBar pe-4 ps-4">
                                <div class="clearfix"> </div>
                                <button *ngIf="addOrUpdate && add_view_toggle === 'addLeaveType'"
                                    class="btn btn-success savebutton2" type="submit" (click)="submit()">Save</button>
                                <button *ngIf="addOrUpdate && add_view_toggle === 'addLeaveType'" type="button"
                                    class="btn btn-danger clearbutton2 ms-2" (click)="clear()">
                                    Clear
                                </button>
                            </div>

                            <div id="showAlertVer" class="modal" style="top: 40px;">
                                <div class="modal-dialog">
                                    <div class="modal-content animate">
                                        <div class="modal-header">
                                            <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                aria-label="Close">×</button>
                                            <h4 class="modal-title">Warning</h4>
                                        </div>

                                        <div class="modal-body"
                                            style="max-height: calc(100vh - 210px);overflow-y: auto;">
                                            <!-- auto scroll -->
                                            <span><b>Added Leave will be deleted permanently . Are you sure you want to
                                                    continue ?</b></span>
                                        </div>

                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-danger medium"
                                                (click)="deleteLeave()">OK</button>
                                            <button type="button" class="btn btn-primary"
                                                (click)="closeMRQModal()">Cancel</button>

                                        </div>

                                    </div>
                                </div>
                            </div>
                            <!-- </div> -->
                            <!-- </div> -->
                            <!-- make change here  -->
                            <!-- </div> -->
                            <!-- </div> -->
                        </section>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>