<div class="col-lg-12 p-0">
  <div class="card rounded_corners p1" id="scroll_container" style="height: 100%;">

    <div class="position-relative" >
      <div class="card-header p-0 px-4 d-flex justify-content-between" id="header_sticky" style="min-height:60px;max-height: 60px;">
        <h4 class="card-title">My Dairy</h4>
        <!-- <div  
          class="btn btn-sm p-0 border-0 bg-transparent arrow-toggle" 
          type="button" 
          data-bs-toggle="collapse" 
          data-bs-target="#taskListContainer" 
          aria-expanded="false" 
          aria-controls="taskListContainer"
          style="border: none; outline: none;">
        <i class="fa fa-chevron-down"></i>
        </div> -->
        <button class="btn btn-sm btn-primary m-0 " data-bs-toggle="modal" data-bs-target="#addTaskModal">
          Add
        </button>
      </div>
      <!-- <h4 class="card-title mb-3">Daily Tasks</h4>
        <button class="btn btn-sm btn-primary m-0 " data-bs-toggle="modal"
          data-bs-target="#addTaskModal">
          Add Task
        </button> -->
    </div>

    <div *ngIf="taskList.length === 0" class="text-center text-muted">
      <p class="mt-5 mb-5">No Task Defined</p>
    </div>

    <div *ngIf="taskList.length > 0">
    <div id="taskListContainer" class="pt-2 border-0 scroll"
    [ngClass]="{'scrollable': taskList.length > 2}">
      <div class="list-group">
        <div class="list-group-item d-flex pt-1 pb-1 border-0" *ngFor="let task of taskList | slice:0:2">

          <div class="ms-1 " id="width_100">
            <p class=" m-0">{{ task.description }}</p>
            <p class="text-right" [ngClass]="{ 'text-danger': task.priority == 'High' }">
              Due date: {{ task.dueDate| date: 'mediumDate'}}
            </p>
          </div>
          <div class="ms-auto">
            <button class="btn btn-danger btn-simple " data-bs-method="delete" rel="tooltip" title="Delete"
              type="button" data-bs-toggle="modal" data-bs-target="#deleteTaskModal">
              <span class="docs-tooltip" data-bs-toggle="tooltip">
                <span class="fa fa-trash"></span>
              </span>
            </button>
          </div>


          <!--DELETE TASK CONFIRMATION MODAL-->
          <div class="modal fade" id="deleteTaskModal" tabindex="-1" role="dialog" aria-labelledby="modalLabel"
            aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="modal-title">Delete Task</h4>
                </div>
                <div class="modal-body">
                  <p>Are you sure you want to delete this task ?</p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                    Cancel
                  </button>
                  <button type="button" class="btn btn-primary medium" (click)="deleteTask(task)">
                    Yes, Delete
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- <p class="text-right" [ngClass]="{ 'badge-danger': task.priority == 'High' }">
              Due date: {{ task.dueDate | date }}
            </p> -->

            
        </div>

        <div *ngIf="taskList.length > 2 && !showRemainingTasks" class="d-flex justify-content-center">
          <div
            class="btn btn-sm p-0 border-0 bg-transparent arrow-toggle"
            type="button"
            (click)="toggleRemainingTasks()"
            style="border: none; outline: none;">
            <i class="fa fa-chevron-down"></i>
        </div>
        </div>

        <div *ngIf="showRemainingTasks">
          <div class="list-group-item d-flex pt-1 pb-1 border-0" *ngFor="let task of taskList | slice:2">
            <div class="ms-1" id="width_100">
              <p class="m-0">{{ task.description }}</p>
              <p class="text-right" [ngClass]="{ 'text-danger': task.priority === 'High' }">
                Due date: {{ task.dueDate| date: 'mediumDate'}}
              </p>
            </div>
            <div class="ms-auto">
              <button class="btn btn-danger btn-simple" type="button" data-bs-toggle="modal" data-bs-target="#deleteTaskModal">
                <span class="fa fa-trash"></span>
              </button>
            </div>
          </div>
        </div>

        <div *ngIf="showRemainingTasks" class="d-flex justify-content-center">
          <div class="btn btn-sm p-0 border-0 bg-transparent arrow-toggle" type="button" (click)="toggleRemainingTasks()">
            <i class="fa fa-chevron-up"></i>
          </div>
        </div>

      </div>
    </div>
</div>
  </div>
</div>

<div class="modal fade" id="addTaskModal" tabindex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Add</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
      </div>

      <div class="modal-body d-flex flex-column">
        <form class="form-horizontal" (ngSubmit)="form.form.valid && saveTask(form.form.valid)" #form="ngForm"
          novalidate>
          <div class="col-md-12">
            <div class="row">
              <div class="form-group col-md-12 " [ngClass]="{ 'has-error': submitted && !description.valid }">
                <label for="description">Description</label>
                <input type="text" class="col-md-12 form-control" id="description" name="description"
                  [(ngModel)]="dailyTasks.description" #description="ngModel" Placeholder="Enter task description"
                  required />
                <!-- <textarea class="form-control taskdescription"  Placeholder="Enter task description.." #description="ngModel" [(ngModel)]="dailyTasks.description"  id="description" name="description" rows="4"  autofocus="true" required ="required" autocomplete="off"></textarea> -->

                <p *ngIf="submitted && !description.valid" class="help-text text-danger" style="bottom: -20px">
                  Description is required.
                </p>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-md-12 m-12" [ngClass]="{ 'has-error': submitted && !priority.valid }">
                <label for="priority">Priority</label>
                <select class="form-control" data-style="btn btn-drpdwn btn-round" name="priority" id="priority"
                  [(ngModel)]="dailyTasks.priority" #priority="ngModel">
                  <option [value]="p" *ngFor="let p of priorityList">
                    {{ p }}
                  </option>
                </select>
                <p *ngIf="submitted && !priority.valid" class="help-block" style="right: 0">
                  Priority is required.
                </p>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-md-12 m-12" [ngClass]="{ 'has-error': submitted && !dueDate.valid }">
                <label for="dueDate">Due Date</label>
                <input type="dueDate" class="form-control" id="dueDate" name="dueDate" type="date"
                  [(ngModel)]="dailyTasks.dueDate" #dueDate="ngModel" Placeholder="Enter Due date" required />

                <p *ngIf="submitted && !dueDate.valid" class="help-text text-danger">
                  Due date is required.
                </p>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="clearTask()">
          Cancel
        </button>
        <button type="button" class="btn btn-primary medium" (click)="saveTask(form.form.valid)">
          Add
        </button>
      </div>
    </div>
  </div>
</div>