import { Component, OnInit } from '@angular/core';
import { PolicySubmission } from '../models/PolicySubmission';
import { PolicyManagerServiceService } from '../services/policy-manager-service.service';
import { SessionService } from '../services';
import { EmployeeMaster } from '../models/employeeMasterts';
import { EmployeeLeaveService } from '../services/employee-leave.service';

@Component({
  selector: 'app-policy-approval',
  templateUrl: './policy-approval.component.html',
  styleUrls: ['./policy-approval.component.css']
})
export class PolicyApprovalComponent implements OnInit {

  constructor(private policyManagerServiceService: PolicyManagerServiceService, private session: SessionService,private empLeaveService: EmployeeLeaveService) { }

  ngOnInit(): void {
    this.limitPara = 10;
    this.offsetPara = 0;
    const maxString = sessionStorage.getItem('permission').replace('[', '');
    const maxStringBox = maxString.replace(']', '');
    const maxStringAgain = maxStringBox.replace(/"/g, '');
    this.permissionList = maxStringAgain.split(',');
    this.checkAccessForUser();
    
  }

  policySubmission: PolicySubmission = new PolicySubmission();
  id: number;
  fileName: any;
  timestamp: Date;
  amount: number;
  fileType: any;
  userId: any;
  status: any;
  attchment: any;
  fileToUpload: File | null = null;
  filename: any;
  policyList: any[] = [];
  filterTaskList: PolicySubmission[];
  selectedList: any[] = [];
  filteredPolicyList: any[] = [];
  fileSavedPath: any;
  selectAll: boolean = false;
  uniqueempid: string = this.session.getempid()
  approvalDetails: any;
  searchText: any;
  limitPara: number = 0;
  offsetPara: number = 10;
  ActiveInativeLuceneFilter: string = 'Active';
  leavingdate: any;
  queryName: any;
  showBasedOnPermissionEmployeeStatus: boolean = false;
  employeeMasterLucene: EmployeeMaster[];
  query: any;
  selectedempid: any;
  permissionList: any[] = [];

  getEmployeeByNameOrId() {
    const [name, id] = this.query.split(' / ');
    this.queryName = name;
    console.log("Len ", this.query);
  
    if (this.queryName.length <= 2) {
      this.employeeMasterLucene = [];
    }
  
    if (this.queryName.length >= 3) {
      this.empLeaveService.getEmployeeByNameOrUniqueId(this.queryName).subscribe(
        data => {
          console.log("Emp Data ", data);
  
          // Check if data.employeeMasterData has any entries
          if (data.employeeMasterData.length > 0) {
            let filteredEmployees = data.employeeMasterData;
  
            // Get the current date for comparison
            const currentDate = new Date();
            console.log("Current Date: ", currentDate);  // Log current date for debugging
  
            // Apply filtering logic based on the selected employee status
            if (this.ActiveInativeLuceneFilter === 'Active') {
              filteredEmployees = filteredEmployees.filter(emp => {
                const leavingDate = emp.leavingdateString ? new Date(emp.leavingdateString) : null;
                console.log("Leaving Date for " + emp.name + ": ", leavingDate);  // Log leaving date for debugging
                // Active employees should have leavingdate in the future or null
                return leavingDate === null || leavingDate > currentDate;
              });
            } else if (this.ActiveInativeLuceneFilter === 'Inactive') {
              filteredEmployees = filteredEmployees.filter(emp => {
                const leavingDate = emp.leavingdateString ? new Date(emp.leavingdateString) : null;
                console.log("Leaving Date for " + emp.name + ": ", leavingDate);  // Log leaving date for debugging
                // Inactive employees should have leavingdate in the past or null
                return leavingDate && leavingDate < currentDate;
              });
            }
  
            // If the status is 'All', no filter is applied
            if (this.ActiveInativeLuceneFilter === 'All') {
              filteredEmployees = data.employeeMasterData;
            }
  
            // Assign filtered data to the employeeMasterLucene
            this.employeeMasterLucene = filteredEmployees;
  
            // Log the data and leaving date of the first employee in the filtered list
            if (this.employeeMasterLucene.length > 0) {
              this.leavingdate = this.employeeMasterLucene[0].leavingdateString; // Extract the leaving date
              console.log("Filtered Employee Data: ", this.employeeMasterLucene);
              console.log("leavingdate", this.leavingdate);
  
              // Assign the unique employee ID to selectedempid
              this.selectedempid = this.employeeMasterLucene[0].uniqueemployeeid;
              console.log("Selected Employee ID: ", this.selectedempid); // Log the selected employee ID for debugging
            } else {
              console.log("No employee data found.");
            }
          } else {
            console.log("No employee data found.");
          }
        },
        error => {
          console.log("Error ", error);
        }
      );
    }
  }
  
  
  onStatusChange() {
    this.getEmployeeByNameOrId();
  }

  getPolicyAppList() {
    this.policyManagerServiceService.getPolicyAppList(this.uniqueempid, this.selectedempid, this.limitPara, this.offsetPara).subscribe(
      (data) => {
        console.log(" Data available : ", data);
        this.policyList = data.policySubmission;
        this.filteredPolicyList = data.policySubmission;
        this.filterExpenseList(this.searchText);
        console.log(" List of all the PolicyList available : ", this.filteredPolicyList);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  onScroll() {
    // this.offsetPara = this.limitPara; // Update offset to current limit
    this.limitPara += 10;
    this.getPolicyAppList();
  }

  approved_details(expid: any) {
    this.policyManagerServiceService.getApprovedDetails(expid).subscribe(
      data => {
        console.log("Approved Details  : ", data)
        this.approvalDetails = data.policyApprovalDetails;
      },
      error => {
        console.log(error);
      }
    );
  }

  viewdocument(path: string, fileName: string) {
    if (path === undefined) {
      path = fileName;
    }
    console.log(" ")
    console.log("view document : ", path)
    this.policyManagerServiceService.viewdocument(path).subscribe(
      (response) => {
        console.log(`Our response : ${response}`)
        if (fileName.includes('.pdf')) {
          var file = new Blob([response], { type: 'application/pdf' });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        } else if (fileName.includes('.jpg') || fileName.includes('.jpeg')) {
          var file = new Blob([response], { type: 'image/jpeg' });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        } else if (fileName.toLowerCase().includes('.png')) {
          var file = new Blob([response], { type: 'image/png' });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }
        else {
          console.log("Other types are not recommended!");
        }
      },
      (error) => { },
    )
  }

  myDocumentDownload(path: any, filename: any) {
    console.log("path ", path);
    this.fileSavedPath = path;
    this.fileName = filename;
    console.log("The File Saved Path is", this.fileSavedPath);
    console.log("The File Saved Path is", this.fileName);
    this.downloadImage();
  }

  downloadImage() {
    console.log("Download Image is called");
    this.policyManagerServiceService.downloadFile(this.fileSavedPath).subscribe(
      (data) => {
        var downloadUrl = URL.createObjectURL(data);
        var link = document.createElement("a");
        link.download = this.fileName;
        link.href = downloadUrl;
        link.click();
        link.remove();
        console.log("The Url is", downloadUrl);
      },
      (error) => {
        console.log("Error occured in download file");
      }
    );
  }

  deletePolicy(id) {
    console.log("delete expense : ", id, " ");

    this.policyManagerServiceService.deletePolicy(id).subscribe(
      (data) => {
        console.log("delete  Expense ", data);
        this.alertmessages("Successfully deleted ", "success");
        this.clear();
        this.getPolicyAppList();
      },
      (error) => {
        this.alertmessages("Expense is in Use ", "danger");
        console.log(error);
      }
    );
    this.clear();
  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement("div");
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
  <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`;
    let alertcomp = document.querySelector(".alertcomp");
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }

  onFileSelected(event: any) {
    this.fileToUpload = event.target.files[0];
  }

  clear() {
    this.fileName = null;
    this.fileType = null;
  }

  checkallbox(event: any): void {
    // Update the status of all checkboxes based on the Select All checkbox
    this.policyList.forEach((pol) => {
      pol.checked = event.target.checked;
      // Add or remove from selectedList based on checked status
      if (pol.checked) {
        this.addToSelectedList(pol);
      } else {
        this.removeFromSelectedList(pol);
      }
    });
    console.log("Policy list", this.policyList);
    console.log("Policy list", this.selectedList);
  }

  addToSelectedList(pol: any): void {
    // Check if the item is not already in the selectedList before adding
    if (!this.selectedList.includes(pol)) {
      this.selectedList.push(pol);
    }
  }

  removeFromSelectedList(pol: any): void {
    // Remove the item from the selectedList if it exists
    this.selectedList = this.selectedList.filter((item) => item !== pol);
  }

  check_info(pol: any, event: any): void {
    // Update the individual checkbox status
    pol.checked = event.target.checked;
    if (pol.checked) {
      this.addToSelectedList(pol);
    } else {
      this.removeFromSelectedList(pol);
    }
    console.log("Policy list", pol.checked);
    console.log("Policy list", pol);
    console.log("Policy list", this.selectedList);
  }

  approvalhandler() {
    let flag = true
    if (this.selectedList.length === 0) {
      this.alertmessages("Please select item for Approval.", "danger")

      flag = false
      return;
    }

    this.policyManagerServiceService
      .approveList(this.selectedList, this.uniqueempid)
      .subscribe(
        (res) => {
          console.log("filteredExpenseList", this.selectedList);
          this.alertmessages("Successfully Saved ", "success");
          this.selectedList = [];
          this.getPolicyAppList();
        },
        (error) => {
          console.log(error);
          this.alertmessages("Please try again later", "danger");
        }
      );
  }

  rejecthandler() {
    let flag = true
    if (this.selectedList.length === 0) {
      this.alertmessages("Please select item for Approval.", "danger")

      flag = false
      return;
    }
    this.policyManagerServiceService
      .rejectList(this.selectedList)
      .subscribe(
        (res) => {
          console.log("filteredExpenseList", this.selectedList);
          this.alertmessages("Successfully Saved ", "Success");
          this.getPolicyAppList();
          this.selectedList = [];
        },
        (error) => {
          console.log(error);
          this.alertmessages("Please try again later", "danger");
        }
      );
  }

  // Search(searchText: string) {
  //   this.searchText = searchText;
  //   this.getPolicyAppList();

  // }


  filterExpenseList(searchText: string) {
    console.log("filter employeename  ")

    if (this.policyList.length != 0) {

      console.log("policyList : ", this.policyList)

      this.filteredPolicyList = this.policyList.filter(pol =>
        pol.userName?.toLowerCase().includes(searchText.toLowerCase())
      );

      console.log("filteredPolicyList : ", this.filteredPolicyList)
      this.filterTaskList = this.filteredPolicyList;
      console.log("filterTaskList : ", this.filterTaskList)

      if (this.filterTaskList.length === 0) {
        this.alertmessages("No Result Found Please Try Again !  ", "danger");
      }

    } else {
      console.log("documentsToBeApproved : ")
      this.filterTaskList = this.filteredPolicyList;

      if (this.filterTaskList.length === 0) {
        this.alertmessages("No Result Found Please Try Again !  ", "danger");
      }
    }
  }
  checkAccessForUser() {
    console.log("checkAccessForUser here");
    this.showBasedOnPermissionEmployeeStatus = this.permissionList.includes('ng.employee.search.show.employeestatus');
    }
}

