import { Component, Input, OnInit } from '@angular/core';
import { EmployeeAttendanceSummary } from 'src/app/models/EmployeeAttendanceSummary';
import { EmployeeMaster } from 'src/app/models/employeeMasterts';
import { DocumentInfoService } from 'src/app/services/documentinfo.service';
import { EmployeeDaily } from 'src/app/models/EmployeeDaily';
import { DatePipe } from '@angular/common';
import { EmployeeattendenceserviceService } from 'src/app/services/employeeattendenceservice.service';
import { Approvalinboxservice } from 'src/app/services/approvalinbox.service';
declare var $: any;

@Component({
  selector: 'app-detailed-report',
  templateUrl: './detailed-report.component.html',
  styleUrls: ['./detailed-report.component.css']
})
export class DetailedReportComponent implements OnInit {
  reportType: string = "Detailed Report";

  @Input() detailedReportData: EmployeeAttendanceSummary[] = [];
  @Input() consolidatedReport: EmployeeMaster[] = [];
  @Input() fromDate: string;
  @Input() toDate: string;
  @Input() shift_setting: string;
  @Input() branch: any;
  @Input() selectedempid: any;
  @Input() keyinempid: any;
  selectedEmpObjforPenalty: EmployeeDaily = new EmployeeDaily();
  emp_attendence_list: any;
  url: string | ArrayBuffer;
  constructor(private documentInfoService: DocumentInfoService, private datepipe: DatePipe, private empAttendenceService: EmployeeattendenceserviceService,
    private approvalinboxservice: Approvalinboxservice
  ) { }

  ngOnInit(): void {
  }

  get_Attendence_info(info: any) {
    console.log("view attendance_info on that day ", info);
    console.log(this.selectedempid, this.datepipe.transform(info.date, 'yyyy-MM-dd'));

    this.empAttendenceService.get_Attendence_info_based_On_date(this.selectedempid, this.datepipe.transform(info.date, 'yyyy-MM-dd')).subscribe(
      data => {
        this.emp_attendence_list = data.currentattendencelistbasedonDate;
        console.log(this.emp_attendence_list, "emp swipe info list for a given day")
      }
    );
  }

  openTabLocation(lat: any, long: any) {
    const latitude = lat;
    const longitude = long;
    if (latitude == undefined || longitude == undefined || latitude == 0.0 || longitude == 0.0) {
      this.alertmessagesForLocation("Locatioin Unknown , Coordinate not Available ", "danger")
    } else {
      // window.open('https://www.google.com/maps/@' + latitude + ',' + longitude + ',21z?entry=ttu', '_blank')
      window.open('https://www.google.com/maps?q=' + latitude + ',' + longitude, '_blank')
    }
  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/attendancereport']);
      });
    }, 3000);
  }

  alertmessagesForLocation(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    let alertcomp = document.querySelector('.alertcompForLocation');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/attendancereport']);
      });
    }, 3000);
  }

  getDynamicStyles(attendance: any) {
    let backgroundColor: string = '';

    if (attendance.annualHolidayColorCode) {
      backgroundColor = attendance.annualHolidayColorCode;
    } else if (attendance.weeklyHolidayColorCode) {
      backgroundColor = attendance.weeklyHolidayColorCode;
    } else if (attendance.leaveTypeColorCode) {
      backgroundColor = attendance.leaveTypeColorCode;
    }
    else {
      backgroundColor = 'white'; // You can change this to any default color  // Default background color if none of the conditions are met
    }

    return {
      'color': attendance.totalSwipeHour === '00:00:00' ? 'red' : 'black',
      'background-color': backgroundColor,
    };
  }

  closemodal(e) {
    //this.savenoticeinfo = new Noticeinfo();
    $("#plusModal1").modal("toggle");
  }

  penaltyPopup(empObj: EmployeeDaily) {
    console.log(empObj)
    $("#plusModal1").modal("toggle");
    this.selectedEmpObjforPenalty = empObj;
    // empObj.penalty = this.penaltyCheckbox;
    // empObj.remarks = this.remarks;
  }

  attendanceReportCSVDownload() {
    // console.log(`Unique Emp Id: ${this.uniqueEmployeeId}, Year: ${year}, Month: ${month}`)
    console.log("consolidate report value from  parent ")
    console.log(this.fromDate, this.toDate, this.shift_setting, this.branch,
      this.selectedempid ? this.selectedempid : sessionStorage.getItem('id'), "keyinempid", this.keyinempid)

    const listingFilters = "GBB";
    this.documentInfoService.downloadAttendanceReportCSVFile(this.reportType, this.selectedempid ? this.selectedempid : sessionStorage.getItem('id'), this.fromDate, this.toDate, null, null, null, listingFilters).subscribe(data => {
      // console.log(data)
      var file = new Blob([data], { type: 'application/vnd.ms-excel' });
      var fileURL = URL.createObjectURL(file);
      var anchor = document.createElement("a");
      anchor.download = "DetailedAttendanceReport.xls";
      anchor.href = fileURL;
      anchor.click();
      //window.open(fileURL);
    },
      error => {
        console.log("Error Occured ", error);
        // console.log("Error Code ",error.status);
      })
  }

  getPreview(info) {

    // const capturePreviewModal = new bootstrap.Modal(document.getElementById('capturePreview'));
    // capturePreviewModal.show();

    console.log(info);
    this.url = undefined;
    console.log("profile path : ", info.capturedPath);
    if (info.capturedPath) {
      this.approvalinboxservice.downloadFile(info.capturedPath).subscribe(
        data => {
          var downloadUrl = URL.createObjectURL(data);
          var reader = new FileReader();
          reader.readAsDataURL(data);
          reader.onload = (_event) => {
            this.url = reader.result;
          }
        },
        error => {
          console.log('Error occured in download file');
        }
      );
    }

  }

}
