<div class="app-content main-content">
    <div class="side-app main-container">

        <div class="alertcomp">

        </div>
        

        <!-- <div class="card-body px-0">
            <div class="page-leftheader my-3">
                <div class="page-title">Expense Type</div>
            </div>
        </div> -->

        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <form id="fcForm" class="form-horizontal form-label-left row" #expenseform="ngForm">
                            <div class="col-md-7 col-sm-7 col-xs-7">
                                <div class="form-group label-floating">
                                    <label class="form-label">Add New Expense Type</label>
                                        <div>
                                        <input type="text" name="expense_name" class="form-control col-md-12 col-xs-12" autocomplete="off"
                                          placeholder="Enter Expense Type" autofocus="true" [(ngModel)]="expenseName" required
                                          #expense_name="ngModel" [ngClass]="(!expense_name.valid && expense_name.touched) ? 'is-invalid' : ''" />
                                      </div>
                                      <span class="help-text text-danger" *ngIf="(!expense_name.valid && expense_name.touched)">Please Enter Expense Type</span>
                                </div>
                            </div>

                            <div class="col-md-5 col-sm-5 col-xs-5 justify-content-between align-items-start">
                                <div class="form-group label-floating">
                                    <label class="form-label">&nbsp;</label>
                                    <button *ngIf="addOrUpdate; else updateButton" type="button" id="navigatetarget"
                                        class="btn btn-primary w-40" style="margin-right: 3vw;" (click)="addexpenseType()">
                                        Add
                                    </button>
                                    <ng-template #updateButton>
                                        <button type="button" id="navigatetarget" class="btn btn-primary w-40"
                                            style="margin-right: 3vw;" (click)="updateExpenseType()">
                                            Update
                                        </button>
                                    </ng-template>
                                    <button type="button" class="btn btn-danger w-40" (click)="clear()">
                                        Clear
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="card col-md-12 col-sm-12 col-xs-12">
            <div class="card-header border-bottom-0">
                <h3 class="card-title">List Of Expense Type</h3>
            </div>
            <div class="card-body card1S p-0">
                <div class="table-responsive">
                    <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                        id="hr-table">
                        <thead class="tablerow">
                            <tr class="rowcolors stickyatTop">
                                <th class="border-bottom-0 w-5 fs-6 ">Expense Type</th>
                                <th class="border-bottom-0 w-5 fs-6 ">Action</th>
                            </tr>
                        </thead>
                     <tbody style="max-height: 50vh; overflow: scroll;">
                           <tr *ngFor="let expTy of expenseType">
                                
                                <td>{{expTy.expenseName}}</td>
                                <td>
                                    <button class="btn btn-primary btn-icon btn-sm me-2" data-method="edit" title="Edit"
                                        (click)="editExpenseType(expTy.id,expTy.expenseName)">
                                        <i class="fa fa-edit" data-bs-toggle="tooltip" data-original-title="edit"></i>
                                    </button>
                                    <button class="btn btn-danger btn-icon btn-sm me-2" data-method="delete"
                                        title="Delete" data-bs-target="#showAlertWarning"   (click)="onDeleteHandler(expTy.id,expTy.name)"
                                        >
                                        <i class="fa fa-trash" data-bs-toggle="tooltip" data-original-title="edit"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="showAlertVer" class="modal" style="top: 40px;">
    <div class="modal-dialog">
        <div class="modal-content animate">
            <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">×</button>
                <h4 class="modal-title">Warning</h4>
            </div>

            <div class="modal-body" style="max-height: calc(100vh - 210px);overflow-y: auto;">
                <!-- auto scroll -->
                <span><b> Expense Type data will be deleted permanently. Are you sure you want to continue?</b></span>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-danger medium" (click)="deleteExpensetype()">OK</button>
                <button type="button" class="btn btn-primary" (click)="closeMRQModal()">Cancel</button>
            </div>

        </div>
    </div>
</div>