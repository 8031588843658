import { Component, Input, OnInit } from '@angular/core';
import { TaskDetailsForLeave } from 'src/app/models/task-details-for-leave.model';
import { ApproveLeavePipe } from 'src/app/pipes/approve-leave.pipe';
import { DashboardService } from 'src/app/services/dashboard.service';
import { Menu } from 'src/app/services/menu.Service';

@Component({
  selector: 'app-team-member-on-leave',
  templateUrl: './team-member-on-leave.component.html',
  styleUrls: ['./team-member-on-leave.component.css']
})
export class TeamMemberOnLeaveComponent implements OnInit {
  
  @Input() teamMemberOnLeave:TaskDetailsForLeave[] = [];

  // teamMemberOnLeave: TaskDetailsForLeave[] = []
  // teamMemberOnLeaveListLength: number;
  // showLeaves: number = 30;

  constructor(private dashboardService: DashboardService, private approve: ApproveLeavePipe,private menuService: Menu) { }

  ngOnInit() {
    // this.getLeaveForApprovalToday()
  }

  // getLeaveForApprovalToday() {
  //   console.log("Called : F2");
  //   this.dashboardService.getLeaveForApprovalToday().subscribe(
  //     data => {
  //       console.log("Approved :", data);
  //       this.teamMemberOnLeave = data.employeeApprovedLeaveList;
  //       this.teamMemberOnLeaveListLength = this.teamMemberOnLeave.length;
  //     },
  //     error => {
  //       console.error("Error while getting Leave For Approval ", error);
  //     }
  //   )
  // }

  setMenuName(name: string) {
    this.menuService.setMenuName(name);
  }
}



