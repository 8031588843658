export class SalaryComponentDetailsInfo{
	id : number;
	salaryHeadId : number;
	salarydetailId : number;
	salaryHeadName : string;
	salaryDetailName : string;
	fromDate : string;
	toDate : string;
	salaryComponentAmount : number;
	userId: '';
}