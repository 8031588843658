export class Noticeinfo {
  id: number;
  title: string;
  description: string;
  fromdate: string;
  todate: string;
  createdBy: string;
  createdTime: string;
  image:string;
}
