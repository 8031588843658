import { Component, OnInit, ViewChild } from '@angular/core';
import { EmployeeMasterservice } from '../services/employeemaster.service';
import { CommonService } from '../services/common.service';
import { DatePipe } from '@angular/common';
import { AlertService } from '../services';
import { AnnualHoliday } from '../models/AnnualHolidayInfo';
import { NgForm, NgModel } from '@angular/forms';
import { EventCalendar } from '../models/EventCalendar';
declare var $:any
@Component({
  selector: 'app-event-calendar',
  templateUrl: './event-calendar.component.html',
  styleUrls: ['./event-calendar.component.css']
})
export class EventCalendarComponent implements OnInit {

   // add_view_toggle: any;
    add_view_toggle = "viewEventCalendar";
    update_view_toggle: any;
  
    Date: String;
    //annualName: String;
    eventName: String;
    creditedDay: String = "F";
    colorCode: String;
    // holiday:any;
  
    //annualHolidayId: number;
    eventId: number;
    //annualhoildaylist: AnnualHoliday[];
    eventlist: EventCalendar[];
  
  
    addOrUpdate: boolean = true;
    saveOrUpdate: boolean = true;
  
    info: any;
    //DeleteAnnualHoliday:AnnualHoliday ;
    DeleteEventday:EventCalendar ;

  constructor(private employeeMasterservice: EmployeeMasterservice, private commonService: CommonService,
      private alertservice: AlertService, private datePipe: DatePipe) { }

  @ViewChild('eventform') event: NgForm;
  @ViewChild('event_name') eventcontrol: NgModel
    
  ngOnInit(): void {
    this.geteventDayList();
    this.viewEventCalendarButton();


  }

  geteventDayList(): void {
    this.commonService.getEventDays().subscribe(data => {
      // console.log(data, "annual holiday list")
      this.eventlist = data.eventDayList;
      console.log("event days list", this.eventlist);
      this.event.reset();

    });
  }


  addEventDay() {
    console.log("here");

    //this.annual.form.markAllAsTouched();

    if ((this.eventName != null || this.eventName != undefined) && (this.Date != null || this.Date != undefined)) {
      console.warn(this.creditedDay);

      this.employeeMasterservice.insert_event_day(this.Date, this.eventName, this.creditedDay, this.colorCode).subscribe(
        data => {
          console.log("added event day ", data);
          this.alertmessages(data.successMessages[0]['key'], "success");
          this.event.reset();
          this.eventcontrol.reset()
          this.clear();
          this.viewEventCalendarButton();

        }, error => {

          console.log("Error Hey Bro Error", error);
          const formattedDate = this.formatDate(this.Date as string);
          this.alertmessages(`Event with the same name already saved for ${formattedDate}`, "danger");
        }
      );
    } else {
      console.log("err");
      this.alertmessages("Please Fill all the required field", "danger");
    }
  }

  formatDate(dateString: string): string {
    const dateParts: string[] = dateString.split("-");
    const formattedDate: string = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
    return formattedDate;
  }

  clear() {
    this.eventName = undefined;
    this.addOrUpdate = true;
    this.Date = undefined;
    // this.branch.reset();
  }


  // updateAnnualHoliday() {
  //   this.annualName = this.annualName.trim();
  //  //console.log("After trim Branch: ", this.branchName, " :  length : ", this.branchName.length)
  //   if (this.annualName != undefined && this.annualName.length > 0) {

  //     const updatedField = {
  //        annualHolidayId: this.annualHolidayId,
  //       name: this.annualName
  //     };

  //     this.employeeMasterservice.editUpdate_branch(updatedField).subscribe(
  //       data => {
  //         console.log("added branch ", data)
  //         this.alertmessages("Successfully Updated ", "success")
  //         this.clear();
  //         //this.getBranch();
  //       }, error => {
  //        this.alertmessages("Please try again later", "danger")
  //         console.log(error);
  //       }
  //     );

  //     this.clear();
  //   }
  // }


  addEventCalendarButton() {
    this.add_view_toggle = "addEventCalendar";
    this.addOrUpdate = true;
    this.creditedDay = "F";
    //this.annual.reset();


  }

  viewEventCalendarButton() {
    this.addOrUpdate = true;
    this.add_view_toggle = "viewEventCalendar";
    this.event.reset();

    // this.annualName = undefined;
    this.geteventDayList();

    this.eventcontrol.reset();

  }
  deleteEventCalendar() {
    const deleteId = this.DeleteEventday. eventID;
    console.log("deleteId", deleteId);
    this.employeeMasterservice.delete_event_day(deleteId).subscribe(
      (data) => {
        this.geteventDayList();
        $('#showAlertVer').modal('toggle');
        this.alertmessages(data.successMessages[0]['key'], "success")


      }, (error) => {
        console.log(error)
        $('#showAlertVer').modal('toggle');
        this.alertmessages("Event Calendar cannot be deleted as it's in use", "danger");

      }
    );


  }


  onDeleteHandler(info: any) {
    console.log("delete : ", info);
    this.DeleteEventday = info;
    $('#showAlertVer').modal('toggle');
  }

  closeMRQModal() {
    $('#showAlertVer').modal('toggle');

  }

  editEventCalendar(info) {
    console.log("edit event day : ", info)
    this.addOrUpdate = false;

    // this.annualHolidayId = id;

    this.add_view_toggle = "addEventCalendar";
    //this.updateAnnualHoliday();

    const formattedDate = this.datePipe.transform(info?.date, 'yyyy-MM-dd');
    console.log(formattedDate, "update date");

    this.Date = formattedDate;
    this.eventName = info.reason;
    this.creditedDay ='F';
    this.colorCode = info.colorcode;
    this.eventId = info.eventID;
    console.log(this.eventId, "event Id");
  }

  updateEventCalendar() {
    this.addEventCalendarButton();

    this.saveOrUpdate = false;

    this.eventName = this.eventName.trim();
    console.log("After trim event day: ", this.eventName, " :  length : ", this.eventName.length)
    if (this.eventName != undefined && this.eventName.length > 0) {

      // const updatedField = {
      //   annualHolidayId: this.annualHolidayId,
      //   name: this.annualName
      // };

      this.employeeMasterservice.editUpdate_event_day(this.eventName, this.eventId, this.Date,
        this.creditedDay, this.colorCode).subscribe(
          data => {
            console.log("added event day ", data, this.eventName)
            this.alertmessages(data.successMessages[0]['key'], "success")
            this.event.reset();
            this.eventName = ''
            this.clear();
            this.eventcontrol.reset();
            this.viewEventCalendarButton();
            this.geteventDayList();

            console.warn(this.event, this.eventcontrol, this.eventcontrol.reset());


          }, error => {
            this.alertmessages("Event with the same name or date already exists", "danger")
            this.viewEventCalendarButton();
            console.log(error);
          }
        );

      // this.clear();
    }
  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }
}

