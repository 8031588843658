import { Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import { ExtraTimeOut } from '../models/extra-time-out';
import { EmployeeMaster } from '../models/employeeMasterts';
import { Extratimeoutservices } from '../services/extratimeoutservices';
import { ActivatedRoute } from '@angular/router';
import { EmployeeLeaveService } from '../services/employee-leave.service';
import { DatePipe } from '@angular/common';

declare var $: any;
@Component({
  selector: 'app-extra-timeout-report',
  templateUrl: './extra-timeout-report.component.html',
  styleUrls: ['./extra-timeout-report.component.css']
})

export class ExtraTimeoutReportComponent implements OnInit {

	orderBy: string = 'employeeName';
	query: any="";
	queryName: any="";
	fromDate: string;
	toDate: string;
	status: string;
	limitPara: any;
	offsetPara: any;
	title: string;
	employeesETOSummary: ExtraTimeOut[];
	employeeMaster: EmployeeMaster[];
	statusValue: string = 'All';
	existingquery:string;
	dateError: boolean = false;
	selectedStatus: string = 'All';
	ActiveInativeLuceneFilter: string = 'Active';
	leavingdate: String;
	permissionList: any[] = [];
   showBasedOnPermissionEmployeeStatus: boolean = false;
	constructor(private empLeaveService: EmployeeLeaveService,private extraTimeOutService: Extratimeoutservices, private route: ActivatedRoute, private render: Renderer2,
	private datePipe: DatePipe) {
		const currentDate = new Date();
		const year = currentDate.getFullYear();
		const month = currentDate.getMonth() + 1;
		this.fromDate = `${year}-${month.toString().padStart(2, '0')}-01`;
		const lastDay = new Date(year, month, 0).getDate();
		this.toDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;
		this.title = "Extra Time Out Summary";
	}

	sortTableData() {
		// Assuming employeesETOSummary is the array you want to sort

		this.employeesETOSummary.sort((a, b) => {
			if (this.orderBy === 'employeeId') {
				return a.uniqueEmployeeId.toString().localeCompare(b.uniqueEmployeeId.toString());
			} else if (this.orderBy === 'employeeName') {
				return a.employeename.localeCompare(b.employeename);
			} else if (this.orderBy === 'date') {
				return new Date(a.date).getTime() - new Date(b.date).getTime();
			}
			// Default case if orderBy is not recognized
			return 0;
		});
	}

	ngOnInit(): void {

		const maxString = sessionStorage.getItem('permission').replace('[', '');
		const maxStringBox = maxString.replace(']', '');
		const maxStringAgain = maxStringBox.replace(/"/g, '');
		this.permissionList = maxStringAgain.split(',');
		this.checkAccessForUser();
		
		this.employeesETOSummary = new Array<ExtraTimeOut>();
		// this.getAllExtraTimeOut();
		this.limitPara = 13;

		this.offsetPara = 0;
		this.sortTableData();
		(<HTMLInputElement>document.getElementById('employeename')).focus();

		this.route.queryParams.subscribe(params => {

			this.status = params.leaveStatus;
			if (this.status && this.status.trim().length !== 0) {
				this.onStatusChange(this.status)
				this.getFilteredETOSummary()
			}
		});
		console.log("parameter passed from dashboard to leave status");

		this.render.listen(document.getElementById('navigateclient'), 'click', () => {
			$('html,body').animate({
				scrollTop: $(`#add-update-client`).offset().top - 90
			},
				'slow');
		})

	}

	getFilteredETOSummary() {
		const [name, id] = this.query.split(' / ');

    let selectedName =name;
    let selectedEmpId =id;
	console.log("selecteduniqueeEmpId",selectedEmpId);
		console.log("fromDate: ", this.fromDate, "toDate: ", this.toDate, "page limit para :", this.limitPara, "page limit offset :", this.offsetPara);
		console.log(this.statusValue);
		let employeename = (<HTMLInputElement>document.getElementById('employeename')).value;
		if (!employeename) {
			employeename = null;
		} else {
			employeename = employeename.trim()
		}
		if (!selectedEmpId) {
			selectedEmpId = 0;
		} else {
			selectedEmpId = id;
		}
		console.log("Employee " + employeename);
		this.extraTimeOutService.getFilteredETOSummary(selectedEmpId, this.fromDate, this.toDate, this.statusValue, this.limitPara, this.offsetPara).subscribe(
			data => {
			
				console.log("Backend Leave Data ", data);
				this.scrollLength()
			
				if (this.offsetPara == 0) {
					this.employeesETOSummary = data.extraTimeOutApplied;

				} else {
					this.employeesETOSummary = this.employeesETOSummary.concat(data.extraTimeOutApplied);
				}

				console.log("Employee ETO Summary ", this.employeesETOSummary);
				this.sortTableData();
				this.existingquery = employeename;
			},
			error => {
				console.log("Error ", error);
				(<HTMLInputElement>document.getElementById('employeename')).value = "";
			}
		)
	}

	scrollLength() {
		console.log("dynamic height on scroll ");
		console.log("");
		const regex = /^(\d+(?:\.\d+)?)\s*([a-zA-Z%]+)$/;
		const height = getComputedStyle(document.getElementById("scrolllength")).getPropertyValue("max-height")
		const computedHeight = height.match(regex);
		console.log("computed height", computedHeight, "computed height in no ", Number(computedHeight[1]), "height ", height)
		if (computedHeight && Number(computedHeight[1]) < 450) {
			let value: any = Number(computedHeight[1]);
			console.log("default value = ", value)
			value = value + (2 * 55);
			value = value + "px";
			console.log("final vl:", value)
			console.log("transition")
			document.getElementById("scrolllength").style.maxHeight = value
		}
		console.log("");
	}

	onStatusChange(selectedValue: string) {
		this.statusValue = selectedValue;
		console.log("Value", this.statusValue)
	}


	getEmployeeByNameOrId() {
		this.query = this.query.trim();
		const [name, id] = this.query.split(' / ');
		this.queryName = name;
		console.log("Len ", this.query);
		
		// Clear employee list if query length is less than or equal to 2
		if (this.query.length <= 2) {
		  this.employeeMaster = [];
		}
	  
		// Only fetch data if query length is greater than or equal to 3
		if (this.query.length >= 3) {
		  this.empLeaveService.getEmployeeByNameOrUniqueId(this.queryName).subscribe(
			data => {
			  console.log("Emp Data ", data);
	  
			  // Check if data.employeeMasterData has any entries
			  if (data.employeeMasterData.length > 0) {
				let filteredEmployees = data.employeeMasterData;
	  
				// Get the current date for comparison
				const currentDate = new Date();
				console.log("Current Date: ", currentDate);  // Log current date for debugging
	  
				// Apply filtering logic based on the selected employee status
				if (this.ActiveInativeLuceneFilter === 'Active') {
				  filteredEmployees = filteredEmployees.filter(emp => {
					const leavingDate = emp.leavingdateString ? new Date(emp.leavingdateString) : null;
					console.log("Leaving Date for " + emp.name + ": ", leavingDate);  // Log leaving date for debugging
					// Active employees should have leavingdate in the future or null
					return leavingDate === null || leavingDate > currentDate;
				  });
				} else if (this.ActiveInativeLuceneFilter === 'Inactive') {
				  filteredEmployees = filteredEmployees.filter(emp => {
					const leavingDate = emp.leavingdateString ? new Date(emp.leavingdateString) : null;
					console.log("Leaving Date for " + emp.name + ": ", leavingDate);  // Log leaving date for debugging
					// Inactive employees should have leavingdate in the past or null
					return leavingDate && leavingDate < currentDate;
				  });
				}
	  
				// If the status is 'All', no filter is applied
				if (this.ActiveInativeLuceneFilter === 'All') {
				  filteredEmployees = data.employeeMasterData;
				}
	  
				// Assign filtered data to the employeeMasterLucene
				this.employeeMaster = filteredEmployees;
	  
				// Log the data and leaving date of the first employee in the filtered list
				if (this.employeeMaster.length > 0) {
				  this.leavingdate = this.employeeMaster[0].leavingdateString; // Extract the leaving date
				  console.log("Filtered Employee Data: ", this.employeeMaster);
				  console.log("leavingdate", this.leavingdate);
				} else {
				  console.log("No employee data found.");
				}
			  } else {
				console.log("No employee data found.");
			  }
			},
			error => {
			  console.log("Error ", error);
			}
		  );
		}
	  }
	  onStatusChanges() {
		this.getEmployeeByNameOrId();
	  }

	onScroll() {
		this.offsetPara = this.offsetPara + 13;
		console.log("limit = ? ", this.limitPara, "offset = +13 ", this.offsetPara)
		this.getFilteredETOSummary();

	}

	limitpara() {
		this.offsetPara = 0;
		console.log(" on clikc limit para ", this.limitPara)
		console.log("this offset", this.offsetPara)
	}

	@HostListener('scroll', ['$event'])
	scrolled() {

		console.log("Scrolled >>> ")
		this.onScroll();
	}


	extraTimeOutReportCSVDownload(employeesETOSummary: any) {
		console.log("employeesETOSummary",employeesETOSummary);
		
		this.extraTimeOutService.downloadETOReportCSVFile(employeesETOSummary).subscribe((data) => {
		  console.log(data)
		  var file = new Blob([data], { type: 'application/vnd.ms-excel' });
		  var fileURL = URL.createObjectURL(file);
		  var anchor = document.createElement("a");
		  anchor.download = "ExtraTimeOutReport.xls";
		  anchor.href = fileURL;
		  anchor.click();
		  
		  },
		  (error) => {
			console.log("Error Occured ", error);
		  }
		);
	  }

	  alertmessages(message: string, sign: string): void {
		let alertDiv = document.createElement('div');
		alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
			<i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message}</div>`
		let alertcomp = document.querySelector('.alertcomp');
		alertcomp.append(alertDiv);
		document.body.scrollTop = document.documentElement.scrollTop = 0;
	
		setTimeout(function () {
		  alertcomp.removeChild(alertDiv);
		}, 5000);
	  }

	  validateDates(): void {
		if (this.fromDate && this.toDate) {
		  const from = new Date(this.fromDate);
		  const to = new Date(this.toDate);
		  this.dateError = to < from;
		 
		  if(this.dateError){
			this.alertmessages("To Date cannot be earlier than From Date.", "danger")
		  }
		}
	  }

	  convertTime(timeString: string): string {
		if (timeString) {
		 
		  const timeParts = timeString.split(':');
		  const date = new Date();
		  date.setHours(+timeParts[0], +timeParts[1], +timeParts[2]);
		  
		  // Format the date using DatePipe
		  return this.datePipe.transform(date, 'hh:mm a') || '';
		}
		return '';
	  }
clear(){
  this.fromDate='';
  this.toDate='';
  this.query='';
  this.orderBy='';
  this.selectedStatus='';

}
checkAccessForUser() {
    console.log("checkAccessForUser here")
    this.showBasedOnPermissionEmployeeStatus = this.permissionList.includes('ng.employee.search.show.employeestatus');
  }

}
