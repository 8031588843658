import { Component, OnInit } from '@angular/core';
import { EmployeeMaster } from '../models/employeeMasterts';
import { EmployeeLeaveService } from '../services/employee-leave.service';
import { CommonService } from '../services/common.service';
declare var $: any;

@Component({
  selector: 'app-taskmaster',
  templateUrl: './taskmaster.component.html',
  styleUrls: ['./taskmaster.component.css']
})
export class TaskmasterComponent implements OnInit {


  taskid: any;
  taskName: any;
  taskDesc: any = '';
  taskComment: any = '';
  dueDate: any;
  status: any;

  queryName: any;
  employeeid: any;
  employeeMasterLucene: EmployeeMaster[];
  query: any;
  add_view_toggle: string; // addTask  // viewTask
  addOrUpdate: boolean;
  taskMasterDetail: any[];

  constructor(private empLeaveService: EmployeeLeaveService, private commonService: CommonService) {
  }

  ngOnInit(): void {
    this.viewTaskButton();
  }

  addTaskButton() {
    this.add_view_toggle = 'addTask';
    this.addOrUpdate = true;
  }

  updateHandeler() {
    if (this.status == 'Pending') {
      $('#showAlertForReassignTask').modal('toggle');
      return;
    }
    if (this.status == 'Close') {
      $('#showAlertForReassignTask').modal('toggle');
      return;
    }
    // if assigned 
    this.updateTaskButton();
  }

  closeReassignTask() {
    $('#showAlertForReassignTask').modal('toggle');
  }

  updateTaskButton() {
    const obj = {
      id: this.taskid,
      title: this.taskName.trim(),
      description: this.taskDesc,
      comment: this.taskComment,
      employeeid: this.employeeid,
      duedate: this.dueDate,
    }

    if (obj.title == undefined || obj.title == null || obj.title == '') {
      this.alertmessages('Please Enter Title', 'danger');
      return
    }

    if (obj.employeeid == undefined || obj.employeeid == null || obj.employeeid == '') {
      this.alertmessages('Please Select Employee', 'danger');
      return
    }

    if (obj.duedate == undefined || obj.duedate == null) {
      this.alertmessages('Please Select Due Date', 'danger');
      return
    }

    console.log(obj);

    console.log("clicked update ");

    this.commonService.updateTaskMaster(obj).subscribe(
      data => {
        this.alertmessages("Successfully Updated ", "success");
        if (this.status != 'Assigned') {
          this.closeReassignTask();
        }

        this.clear();
        this.viewTaskButton();
      }, error => {
        this.alertmessages("Try Again Later", "danger");
      }
    );

  }

  viewTaskButton() {
    this.clear();
    this.addOrUpdate = true;
    this.add_view_toggle = 'viewTask';
    this.getTask();
  }

  saveTask() {

    const obj = {
      title: this.taskName.trim(),
      description: this.taskDesc,
      comment: this.taskComment,
      employeeid: this.employeeid,
      duedate: this.dueDate,
    }

    if (obj.title == undefined || obj.title == null || obj.title == '') {
      this.alertmessages('Please Enter Title', 'danger');
      return
    }

    if (obj.employeeid == undefined || obj.employeeid == null || obj.employeeid == '') {
      this.alertmessages('Please Select Employee', 'danger');
      return
    }

    if (obj.duedate == undefined || obj.duedate == null) {
      this.alertmessages('Please Select Due Date', 'danger');
      return
    }

    console.log(obj);

    console.log("clicked save");

    this.commonService.insertTaskMaster(obj).subscribe(
      data => {
        this.alertmessages("Successfully Saved ", "success");
        this.clear();
        this.viewTaskButton();
      }, error => {
        this.alertmessages("Try Again Later", "danger");
      }
    );

  }

  clear() {
    this.taskid = undefined;
    this.taskName = '';
    this.taskDesc = '';
    this.taskComment = '';
    this.dueDate = undefined;
    this.query = '';
    this.employeeid = '';
    this.status = '';
  }

  getEmployeeByNameOrId(info) {
    console.log('check info ', info);

    const [name, id] = this.query.split(' / ');
    this.queryName = name;
    this.employeeid = id; // Store the ID if it's available

    console.log("Len ", this.query);
    console.log('array ', this.employeeMasterLucene);


    if (this.queryName.length <= 2) {
      this.employeeMasterLucene = [];
    }

    if (this.queryName.length >= 3) {
      this.empLeaveService.getEmployeeByNameOrUniqueId(this.queryName).subscribe(
        data => {
          console.log("Emp Data ", data.employeeMasterData);
          this.employeeMasterLucene = data.employeeMasterData;
        },
        error => {
          console.log("Error ", error);
        }
      );
    }
  }

  deleteTask() {
    this.taskid;
    console.log("deleteId", this.taskid);
    this.commonService.deleteTask(this.taskid).subscribe(
      (data) => {
        this.getTask();
        $('#showAlertVer').modal('toggle');
        this.alertmessages(data.successMessages[0]['key'], "success")
      }, (error) => {
        console.log(error)
        $('#showAlertVer').modal('toggle');
        this.alertmessages("Task cannot be deleted as it's in use", "danger");
      }
    );

  }

  closeTask() {
    $('#showAlertVer').modal('toggle');
  }

  editTask(info: any) {

    console.log(info);
    this.taskid = info.id;
    this.taskName = info.title;
    this.taskDesc = info.description;
    this.taskComment = info.comment;
    this.query = info.employeeName;
    this.queryName = info.employeeName;
    this.employeeid = info.employeeid;
    this.dueDate = info.duedate;
    this.taskComment = info.comment;
    this.status = info.status;
    this.add_view_toggle = 'addTask';
    this.addOrUpdate = false;
  }

  onDeleteHandler(id: any) {
    $('#showAlertVer').modal('toggle');
    this.taskid = id;
  }

  getTask() {

    this.commonService.getTaskMaster().subscribe(
      data => {
        this.clear();
        this.taskMasterDetail = data.taskMaster;
      }, error => {
        this.taskMasterDetail = [];
      }
    );

  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }



}
