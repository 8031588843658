import { Component, OnInit, ViewChild } from '@angular/core';
import { ExpensemanagerService } from '../services/expensemanager.service';
import { NgForm } from '@angular/forms';
declare var $:any
@Component({
  selector: 'app-expense-type',
  templateUrl: './expense-type.component.html',
  styleUrls: ['./expense-type.component.css']
})
export class ExpenseTypeComponent implements OnInit {
  id: number;
  expenseName: any;
  userId: any;
  timeStamp: Date;
  expenseType: any[] [];
  addOrUpdate: boolean = true;
  constructor(private expensemanagerService: ExpensemanagerService) { }
  @ViewChild('expenseform', { static: false }) expense: NgForm;
  ngOnInit(): void {
    this.getexpenseType();
  }

  getexpenseType() {
    this.expensemanagerService.getExpenseTypeList().subscribe(
      data => {
        console.log("List of Expense Type :", data)
        this.expenseType = data.expenseType;
        console.log("List of Expense Type :", this.expenseType)
      }, error => {
        console.log(error);
      }
    );
  }
  clear() {
    this.expenseName = undefined;
    this.addOrUpdate = true;
    this.expense.reset();
  }
  addexpenseType() {
    this.expense.form.markAllAsTouched()
    if (this.expense.invalid) {
      // Handle invalid form submission
      return;
    }
    this.expenseName = this.expenseName.trim();
    if (this.expenseName != undefined && this.expenseName.length > 0) {
      console.log("Add Expense TYpe: ", this.expenseName, " :  length : ", this.expenseName.length)
      this.expensemanagerService.insert_expenseType(this.expenseName).subscribe(
        data => {
          console.log("added Expense Type ", data)
          this.alertmessages("Successfully Saved ", "success")
          this.clear();
          this.getexpenseType();
        }, error => {
          console.log(error);
          this.alertmessages("Please try again later", "danger")
        }
      );
    }
  }
  
  updateExpenseType() {
    this.expenseName = this.expenseName.trim();
    console.log("After trim expense type: ", this.expenseName, " :  length : ", this.expenseName.length)
    if (this.expenseName != undefined && this.expenseName.length > 0) {

      const updatedField = {
        id: this.id,
        expenseName: this.expenseName
      };

      this.expensemanagerService.editUpdate_expenseType(updatedField).subscribe(
        data => {
          console.log("added Expense Type ", data)
          this.alertmessages("Successfully Updated ", "success")
          this.clear();
          this.getexpenseType();
        }, error => {
          this.alertmessages("Please try again later", "danger")
          console.log(error);
        }
      );

      this.clear();
    }
  }
  editExpenseType(id, info) {
    console.log("edit Expense Type : ", info)
    this.expenseName = info;
    this.id = id;

    this.addOrUpdate = false;
  }
  
  deleteExpensetype() {
    //console.log("delete Expense Type : ", id, " ", expenseName)
    const id = this.id;
    this.expensemanagerService.delete_expenseType(id).subscribe(
      data => {
        console.log("delete  Expense Type ", data)
        this.alertmessages("Successfully deleted ", "success")
        this.clear();
        this.getexpenseType();
        $('#showAlertVer').modal('toggle');
      }, error => {
        $('#showAlertVer').modal('toggle');
        this.alertmessages("Expense Type is in Use ", "danger")
        console.log(error);
      }
    );

    this.clear();
  }
  onDeleteHandler(id,expenseName) {
    // console.log("delete : ", info);
    this.id=id;
    this.expenseName = expenseName;
    $('#showAlertVer').modal('toggle');
  }

  closeMRQModal() {
    $('#showAlertVer').modal('toggle');

  }
  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }
}
