<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp">

        </div>
        <!--Page header-->
        <!-- <div class="page-header d-xl-flex d-block"> -->
            <!-- <div class="page-leftheader">
                <div class="page-title">Comp Off Approval</div>
            </div> -->
        <!-- </div> -->
        <!--End Page header-->

        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-body">

                        <form id="fcForm" class="form-horizontal form-label-left">
                            <form class="form-horizontal card-body row label-floating px-4" id="fcForm"
                                (keyup.enter)="search()">

                                <!-- <div class="col-md-6 col-sm-12 col-xs-12 mb-4">
                                    <label class=" p-0 form-label col-xl-6 col-md-6 col-lg-6">Employee Name / ID
                                    </label>
                                    <div class="form-group m-0">
                                        <div>
                                            <input type="text" class="form-control " name="employeename"
                                                id="employeename" [(ngModel)]="searchleave" autocomplete="off"
                                                (input)="chkInput($event)" autofocus="autofocus"
                                                placeholder="Search Employee Name">
                                        </div>

                                    </div>
                                </div> -->

                                <div class="col-md-4 col-sm-12 col-xs-12 mb-4">
                                    <label class="form-label">Employee Name / ID</label>
                                    <input type="search" class="form-control" name="Employee Name" id="employeename" 
                                        (input)="chkInput($event)" placeholder="Enter Employee Name / ID" [(ngModel)]="query" 
                                        autocomplete="off" (input)="getEmployeeByNameOrId()" list="employeeMasterLucene">
                                    <datalist id="employeeMasterLucene">
                                        <option data-value="{{emp.name}}" *ngFor="let emp of employeeMasterLucene">
                                            {{emp.employeeid}}
                                        </option>
                                    </datalist>
                                </div>

                                <!-- Active/Inactive Employee Status filter for Lucene Search -->
                                <div class="col-md-3 col-sm-12 col-xs-12 mb-4">
                                    <label class="form-label">Employee Status</label>
                                    <select class="form-control" style="width: 100%;" required [(ngModel)]="ActiveInativeEmployeeFilter" 
                                        name="selVal" (change)="onStatusChange()"
                                        [disabled]="!showBasedOnPermissionEmployeeStatus">
                                        <option value="All">All</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>
                                </div>

                                <div class="col-md-3 col-sm-4 col-xs-12 mb-4">
                                    <label class=" p-0 form-label col-xl-4 col-md-4 col-lg-4">Status</label>
                                    <div class="form-group m-0">
                                        <div>
                                            <div>
                                                <select id="leaveStatusFilters" class="form-control" name="dateFilter"
                                                    [(ngModel)]="statusFilter">
                                                    <option value="all">All</option>
                                                    <option value="Comp Off Approve Pending">Comp Off Approve Pending</option>
                                                    <option value="Comp Off Cancel Pending">Comp Off Cancel Pending</option>
                                                    <option value="Partial Approve 1">Partial Approve 1</option>
                                                    <option value="Partial Approve 2">Partial Approve 2</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2 col-sm-12 col-xs-12">
                                    <label class="form-label col-xl-2 col-md-2 col-lg-2 p1 m-0 p-0 ">&nbsp;</label>
                                    <button type="button" id="navigatetarget" class="btn btn-primary  wd w-100"
                                        (click)="search()">
                                        <i class="fa fa-search p-0" aria-hidden="true"></i> Search
                                    </button>
                                </div>

                            </form>
                        </form>
                    </div>
                </div>
            </div>
        </div>


        <div class="empty-div-one" id="navigatedest"></div>
        <!-- Row -->
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header d-flex justify-content-between align-items-center  border-0">
                        <h4 class="card-title">Comp Off Summary</h4>
                    </div>
                    <div class="card-body">
                        <div class="col-xs-12 mobile view">
                            <form action="#">
                                <div class="table-responsive">
                                    <div class="scrollsearchresults" id="scrollength">
                                        <table class="table  table-vcenter text-nowrap table-bordered border-bottom"
                                            id="hr-leaves">
                                            <thead class="tablerow">
                                                <tr class="rowcolors stickyatTop ">
                                                    <th class="secondcolumn fixedcolumn"><input type="checkbox"
                                                            style="cursor: pointer;"
                                                            (change)="checkAllSelected($event)" /></th>
                                                    <th class="border-bottom-0 w-5 fixedcolumn">Employee ID</th>
                                                    <th class="border-bottom-0 w-5 secondcolumn">Name</th>
                                                    <th class="border-bottom-0">In Time</th>
                                                    <th class="border-bottom-0">Out Time</th>
                                                    <th class="border-bottom-0">Total Time</th>
                                                    <th class="border-bottom-0">Worked On</th>
                                                    <th class="border-bottom-0">Day</th>
                                                    <th class="border-bottom-0">Reason</th>
                                                    <th class="border-bottom-0">Status</th>
                                                    <th class="border-bottom-0">Applied on</th>
                                                    <th class="border-bottom-0">Days</th>
                                                    <th class="border-bottom-0">Remark</th>
                                                </tr>
                                            </thead>
                                            <tbody *ngIf="showTable">
                                                <tr *ngFor="let leave of leaveDetailFlow;let i=index;">
                                                    <td class=" secondcolumn fixedcolumn backgroundFW"><input
                                                            type="checkbox" style="cursor: pointer;"
                                                            (change)="checkSelected(leave,$event)" /></td>
                                                    <td class="fixedcolumn backgroundFW">
                                                        {{leave.employeeLeaveApplied.employeeId}}</td>
                                                    <td class="secondcolumn backgroundSW">
                                                        <div class="d-flex">
                                                            <div class="me-3 mt-0 mt-sm-2 d-block">
                                                                <h6 class="mb-1 fs-14">{{leave.name}}</h6>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <!-- | date:'shortTime' -->
                                                    <td>{{leave.employeeLeaveApplied.inTime | date: 'shortTime' }}</td>
                                                    <td>{{leave.employeeLeaveApplied.outTime | date: 'shortTime' }}</td>
                                                    <td>{{leave.employeeLeaveApplied.totalTime | slice:0:5 }}</td>
                                                    <td>{{leave.employeeLeaveApplied.leaveDate| date: 'mediumDate'}}</td>
                                                    <td class="font-weight-semibold">
                                                        {{leave.employeeLeaveApplied.halfFullIndic === "F" ? "Full Day"
                                                        :
                                                        "Half Day"}}</td>
                                                    <td class="text-wrap" style="min-width:10vw;max-width: 12vw;">
                                                        {{leave.employeeLeaveApplied.reason}}</td>
                                                    <td>
                                                        <span style="font-size: 13px; font-weight: 500;" [ngClass]="
                                                            leave.employeeLeaveApplied.leaveStatus === 'Comp Off Cancel Pending' ? 'bg-custom-warning2 text-custom-dark' :
                                                            leave.employeeLeaveApplied.leaveStatus === 'Approved' ? 'bg-custom-green text-custom-dark' :
                                                            leave.employeeLeaveApplied.leaveStatus === 'Rejected' ? 'bg-custom-red text-custom-dark' :
                                                            leave.employeeLeaveApplied.leaveStatus === 'Partial Approve 1' ? 'bg-custom-orange1 text-custom-dark' :
                                                            leave.employeeLeaveApplied.leaveStatus === 'Partial Approve 2' ? 'bg-custom-orange2 text-custom-dark' :
                                                            'bg-custom-warning text-custom-dark'
                                                          " class="badge">
                                                            {{ leave.employeeLeaveApplied.leaveStatus }}
                                                        </span>
                                                    </td>

                                                    <td>{{leave.employeeLeaveApplied.appliedDate| date: 'mediumDate'}}</td>
                                                    <td style="min-width:10vw;max-width: 12vw;">
                                                        <select class="form-control" name="dateFilter{{i}}"
                                                            [(ngModel)]="leave.employeeLeaveApplied.temp_halfFullIndic"
                                                            #days="ngModel">
                                                            <option [value]="'H'">Half Day</option>
                                                            <option [value]="'F'">Full Day</option>
                                                        </select>
                                                          
                                                    </td>
                                                    <td><input autocomplete="off" type="text" style="min-width: 9.7rem;"
                                                            name="remark" class="form-control"
                                                            [(ngModel)]="leave.employeeLeaveApplied.remark"
                                                            placeholder="Comments for Rejection"></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div *ngIf="showTable" class="pro-fab btn-float-right">
                                    <a [routerLink]="['/employeeleavestatusreport']" class="btn btn-primary me-3" [routerLinkActive]="['highlightActiveLink']"
                            (click)="setMenuName('Leave Status Report');navigateLeaveStatus()">Leave Status Report</a>
                                    <button type="button" (click)="onApproveHandler()"
                                        class="btn btn-success">Approve</button>
                                    <button type="button" (click)="onRejectHandler()"
                                        class="btn btn-danger ms-2">Reject</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Row-->

    </div><!-- end app-content-->
</div>