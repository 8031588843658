<!-- page content -->
<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp">
        </div>
        <!-- <div class="page-header d-xl-flex d-block">
            <div class="page-leftheader">
                <div class="page-title">Employee Search</div>
            </div>

        </div> -->

        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <!-- <div class="card-header border-bottom-0">
                <h4 class="card-title">Horizontal Form</h4>
            </div> -->
                    <div class="card-body px-5">
                        <form class="form-horizontal" id="fcForm" (keyup.enter)="limitpara(); searchEmployee()">
                            <div class="form-group row">
                                <div class="form-group col-md-6 col-sm-6 col-xs-6 px-2">
                                    <label class="form-label">Employee Name / ID</label>
                                    <input type="search" class="form-control" name="Employee Name" id="employeename"
                                        placeholder="Enter Employee Name / ID" [(ngModel)]="query" autocomplete="off"
                                        (input)="getEmployeeByNameOrId()" list="employeeMasterLucene"
                                        autofocus="autofocus">
                                    <datalist id="employeeMasterLucene">
                                        <option [value]="emp.name + ' / ' + emp.employeeid"
                                            *ngFor="let emp of employeeMasterLucene">
                                            <!-- {{emp.name}} -->
                                        </option>
                                    </datalist>
                                </div>

                                 <!-- Active/Inactive Employee Status filter for Lucene Search -->
                                <div class="form-group col-md-4 col-sm-4 col-xs-4 px-2">
                                    <label class="form-label">Employee Status</label>
                                    <select class="form-control" type="text" style="width: 100%;" required
                                        [(ngModel)]="selectedValue" name="selVal" (change)="onStatusChange()"
                                        [disabled]="!showBasedOnPermissionEmployeeStatus">
                                        <option value="All">All</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>
                                </div>

                                <div class="form-group col-md-2 col-sm-2 px-2">
                                    <label class="form-label">&nbsp;</label>
                                    <button type="button" id="navigatetarget" class="btn btn-primary w-100  p-md-1"
                                        (click)="limitpara(); searchEmployee(); ">
                                        <i class="fa fa-search" aria-hidden="true"></i> Search
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">
                            View Employee Designation{{ searchedEmployeeName || uniqueemployeeid ? ' of ' +
                            (searchedEmployeeName || 'ID: ' + uniqueemployeeid) : '' }}
                        </h4>

                    </div>

                    <div class="empty-div-one" style="height: 10px;" id="navigatedest"></div>
                    <div class="card-body">
                        <!-- <div class="table-responsive"> -->
                        <div class="col-xs-12  mobile view">
                            <div>
                                <div class="scrollsearchresults" infiniteScroll [infiniteScrollDistance]="2"
                                    [infiniteScrollUpDistance]="1.5" [infiniteScrollThrottle]="100"
                                    [scrollWindow]="false" (scrolled)="onScroll()" id="scrolllength">
                                    <table
                                        class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                        id="hr-table">
                                        <thead class="tablerow">
                                            <tr class=" stickyatTop rowcolors">
                                                <th class="border-bottom-0 fs-7">Designation Name</th>
                                                <th class="border-bottom-0 fs-7">From Date</th>
                                                <th class="border-bottom-0 fs-7">To Date</th>
                                                <th class="border-bottom-0 fs-7">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngIf="isSearchClicked && isEmployeeFound && (searchedEmployeeName || uniqueemployeeid)">
                                                <!-- Number Input Field -->
                                                <!-- <td>
                                                    <input type="text" class="form-control"
                                                           [(ngModel)]="insertdesignationDetails.uniqueemployeeid"
                                                           name="numberField" placeholder="Enter Number"
                                                           pattern="^[0-9]*$" maxlength="10">
                                                  </td> -->

                                                <!-- Designation Dropdown -->
                                                <td>
                                                    <select class="form-control" style="appearance: auto !important;"
                                                        data-style="btn btn-drpdwn btn-round" id="dGroup"
                                                        name="designation"
                                                        [(ngModel)]="insertdesignationDetails.desgname">
                                                        <option [ngValue]="null" disabled>Select Designation</option>
                                                        <option *ngFor="let d of designation" [value]="d.name">
                                                            {{ d.name }}
                                                        </option>
                                                    </select>
                                                </td>


                                                <!-- From Date Input -->
                                                <td>
                                                    <input type="date" class="form-control"
                                                        [(ngModel)]="insertdesignationDetails.fromDate" name="from_date"
                                                        (change)="todatechange(insertdesignationDetails.fromDate)">
                                                </td>

                                                <!-- To Date Input -->
                                                <td>
                                                    <input type="date" class="form-control"
                                                        [(ngModel)]="insertdesignationDetails.toDate" name="to_date"
                                                        [disabled]="isToDateReadOnly">
                                                </td>
                                            </tr>

                                            <ng-container
                                                *ngIf="employeedesignationList && employeedesignationList.length > 0">
                                                <tr *ngFor="let d of employeedesignationList; let i = index">
                                                    <td>{{d.desgname}} </td>
                                                    <td>{{d.fromDate | date: 'mediumDate'}} </td>
                                                    <td>{{d.toDate | date: 'mediumDate'}} </td>
                                                    <td>
                                                        <ng-container *ngIf="d.id && i === 0">
                                                            <button class="btn btn-primary btn-icon btn-sm me-2"
                                                                data-method="edit" title="Edit"
                                                                (click)="editdesignation(d.id)">
                                                                <i class="fa fa-edit" data-bs-toggle="tooltip"
                                                                    data-original-title="edit"></i>
                                                            </button>
                                                            <button class="btn btn-danger btn-icon btn-sm me-2"
                                                                data-method="delete" title="Delete"
                                                                (click)="onDeleteHandler(d)"
                                                                data-bs-target="#showAlertWarning">
                                                                <i class="fa fa-trash" data-bs-toggle="tooltip"
                                                                    data-original-title="delete"></i>
                                                            </button>



                                                        </ng-container>
                                                    </td>
                                                </tr>
                                            </ng-container>


                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="actionBar pe-4 ps-4"
            *ngIf="isSearchClicked && isEmployeeFound && (searchedEmployeeName || uniqueemployeeid) ">
            <div class="clearfix"> </div>
            <!-- <button class="btn btn-danger me-2" type="button" (click)="clear()">Clear</button> -->
            <button *ngIf="saveOrUpdate; else updateButton" class="btn btn-success me-2" type="button"
                id="navigatetarget" (click)="submit()">Save</button>
            <ng-template #updateButton>
                <button type="button" id="navigatetarget" class="btn btn-primary me-2" (click)="updatedesignation()">
                    Update
                </button>
                <!-- <button class="btn btn-danger " type="button" (click)="clear()">Clear</button> -->
            </ng-template>
            <button class="btn btn-danger " type="button" (click)="clear()">Clear</button>
        </div>
    </div>
</div>

<div id="showAlertVer" class="modal" style="top: 40px;">
    <div class="modal-dialog">
        <div class="modal-content animate">
            <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">×</button>
                <h4 class="modal-title">Warning</h4>
            </div>

            <div class="modal-body" style="max-height: calc(100vh - 210px);overflow-y: auto;">
                <!-- auto scroll -->
                <span><b> Employee Designation will be deleted permanently. Are you sure you want to
                        continue?</b></span>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-danger medium" (click)="deletemployeedesignation()">OK</button>
                <button type="button" class="btn btn-primary" (click)="closeMRQModal()">Cancel</button>
            </div>

        </div>
    </div>
</div>