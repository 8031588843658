import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeAttendenceProxyService {

  protected basePath = '/api';
  public defaultHeaders: Headers = new Headers();
  public url;

  constructor(private httpclient: HttpClient, private commonService: CommonService) { }

  public getServerTime(emp: any): Observable<any> {
    this.url = this.basePath + `/v1/current-emp-attendence-server-time`;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('empid', emp);
    return this.httpclient.get(this.url);
  }

  public getEmpDailyData(searchDate: any, empID: any, branchId: any, priorityNo: any, departmentid: any, limit: any,
    offset: any, activeExitFilters: string): Observable<any> {
    this.url = '/v1/employee/employee';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('searchDate', searchDate);
    queryParameters = queryParameters.set('empID', empID);
    queryParameters = queryParameters.set('priorityNo', priorityNo);
    queryParameters = queryParameters.set('branchId', branchId);
    queryParameters = queryParameters.set('departmentid', departmentid);
    queryParameters = queryParameters.set('limit', limit);
    queryParameters = queryParameters.set('offset', offset);
    queryParameters = queryParameters.set('activeExitFilters', activeExitFilters);
    // return this.httpclient.post(path,queryParameters);
    // return this.httpclient.get(path,{
    //   params:queryParameters
    // });
    // return this.commonService.postWithHttpInfo(queryParameters,path);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public saveEmpDailyData(employeeDailyInfo: any): Observable<any> {
    this.url = '/v1/saveEmployeeProxy';
    console.log(this.url, "url", employeeDailyInfo)
    return this.Save(employeeDailyInfo);

  }
  public Save(queryParameters: any): Observable<any> {
    const path = this.basePath + this.url;
    return this.httpclient.post(path, queryParameters);
  }

  public saveEmpPermissions(empPermission: any): Observable<any> {
    this.url = '/v1/save/empPermission';
    return this.SavePermission(empPermission);
  }

  public SavePermission(queryParameters: any): Observable<any> {
    const path = this.basePath + this.url;
    return this.httpclient.post(path, queryParameters);
  }



  // public saveEmpDailyData(employeeDailyInfo:EmployeeDaily): Observable<any> {
  //   this.url = '/v1/employee/employee/save';
  //   const path = this.basePath + this.url;
  //   return this.httpclient.post(path, employeeDailyInfo);
  // }


  // on load get work branches
  public getAllBranchs(): Observable<any> {
    this.url = '/v1/employee/branchs';
    const path = this.basePath + this.url;
    return this.httpclient.get(path);
  }

  // on load get work branches
  public getAllBranchsActive(): Observable<any> {
    this.url = '/v1/employee/branchsActive';
    const path = this.basePath + this.url;
    return this.httpclient.get(path);
  }

  // on load get department
  public getAllDepartment(empid: any): Observable<any> {
    this.url = '/v1/employee/department';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('empid', empid);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  // get employee branch wise
  public getBranchWiseEmp(locId: any, searchDate: any, empID: any, priorityNo: any): Observable<any> {
    this.url = '/v1/employee/SelectedLocEmp';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('locId', locId);
    queryParameters = queryParameters.set('searchDate', searchDate);
    queryParameters = queryParameters.set('empID', empID);
    queryParameters = queryParameters.set('priorityNo', priorityNo);
    return this.httpclient.post(path, queryParameters);
  }

  public getDepartmentWiseEmp(deptId: any, searchDate: any, empID: any, priorityNo: any): Observable<any> {
    this.url = '/v1/employee/SelectedDeptEmp';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('deptId', deptId);
    queryParameters = queryParameters.set('searchDate', searchDate);
    queryParameters = queryParameters.set('empID', empID);
    queryParameters = queryParameters.set('priorityNo', priorityNo);
    return this.httpclient.post(path, queryParameters);
  }



  public getLocationWiseEmpDashboard(locId: any, searchDate: any, empID: any, priorityNo: any): Observable<any> {
    this.url = '/v1/employee/LocEmpDashboard';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('locId', locId);
    queryParameters = queryParameters.set('searchDate', searchDate);
    queryParameters = queryParameters.set('empID', empID);
    queryParameters = queryParameters.set('priorityNo', priorityNo);
    return this.httpclient.post(path, queryParameters);
  }

  public getDepartmentWiseEmpDashboard(deptId: any, searchDate: any, empID: any, priorityNo: any): Observable<any> {
    this.url = '/v1/employee/DeptEmpDashboard';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('deptId', deptId);
    queryParameters = queryParameters.set('searchDate', searchDate);
    queryParameters = queryParameters.set('empID', empID);
    queryParameters = queryParameters.set('priorityNo', priorityNo);
    return this.httpclient.post(path, queryParameters);
  }

  public getEmpDailyDetailsDashboard(searchDate: any, empID: any, priorityNo: any): Observable<any> {
    this.url = '/v1/employee/empDashboard';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('searchDate', searchDate);
    queryParameters = queryParameters.set('empID', empID);
    queryParameters = queryParameters.set('priorityNo', priorityNo);
    return this.httpclient.post(path, queryParameters);
  }

  public getAllPeople(): Observable<any> {
    this.url = '/v1/people';
    const path = this.basePath + this.url;
    return this.httpclient.get(path);
  }

  public getEmpPermissions(peopleid: any): Observable<any> {
    this.url = '/v1/getEmpPermission';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('peopleid', peopleid);

    return this.httpclient.post(path, queryParameters);
  }

  // get only active employee list 
  public getAllActiveEmployeeList(): Observable<any> {
    this.url = '/v1/activeEmployeeList';
    const path = this.basePath + this.url;
    return this.httpclient.get(path);
  }

  public getAdminConfigShiftTime(): Observable<any> {
    this.url = '/v1/getConfigShiftTime';
    const path = this.basePath + this.url;
    return this.httpclient.get(path);
  }

}
