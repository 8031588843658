<div style="height:100%; max-height: 260px;">
  <div class="card overflow-hidden mt-3 mb-3 mt-md-0 mb-md-0 mobile-margin-right" style="height:100%">
    <div class="position-relative">
      <div class="card-header p-0 px-4 d-flex justify-content-between" style="min-height:60px;max-height: 60px;">
        <h4 class="card-title">Annual Holidays</h4>
      </div>
    </div>

    <!-- <div class="pt-2 scroll">
      <div class="list-group">
        <div class="list-group-item d-flex p-0 px-3 m-1 border-0"
          *ngFor="let holiday of annualhoildaylist; let i = index"
          [ngClass]="i % 2 === 0 ? 'bg-pink-transparent' : 'bg-success-transparent'">
          <div class="me-3 me-xs-0">
            <div class="calendar-icon icons">
              <div class="date_time" style="width: auto;">
                <span class="date" style="display: inline-block; margin-right: 5px;">
                  {{ holiday.date | date: 'mediumDate' }}
                </span>
              </div>
            </div>
          </div>
          <div class="ms-1 d-flex justify-content-center align-items-center">
            <div class="h5 fs-14 mb-1">{{ holiday.reason }}</div>
          </div>
        </div>
      </div>
    </div> -->


    <div class="pt-2 scroll">
      <div class="list-group">
        <div *ngFor="let holiday of annualhoildaylist; let i = index">
          <div class="list-group-item d-flex pt-0 pb-1 border-0">
            <div class="me-3 me-xs-0">
              <div class="calendar-icon icons"
                [ngClass]="i % 2 === 0 ? 'bg-pink-transparent' : 'bg-success-transparent'">
                <div class="date_time"> <span class="date"> {{ holiday.date | date:'d' }} </span>
                  <span class="month" style="padding-right:10px"> {{ holiday.date | date: 'MMM' }}</span>
                </div>
              </div>
            </div>
            <div class="ms-1">
              <div class="h5 fs-14 mb-1 pt-3">{{ holiday.reason }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>