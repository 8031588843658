<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp"></div>

        <div class="row">
            <div class="col-md-12">
                <div class="card-content">
                    <form #salaryHeadForm="ngForm">
                        <section>
                            <div class="col-xl-12 col-md-12 col-lg-12">
                                <div class="card">
                                    <div class="card-body">
                                        <form id="fcForm" class="form-horizontal form-label-left">
                                            <form class="form-horizontal card-body row label-floating px-4" id="fcForm">
                                                <div class="form-group row">
                                                    <div class="form-group col-md-6 col-sm-6 col-xs-6 px-2">
                                                        <label class="form-label">Employee Name / ID</label>
                                                        <input type="search" class="form-control" name="Employee Name"
                                                            id="employeename" placeholder="Enter Employee Name / ID"
                                                            [(ngModel)]="query" autocomplete="off"
                                                            (input)="getEmployeeByNameOrId()"
                                                            list="employeeMasterLucene" autofocus="autofocus">
                                                        <datalist id="employeeMasterLucene">
                                                            <option [value]="emp.name + ' / ' + emp.employeeid"
                                                                *ngFor="let emp of employeeMasterLucene">
                                                                <!-- {{emp.name}} -->
                                                            </option>
                                                        </datalist>
                                                    </div>
                                                    <div class="form-group col-md-4 col-sm-4 col-xs-4 px-2">
                                                        <label class="form-label">Employee Status</label>
                                                        <select class="form-control" type="text" style="width: 100%;"
                                                            required [(ngModel)]="selectedValue" name="selVal"
                                                            (change)="onStatusChange()"
                                                            [disabled]="!showBasedOnPermissionEmployeeStatus">
                                                            <option value="All">All</option>
                                                            <option value="Active">Active</option>
                                                            <option value="Inactive">Inactive</option>
                                                        </select>
                                                    </div>
                                                    <div class="form-group col-md-2 col-sm-2 px-2">
                                                        <label class="form-label">&nbsp;</label>
                                                        <button type="button" id="navigatetarget"
                                                            class="btn btn-primary  wd w-100"
                                                            (click)="searchEmployee()">
                                                            <i class="fa fa-search p-0" aria-hidden="true"></i> Search
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div class="empty-div-one" id="navigatedest"></div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card" *ngIf="add_view_toggle === 'searchempsuspension' ">
                                        <div class="card-header border-bottom-0">
                                            <h3 class="card-title ">Employee Suspension Configuration </h3>
                                        </div>
                                        <div class="card-body">
                                            <div class="panel-group1" id="accordion1">
                                                <div class="panel panel-default mb-4 overflow-hidden br-7">
                                                    <div class="panel-heading1">
                                                        <h4 class="panel-title1">
                                                            <a class="accordion-toggle collapsed  "
                                                                data-bs-toggle="collapse" data-parent="#accordion"
                                                                href="#collapseFour" aria-expanded="false">Employee
                                                                Suspension
                                                                Detail</a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapseFour" class="panel-collapse collapse"
                                                        role="tabpanel" aria-expanded="false">
                                                        <div class="panel-body m-0 p-0">
                                                            <div class="card-body">
                                                                <div class="row col-md-12 col-sm-12 col-xs-12">
                                                                    <div class=" row col-md-12 col-sm-12 col-xs-12">
                                                                        <div class="col-md-4 col-sm-4 col-xs-4">
                                                                            <div class="form-group label-floating">
                                                                                <label class="form-label">Employee Name
                                                                                    / ID <span
                                                                                        style="color: rgb(248, 111, 111);">*</span></label>
                                                                                <div>
                                                                                    <input type="search"
                                                                                        class="form-control"
                                                                                        name="Employee Name"
                                                                                        id="employeename"
                                                                                        placeholder="Enter Employee Name / ID"
                                                                                        [(ngModel)]="query"
                                                                                        autocomplete="off"
                                                                                        (input)="getEmployeeByNameOrId()"
                                                                                        list="employeeMasterLucene"
                                                                                        autofocus="autofocus">
                                                                                    <datalist id="employeeMasterLucene">
                                                                                        <option
                                                                                            [value]="emp.name + ' / ' + emp.employeeid"
                                                                                            *ngFor="let emp of employeeMasterLucene">
                                                                                            <!-- {{emp.name}} -->
                                                                                        </option>
                                                                                    </datalist>
                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                        <div class="col-md-4 col-sm-4 col-xs-4">
                                                                            <div class="form-group label-floating">
                                                                                <label class="form-label">From Date
                                                                                    <span
                                                                                        style="color: rgb(248, 111, 111);">*</span></label>
                                                                                <div>
                                                                                    <input type="date" name="Fromdate"
                                                                                        class="form-control col-md-12 col-xs-12"
                                                                                        autocomplete="off"
                                                                                        autofocus="true"
                                                                                        [(ngModel)]="insertemployeesuspension.fromdate"
                                                                                        #FROMDate_m2="ngModel"
                                                                                        [ngClass]="(!FROMDate_m2.valid && FROMDate_m2.pristine) ? 'is-invalid' : ''" />
                                                                                </div>
                                                                                <span class="help-text text-danger"
                                                                                    *ngIf="(!FROMDate_m2.valid && FROMDate_m2.touched)">Enter
                                                                                    From Date</span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-4 col-sm-4 col-xs-4">
                                                                            <div class="form-group label-floating">
                                                                                <label class="form-label">To Date <span
                                                                                        style="color: rgb(248, 111, 111);">*</span></label>
                                                                                <div>
                                                                                    <input type="date" name="Todate"
                                                                                        class="form-control col-md-12 col-xs-12"
                                                                                        autocomplete="off"
                                                                                        autofocus="true"
                                                                                        [(ngModel)]="insertemployeesuspension.todate"
                                                                                        #ToDate_m2="ngModel"
                                                                                        [ngClass]="(!ToDate_m2.valid && ToDate_m2.pristine) ? 'is-invalid' : ''" />
                                                                                </div>
                                                                                <span class="help-text text-danger"
                                                                                    *ngIf="(!FROMDate_m2.valid && ToDate_m2.touched)">Enter
                                                                                    To Date</span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-4 col-sm-4 col-xs-4">
                                                                            <div class="form-group label-floating">
                                                                                <label class="form-label">Reason <span
                                                                                        style="color: rgb(248, 111, 111);">*</span></label>
                                                                                <div>
                                                                                    <textarea type="date" name="Todate"
                                                                                        class="form-control col-md-12 col-xs-12"
                                                                                        autocomplete="off"
                                                                                        autofocus="true"
                                                                                        placeholder="Please enter reason for suspension"
                                                                                        [(ngModel)]="insertemployeesuspension.reason"
                                                                                        #reason_m2="ngModel"
                                                                                        [ngClass]="(!reason_m2.valid && reason_m2.pristine) ? 'is-invalid' : ''">
                                                                                    </textarea>
                                                                                </div>
                                                                                <span class="help-text text-danger"
                                                                                    *ngIf="(!reason_m2.valid && reason_m2.touched)">Enter
                                                                                    Reason</span>
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                </div>

                                                                <div class="clearfix"> </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="add_view_toggle === 'searchempsuspension'" class="actionBar pe-4 ps-4">
                                <div class="clearfix"> </div>
                                <button *ngIf="add_view_toggle === 'searchempsuspension'" class="btn btn-success me-2 "
                                    type="submit" (click)="submit()">Save</button>
                                <button *ngIf="add_view_toggle === 'searchempsuspension'" class="btn btn-danger"
                                    type="button" (click)="clear()">Clear</button>

                            </div>



                        </section>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>