<div class="app-content main-content">
    <div class="side-app main-container main-container-margin">
        <div class="alertcomp">

        </div>
        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-body">

                        <form id="fcForm" class="form-horizontal form-label-left">
                            <form class="form-horizontal card-body row label-floating px-4" id="fcForm"
                                (keyup.enter)="search()">

                                <div class="col-md-4 col-sm-4 col-xs-4 mb-4">
                                    <label class=" p-0 form-label col-xl-6 col-md-6 col-lg-6">Employee Name / ID
                                    </label>
                                    <div class="form-group m-0">
                                        <div>
                                            <input type="search" class="form-control " name="employeename"
                                                id="employeename" [(ngModel)]="query" autocomplete="off"
                                                (input)="getEmployeeByNameOrId()" list="employeeMaster"
                                                autofocus="autofocus" placeholder="Search Employee Name / ID">

                                            <datalist id="employeeMaster">
                                                <option data-value={{emp.name}} *ngFor="let emp of employeeMaster">
                                                    {{emp.employeeid}}
                                                </option>
                                            </datalist>

                                        </div>

                                    </div>
                                </div>

                                <div class="form-group col-md-3 col-sm-3 col-xs-3 px-2">
                                    <label class="form-label">Employee Status</label>
                                    <select class="form-control" type="text" style="width: 100%;" required
                                        [(ngModel)]="selectedValue" name="selVal"(change)="onStatusChange()"
                                        [disabled]="!showBasedOnPermissionEmployeeStatus">
                                        <option value="All">All</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>
                                </div>

                                <div class="form-group col-md-3 col-sm-3 col-xs-3 px-2">
                                    <div class="form-group label-floating">
                                        <label class="form-label">Select Status </label>
                                        <div>
                                            <div>
                                                <select id="SuspensionStatusFilters" class="form-control"
                                                    [(ngModel)]="statusValue" name="dateFilter">
                                                    <option value="All">All</option>
                                                    <option value="Approved">Approved</option>
                                                    <option value="Pending">Pending</option>
                                                    <option value="Rejected">Rejected</option>

                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group col-md-2 col-sm-2 px-2">
                                    <label class="form-label">&nbsp;</label>
                                    <button type="button" id="navigatetarget" class="btn btn-primary  wd w-100"
                                        (click)="search()">
                                        <i class="fa fa-search p-0" aria-hidden="true"></i> Search
                                    </button>
                                </div>

                            </form>
                        </form>
                    </div>
                </div>
            </div>
        </div>


        <div class="empty-div-one" id="navigatedest"></div>
        <div class="row top_margin">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header d-flex justify-content-between align-items-center  border-0">
                    </div>
                    <div class="card-body">
                        <!-- <form id="salaryadvanceListform" class="form-horizontal form-label-left row" #salaryadvanceListform="ngForm"> -->
                        <div class="table-responsive">
                            <div class="scrollsearchresults" infiniteScroll (scrolled)="onScroll()" id="scrolllength">
                                <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom">
                                    <thead class="tablerow stickyatTop">
                                        <tr class="rowcolors stickyatTop">
                                            <th>
                                                <input type="checkbox" [checked]="selectAll" (change)="checkallbox($event)" style="cursor: pointer;" />
                                            </th>
                                            <th class="border-bottom-0 fs-7">Employee ID</th>
                                            <th class="border-bottom-0 fs-7">Employee Name</th>
                                            <th class="border-bottom-0 fs-7">From Date</th>
                                            <th class="border-bottom-0 fs-7">To Date</th>
                                            <th class="border-bottom-0 fs-7">Reason</th>
                                            <th class="border-bottom-0 fs-7">Applied By</th>
                                            <th class="border-bottom-0 fs-7">Rejection Reason</th>
                                            <th class="border-bottom-0 fs-7">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let s of approvesuspenseReport">
                                            <td>
                                                <!-- Disable checkbox if status is not 'Pending' -->
                                                <input class="unitaskbox" [checked]="s.checked" (change)="check_info(s, $event)" type="checkbox" 
                                                    [disabled]="s.status !== 'Pending'" style="cursor: pointer;" />
                                            </td>
                                            <td>{{s.empid}}</td>
                                            <td>{{s.empname}}</td>
                                            <td>{{s.fromdate | date: 'mediumDate'}}</td>
                                            <td>{{s.todate | date: 'mediumDate'}}</td>
                                            <td>{{s.reason}}</td>
                                            <td>{{s.updatedBy}}</td>
                                            <td *ngIf="s.status === 'Pending' || s.status === 'Rejected'; else showNull">
                                                <input autocomplete="off" type="text" style="min-width: 9.7rem;" name="remark" class="form-control"
                                                    [(ngModel)]="s.remark" placeholder="Comments for Rejection">
                                            </td>
                                            <ng-template #showNull>
                                                <!-- Display null or empty content -->
                                                <td></td>
                                            </ng-template>
                                
                                            <td>
                                                <span style="font-size: 13px;font-weight: 500;"
                                                    [ngClass]="s.status === 'Approved' ? 'badge-success' : s.status === 'Rejected' ? 'badge-danger' : s.status === 'Pending' ? 'badge-warning text-custom-dark' : 'bg-custom-warning text-custom-dark'"
                                                    class="badge">{{s.status}}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                
                            </div>
                        </div>

                        <div class="pro-fab btn-float-right">
                            <a [routerLink]="['/suspensionreport']" class="btn btn-primary me-3"
                                [routerLinkActive]="['highlightActiveLink']" (click)="setMenuName('Employee Suspension Report');
                                navigateSuspenseStatus()"
                                [ngClass]="{'disable-div': !allowToViewReport}"
                                *ngIf="allowToViewReport">Employee Suspension
                                Report</a>
                                <button *ngIf="approvesuspenseReport.length > 0 && searchClicked" type="button" class="btn btn-success" (click)="onApproveHandler()">Approve</button>
                                <button *ngIf="approvesuspenseReport.length > 0 && searchClicked" type="button" class="btn btn-danger ms-2" (click)="onRejectHandler()">Reject</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div><!-- end app-content-->
</div>