import { Component, OnInit, HostListener, Renderer2 } from '@angular/core';
import { AlertService, ConstantService, SessionService } from 'src/app/services';
import { EmployeeMaster } from 'src/app/models/employeeMasterts';
import { EmployeeMasterservice } from 'src/app/services/employeemaster.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeLeaveService } from '../services/employee-leave.service';
import { DocumentInfoService } from '../services/documentinfo.service';
import { Approvalinboxservice } from '../services/approvalinbox.service';
import { Menu } from '../services/menu.Service';

declare var $: any;


@Component({
  selector: 'app-employeesearch',
  templateUrl: './employeesearch.component.html',
  styleUrls: ['./employeesearch.component.css']
})
export class EmployeesearchComponent implements OnInit {
  employeeMasterList: EmployeeMaster[];

  LeaveTypeList: [];
  employeeMaster: EmployeeMaster;

  url: string | ArrayBuffer;

  empMasterInfo1: EmployeeMaster;
  uniqueemployeeid: String;
  employeeMasterLucene: EmployeeMaster[];
  query: any;
 

  // datas must load when when we scroll down
  limitPara: any;
  offsetPara: any;
  id: any;

  permissionList: any[] = [];
  showBasedOnPermissionForAddOnComponent: boolean = false;
  showBasedOnPermissionForLeaveType: boolean = false;
  accessToEditAndView: boolean = false;
  allowBlankSearch: boolean = false;
  profileName: any;
  selectedValue : string='Active';
  leavingdate: any;



  constructor(private approvalinboxservice: Approvalinboxservice, private render: Renderer2,
    private route: ActivatedRoute, private router: Router, private alertservice: AlertService,
    private constantService: ConstantService, private sessionService: SessionService,
    private employeeMasterservice: EmployeeMasterservice, private empLeaveService: EmployeeLeaveService,
    private docmentInfoService: DocumentInfoService, private menuService: Menu) { }


  ngOnInit() {
    this.employeeMasterList = new Array<EmployeeMaster>();
    this.employeeMaster = new EmployeeMaster;
    this.empMasterInfo1 = new EmployeeMaster;


    this.limitPara = 0;
    this.offsetPara = 13;


    (<HTMLInputElement>document.getElementById('employeename')).focus();

    const queryParams = this.route.snapshot.queryParams;
    // queryParams?.join("")
    // document.getElementById('uniqueid').innerText =queryParams?.join("")
    // (<HTMLInputElement>document.getElementById('uniqueid')).
    // console.log(queryParams)

    console.log("queryParams : ", queryParams)
    console.log("queryParams type : ", typeof queryParams)

    const maxString = sessionStorage.getItem('permission').replace('[', '');
    const maxStringBox = maxString.replace(']', '');
    const maxStringAgain = maxStringBox.replace(/"/g, '');
    this.permissionList = maxStringAgain.split(',');

    this.checkAccessForUser();
    // this.getLeaveType();

    this.render.listen(document.getElementById('navigatetarget'), 'click', () => {
      $('html,body').animate({
        scrollTop: $(`#navigatedest`).offset().top - 90
      },
        'slow');
    })
  }

  checkAccessForUser() {
    console.log("checkAccessForUser here")
    // console.log("Permisson List in Search Employee : ", this.permissionList)
    this.showBasedOnPermissionForLeaveType = this.permissionList.includes('ng.employee.search.show.leaveType');
    this.showBasedOnPermissionForAddOnComponent = this.permissionList.includes('ng.employee.search.show.AddonComponent');
    this.accessToEditAndView = this.permissionList.includes('ng.employee.search.allow.edit.view')
    this.allowBlankSearch = this.permissionList.includes('ng.employee.blank.search.permission')

    console.log("check showBasedOnPermissionForLeaveType : ", this.showBasedOnPermissionForLeaveType);
    console.log("check showBasedOnPermissionForAddOnComponent : ", this.showBasedOnPermissionForAddOnComponent);
    console.log("check accessToEditAndView : ", this.accessToEditAndView);
    console.log("check allowBlankSearch : ", this.allowBlankSearch);


    if (this.showBasedOnPermissionForLeaveType) {  // showBasedOnPermissionForLeaveType is true 
      this.getLeaveType();
    }

  }

  ngAfterViewChecked() {
    //    Activate bootstrap-select
    // if ($(".selectpicker").length != 0) {
    //   $(".selectpicker").selectpicker();
    // }
  }

  searchEmployee() {
    console.log("check : ", typeof this.query); // chatgpt

    if (this.allowBlankSearch) {
      var employeename = (<HTMLInputElement>document.getElementById('employeename')).value;
      if (!employeename) {
        employeename = null;
      } else {
        employeename = employeename.trim()
      }
      // if (employeename?.trim().length === 0) {
      //   employeename = null;
      // }
      if (this.uniqueemployeeid === undefined) {
        this.uniqueemployeeid = null
      }
      console.log("permisison : ", this.allowBlankSearch)
      console.log("Initial value before checking ")
      console.log("Unique Id : " + this.uniqueemployeeid);
      console.log("Employee Name  : " + employeename);
      if (this.allowBlankSearch) {
        console.log(" ")
        console.log("true  : ", this.allowBlankSearch)
        console.log("Unique Id : " + this.uniqueemployeeid);
        console.log("Employee Name  : " + employeename);
        this.employeeMasterservice.getEmployeeMaster(this.uniqueemployeeid, employeename,this.selectedValue, this.limitPara, this.offsetPara).subscribe(
          data => {
            // START onScrollLoadData
            if (this.limitPara == 0) {
              this.employeeMasterList = data.employeeMasterData;
              console.log(" data : ", this.employeeMasterList);
            } else {
              this.employeeMasterList = this.employeeMasterList.concat(data.employeeMasterData);
              console.log("data concat : ", this.employeeMasterList);
            }
            // this.scrollLength()
            this.employeeMaster = new EmployeeMaster;
            (<HTMLInputElement>document.getElementById('employeename')).value = "";
          }, error => {
            console.log(error);
            this.employeeMaster = new EmployeeMaster;
            (<HTMLInputElement>document.getElementById('employeename')).value = "";
          }
        );
      } else {
        if (this.uniqueemployeeid == null && employeename == null) {

          console.log(" check null or not ")
          this.alertmessages("Please Enter Employee Name or Unique Employee ID ", "danger");

        } else {
          console.log("uid / ename .....  not null  ")

          console.log("Unique Id : " + this.uniqueemployeeid);
          console.log("Employee Name  : " + employeename);

          this.employeeMasterservice.getEmployeeMaster(this.uniqueemployeeid, employeename,this.selectedValue, this.limitPara, this.offsetPara).subscribe(
            data => {
              // START onScrollLoadData
              if (this.limitPara == 0) {
                this.employeeMasterList = data.employeeMasterData;
                console.log(" data : ", this.employeeMasterList);

              } else {
                this.employeeMasterList = this.employeeMasterList.concat(data.employeeMasterData);
                console.log("data concat : ", this.employeeMasterList);
              }
              // this.scrollLength()
              this.employeeMaster = new EmployeeMaster;
              (<HTMLInputElement>document.getElementById('employeename')).value = "";

            }, error => {
              console.log(error);
              this.employeeMaster = new EmployeeMaster;
              (<HTMLInputElement>document.getElementById('employeename')).value = "";
            }

          );
        }
      }
    }
    if (this.query?.length <= 2) {
      var employeename = (<HTMLInputElement>document.getElementById('employeename')).value;
      if (!employeename) {
        employeename = null;
      } else {
        employeename = employeename.trim()
      }
      if (this.uniqueemployeeid === undefined) {
        this.uniqueemployeeid = null
      }
      if (employeename?.trim().length === 0) {
        employeename = null;
      }
      console.log("permisison : ", this.allowBlankSearch)
      console.log("Initial value before checking ")
      console.log("Unique Id : " + this.uniqueemployeeid);
      console.log("Employee Name  : " + employeename);

      if (this.allowBlankSearch) {
        if (Number(employeename)) {
          console.log("nametosearch  : " + employeename);
          this.uniqueemployeeid = employeename;
          employeename = null
        }
        console.log(" ")
        console.log("true  : ", this.allowBlankSearch)
        console.log("Unique Id : " + this.uniqueemployeeid);
        console.log("Employee Name  : " + employeename);

        this.employeeMasterservice.getEmployeeMaster(this.uniqueemployeeid, employeename,this.selectedValue, this.limitPara, this.offsetPara).subscribe(
          data => {
            // START onScrollLoadData
            if (this.limitPara == 0) {
              this.employeeMasterList = data.employeeMasterData;
              console.log(" data : ", this.employeeMasterList);
            } else {
              this.employeeMasterList = this.employeeMasterList.concat(data.employeeMasterData);
              console.log("data concat : ", this.employeeMasterList);
            }
            // this.scrollLength()
            this.employeeMaster = new EmployeeMaster;
            (<HTMLInputElement>document.getElementById('employeename')).value = "";

          }, error => {
            console.log(error);
            this.employeeMaster = new EmployeeMaster;
            (<HTMLInputElement>document.getElementById('employeename')).value = "";
          }
        );
      } else {
        if (this.uniqueemployeeid == null && employeename == null) {
          console.log(" check null or not ")
          this.alertmessages("Please Enter Employee Name or Employee ID ", "danger");
        } else {
          console.log("uid / ename .....  not null  ")
          console.log("Unique Id : " + this.uniqueemployeeid);
          console.log("Employee Name  : " + employeename);
          if (Number(employeename)) {
            console.log("nametosearch  : " + employeename);
            
            this.uniqueemployeeid = employeename;
            employeename = null
          }
          this.employeeMasterservice.getEmployeeMaster(this.uniqueemployeeid, employeename,this.selectedValue, this.limitPara, this.offsetPara).subscribe(
            data => {
              // START onScrollLoadData
              if (this.limitPara == 0) {
                this.employeeMasterList = data.employeeMasterData;
                console.log(" data : ", this.employeeMasterList);

              } else {
                this.employeeMasterList = this.employeeMasterList.concat(data.employeeMasterData);
                console.log("data concat : ", this.employeeMasterList);
              }
              // this.scrollLength()
              this.uniqueemployeeid = null
              this.employeeMaster = new EmployeeMaster;
              (<HTMLInputElement>document.getElementById('employeename')).value = "";
            }, error => {
              console.log(error);
              this.employeeMaster = new EmployeeMaster;
              (<HTMLInputElement>document.getElementById('employeename')).value = "";
            }
          );
        }
      }
    }
    if (this.query?.length >= 3) {
      var employeename = (<HTMLInputElement>document.getElementById('employeename')).value;
      if (!employeename) {
        employeename = null;
      } else {
        employeename = employeename.trim()
      }
      if (employeename?.trim().length === 0) {
        employeename = null;
      }
      if (this.uniqueemployeeid === undefined) {
        this.uniqueemployeeid = null
      }
      console.log("permisison : ", this.allowBlankSearch)
      console.log("Initial value before checking ")
      console.log("Unique Id : " + this.uniqueemployeeid);
      console.log("Employee Name  : " + employeename);

      if (this.allowBlankSearch) {
        console.log(" ")
        console.log("true  : ", this.allowBlankSearch)
        console.log("Unique Id : " + this.uniqueemployeeid);
        console.log("Employee Name  : " + employeename);
        this.employeeMasterservice.getEmployeeMaster(this.uniqueemployeeid, employeename,this.selectedValue, this.limitPara, this.offsetPara).subscribe(
          data => {
            // START onScrollLoadData
            if (this.limitPara == 0) {
              this.employeeMasterList = data.employeeMasterData;
              console.log(" data : ", this.employeeMasterList);
            } else {
              this.employeeMasterList = this.employeeMasterList.concat(data.employeeMasterData);
              console.log("data concat : ", this.employeeMasterList);
            }
            // this.scrollLength()
            this.uniqueemployeeid = null;
            this.employeeMaster = new EmployeeMaster;
            (<HTMLInputElement>document.getElementById('employeename')).value = "";

          }, error => {
            console.log(error);
            this.employeeMaster = new EmployeeMaster;
            (<HTMLInputElement>document.getElementById('employeename')).value = "";
          }
        );
      } else {
        if (this.uniqueemployeeid == null && employeename == null) {

          console.log(" check null or not ")
          this.alertmessages("Please Enter Employee Name or Unique Employee ID ", "danger");
        } else {
          console.log("uid / ename .....  not null  ")

          console.log("Unique Id : " + this.uniqueemployeeid);
          console.log("Employee Name  : " + employeename);
          if (Number(employeename)) {
            console.log("nametosearch  : " + employeename);

            this.uniqueemployeeid = employeename;
            employeename = null
          }
          this.employeeMasterservice.getEmployeeMaster(this.uniqueemployeeid, employeename,this.selectedValue, this.limitPara, this.offsetPara).subscribe(
            data => {
              // START onScrollLoadData
              if (this.limitPara == 0) {
                this.employeeMasterList = data.employeeMasterData;
                console.log(" data : ", this.employeeMasterList);
              } else {
                this.employeeMasterList = this.employeeMasterList.concat(data.employeeMasterData);
                console.log("data concat : ", this.employeeMasterList);
              }
              // this.scrollLength()
              this.employeeMaster = new EmployeeMaster;
              (<HTMLInputElement>document.getElementById('employeename')).value = "";
            }, error => {
              console.log(error);
              this.employeeMaster = new EmployeeMaster;
              (<HTMLInputElement>document.getElementById('employeename')).value = "";
            }

          );
        }
      }
    }

  }

  getLeaveType() {
    console.log("get leave type ")
    this.employeeMasterservice.getLeaveType().subscribe(
      data => {
        this.LeaveTypeList = data;
        console.log("Leave types are  : ", this.LeaveTypeList)
      }, error => {
        console.log(error);
      }

    );
  }

  getemployeeinfo(empifo) {
    console.log("here info ... ")
    console.log(empifo)
    empifo.isedit = false;
    this.router.navigate(['/employeemaster'], { queryParams: empifo });
  }

  editempdetails(info) {
    console.log("Edit info...... ... ")
    info.isedit = true;
    this.router.navigate(['/employeemaster'], { queryParams: info });
  }

  onScroll() {
    this.limitPara = this.limitPara + 1;
    this.searchEmployee();
  }

  // scrollLength() {
  // 	console.log("dynamic height on scroll ");
  // 	console.log("");
  // 	const regex = /^(\d+(?:\.\d+)?)\s*([a-zA-Z%]+)$/;
  // 	const height = getComputedStyle(document.getElementById("scrolllength")).getPropertyValue("max-height")
  // 	const computedHeight = height.match(regex);
  // 	console.log("computed height", computedHeight,"computed height in no ",Number(computedHeight[1]),"height ",height)
  // 	if (computedHeight && Number(computedHeight[1]) < 450) {
  // 		let value: any = Number(computedHeight[1]);
  // 		console.log("default value = ", value)
  // 		value = value + (2*55);
  // 		value = value + "px";
  // 		console.log("final vl:", value)
  // 		console.log("transition")
  // 		document.getElementById("scrolllength").style.maxHeight = value
  // 	}
  // 	console.log("");
  // }

  limitpara() {
    this.limitPara = 0;
  }

  @HostListener('scroll', ['$event'])
  scrolled() {
    console.log("Scrole called >>")
    this.onScroll();
  }

  getEmployeeByNameOrId() {
    console.log("Len ", this.query);
  
    // Ensure the query length is valid (either <= 2 or >= 3)
    if (this.query?.length <= 2 || this.query?.length >= 3) {
      this.empLeaveService.getEmployeeByNameOrUniqueId(this.query).subscribe(
        data => {
          console.log("Emp Data ", data);
  
          // Check if data.employeeMasterData has any entries
          if (data.employeeMasterData.length > 0) {
            let filteredEmployees = data.employeeMasterData;
  
            // Get the current date for comparison
            const currentDate = new Date();
            console.log("Current Date: ", currentDate);  // Log current date for debugging
  
            // Apply filtering logic based on the selected employee status
            if (this.selectedValue === 'Active') {
              filteredEmployees = filteredEmployees.filter(emp => {
                const leavingDate = emp.leavingdateString ? new Date(emp.leavingdateString) : null;
                console.log("Leaving Date for " + emp.name + ": ", leavingDate);  // Log leaving date for debugging
                // Active employees should have leavingdate in the future or null
                return leavingDate === null || leavingDate > currentDate;
              });
            } else if (this.selectedValue === 'Inactive') {
              filteredEmployees = filteredEmployees.filter(emp => {
                const leavingDate = emp.leavingdateString ? new Date(emp.leavingdateString) : null;
                console.log("Leaving Date for " + emp.name + ": ", leavingDate);  // Log leaving date for debugging
                // Inactive employees should have leavingdate in the past or null
                return leavingDate && leavingDate < currentDate;
              });
            }
  
            // If the status is 'All', no filter is applied
            if (this.selectedValue === 'All') {
              filteredEmployees = data.employeeMasterData;
            }
  
            // Assign filtered data to the employeeMasterLucene
            this.employeeMasterLucene = filteredEmployees;
  
            // Log the data and leaving date of the first employee in the filtered list
            if (this.employeeMasterLucene.length > 0) {
              this.leavingdate = this.employeeMasterLucene[0].leavingdateString; // Extract the leaving date
              console.log("Filtered Employee Data: ", this.employeeMasterLucene);
              console.log("leavingdate", this.leavingdate);
            } else {
              console.log("No employee data found.");
            }
          } else {
            console.log("No employee data found.");
          }
        },
        error => {
          console.log("Error ", error);
        }
      );
    }
  
    // This line will still be executed before the async request completes,
    // so you may not have a value for 'this.leavingdate' here.
    console.log("Unique Id : " + this.uniqueemployeeid);
  }
  
  onStatusChange() {
    this.getEmployeeByNameOrId();
  }
  
  employeeMasterCSVDownload() {
    var employeename = (<HTMLInputElement>document.getElementById('employeename')).value;
     console.log("Unique Emp Id",this.uniqueemployeeid)
     console.log("Unique Emp name",employeename)
    this.docmentInfoService.downloadEmployeeMatserCSVFile(this.uniqueemployeeid, employeename,this.selectedValue, this.limitPara, this.offsetPara).subscribe(data => {
      // console.log(data)
      this.uniqueemployeeid = null;
      var file = new Blob([data], { type: 'application/vnd.ms-excel' });
      var fileURL = URL.createObjectURL(file);
      var anchor = document.createElement("a");
      anchor.download = "EmployeeMasterDetails.xls";
      anchor.href = fileURL;
      anchor.click();
      //window.open(fileURL);
    },
      error => {
        console.log("Error Occured ", error);
        // console.log("Error Code ",error.status);
      })
  }

  viewProfilePic(info: any) {
    this.url = undefined;
    console.log("profile path : ", info.profilepath, "profile name : ", info.name)
    this.profileName = info.name;
    if (info.profilepath) {
      this.approvalinboxservice.downloadFile(info.profilepath).subscribe(
        data => {

          var downloadUrl = URL.createObjectURL(data);

          var reader = new FileReader();
          reader.readAsDataURL(data);
          reader.onload = (_event) => {

            this.url = reader.result;

          }

        },
        error => {
          console.log('Error occured in download file');
        }
      );
    }


  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }

  setMenuName(name: string) {
    this.menuService.setMenuName(name);
  }
  

}
