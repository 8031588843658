import { Directive, ElementRef, Input, OnChanges } from '@angular/core';
import { ConstantService } from '../services/constant.service';
import { SessionService } from '../services/session.service';

declare var $: any;

@Directive({
      // tslint:disable-next-line:directive-selector
      selector: '[hasAccess]',
      exportAs: 'hasAccess'
})

export class HasAccessDirective implements OnChanges {

      // tslint:disable-next-line:no-input-rename
      @Input('hasAccess') elemData: any;

      origDisplay: string;


      constructor(private el: ElementRef,
            private constantService: ConstantService,
            private sessionService: SessionService) {
      }


      ngOnChanges() {
            var rolePermission: any[] = this.constantService.getRolePermissions();
            var allowedPermissionList: any[] = this.sessionService.getRolePermissionList();
            
            if (!allowedPermissionList.includes(rolePermission[this.elemData['key']])) {
                this.blockAccess();
            } else {
                  this.allowAccess();
            }
      }

      isPresent(permissionKey:string){
            debugger;
            var rolePermission: any[] = this.constantService.getRolePermissions();
            var allowedPermissionList: any[] = this.sessionService.getRolePermissionList();
            
            if (!allowedPermissionList.includes(rolePermission[this.elemData[`${permissionKey}`]])) {
               return false
            } else {
                 return true
            }
      }

      disableAccess(): any {
            if (this.origDisplay) {
                  this.el.nativeElement.style.display = this.origDisplay;
            }
            this.el.nativeElement.disabled = true;
      }


      enableAccess(): any {
            if (this.origDisplay) {
                  this.el.nativeElement.style.display = this.origDisplay;
            }
            this.el.nativeElement.disabled = false;
      }


      blockAccess(): any {
            this.origDisplay = this.origDisplay || this.el.nativeElement.style.display; // Only consider the value if block is being called first time. 
            this.origDisplay = this.origDisplay ? this.origDisplay : 'initial';
            this.el.nativeElement.style.display = 'none';
      }

      allowAccess(): any {
            this.origDisplay = this.origDisplay || this.el.nativeElement.style.display; // Only consider the value if block is being called first time. 
            this.origDisplay = this.origDisplay ? this.origDisplay : 'initial';
            this.el.nativeElement.style.display = 'block';
      }

}