<div class="col-lg-12 p-0" style="height:100%" *ngIf="showLeaveForApproval.length">
  <div class="card rounded_corners" id="scroll_container" style="height: fit-content;">

    <div class="position-relative">
      <div class="card-header p-0 px-4 d-flex justify-content-between" style="min-height:60px;max-height: 60px;"
        id="header_sticky">
        <h4 class="card-title">Leave Pending Review</h4>

      </div>
    </div>

    <div class="p-0 border-0 position-relative" style="min-height:200px;max-height: 200px;">
      <div class="list-group scroll" style="max-height: 147px;">
        <div class="list-group-item d-flex pt-1 pb-1 border-0" *ngFor="let task of showLeaveForApproval | slice:0:4">
          <div class="ms-1">
            <p class="m-0 font-weight-bold">{{ task.name }}</p>
            <p class="text-right mb-0">
              {{task.employeeLeaveApplied?.leaveDate| date: 'mediumDate'}} | {{task.employeeLeaveApplied?.leaveType}}
            </p>
          </div>

        </div>
      </div>
      <div class="card-footer w-100 d-flex justify-content-end pt-3 position-absolute fixed-bottom">
        <a [routerLink]="['/employeeleaveapproval']" [routerLinkActive]="['highlightActiveLink']"
        (click)="setMenuName('Approve Leave'); navigateLeaveApproval()">View All</a>
      </div>
    </div>

  </div>
</div>