<div class="row">
    <div class="col-xl-12 col-md-12 col-lg-12">

        <div class="card">
            <div class=" card-header  d-flex justify-content-between order-0">

                <h4 *ngIf="byStatusorHour" class="w-15 card-title">Attendance Matrix By Hours</h4>

                <h4 *ngIf="!byStatusorHour" class="w-15 card-title">Attendance Matrix By Status</h4>


                <div class="panel-body border-0 d-flex flex-wrap">
                    <div *ngFor="let loop of LeaveList" class="p-1 d-flex font-weight-semibold align align-items-center"
                        [style.fontSize.px]="12.5">
                        <span [style.backgroundColor]="loop.colorcode" class="dot-label me-2 my-auto"></span>
                        {{ loop.leaveName }}
                    </div>
                </div>

                <!-- <div class="panel-heading " role="tab" id="headingOne1">
                    <h4 class="panel-title">
                        <a role="button" data-bs-toggle="collapse" data-parent="#accordion1" href="#collapseOne1"
                            aria-expanded="true" aria-controls="collapseOne1">

                            Leave Color Code Preview
                            <span class="float-end"><i class="fe fe-chevron-right"></i></span>
                        </a>
                    </h4>
                </div> -->
                <div class="">
                    <button type="button" class="btn btn-primary" (click)="attendanceReportCSVDownload()">
                        <i class="fa fa-download" data-bs-toggle="tooltip" data-original-title="download"></i>
                    </button>
                </div>

            </div>

            <!-- <div id="collapseOne1" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne1">
                <div class="panel-body border-0">
                    <div class="d-flex flex-wrap" *ngFor="let loop of LeaveList">
                        <div class="p-1 d-flex font-weight-semibold">
                            <span [style.backgroundColor]="loop.colorcode" class="dot-label me-2 my-auto"></span>
                            {{ loop.leaveName }}
                        </div>
                    </div>
                </div>
            </div> -->
            <!-- <div id="collapseOne1" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne1"> -->

            <!-- </div> -->




            <div class="card-body">
                <div class="col-xs-12 mobile view">
                    <div class="scrollsearchresults" infiniteScroll [infiniteScrollDistance]="2"
                        [infiniteScrollUpDistance]="1.5" [infiniteScrollThrottle]="100" [scrollWindow]="false"
                        (scrolled)="offsetChange()" id="scrolllength">
                        <table class="table  table-vcenter text-nowrap table-bordered border-bottom" id="hr-table">
                            <!-- <thead class="tablerow stickyatTop">
                                <tr class="rowcolors stickyatTop">
                                    <th class="text-center border-bottom-0 w-5 fs-6">ID</th>
                                    <th class="text-center border-bottom-0 w-5 fs-6">Employee Name </th>
                                    <th class="text-center" *ngFor="let day of daysArray">
                                        {{ day }}
                                    </th>
                                </tr>
                            </thead> -->
                            <thead class="tablerow stickyatTop">
                                <tr class="rowcolors stickyatTop">
                                    <th class="padding_top_only text-center border-bottom-0 w-5 fs-6 wC fixedcolumn">
                                        Employee</th>
                                    <th
                                        class="padding_top_only text-center border-bottom-0 w-5 fs-6 wC backblur fixedcolumn">
                                        Employee </th>
                                    <th *ngIf="listingFilters !='GBB'"
                                        class="padding_top_only text-center border-bottom-0 w-5 fs-6 wC backblur fixedcolumn">
                                        Employee </th>
                                    <th class="padding_top_only text-center border-bottom-0 wC"
                                        *ngFor="let day of daysArray">
                                        {{ day }}
                                    </th>
                                    <th class="padding_top_only text-center border-bottom-0 wC">Day
                                    </th>
                                </tr>
                                <tr class="rowcolors stickyatTop">
                                    <th class="padding_top_only1 text-center border-bottom-0 w-5 fs-6 fixedcolumn">ID
                                    </th>
                                    <th
                                        class="padding_top_only1 text-center border-bottom-0 w-5 fs-6 backblur fixedcolumn">
                                        Name</th>
                                    <th *ngIf="listingFilters !='GBB'"
                                        class="padding_top_only1 text-center border-bottom-0 w-5 fs-6 backblur fixedcolumn">
                                        Branch</th>

                                    <th class="padding_top_only1 text-center" *ngFor="let dayOfWeek of dayOfWeekArray">
                                        {{ dayOfWeek }}
                                    </th>
                                    <th class="padding_top_only1 text-center">
                                        Present
                                    </th>
                                </tr>
                            </thead>
                            <tbody *ngIf="byStatusorHour">

                                <tr *ngFor="let report of attendanceMatrixReport">
                                    <td class="fixedcolumn backgroundFW">{{ report.employeeId }}</td>
                                    <td class="fixedcolumn backgroundFW">{{ report.employeeName }}</td>
                                    <td *ngIf="listingFilters !='GBB'" class="fixedcolumn backgroundFW">{{
                                        report.branchName ? report.branchName : '--'
                                        }}</td>

                                    <td class="text-center" *ngFor="let attendance of report.attendanceList"
                                        [ngStyle]="getDynamicStyles(attendance)"
                                        [ngClass]="{'bg-warning-transparent': attendance.weeklyHolidays === 1, 'bg-info-transparent': attendance.annualHoliday === 1}">
                                        <span
                                            [ngStyle]="{
                                        'text-decoration': (attendance.totalSwipeHour !== undefined && attendance.totalSwipeHour !== '00:00:00' && (attendance.daysPresent !== 1 && attendance.daysPresent !== 0.5)) ? 'underline' : 'none',
                                        'color': (attendance.totalSwipeHour !== undefined && attendance.totalSwipeHour !== '00:00:00' && (attendance.daysPresent !== 1 && attendance.daysPresent !== 0.5)) ? 'red' : 'inherit' }">
                                            {{ (attendance.totalSwipeHour | stringDate24To12 )||
                                            (attendance.totalSwipeHourStringFormate | stringDate24To12) ||
                                            '--' }}
                                        </span>
                                    </td>

                                    <td class="text-center">{{ report.totalDayPresent }}</td>

                                </tr>

                            </tbody>

                            <tbody *ngIf="!byStatusorHour">

                                <tr *ngFor="let report of attendanceMatrixReport">
                                    <td class="fixedcolumn backgroundFW">{{ report.employeeId }}</td>
                                    <td class="fixedcolumn backgroundFW">{{ report.employeeName }}</td>
                                    <td *ngIf="listingFilters !='GBB'" class="fixedcolumn backgroundFW">{{
                                        report.branchName ? report.branchName : '--'
                                        }}</td>
                                    <td class="text-center" *ngFor="let attendance of report.attendanceList"
                                        [ngStyle]="getDynamicStyles(attendance)"
                                        [ngClass]="{'bg-warning-transparent': attendance.weeklyHolidays === 1, 'bg-info-transparent': attendance.annualHoliday === 1}">
                                        <span
                                            [ngStyle]="{
                                        'text-decoration': (attendance.totalSwipeHour !== undefined && attendance.totalSwipeHour !== '00:00:00' && (attendance.daysPresent !== 1 && attendance.daysPresent !== 0.5)) ? 'underline' : 'none',
                                        'color': (attendance.totalSwipeHour !== undefined && attendance.totalSwipeHour !== '00:00:00' && (attendance.daysPresent !== 1 && attendance.daysPresent !== 0.5)) ? 'red' : 'inherit' }">
                                            {{ attendance.doubleShift >= 2 ? 'D'
                                            : attendance.daysPresent == 1 ? 'P'
                                            : attendance.daysPresent == 0.5 ? 'H'
                                            : attendance.weeklyHolidays == 1 ? '--'
                                            : attendance.annualHoliday == 1 ? '--'
                                            : attendance.leaveTaken == 1 ? '--'
                                            : ( attendance.leaveTaken == 0.5 && attendance.daysAbsent == 0.5 ) ? 'H'
                                            : attendance.oddPunch == 1 ? 'ODD'
                                            : attendance.suspendedDays == 1 ? 'S'
                                            : attendance.daysAbsent == 1 ? 'A' : '--' }}
                                        </span>
                                    </td>
                                    <td class="text-center">{{ report.totalDayPresent }}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>