<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp"></div>
        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <form id="fcForm" class="form-horizontal form-label-left row">

                            <div class="row col-md-12 col-sm-12 col-xs-12">
                                <div class="col-md-4 col-sm-4 col-xs-4">

                                    <div class="form-group label-floating">
                                        <label class="form-label">Requested Relieving Date</label>
                                        <div>
                                            <input type="date" name="request_relieving_date"
                                                class="form-control col-md-12 col-xs-12" autocomplete="off"
                                                placeholder="Request Relieving Date" autofocus="true"
                                                (change)="calculateNoticePeriod($event)" />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label class="form-label" for="Reason">Reason</label>
                                        <div class="w-100">
                                            <select class="form-control col-md-12 col-xs-12" style="height: 38px"
                                                data-style="btn btn-drpdwn btn-round" id="Reason">

                                                <option>Medical Emergency</option>
                                                <option>Break</option>
                                                <option>others</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label class="form-label">Notice period in days</label>
                                        <div>
                                            <input type="text" class="form-control col-md-12 col-xs-12"
                                                autocomplete="off" placeholder="Notice period in days"
                                                name="noticePeriod" [value]="noticePeriod" readonly />
                                        </div>
                                    </div>
                                </div>


                                <div class="col-md-5 col-sm-5 col-xs-5">
                                    <div class="form-group label-floating">
                                        <label for="form-label" class="form-label">Remarks</label>
                                        <input type="text" class="form-control col-md-12 col-xs-12" autocomplete="off"
                                            placeholder="Remarks" name="enteredamount" autofocus="true" />
                                        <!-- <span class="help-text text-danger" *ngIf="(!filename.valid && filename.touched)">Upload
                        File</span> -->
                                    </div>
                                </div>
                            </div>

                            <div class="actionBar">
                                <div class="clearfix"></div>
                                <div class="pro-fab">
                                    <button class="btn btn-success" type="button">
                                        Save
                                    </button>
                                    <button class="btn btn-danger ms-2" type="button">
                                        Clear
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="card col-md-12 col-sm-12 col-xs-12">
            <div class="card-header border-bottom-0">
                <h3 class="card-title">List of Expense</h3>
            </div>
            <div class="card-body card1S p-0">
                <div class="table-responsive">
                    <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                        id="hr-table">
                        <thead class="tablerow">
                            <tr class="rowcolors stickyatTop">
                                <th class="border-bottom-0 fs-7">Expense ID</th>
                                <th class="border-bottom-0 w-5 fs-7">Expense Name</th>
                                <th class="border-bottom-0 fs-7">Expense Date</th>
                                <th class="border-bottom-0 fs-7">Expense Type</th>
                                <th class="border-bottom-0 fs-7">Amount</th>
                                <th class="border-bottom-0 fs-7">Approved Amount</th>
                                <th class="border-bottom-0 fs-7">Attachment</th>
                                <th class="border-bottom-0 fs-7">Status</th>
                                <th class="border-bottom-0 fs-7">Action</th>
                            </tr>
                        </thead>
                        <tbody style="max-height: 50vh; overflow: scroll; padding-bottom: 10vh;">
                            <tr *ngFor="let exp of expenseList; let i = index">
                                <td>{{ i + 1 }}</td>
                                <td>{{ exp.client_name }}</td>
                                <td>{{ exp.date_of_expense| date: 'mediumDate'}}</td>
                                <td>{{ exp.expenseName }}</td>
                                <td style="text-align: right;">
                                    {{ exp.amount | currency : "INR" : "symbol" : "1.2-2" }}
                                </td>
                                <td style="text-align: right;">{{ exp.approvalamount | currency : "INR" : "symbol" :
                                    "1.2-2"}}</td>
                                <td>{{ exp.filename | slice:0:20 }}{{ exp.filename.length > 20 ? '...' : '' }}</td>
                                    <span *ngIf="exp.status === 'PENDING'"
                                        class="badge badge-warning">{{exp.status}}</span>
                                    <span *ngIf="exp.status === 'APPROVED'"
                                        class="badge badge-success">{{exp.status}}</span>
                                    <span *ngIf="exp.status === 'REJECTED'"
                                        class="badge badge-danger">{{exp.status}}</span>
                                </td>
                                <td>
                                    <button class="btn btn-primary btn-icon btn-sm me-2"
                                        (click)="myDocumentDownload(exp.attachment, exp.filename)">
                                        <i class="fa fa-download" data-bs-toggle="tooltip"
                                            data-original-title="download"></i>
                                    </button>

                                    <button class="btn btn-primary btn-icon btn-sm me-2"
                                        (click)="viewdocument(exp.attachment, exp.filePath)" style="width: 32px;">
                                        <i class="fa fa-eye" data-bs-toggle="tooltip"
                                            data-original-title="download"></i>
                                    </button>
                                    <button class="btn btn-danger btn-icon btn-sm me-2" data-method="delete"
                                        title="Delete" data-bs-target="#showAlertWarning"
                                        (click)="onDeleteHandler(exp.id)">
                                        <i class="fa fa-trash" data-bs-toggle="tooltip" data-original-title="edit"></i>
                                    </button>
                                </td>
                            </tr>
                            <br><br><br>
                        </tbody>
                    </table>
                </div>
            </div>
        </div> -->
    </div>
</div>
<!-- <div id="showAlertVer" class="modal" style="top: 40px;">
    <div class="modal-dialog">
        <div class="modal-content animate">
            <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">×</button>
                <h4 class="modal-title">Warning</h4>
            </div>

            <div class="modal-body" style="max-height: calc(100vh - 210px);overflow-y: auto;">
                <span><b> Expense Submission data will be deleted permanently. Are you sure you want to
                        continue?</b></span>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-danger medium" (click)="deleteExpense()">OK</button>
                <button type="button" class="btn btn-primary" (click)="closeMRQModal()">Cancel</button>
            </div>

        </div>
    </div>
</div> -->