<!-- page content -->
<!-- <div class="main-panel">
  <div class="content"> -->
<div class="app-content main-content">
  <div class="side-app main-container">

    <div class="alertcomp">

    </div>
    <!-- <div class="container-fluid" role="main">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-content">
                <form id="fcForm" (keyup.enter)="listDocument()">

                  <section>

                    <div>
                      <h3 style="margin-bottom: 30px;padding-left: 10px;">Document Type Search</h3>
                      <div class="clearfix"></div>
                    </div>


                    <div class="col-md-3 col-sm-5 col-xs-7">
                      <div class="form-group label-floating">
                        <label class="control-label"> Document Type Name </label>
                        <div>

                          <input type="text" class="form-control col-md-7 col-xs-12" name="customer" id="customer1"
                            autofocus="autofocus"  autocomplete="off"/>

                        </div>
                      </div>
                    </div>
                  </section>


                  <div class="col-md-1 col-sm-4 col-xs-6">
                    <div class="form-group label-floating">
                      <label class="control-label">&nbsp;&nbsp;</label>
                      <div>
                        <button type="button" class="btn btn-info" (click)="listDocument()">
                          <i class="fa fa-search" aria-hidden="true"></i> Search
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                </div>
              </div>
          </div>


                  <div class="col-md-12">
                    <div class="card">
                      <div class="card-content">

                        <form id="docList" name="docList" enctype="multipart/form-data">

                          <div class="Clearfix"></div>
                          <div class="scrollsearchresults">
                            <div class="col-xs-12 col-md-12">
                              <div class="mobile">
                                <div class="col-xs-12 col-md-6">
                                </div> -->
    <!-- Added Starts-->
    <!--
                        <div class="col-md-2 col-sm-4 col-xs-6">
                          <div class="form-group">
                            <label>&nbsp;&nbsp;</label>
                            <div>
                              <button type="button" class="btn btn-info" (click)="searchDocument()">
                                <i class="fa fa-search" aria-hidden="true"></i> Search
                              </button>
                            </div>
                          </div>
                        </div>
                      -->
    <!-- <div class="Clearfix"></div>

                                <div class="pro-fab">
                                  <button type="button" class="btn btn-info" (click)="openPage()">
                                    <i class="fa fa-plus-square-o" aria-hidden="true"> </i> Add
                                  </button>
                                </div> -->


    <!-- <div class="Clearfix"></div> -->

    <!-- Added Ends-->
    <!-- Input --->
    <!--<app-savedoc [docInfo] = "docInfoDetails"></app-savedoc>-->
    <!-- <table class="table table-hover cf">
                                  <thead>
                                    <tr>
                                      <th class="scrolltheadFixed">Doc Group</th>
                                      <th class="scrolltheadFixed">Doc Type</th>
                                      <th class="scrolltheadFixed">Mandatory</th>
                                      <th class="scrolltheadFixed">Description</th>
                                      <th class="scrolltheadFixed">Sort Order</th>
                                      <th class="scrolltheadFixed">Validity Period</th>
                                      <th class="scrolltheadFixed"></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let j=index; let t of documentList ">
                                      <td data-th="Doc Group" (click)="documentEdit(t.docId,t)">
                                        {{t.docGroup}}
                                      </td>
                                      <td data-th="Doc Type" (click)="documentEdit(t.docId,t)">
                                        {{t.docType}}
                                      </td>
                                      <td data-th="Mandatory" (click)="documentEdit(t.docId,t)">
                                        {{t.mandatory}}
                                      </td>
                                      <td data-th="Description" (click)="documentEdit(t.docId,t)">
                                        {{t.description}}
                                      </td>
                                      <td data-th="Sort Order">
                                        {{t.sortOrder}}
                                      </td>
                                      <td data-th="Validity">
                                        {{t.validityPeriod}}

                                      </td> -->
    <!--<app-savedoc [item]="currentItem"></app-savedoc>-->
    <!--<app-savedoc [docInfo] = "documentInfo (emitComplete) = turnOffFlag"></app-savedoc>-->
    <!--<app-savedoc [docInfo] = "documentInfo"></app-savedoc>-->
    <!--
                              <td>
                                <button type="button" class="btn btn-xs btn-success-small" data-method="edit" title="Edit"
                                  (click)="documentEdit(t.docId)">
                                  <span class="docs-tooltip" data-toggle="tooltip">
                                    <span class="fa fa-edit"></span>
                                  </span> 
                                </button>
        
                              </td>-->
    <!-- <td>
                                        <table>
                                          <tr>
                                            <td>

                                              <button type="button" class="btn btn-success btn-simple" data-method="edit"
                                                title="Edit" (click)="documentEdit(t.docId,t)">
                                                <span class="docs-tooltip" data-toggle="tooltip">
                                                  <span class="fa fa-edit"></span>
                                                </span>
                                              </button>

                                            </td>


                                            <td>
                                              <button type="button" class="btn btn-danger btn-simple" data-method="delete"
                                                title="Delete" (click)="documentDelete(j,t.docId)">
                                                <span class="docs-tooltip" data-toggle="tooltip">
                                                  <span class="fa fa-trash"></span>
                                                </span>
                                              </button>
                                            </td>

                                          </tr>
                                        </table>
                                      </td>



                                    </tr>
                                  </tbody>
                                </table>
                              </div> -->
    <!-- 
                    <div color="red">
                        Records Per Page:
                        <select [(ngModel)]="recordPerPage" name="rpp" (change)="selectPageSize()" tabindex="0">
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="25">25</option>
                            <option value="30">30</option>
                            <option value="35">35</option>
                        </select>
                        &nbsp;&nbsp;&nbsp;
                        Total Count: <span class="red"><b>{{recordCount}}</b></span>
                    </div> -->
    <!-- <div>  &nbsp;&nbsp; </div> -->
    <!-- <div class="text-right">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                    </div> -->
    <!-- </div>


                          </div>

                        </form>


                      </div>
                    </div>
                  </div>
              </div>
            </div> -->

    <div class="page-header d-xl-flex d-block">
      <!-- <div class="page-leftheader">
                  <div class="page-titleMod">Document Type Search</div>
              </div> -->
      <div class="page-rightheader ms-md-auto">
        <div class="align-items-end flex-wrap my-auto right-content breadcrumb-right">
          <div class="btn-list">
            <a (click)="openPage()" class="btn btn-primary me-3">Add new Document</a>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12 col-md-12 col-lg-12">
        <div class="card">
          <div class="card-body">
            <form id="fcForm" class="form-horizontal form-label-left">
              <form class="form-horizontal card-body row label-floating px-4" id="fcForm"
                (keyup.enter)="listDocument()">
                <label class="form-label col-xl-12 col-md-12 col-lg-12">Document Type Name</label>
                <div class="col-md-10 col-sm-12 col-xs-12 mb-4">
                  <div class="form-group m-0">
                    <div>
                      <input type="text" class="form-control" name="customer" id="customer1" autofocus="autofocus"
                        placeholder="Enter Document Type name" />
                    </div>
                  </div>
                </div>
                <div class="col-md-2 col-sm-12 col-xs-12">
                  <button type="button" id="navigatetarget" class="btn btn-primary  wd w-100" (click)="listDocument()">
                    <i class="fa fa-search p-0" aria-hidden="true"></i> Search
                  </button>
                </div>
              </form>
            </form>
          </div>
        </div>
      </div>
    </div>



    <div class="row">
      <div class="col-xl-12 col-md-12 col-lg-12">
        <div class="card">
          <div class="card-header  border-0">
            <h4 class="card-title">Employees List</h4>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <div class="col-xs-12 mobile">
                <div *ngIf="documentList !== null " style="height: 46vh;">
                  <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom" id="hr-table">
                    <thead class="tablerow">
                      <tr class="rowcolors">
                        <th class="border-bottom-0 w-5 fs-7">Doc Group</th>
                        <th class="border-bottom-0 w-5 fs-7">Doc Type</th>
                        <th class="border-bottom-0 fs-7">Mandatory</th>
                        <th class="border-bottom-0 w-10 fs-7">Description</th>
                        <!-- <th class="border-bottom-0 fs-7">Sort Order</th> -->
                        <th class="border-bottom-0 fs-7">Validity Period</th>

                        <th class="border-bottom-0"> </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let j=index; let t of documentList ">
                        <td data-th="Doc Group" (click)="documentEdit(t.docId,t)">
                          {{t.docGroup}}
                        </td>
                        <td data-th="Doc Type" (click)="documentEdit(t.docId,t)">
                          {{t.docType}}
                        </td>
                        <td data-th="Mandatory" (click)="documentEdit(t.docId,t)">
                          {{t.mandatory}}
                        </td>
                        <td data-th="Description" (click)="documentEdit(t.docId,t)">
                          {{t.description}}
                        </td>
                        <!-- <td data-th="Sort Order">
                                      {{t.sortOrder}}
                                    </td> -->
                        <td data-th="Validity">
                          {{t.validityPeriod}}

                        </td>
                        <!-- <td><span class="badge badge-success">Active</span></td> -->
                        <!-- <td>
                                          <a class="btn btn-primary btn-icon btn-sm"  href="hr-empview.html">
                                              <i class="feather feather-edit" data-bs-toggle="tooltip" data-original-title="View/Edit"></i>
                                          </a>
                                          <a class="btn btn-danger btn-icon btn-sm" data-bs-toggle="tooltip" data-original-title="Delete"><i class="feather feather-trash-2"></i></a>
                                      </td> -->
                        <td>
                          <a class="btn btn-primary btn-icon btn-sm me-2" (click)="documentEdit(t.docId,t)">
                            <i class="feather feather-edit" data-bs-toggle="tooltip"
                              data-original-title="View/Edit"></i>
                          </a>
                          <a class="btn btn-danger btn-icon btn-sm" (click)="documentDelete(j,t.docId)"
                            data-bs-toggle="tooltip" data-original-title="Delete"><i
                              class="feather feather-trash-2"></i></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div id="showAlert" class="modal" style="top: 40px;">
  <div class="modal-dialog">
    <div class="modal-content animate">
      <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          (click)="closeMRQModal()">×</button>
        <h4 class="modal-title">Warning</h4>
      </div>

      <div class="modal-body" style="max-height: calc(100vh - 210px);overflow-y: auto;">
        <!-- auto scroll -->
        <span><b> Document will be deleted permanently . Are you sure you want to continue ?</b></span>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-danger medium" (click)="confirmDocDelete()">OK</button>
        <button type="button" class="btn btn-primary" (click)="closeMRQModal()">Cancel</button>

      </div>

    </div>
  </div>
</div>