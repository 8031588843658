import { Component, HostListener, OnInit } from '@angular/core';
import { EmployeeLeaveService } from '../services/employee-leave.service';
import { EmployeeMaster } from '../models/employeeMasterts';
import { ApplicationSettingsService } from '../services/application-settings.service';
import { EmployeeMasterservice } from '../services/employeemaster.service';
import { Employeesuspensioninfo } from '../models/Employeesuspensioninfo';

@Component({
  selector: 'app-employee-suspension-report',
  templateUrl: './employee-suspension-report.component.html',
  styleUrls: ['./employee-suspension-report.component.css']
})
export class EmployeeSuspensionReportComponent implements OnInit {
  statusValue: string = 'All';
  selectedValue: string = 'Active';
  query: any;
  queryName: any;
  employeeid: String;
  uniqueemployeeid: String;
  employeeMasterLucene: EmployeeMaster[];
  department: any[] = [];
  limitPara: any;
	offsetPara: any;
  selecteddepartment: string= "0";
  fromDate: string;
	toDate: string;
  leaveStatus: string;
  suspenseReport: Employeesuspensioninfo[]=[];
  client_id: EmployeeMaster[];
  keyinempid: any | undefined;
  employeeMaster: EmployeeMaster[];
  selectedempid: any;
  leavingdate: any;
  permissionList: any[] = [];
  showBasedOnPermissionEmployeeStatus: boolean = false;

  constructor(private empLeaveService: EmployeeLeaveService,private applicationSettingsService: ApplicationSettingsService,
    private employeeMasterservice:EmployeeMasterservice) {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1;
      this.fromDate = `${year}-${month.toString().padStart(2, '0')}-01`;
      const lastDay = new Date(year, month, 0).getDate();
      this.toDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;
   }

  ngOnInit(): void {
    this.getDepartment();
    this.limitPara = 13;
    this.offsetPara = 0;
    const maxString = sessionStorage.getItem('permission').replace('[', '');
    const maxStringBox = maxString.replace(']', '');
    const maxStringAgain = maxStringBox.replace(/"/g, '');
    this.permissionList = maxStringAgain.split(',');
    this.checkAccessForUser();
  
  }
  getDepartment() {
    this.applicationSettingsService.getDepartmentType().subscribe(
      (data) => {
        console.log("Data available :", data);
        this.department = data.departmentDetail;
        console.log("List of all the department available :", this.department);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  onSuspensionStatusChange(selectedValue: string) {
		this.statusValue = selectedValue;
		console.log("Value", this.statusValue)
	}
 
  getEmployeeByNameOrId() {
    const [name, id] = this.query.split(' / ');
    this.queryName = name;
    this.employeeid = id; // Store the ID if it's available
    console.log("query", this.queryName);
    
    if (this.queryName.length <= 2) {
      this.employeeMaster = [];
    }
  
    if (this.queryName.length >= 3) {
      this.empLeaveService.getEmployeeByNameOrUniqueId(this.queryName).subscribe(
        data => {
          console.log("Emp Data ", data);
          
          // Check if data.employeeMasterData has any entries
          if (data.employeeMasterData.length > 0) {
            let filteredEmployees = data.employeeMasterData;
    
            // Get the current date for comparison
            const currentDate = new Date();
            console.log("Current Date: ", currentDate);  // Log current date for debugging
    
            // Apply filtering logic based on the selected employee status
            if (this.selectedValue === 'Active') {
              filteredEmployees = filteredEmployees.filter(emp => {
                const leavingDate = emp.leavingdateString ? new Date(emp.leavingdateString) : null;
                console.log("Leaving Date for " + emp.name + ": ", leavingDate);  // Log leaving date for debugging
                // Active employees should have leavingdate in the future or null
                return leavingDate === null || leavingDate > currentDate;
              });
            } else if (this.selectedValue === 'Inactive') {
              filteredEmployees = filteredEmployees.filter(emp => {
                const leavingDate = emp.leavingdateString ? new Date(emp.leavingdateString) : null;
                console.log("Leaving Date for " + emp.name + ": ", leavingDate);  // Log leaving date for debugging
                // Inactive employees should have leavingdate in the past or null
                return leavingDate && leavingDate < currentDate;
              });
            }
    
            // If the status is 'All', no filter is applied
            if (this.selectedValue === 'All') {
              filteredEmployees = data.employeeMasterData;
            }
    
            // Assign filtered data to the employeeMasterLucene
            this.employeeMasterLucene = filteredEmployees;
    
            // Log the data and leaving date of the first employee in the filtered list
            if (this.employeeMasterLucene.length > 0) {
              this.leavingdate = this.employeeMasterLucene[0].leavingdateString; // Extract the leaving date
              console.log("Filtered Employee Data: ", this.employeeMasterLucene);
              console.log("leavingdate", this.leavingdate);
            } else {
              console.log("No employee data found.");
            }
          } else {
            console.log("No employee data found.");
          }
          
          // Update employeeMaster with the filtered data
          this.employeeMaster = this.employeeMasterLucene;
          let filteredEmployees = data.employeeMasterData;
            // Now we find the employee with the matching employeeid
            const selectedEmployee = filteredEmployees.find(emp => emp.employeeid === this.employeeid);

  
          // Set the selectedempid to the first employee's unique ID
          if (selectedEmployee) {
            this.selectedempid = selectedEmployee.uniqueemployeeid;
          }
          
        },
        error => {
          console.log("Error ", error);
        }
      );
    }
  }
  
  onStatusChange() {
    this.getEmployeeByNameOrId();
  }

    onScroll() {
      this.offsetPara = this.offsetPara + 13;
      console.log("limit = ? ", this.limitPara, "offset = +13 ", this.offsetPara)
      this.searchSuspensionReport();
  
    }
  
    limitpara() {
      this.offsetPara = 0;
      console.log(" on clikc limit para ", this.limitPara)
      console.log("this offset", this.offsetPara)
    }
  
    @HostListener('scroll', ['$event'])
    scrolled() {
  
      console.log("Scrolled >>> ")
      this.onScroll();
    }
  
  
  searchSuspensionReport() {
    console.warn("search");
    let employeename = (<HTMLInputElement>document.getElementById('employeename')).value;
    if (!employeename) {
      employeename = null;
    } else {
      employeename = employeename.trim()
    //   this.statusValue ="All";
    //  this.selecteddepartment = "0";
    }
    var id = null;
    if (employeename) {
      this.client_id = this.employeeMaster.filter((dName) => {
        return dName.name === employeename;
      });

      console.log("data parent: ", this.client_id)
      id = this.selectedempid;
      this.keyinempid = id;
    } else {
      console.log("Id parent", id)
    }
    this.employeeMasterservice.getEmployeeSuspensionReport(this.fromDate, this.toDate,this.statusValue,this.selecteddepartment, id,employeename, this.limitPara, this.offsetPara).subscribe(
        (data) => {
          this.suspenseReport=data.employeesuspension_intermediate;
          console.log("Data available :", data);
          console.log(data.employeesuspensioninfo);
          console.log(this.suspenseReport);
          
        },
        (error) => {
          console.log(error);
        }
      );
  }
  clear(){
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    this.fromDate = `${year}-${month.toString().padStart(2, '0')}-01`;
    const lastDay = new Date(year, month, 0).getDate();
    this.toDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;
     this.selecteddepartment = "0";
    this.query='';
   }

   checkAccessForUser() {
    console.log("checkAccessForUser here")
    this.showBasedOnPermissionEmployeeStatus = this.permissionList.includes('ng.employee.search.show.employeestatus');
  }
}
