<!-- page content -->
<!-- <div class="main-panel">
  <div class="content"> -->
<div class="app-content main-content">
  <div class="side-app main-container">
    <div class="alertcomp">

    </div>
    <!-- <div class="page-header d-xl-flex d-block">
      <div class="page-leftheader">
        <div class="page-title">Add Documents</div>
      </div>
    </div> -->

    <div class="row">
      <div class="col-xl-12 col-md-12 col-lg-12">
        <div class="card">
          <div class="card-body">
            <form enctype="multipart/form-data" id="fcForm" class="form-horizontal form-label-left" #f="ngForm"
              (ngSubmit)="f.form.valid" novalidate>

              <div class="x_content row p-4">
                <div class="col-md-3 col-sm-6 col-xs-6">
                  <div class="form-group label-floating">
                    <label class="form-label"> Document Group </label>
                    <div>

                      <select class="form-control" data-style="btn btn-drpdwn btn-round" id="dGroup" name="dGroup"
                        [(ngModel)]="myDocuments.docGroup" #docGroup="ngModel" (change)="docGroupChange()" required>

                        <option [ngValue]="undefined" [disabled]="true" [hidden]="true">
                          Select Group</option>
                        <option [value]="g" *ngFor="let g of docGrpGrp">{{g}}</option>
                      </select>
                      <span *ngIf="submitted && !docGroup.valid " class="help-block" style="color: red;">
                        Please Select Document Group</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-3 col-sm-6 col-xs-6">
                  <div class="form-group label-floating">
                    <label class="form-label"> Document Type </label>
                    <div>
                      <select class="form-control" data-style="btn btn-drpdwn btn-round" id="dType" name="dType"
                        [(ngModel)]="myDocuments.docType" #docType="ngModel" (change)="docTypeChange()" required>

                        <!-- <option [ngValue]="null">
                            Choose Months Commitment...
                          </option> -->
                        <option [ngValue]="undefined" [disabled]="true" [hidden]="true">
                          Select Type</option>
                        <option [ngValue]="t" *ngFor="let t of docTypArray">{{t}}</option>

                      </select>
                      <span *ngIf="submitted && !docType.valid " class="help-block" style="color: red;">
                        Please Select Document Type</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-sm-6 col-xs-12">
                  <div class="form-group label-floating">
                    <label for="form-label" class="form-label">Attachment</label>
                    <input type="file" id="file" class="form-control custom-file-input" name="myFilename"
                      style="box-sizing: border-box;" [(ngModel)]="myDoc.file" #file="ngModel" required="required"
                      enctype="multipart/form-data" (change)="handleFileInput($event)"
                      accept="image/*" /> <!-- Restricts the file input to image files -->
                      <p *ngIf="!isValidImageFile" class="help-block" style="color: red;">
                        Please choose a valid image file (e.g., .jpg, .png, .jpeg).
                      </p>
                    <p *ngIf="submitted && !file.valid" class="help-block" style="color: red;">
                      Please Attach File</p>
                  </div>



                  <!-- <div class="custom-file">
                      <label> Choose File </label>
                      <div >
                        <i style="cursor: pointer;" class = "pull-right" (click)="clearSelection()">x</i>

                        <input type="file" id="file" class="custom-file-input" name="myFilename"
                          style="box-sizing: border-box; width: 175px; border: 0;" [(ngModel)]="myDoc.file"
                          #file="ngModel" required="required" enctype="multipart/form-data"
                          (change)="handleFileInput($event)" />
                      

                      </div>

                    </div> -->
                </div>

                <div class="col-md-3 col-sm-6 col-xs-6">
                  <div class="form-group label-floating">
                    <label class="form-label"> Expiry Date </label>
                    <div [ngClass]="{ 'has-error':errExpDate}">
                      <input type="date" class="form-control" name="expiryDate" [(ngModel)]="myDocuments.expiryDate"
                        #expiryDate="ngModel" disabled={{disabledExpDate}} (focus)="onfocusExpDate()" />
                      <p *ngIf="errExpDate" class="help-block" style="color: red;">
                        Please Enter Expiry Date</p>
                    </div>
                  </div>
                </div>


              </div>
              <div class="actionBar">
                <div class="clearfix"> </div>
                <div class="pro-fab">
                  <button class="btn btn-success" type="button" [disabled]=disabledSaveButton
                    (click)="save(f.form.valid)">Save</button>
                  <button class="btn btn-danger ms-2" type="button" (click)="reFresh()">Clear</button>
                </div>
              </div>

            </form>
          </div>

        </div>
      </div>
    </div>


    <div class="row">
      <div class="col-xl-12 col-md-12 col-lg-12">
        <div class="card">
          <div class="card-header  border-0">
            <h4 class="card-title">Document List</h4>
          </div>
          <div class="card-body">
            <div class="col-xs-12 mobile">
              <div *ngIf="showDocList !== null ">
                <div class="scrollsearchresults">
                  <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom" id="hr-table">
                    <thead class="tablerow">
                      <tr class="rowcolors stickyatTop">
                        <th class="border-bottom-0 w-5 fs-7">Doc Group</th>
                        <th class="border-bottom-0 fs-7">Doc Type</th>
                        <th class="border-bottom-0 fs-7">File Name</th>
                        <th class="border-bottom-0 w-10 fs-7">Uploaded Date</th>
                        <th class="border-bottom-0 fs-7">Expiry Date</th>
                        <th class="border-bottom-0 fs-7">Comments</th>
                        <th class="border-bottom-0 fs-7">Status</th>
                        <th class=""> </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let j=index; let t of myDocuList ">
                        <td data-th="Doc Group">{{t.docGroup}}</td>
                        <td data-th="Doc Type">{{t.docType}}</td>
                        <td data-th="FileName">{{t.name}}</td>
                        <td data-th="Upoaded Date">{{t.uploadedOn | date: 'mediumDate'}}</td>
                        <td data-th="Expiry Date">{{t.expiryDate | date: 'mediumDate'}}</td>
                        <td data-th="Comments">{{t.comments}}</td>
                        <td data-th="Status">{{t.overAllStatus}}</td>
                        <td>
                          <button class="btn btn-primary btn-icon btn-sm me-2"
                            (click)="myDocumentDownload(t.name, t.filePath)">
                            <i class="fa fa-download" data-bs-toggle="tooltip" data-original-title="download"></i>
                          </button>
                          <!-- <ng-container ngSwitch="{{t.overAllStatus}}"> -->
                          <!-- <button  *ngSwitchCase="'Submitted'"  class="btn btn-danger btn-icon btn-sm" data-bs-toggle="tooltip" data-method="delete" (click)="myDocumentDelete(j, t.id)" data-original-title="Delete"><i class="feather feather-trash-2"></i></button> -->

                          <!-- <button  *ngSwitchCase="'Rejected'"  class="btn btn-danger btn-icon btn-sm" data-method="delete" data-bs-toggle="tooltip" (click)="myDocumentDelete(j, t.id)" data-original-title="Delete"><i class="feather feather-trash-2"></i></button> -->


                          <!-- </ng-container> -->
                        </td>



                        <!-- <td  ngSwitch="{{t.overAllStatus}}">
                                  <button  *ngSwitchCase="'Submitted'" type="button" class="btn btn-danger btn-icon btn-sm" data-method="delete" 
                                    title="Delete" (click)="myDocumentDelete(j, t.id)">
                                    <span class="docs-tooltip" data-toggle="tooltip">
                                      <span class="feather feather-trash-2"></span>
                                    </span>
                                  </button>
                                  <button  *ngSwitchCase="'Rejected'" type="button" class="btn btn-danger btn-icon btn-sm" data-method="delete" 
                                  title="Delete" (click)="myDocumentDelete(j, t.id)">
                                  <span class="docs-tooltip" data-toggle="tooltip">
                                    <span class="feather feather-trash-2"></span>
                                  </span>
                                </button>
                                </td> -->
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="container-fluid" role="main">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-content">
              <form enctype="multipart/form-data" id="fcForm" class="form-horizontal form-label-left" #f="ngForm"
                (ngSubmit)="f.form.valid" novalidate>
                <div class="">
                  <div class="x_content">
                    <div class="x_title">
                      <h2>Add Documents</h2>
                      <div class="clearfix"></div>
                    </div>

                    <div class="col-md-2 col-sm-4 col-xs-6">
                      <div class="form-group label-floating">
                        <label> Document Group </label>
                        <div>

                          <select class="form-control" data-style="btn btn-drpdwn btn-round" id="dGroup" name="dGroup"
                            [(ngModel)]="myDocuments.docGroup" #docGroup="ngModel" (change)="docGroupChange()" required>


                            <option [value]="g" *ngFor="let g of docGrpGrp">{{g}}</option>
                          </select>
                          <span *ngIf="submitted && !docGroup.valid " class="help-block" style="color: red;">
                            Please Select Document Group</span>
                        </div>
                      </div>
                    </div>


                    <div class="col-md-2 col-sm-4 col-xs-6">
                      <div class="form-group label-floating">
                        <label> Document Type </label>
                        <div>
                          <select class="form-control" data-style="btn btn-drpdwn btn-round" id="dType" name="dType"
                            [(ngModel)]="myDocuments.docType" #docType="ngModel" (change)="docTypeChange()" required>


                            <option [value]="t" *ngFor="let t of docTypArray">{{t}}</option>


                          </select>
                          <span *ngIf="submitted && !docType.valid " class="help-block" style="color: red;">
                            Please Select Document Type</span>
                        </div>
                      </div>
                    </div>



                    <div class="col-md-4 col-sm-6 col-xs-12">
                      <div class="custom-file">
                        <label> Choose File </label>
                        <div style="padding-top: 30px;">
                          <i style="cursor: pointer;" class = "pull-right" (click)="clearSelection()">x</i>
                          <input type="file" id="file" class="custom-file-input" name="myFilename"
                            style="box-sizing: border-box; width: 175px; border: 0;" [(ngModel)]="myDoc.file"
                            #file="ngModel" required="required" enctype="multipart/form-data"
                            (change)="handleFileInput($event)" />
                          <p *ngIf="submitted && !file.valid " class="help-block" style="color: red;">
                            Please Choose File</p>

                        </div>

                      </div>
                    </div>



                    <div class="col-md-2 col-sm-4 col-xs-6">
                      <div class="form-group label-floating">
                        <label> Expiry Date </label>
                        <div [ngClass]="{ 'has-error':errExpDate}">
                          <input type="date" class="form-control" name="expiryDate" [(ngModel)]="myDocuments.expiryDate"
                            #expiryDate="ngModel" disabled={{disabledExpDate}} (focus)="onfocusExpDate()" />
                          <p *ngIf="errExpDate" class="help-block" style="color: red;">
                            Please Enter Proper Expiry Date</p>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
                <div class="actionBar">
                  <div class="clearfix"> </div>
                  <div class="pro-fab">
                    <button class="btn btn-success" type="button"  [disabled]= disabledSaveButton (click)="save(f.form.valid)">Save</button>
                    <button class="btn btn-danger" type="button" (click)="reFresh()">Clear</button>
                  </div>
                </div>
              </form>

            </div>
          </div>
          <div class="x_panel" *ngIf="showDocList">
            <div class="card">
              <div class="card-content">
                <form id="docList" name="docList" type="multipart/form-data">
                  <div class="scrollsearchresults">
                    <div class="col-xs-12 col-md-12">
                      
                      <div class="mobile">
                        <table class="table table-hover cf">
                          <thead>
                            <tr>
                              <th class="scrolltheadFixed">Doc Group</th>
                              <th class="scrolltheadFixed">Doc Type</th>
                              <th class="scrolltheadFixed">File Name</th>
                              <th class="scrolltheadFixed">Uploaded Date </th>
                              <th class="scrolltheadFixed">Expiry Date</th>
                              <th class="scrolltheadFixed">Comments</th>
                              <th class="scrolltheadFixed">Status</th>
                              <th class="scrolltheadFixed"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let j=index; let t of myDocuList ">

                              <td data-th="Doc Group">{{t.docGroup}}

                              </td>

                              <td data-th="Doc Type">{{t.docType}}
                              </td>
                              <td data-th="FileName">{{t.name}}
                              </td>



                              <td data-th="Upoaded Date">{{t.uploadedOn | date: 'dd/MM/yyyy'}}
                              </td>
                              <td data-th="Expiry Date">{{t.expiryDate | date: 'dd/MM/yyyy'}}
                              </td>

                              <td data-th="Comments">{{t.comments}}

                              </td>

                              <td data-th="Status">{{t.overAllStatus}}

                              </td>
                              <td>
                                <table>
                                  <tr>
                                    <td> -->

    <!-- <button class="btn btn-success btn-simple" data-method="download" title="download" -->
    <!-- (click)="myDocumentDownload(t.name, t.filePath)"> -->
    <!--<a href="../../../assets/uploads/2.png" download='2.png'>download-->
    <!-- <span class="fa fa-download" title="download"></span> -->
    <!--</a>-->

    <!-- </button> -->
    <!-- </td> -->
    <!-- <td  ngSwitch="{{t.overAllStatus}}">
                                      <button  *ngSwitchCase="'Submitted'" type="button" class="btn btn-danger btn-simple" data-method="delete" 
                                        title="Delete" (click)="myDocumentDelete(j, t.id)">
                                        <span class="docs-tooltip" data-toggle="tooltip">
                                          <span class="fa fa-trash"></span>
                                        </span>
                                      </button>
                                      <button  *ngSwitchCase="'Rejected'" type="button" class="btn btn-danger btn-simple" data-method="delete" 
                                      title="Delete" (click)="myDocumentDelete(j, t.id)">
                                      <span class="docs-tooltip" data-toggle="tooltip">
                                        <span class="fa fa-trash"></span>
                                      </span>
                                    </button>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
    <!-- <div class="right_col" role="main">
  <div class="row">
    <div class="x_content">

    </div>
  </div>
</div> -->

    <div id="showAlert" class="modal" style="top: 40px;">
      <div class="modal-dialog">
        <div class="modal-content animate">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
              (click)="closeMRQModal()">×</button>
            <h4 class="modal-title">Warning</h4>
          </div>

          <div class="modal-body" style="max-height: calc(100vh - 210px);overflow-y: auto;">
            <!-- auto scroll -->
            <span><b> Document will be deleted permanently . Are you sure you want to continue ?</b></span>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-danger medium" (click)="confirmMyDocDelete()">OK</button>
            <button type="button" class="btn btn-primary" (click)="closeMRQModal()">Cancel</button>

          </div>

        </div>
      </div>
    </div>

    <div id="showNoPermission" class="modal" style="top: 40px;">
      <div class="modal-dialog">
        <div class="modal-content animate">
          <div class="modal-header">
            <!--<button type="button" class="close" (click)="closeMRQModal()">×</button>-->
            <h4 class="modal-title">Warning</h4>
          </div>

          <div class="modal-body" style="max-height: calc(100vh - 210px);overflow-y: auto;">
            <!-- auto scroll -->
            <span><b>Your Employee Id is not Setup. You Won't be able to Upload the Document. Please Contact
                Admin</b></span>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-danger medium" (click)="confirmNoPermission()">OK</button>
            <!--<button type="button" class="btn btn-primary" (click)="closeMRQModal()">Cancel</button>-->

          </div>

        </div>
      </div>
    </div>