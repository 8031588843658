import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PeopleInfo } from '../../models/peopleInfo'
import { CommonService } from '../../services/common.service';
import { AlertService } from '../../services';
import { DocumentInfoService } from '../../services/documentinfo.service';
import { DocumentInfo } from '../../models/documentInfo';

declare var $: any;

//Added for Listing Purpose
//import { CustomersInfo } from 'src/app/models/customersInfo';
//import { CustomerService } from 'src/app/services/customer.service';
//Added Ends Here

@Component({
  selector: 'app-savedoc',
  templateUrl: './savedoc.component.html',
  styleUrls: ['./savedoc.component.css']
})
export class SaveDocComponent implements OnInit {
  peopleInfo: PeopleInfo;
  documentInfo: DocumentInfo;
  submitted: boolean;

  username: any;
  //documentInfo: any = {};
  document: any = {};
  //ContactType=['Mobile','Telephone','Email','Fax']; 
  //mandatoryType = [{ name: 'Yes', value: 'Yes' }, { name: 'No', value: 'N' }];
  mandatoryType = ['Yes', 'No'];
  validityPeriod: any[] = [{ name: 'Yes', value: 'Y' }, { name: 'No', value: 'N' }];

  documentDetails = [];
  documentList = [];
  documentInfoDetails = [];
  queryString: any;
  add_view_toggle: any;
  documentListX = [];
   DeleteDocumentMaster: DocumentInfo;
   addOrUpdate: boolean = true;
  //@Input() docInfo: DocumentInfo; // decorate the property with @Input()
  @Input() docInfo: any = {}; // decorate the property with @Input()
  //@Input() docInfo = new EventEmitter();
  //@Input() docInfo = new EmitComplete();
  //@Input() data: string[];

  constructor(private router: Router, private route: ActivatedRoute, private commonService: CommonService, private alertService: AlertService, private docmentInfoService: DocumentInfoService) { }
  // private router: Router,private customerService :CustomerService,private alertservice: AlertService ) { }

  ngOnInit() {
    this.DocumentlistXX();

    this.username = sessionStorage.getItem('username')
    this.peopleInfo = new PeopleInfo();
    this.documentInfo = new DocumentInfo();
    this.add_view_toggle ="viewDocType"
    //this.docInfo = new DocumentInfo();
    //this.documentInfo = { mandatory: 'Yes', validityPeriod: 'Y' }
    this.peopleInfo.name = this.username;
    this.submitted = false;

    const queryParams = this.route.snapshot.queryParams;
    //console.log("Hiii", queryParams.docId);
    if (!this.docInfo) {
      //this.documentInfo = this.docInfo;
    }

    if (queryParams.docId != null) {

      // console.log(" The Id is", this.docInfo);
      //console.log(" The Input is ", this.item);
      this.docmentInfoService.getDocument('').subscribe(
        data => {
          //this.documentDetails = data.documentInfo;
          this.documentInfoDetails = data.masterDocuments;
          //console.log("Data is", this.documentInfoDetails[0]);
          this.documentList = [];
          for (var i = 0; i < this.documentInfoDetails.length; i++) {
            if (queryParams.docId == this.documentInfoDetails[i].docId) {
              this.documentList.push(this.documentInfoDetails[i]);
              //console.log("The Value should be update is",this.documentInfoDetails[i]);
              this.documentInfo = this.documentInfoDetails[i];
            }
          }
        },
        error => {
          console.log("The Error is ", error);
        });


    }

  }



  save(valid) {

    this.submitted = true;
    if (!valid) {
      return;
    }
    //this.documentInfo.mandatory = this.document.mandatory;
    // console.log("The Id is", this.documentInfo);
    console.log(this.documentInfo)
    if (this.documentInfo.docId != null) {
      
      //console.log("The Id is", this.documentInfo);
      this.docmentInfoService.updateDocument(this.documentInfo).subscribe(
        data => {
         
          if (data.successMessages != null && data.successMessages.length !== 0) {
            // console.log(this.documentInfo + "is document is Updated");
            this.submitted = false;
            // this.alertService.success(data.successMessages[0]['key']);
            let alertDiv = document.createElement('div');
            alertDiv.innerHTML = ` <div class="alert alert-success mt-4 " role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
				<i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>  Document is Updated.</div>`
            let alertcomp = document.querySelector('.alertcomp');
            alertcomp.append(alertDiv);
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            setTimeout(function () {
              alertcomp.removeChild(alertDiv);
            }, 3000);
            // this.router.navigate(['/document']);

          }
          this.viewDoctype();

        },
        error => {

          const response = error.error;
          // console.log("Error is ", response);
          let alertDiv = document.createElement('div');
          alertDiv.innerHTML = ` <div class="alert alert-danger mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
          <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>  Document is Not Updated.</div>`
          let alertcomp = document.querySelector('.alertcomp');
          alertcomp.append(alertDiv);
          document.body.scrollTop = document.documentElement.scrollTop = 0;
          setTimeout(function () {
            alertcomp.removeChild(alertDiv);
          }, 3000);
          //   // console.error(error);
          // console.log(response);
          // console.log(response.errorMessages);
          // this.alertmessages("Document was" , "success");
          //   if (response.error !== null && response.error.length !== 0) {
          //     console.log("error inside response statement")
          //     console.log(response.error)
          //   this.alertService.error(response.error);
          //   }
        }
      );

    }
    else {

      this.docmentInfoService.saveDocument(this.documentInfo).subscribe(
        data => {
          if (data.successMessages != null && data.successMessages.length !== 0) {
            // console.log(this.documentInfo + "is document is Saved");
            this.submitted = false;
            // this.alertService.success(data.successMessages[0]['key']);
            let alertDiv = document.createElement('div');
            alertDiv.innerHTML = ` <div class="alert alert-success mt-4 " role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
            <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>  Document is Saved.</div>`
            let alertcomp = document.querySelector('.alertcomp');
            alertcomp.append(alertDiv);
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            setTimeout(function () {
              alertcomp.removeChild(alertDiv);
            }, 3000);
            this.router.navigate(['/document']);

          }
        },
        error => {
          //const response = error.error;
          // console.log("Error is ", response);
          //let alertDiv = document.createElement('div');         
          // alertDiv.innerHTML = ` <div class="alert alert-danger mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
          //<i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>  Document is Not Saved.</div>`
          //let alertcomp = document.querySelector('.alertcomp');
          //alertcomp.append(alertDiv);
          document.body.scrollTop = document.documentElement.scrollTop = 0;
          if (error.error.errorMessages != null && error.error.errorMessages.length !== 0) {
            this.alertmessages(error.error.errorMessages[0]['key'], "danger");
          } else {
            this.alertmessages("Not Saved", "danger")
          }
          //setTimeout(function(){
          //alertcomp.removeChild(alertDiv);
          //}, 3000);
          //   // console.error(error);
          // console.log(response);
          // console.log(response.errorMessages);
          // if (response.error !== null ) {
          //   console.log("error inside response statement")
          //   console.log(response.error)
          // this.alertService.error(response.error);
          // }

          //   // console.error(error);
          //   // if (response.errorMessages != null && response.errorMessages.length !== 0) {
          //   // this.alertservice.error(response.errorMessages[0]['key']);
          //   // }
        }
      );

    }


  }

  // Documentlist(){

  // }

  DocumentlistXX() {
    console.log("Listing all Documents");
    this.docmentInfoService.getDocumentX().subscribe(
      data => {
        //this.documentDetails = data.documentInfo;
        this.documentListX = data.masterDocuments;
        //console.log("Data is", data.masterDocuments);
        // this.listAllDocumentDetails(this.documentInfoDetails);
        console.log("Data from MySQL",this.documentListX)
      },
      error => {
        console.log("The Error is ", error);
      });
  }

  // listAllDocumentDetails(documentInfoDetails: any) {
  //   this.documentList = [];
  //   if (this.documentInfoDetails.length != 0) {
  //     // var i = 0;i<this.documentInfoDetails.length; i++)
  //     for (var i = 0; i < this.documentInfoDetails.length; i++) {
  //       this.documentList.push(this.documentInfoDetails[i]);
  //       /*
  //       if (i == 0) {
  //         console.log("The First Element is", this.documentInfoDetails[i]);
  //       }
  //       */
  //     }
  //   }
  // }

  documentEdit(id: any, object) {
    this.documentInfo = object;

    this.add_view_toggle = "add_document";

    $('#save-doc-type-btn').text('Update');

    console.log("Edit Now ",id, object)
    //console.log(" Object Is", this.docInfoDetails);
    // this.router.navigate(['/document/savedoc'], { queryParams: { docId: id } });

    //console.log("The query Parameter", { queryParams: { docId: id } });
    this.addOrUpdate =false;
  }


  onDeleteHandler(info: any) {
    console.log("delete : ", info);
    this.DeleteDocumentMaster = info;
    $('#showAlertVer').modal('toggle');
  }

  closeMRQModal() {
    $('#showAlertVer').modal('toggle');

  }

  documentDelete() {
    const deleteId = this.DeleteDocumentMaster.docId;
    console.log("deleteId", deleteId);
    this.docmentInfoService.deleteDocument(deleteId).subscribe(
      (data) => {
        this.DocumentlistXX();
        $('#showAlertVer').modal('toggle');
        this.alertmessages(data.successMessages[0]['key'], "success")


      }, (error) => {
        console.log(error)
        $('#showAlertVer').modal('toggle');
        this.alertmessages(" Document Type is in Use ", "danger")

      }
    );


  }

  selectMandatory(itemSelectionType) {
    var selectionType = null;
    selectionType = itemSelectionType;

  }
  redirectToSearchDocument(e) {
    this.router.navigate(['/document']);
  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }


  reFresh() {
    // console.log("This is Invoked");
    this.peopleInfo = new PeopleInfo();
    this.documentInfo = new DocumentInfo();
    this.submitted = false;
    this.addOrUpdate = true;
    $('#save-doc-type-btn').text('Save');
  }

  trimtrailing(value: string, info, key) {
    console.log(value, info)
    info = value.trim();
    console.log(info.length, info)

    this.documentInfo[`${key}`] = info;
    console.log(this.documentInfo[`${key}`])
    // info = value.trim()
    //  value.trim();
  }

  addDoc() {
    this.add_view_toggle = "add_document";
    

}
viewDoctype(){
this.add_view_toggle ="viewDocType"
this.addOrUpdate =true;
this.DocumentlistXX();
}

}