<div class="card  mb-3 calender" style="margin-bottom:15px !important">
  <div class="card-content">
    <span class="d-flex justify-content-between p-4 ">
      <button type="button" class="btn btn-primary ion ion-chevron-left" id="previous" (click)="previous()"></button>  
    
      <h4 class="card-title col-6 mb-3 pt-2" id="monthAndYear"></h4>
      <button type="button" class="btn btn-primary ion ion-chevron-right" id="next" (click)="next()"></button>    

    </span>
    <div class="card-body calender-wrapper">
      <div class="container-calendar">
        <table class="table-calendar" id="calendar" data-lang="en">
          <thead id="thead-month"></thead>
          <tbody id="calendar-body"></tbody>
        </table>
      </div>
    </div>
  </div>
</div>
