export class SalaryDetailMaster {
    salaryHeadName: '';
    salaryHeadId: '';
    salaryDetailName: '';
    salaryDetailId: '';
    useForCalcIndic: '';
    esicreteria: false;
    leaveEncashment: false;
    generalHolidayCal: false;
    taxableSalary: false;
    recoveryComp: false;
    orderSalaryComponent: '';
    includeGross: false;
    eligibleDa: false;
    typeOfComponent: false;
    systemComponent: false;
    hraIsApplicable: false;
    Remove_from_sal_if: false;
    availablemonths: '';
    paymentmonths: [];
    deductionSec: '';
}

export class salaryHeadMaster {
    salaryHeadName: '';
    salaryHeadId:number;
    addDeductIndic: '';
    statuatoryCode: '';
    salaryDetailMaster: SalaryDetailMaster[] = []

 
}

export class SalaryMaster {
    salaryheadmaster: salaryHeadMaster = new salaryHeadMaster();
}