import { Component, OnInit, HostListener, Renderer2 } from '@angular/core';
import { EmployeeMaster } from 'src/app/models/employeeMasterts';
import { EmployeeMasterservice } from 'src/app/services/employeemaster.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentInfoService } from '../services/documentinfo.service';
import { DatePipe } from '@angular/common';
import { EmployeeLeaveService } from '../services/employee-leave.service';
import { LeaveEncashmentInfo } from '../models/employee-leave-encash-info.model';
import { EmployeeLeaveEncashService } from '../services/employee.leave.encash.service';

declare var $: any;
@Component({
  selector: 'app-employee-leave-encash-report',
  templateUrl: './emp-leave-encash-report.component.html',
  styleUrls: ['./emp-leave-encash-report.component.css']
})
export class EmployeeLeaveEncashReportComponent implements OnInit {
  employeeMasterLucene: EmployeeMaster[];
  startDate: boolean;
  endDate: boolean;
  submitted: boolean;
  fromDate: string;
  toDate: string;

  leaveEncashmentList: LeaveEncashmentInfo[];
  dateError: boolean = false;

  LeaveTypeList: [];
  employeeMaster: EmployeeMaster;

  url: string | ArrayBuffer;

  empMasterInfo1: EmployeeMaster;
  uniqueemployeeid: string;

  employeename: string;

  query: any;
  ActiveInativeLuceneFilter : string='Active';

  // datas must load when when we scroll down
  limitPara: any;
	offsetPara: any;
  id: any;
  currentDate: string;
  permissionList: any[] = [];
  showBasedOnPermissionEmployeeStatus: boolean = false;
  showBasedOnPermissionForAddOnComponent: boolean = false;
  showBasedOnPermissionForLeaveType: boolean = false;
  accessToEditAndView: boolean = false;
  allowBlankSearch: boolean = false;
  profileName: any;
  queryName: any;
  leavingdate: any;
  




  constructor(private render: Renderer2, private route: ActivatedRoute, private router: Router,
    private employeeMasterservice: EmployeeMasterservice, private empLeaveEncashReportService: EmployeeLeaveEncashService,
    private docmentInfoService: DocumentInfoService, private empLeaveService: EmployeeLeaveService, private datepipe: DatePipe) {

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    this.currentDate = `${year}-${month}-${day}`;
    this.fromDate = `${year}-${month.toString().padStart(2, '0')}-01`;
    const lastDay = new Date(year, month, 0).getDate();
    this.toDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;

  }


  ngOnInit() {

    
    this.leaveEncashmentList = new Array<LeaveEncashmentInfo>();
    this.employeeMaster = new EmployeeMaster;
    this.empMasterInfo1 = new EmployeeMaster;


    this.limitPara = 10;
    this.offsetPara = 0;


    (<HTMLInputElement>document.getElementById('employeename')).focus();

    const queryParams = this.route.snapshot.queryParams;

    console.log("queryParams : ", queryParams)
    console.log("queryParams type : ", typeof queryParams)

    const maxString = sessionStorage.getItem('permission').replace('[', '');
    const maxStringBox = maxString.replace(']', '');
    const maxStringAgain = maxStringBox.replace(/"/g, '');
    this.permissionList = maxStringAgain.split(',');
    this.checkAccessForUser();


   this.render.listen(document.getElementById('navigateclient'), 'click', () => {
			$('html,body').animate({
				scrollTop: $(`#add-update-client`).offset().top - 90
			},
				'slow');
		})
  }

  searchLeaveEncashReport() {

  //   const [name, id] = this.query.split(' / ');

  //   let selectedName =name;
  //   let selectedEmpId =id;
	// console.log("selecteduniqueeEmpId",selectedEmpId);
	// 	console.log("fromDate: ", this.fromDate, "toDate: ", this.toDate, "page limit para :", this.limitPara, "page limit offset :", this.offsetPara);
		
	// 	let employeename = (<HTMLInputElement>document.getElementById('employeename')).value;
	// 	if (!employeename) {
	// 		employeename = null;
	// 	} else {
	// 		employeename = employeename.trim()
	// 	}
	// 	if (!selectedEmpId) {
	// 		selectedEmpId = 0;
	// 	} else {
	// 		selectedEmpId = id;
	// 	}
	// 	console.log("Employee " + employeename);
    this.empLeaveEncashReportService.getLeaveEncashRepot(this.datepipe.transform(this.fromDate, 'yyyy-MM-dd'), this.datepipe.transform(this.toDate, 'yyyy-MM-dd'), this.employeename, this.limitPara, this.offsetPara).subscribe(
  

    data => {

      this.scrollLength()
      if (this.offsetPara == 0) {
        this.leaveEncashmentList = data.encashableLeaveDetail ;

      } else {
        this.leaveEncashmentList = this.leaveEncashmentList.concat(data.encashableLeaveDetail);
      }
      
    },
    (error) => {
      console.log(error);
    }
  );
}
scrollLength() {
  console.log("dynamic height on scroll ");
  console.log("");
  const regex = /^(\d+(?:\.\d+)?)\s*([a-zA-Z%]+)$/;
  const height = getComputedStyle(document.getElementById("scrolllength")).getPropertyValue("max-height")
  const computedHeight = height.match(regex);
  console.log("computed height", computedHeight, "computed height in no ", Number(computedHeight[1]), "height ", height)
  if (computedHeight && Number(computedHeight[1]) < 450) {
    let value: any = Number(computedHeight[1]);
    console.log("default value = ", value)
    value = value + (2 * 55);
    value = value + "px";
    console.log("final vl:", value)
    console.log("transition")
    document.getElementById("scrolllength").style.maxHeight = value
  }
  console.log("");
}


  getLeaveType() {
    console.log("get leave type ")
    this.employeeMasterservice.getLeaveType().subscribe(
      data => {
        this.LeaveTypeList = data;
        console.log("Leave types are  : ", this.LeaveTypeList)
      }, error => {
        console.log(error);
      }

    );
  }
  // validateDates(): void {
	// 	if (this.fromDate && this.toDate) {
	// 	  const from = new Date(this.fromDate);
	// 	  const to = new Date(this.toDate);
	// 	  this.dateError = to < from;
		 
	// 	  if(this.dateError){
	// 		this.alertmessages("To Date cannot be earlier than From Date.", "danger")
	// 	  }
	// 	}
	//   }

  onScroll() {
		this.offsetPara = this.offsetPara + 10;
		console.log("limit = ? ", this.limitPara, "offset = +13 ", this.offsetPara)
		this.searchLeaveEncashReport();

	}

	limitpara() {
		this.offsetPara = 0;
		console.log(" on clikc limit para ", this.limitPara)
		console.log("this offset", this.offsetPara)
	}

	@HostListener('scroll', ['$event'])
	scrolled() {

		console.log("Scrolled >>> ")
		this.onScroll();
	}



  employeeEncashReportCSVDownload() {
    var employeename = (<HTMLInputElement>document.getElementById('employeename')).value;
    this.empLeaveEncashReportService.downloadEncashmentReport().subscribe(data => {
      this.uniqueemployeeid = null;
      var file = new Blob([data], { type: 'application/vnd.ms-excel' });
      var fileURL = URL.createObjectURL(file);
      var anchor = document.createElement("a");
      anchor.download = "EncashmentReport.xls";
      anchor.href = fileURL;
      anchor.click();
    },
      error => {
        console.log("Error Occured ", error);
      })
  }


  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }


  //   new
  checkFromAndToDate() {
    this.startDate = false;
    this.endDate = false;
    this.submitted = true;
    const currentDate = new Date();
    console.log("this.fromDate", this.fromDate);
    console.log("this.toDate ", this.toDate);
    if (this.toDate) {
      if (this.fromDate > this.toDate) {
        this.submitted = false
        this.endDate = true;
      } else {
        this.endDate = false;
        return true;
      }
    }
  }

  getEmployeeByNameOrId() {
    const [name, id] = this.employeename.split(' / ');
    this.queryName = name;
    console.log("Len ", this.query);
    
    if (this.queryName.length <= 2) {
      this.employeeMasterLucene = [];
    }
  
    if (this.queryName.length >= 3) {
      this.empLeaveService.getEmployeeByNameOrUniqueId(this.queryName).subscribe(
        data => {
          console.log("Emp Data ", data);
  
          // Check if data.employeeMasterData has any entries
          if (data.employeeMasterData.length > 0) {
            let filteredEmployees = data.employeeMasterData;
  
            // Get the current date for comparison
            const currentDate = new Date();
            console.log("Current Date: ", currentDate);  // Log current date for debugging
  
            // Apply filtering logic based on the selected employee status
            if (this.ActiveInativeLuceneFilter === 'Active') {
              filteredEmployees = filteredEmployees.filter(emp => {
                const leavingDate = emp.leavingdateString ? new Date(emp.leavingdateString) : null;
                console.log("Leaving Date for " + emp.name + ": ", leavingDate);  // Log leaving date for debugging
                // Active employees should have leavingdate in the future or null
                return leavingDate === null || leavingDate > currentDate;
              });
            } else if (this.ActiveInativeLuceneFilter === 'Inactive') {
              filteredEmployees = filteredEmployees.filter(emp => {
                const leavingDate = emp.leavingdateString ? new Date(emp.leavingdateString) : null;
                console.log("Leaving Date for " + emp.name + ": ", leavingDate);  // Log leaving date for debugging
                // Inactive employees should have leavingdate in the past or null
                return leavingDate && leavingDate < currentDate;
              });
            }
  
            // If the status is 'All', no filter is applied
            if (this.ActiveInativeLuceneFilter === 'All') {
              filteredEmployees = data.employeeMasterData;
            }
  
            // Assign filtered data to the employeeMasterLucene
            this.employeeMasterLucene = filteredEmployees;
  
            // Log the data and leaving date of the first employee in the filtered list
            if (this.employeeMasterLucene.length > 0) {
              this.leavingdate = this.employeeMasterLucene[0].leavingdateString; // Extract the leaving date
              console.log("Filtered Employee Data: ", this.employeeMasterLucene);
              console.log("leavingdate", this.leavingdate);
            } else {
              console.log("No employee data found.");
            }
          } else {
            console.log("No employee data found.");
          }
        },
        error => {
          console.log("Error ", error);
        }
      )
    }
  }
  
  onStatusChange() {
		this.getEmployeeByNameOrId();
	  }
  // getEmployeeByNameOrId() {
  //   console.log("Len ", this.employeename);
  //   // if (!isNaN(Number(this.query))) {
  //   if (this.employeename?.length <= 2) {
  //     console.log("number")
  //     // this.employeeMasterLucene = []
  //     this.empLeaveService.getEmployeeByNameOrUniqueId(this.employeename).subscribe(
  //       data => {
  //         console.log("Emp Data ", data);
  //         this.employeeMasterLucene = data.employeeMasterData

  //       },
  //       error => {
  //         console.log("Error ", error);
  //       })
  //   }
  //   // }
  //   else {
  //     if (this.query?.length >= 3) {
  //       this.empLeaveService.getEmployeeByNameOrUniqueId(this.employeename).subscribe(
  //         data => {
  //           console.log("Emp Data ", data);
  //           this.employeeMasterLucene = data.employeeMasterData

  //           // if(this.employeeMaster.length > 0){
  //           // 	const autoCompleteId = document.getElementById("employeeMaster");
  //           // 	autoCompleteId.classList.remove("auto-complete-active");
  //           // }
  //         },
  //         error => {
  //           console.log("Error ", error);

  //         }
  //       )
  //     }
  //   }
  // }

  

  clear() {


    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    this.currentDate = `${year}-${month}-${day}`;
    this.fromDate = `${year}-${month.toString().padStart(2, '0')}-01`;
    const lastDay = new Date(year, month, 0).getDate();
    this.toDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;
    this.employeename = "";
    this.leaveEncashmentList = null;
  }
  checkAccessForUser() {
    console.log("checkAccessForUser here")
    this.showBasedOnPermissionEmployeeStatus = this.permissionList.includes('ng.employee.search.show.employeestatus');
  }
}