import { DatePipe } from '@angular/common';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { EmployeeAttendanceSummary } from '../models/EmployeeAttendanceSummary';
import { EmployeeAttendence } from '../models/EmployeeAttendenceInfo';
import { SessionService } from '../services';
import { EmployeeattendenceserviceService } from '../services/employeeattendenceservice.service';
import { GlobalConstants } from '../constants/GlobalConstants';
import { Approvalinboxservice } from '../services/approvalinbox.service';
import { EmployeeMaster } from '../models/employeeMasterts';
import { EmployeeLeaveService } from '../services/employee-leave.service';

declare var $: any


@Component({
  selector: 'app-employee-attendence-summary',
  templateUrl: './employee-attendence-summary.component.html',
  styleUrls: ['./employee-attendence-summary.component.css']
})
export class EmployeeAttendenceSummaryComponent implements OnInit {

  currentdate: any;

  loggedUserName = this.sessionService.getloggedusername();
  fromDate: string;
  toDate: string;
  showTable: boolean = false;
  submitted = false;
  isEmptyF = false;
  isEmptyT = false;
  isFromGreater = false;
  sendId: any;
  selectedType: string = 'select';
  sendUniqueEmpId: number = null;

  uniqueEmpId = this.sessionService.getempid();
  empAttdSummaryList: EmployeeAttendanceSummary[];
  teamMemberList: EmployeeAttendenceSummaryComponent[];

  regularizedtemp: EmployeeAttendence[] = [];
  req_regularized_list: EmployeeAttendence[] = []
  userUniqueid: any;
  emp_attendence_list: any;
  swipeDate: any;

  swipeintype = GlobalConstants.SWIPE_IN;
  swipeouttype = GlobalConstants.SWIPE_OUT;
  url: string | ArrayBuffer;
  query: any;
  // employeeMaster: EmployeeMaster[];
  selectedempid: any;
  uniqEmpId: any;
  isEmployeeNameEmpty: boolean = false;
  suspension: any;
  selectedluceneValue : string='Active';
  leavingdate: any;
  permissionList: any[] = [];
  showBasedOnPermissionEmployeeStatus: boolean = false;
  employeeMasterLucene: EmployeeMaster[];
  uniqueemployeeid: String;
  queryName: any;

  constructor(private render: Renderer2, private empAttendenceService: EmployeeattendenceserviceService, private empLeaveService: EmployeeLeaveService,
    private sessionService: SessionService, private datepipe: DatePipe, private approvalinboxservice: Approvalinboxservice) {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    this.fromDate = `${year}-${month.toString().padStart(2, '0')}-01`;
    const lastDay = new Date(year, month, 0).getDate();
    this.toDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;

  }

  ngOnInit(): void {
     const maxString = sessionStorage.getItem('permission').replace('[', '');
    const maxStringBox = maxString.replace(']', '');
    const maxStringAgain = maxStringBox.replace(/"/g, '');
    this.permissionList = maxStringAgain.split(',');
    this.checkAccessForUser();
    this.submitted = false;
    this.isEmptyF = false;
    this.isEmptyT = false;
    this.isFromGreater = false;
    this.getsuspensionlist();
    // let defaultinfo = new EmployeeAttendence();
    // this.regularizedtemp.push(defaultinfo);
    this.viewTeam();


    this.render.listen(document.getElementById('navigatetarget'), 'click', () => {
      $('html,body').animate({
        scrollTop: $(`#navigatedest`).offset().top - 90
      },
        'slow');
    })

  }

  changeFromDate(selectedDate: any) {
    this.fromDate = selectedDate.target.value;
    this.isEmptyF = false

    if (this.isFromGreaterThanTo(this.fromDate, this.toDate)) {
      console.log("in");

      this.isEmptyF = false
      this.isFromGreater = true
    } else {
      console.log("out");

      this.isFromGreater = false
    }
  }

  isFromGreaterThanTo(from, to) {
    console.log(`from ${from} - to ${to}`);

    if (from !== undefined && to !== undefined) {
      return from > to;
    }
  }

  changeToDate(selectedDate: any) {
    this.toDate = selectedDate.target.value;
    this.isEmptyT = false

    if (this.isFromGreaterThanTo(this.fromDate, this.toDate)) {
      this.isEmptyF = false
      this.isFromGreater = true
    } else {
      this.isFromGreater = false
    }
  }

  reviewHandler() {
    console.log("Redirect to Regularize attendance page");

  }

  getEmpAttendanceSummary() {

    this.empAttdSummaryList = [];

    this.submitted = true;
    this.isEmptyF = false;
    this.isEmptyT = false;
    this.isFromGreater = false
    this.isEmployeeNameEmpty = false;

    if (this.fromDate === undefined || this.fromDate === null || this.fromDate === '') {
      this.isEmptyF = true;
    }
    if (this.toDate === undefined || this.toDate === null || this.toDate === '') {
      this.isEmptyT = true;
    }

    if (this.isEmptyF || this.isEmptyT) {
      return
    }

    if (this.isFromGreaterThanTo(this.fromDate, this.toDate)) {
      this.isEmptyF = false
      this.isFromGreater = true
    }

    if (this.isFromGreater) {
      return
    }

    if (this.selectedType === 'Team_Member' && (!this.query || this.query.trim() === '')) {
      this.isEmployeeNameEmpty = true;
      return; // Stop further execution
    }


    this.isEmployeeNameEmpty = false;

    this.sendId = this.uniqueEmpId;
    console.log("logged id: " + this.sendId)


    // if (this.sendUniqueEmpId != null) {

    //   this.sendId = this.sendUniqueEmpId;
    //   console.log("override  id: " + this.sendId)

    // }
    console.log("uniqEmpId id: " + this.uniqEmpId)
    if (this.uniqEmpId != null) {

      this.sendId = this.uniqEmpId;
      console.log("override  id: " + this.sendId)

    }
    console.log("Data to send : Server ", this.fromDate, this.toDate)
    this.empAttendenceService.getEmpAttendenceSummary(this.sendId, this.fromDate, this.toDate).subscribe(
      data => {
        this.empAttdSummaryList = data.attendanceSummary;
        if (this.empAttdSummaryList.length > 0) {
          this.showTable = true;
        }
        console.log("Attd Summary ", this.empAttdSummaryList)

        // this.scrollLength()
        $('html,body').animate({
          scrollTop: $(`#navigatedest`).offset().top - 90
        },
          'slow');
      },
      error => {
        // this.onClear()
        console.log("Error Attendance Summary ", error)
      }
    )


  }



  scrollLength() {
    console.log("dynamic height on scroll ");
    console.log("");
    const regex = /^(\d+(?:\.\d+)?)\s*([a-zA-Z%]+)$/;
    const height = getComputedStyle(document.getElementById("scrolllength")).getPropertyValue("max-height")
    const computedHeight = height.match(regex);
    console.log("computed height", computedHeight, "computed height in no ", Number(computedHeight[1]), "height ", height)
    if (computedHeight && Number(computedHeight[1]) < 450) {
      let value: any = Number(computedHeight[1]);
      console.log("default value = ", value)
      value = value + (2 * 55);
      value = value + "px";
      console.log("final vl:", value)
      console.log("transition")
      document.getElementById("scrolllength").style.maxHeight = value
    }
    console.log("");
  }

  onClear() {
    this.fromDate = ""
    this.toDate = ""
    this.submitted = false;
    this.isEmptyF = false;
    this.isEmptyT = false;
    this.isFromGreater = false;
    this.selectedType = 'select';
  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
		<i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message}</div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    $('#additionalModal').modal('toggle');
    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 5000);
  }

  list_for_regularize: any[];
  checkedlistfor_regularize: any[];
  date_to_be_regularized: string;

  getswipeinfo(info) {

    this.regularizedtemp = [];

    console.log(info)
    this.userUniqueid = info.uniqueemployeeid;
    this.swipeDate = info.swipetime;
    this.currentdate = this.datepipe.transform(this.swipeDate, 'MMM d');
    console.log(this.list_for_regularize);
    this.regularizedtemp.push(new EmployeeAttendence())
  }


  submitforRegularize() {
    console.log(this.regularizedtemp)
    this.req_regularized_list = [...this.regularizedtemp];
    console.log("")
    console.log("required info = ", this.req_regularized_list)
    let flag = true;
    for (let i = 0; i < this.req_regularized_list.length; i++) {
      if (!(this.req_regularized_list[i].stime && Number(this.req_regularized_list[i].swipetype))) {
        this.alertmessages("Please Select the records you want to regularize.", "danger")
        flag = false
        return;
      }
    }


    for (let i = 0; i < this.req_regularized_list.length; i++) {
      const record = this.req_regularized_list[i];
      const regularizeDate = new Date(record.stime).toISOString().split("T")[0]; // Extract date part only

      const isSuspended = this.suspension.some(
        (suspension) =>
          suspension.uniqueEmployeeId === this.userUniqueid &&
          suspension.status === "Approved" &&
          suspension.fromdate <= regularizeDate &&
          suspension.todate >= regularizeDate
      );

      if (isSuspended) {
        this.alertmessages(
          `You cannot regularize attendance for the suspended date: ${regularizeDate}.`,
          "danger"
        );
        flag = false;
        return;
      }
    }
    
    console.log("required info");
    console.log(this.req_regularized_list)
    this.req_regularized_list[0].uniqueemployeeid = this.userUniqueid;
    if (flag) {
      console.log("this.selected type = ", this.selectedType)
      this.empAttendenceService.applyforRegularizeTeam(this.req_regularized_list, this.selectedType).subscribe({
        next: (res) => {
          // console.log(res)
          console.log("response on submit ", res)
          this.alertmessages("The Regularize attendance is apply .", "success");
          this.getEmpAttendanceSummary();

        },
        error: (error) => {
          this.alertmessages("Error occured while regularizing , Please try again ", "danger");
        }

      })
    }
    this.regularizedtemp = [];
  }

  clearall() {
    this.list_for_regularize = [];
    this.checkedlistfor_regularize = [];
    this.req_regularized_list = [];
    // let info = new EmployeeAttendence()
    // this.regularizedtemp.push(info)
    // console.log(this.regularizedtemp)
    this.regularizedtemp = [];
    this.date_to_be_regularized = ''
    $('#additionalModal').modal('toggle');
  }

  checkall(e) {
    const checkboxes = document.querySelectorAll("input");
    if (e.target.checked) {
      for (let i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].type == "checkbox") {
          checkboxes[i].checked = true;
          this.checkedlistfor_regularize = [...this.list_for_regularize];
        }
      }
    } else {
      for (let i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].type == "checkbox") {
          checkboxes[i].checked = false;
          this.checkedlistfor_regularize = [];
        }
      }
    }
  }

  add() {
    let info = new EmployeeAttendence()
    this.regularizedtemp.push(info)
  }

  check(info: EmployeeAttendence, e) {

    console.log(info, e)
  }

  getswipetime(info: EmployeeAttendence, e) {
    console.log(info, e)
    info.uniqueemployeeid = Number(this.sessionService.getempid());
    info.stime = this.datepipe.transform(e.target.value,
      'yyyy-MM-dd HH:mm:ss'
    )
    console.log(this.datepipe.transform(e.target.value,
      'yyyy-MM-dd HH:mm:ss'
    ))

  }

  getswipetype(info: EmployeeAttendence, e) {
    console.log(info, e)
    info.swipetype = e.target.value;
  }

  // onChange(event) {
  //   this.selectedType = event.target.value;
  //   console.log("selectedType---",this.selectedType)

  //   if (this.selectedType === 'Team_Member') {
  //     this.viewTeam();
  //     this.empAttdSummaryList = [];

  //   } else {
  //     this.sendId = this.uniqueEmpId;
  //     console.log("sendId---",this.sendId)
  //     this.uniqEmpId = null
  //     console.log("uniqEmpId---",this.uniqEmpId)
  //     this.empAttdSummaryList = [];


  //   }
  // }
  onChange(event) {
    this.selectedType = event.target.value;
    console.log("selectedType---", this.selectedType);

    if (this.selectedType === 'Team_Member') {
      // Reset the query and employee list
      this.query = '';
      this.employeeMasterLucene = [];
      this.isEmployeeNameEmpty = false; // Reset validation message if any
      this.viewTeam();
      this.empAttdSummaryList = [];
    } else {
      this.sendId = this.uniqueEmpId;
      console.log("sendId---", this.sendId);
      this.uniqEmpId = null;
      console.log("uniqEmpId---", this.uniqEmpId);
      this.empAttdSummaryList = [];
    }
  }
  onChange1(event) {
    this.sendUniqueEmpId = event.target.value;
    console.log("sendUniqueEmpId---------------------", this.sendUniqueEmpId);
    this.uniqEmpId = this.employeeMasterLucene[0].uniqueemployeeid;
    console.log("uniqEmpId---", this.uniqEmpId)
    this.getEmpAttendanceSummary();
    this.empAttdSummaryList = [];

  }

  viewTeam() {

    console.log("clicked team member ")
    this.empAttendenceService.getTeamMemberList(this.uniqueEmpId).subscribe(
      data => {

        this.teamMemberList = data.teamListSummary;
        console.log("team member list ", this.teamMemberList)
        // this.onClear()
      },
      error => {
        // this.onClear()
        console.log("Error team member list ", error)
      }
    )
  }

  get_Attendence_info(info) {
    console.log("view attendance_info on that day ")
    this.userUniqueid = info.uniqueemployeeid;
    this.swipeDate = info.swipetime;
    this.currentdate = this.datepipe.transform(this.swipeDate, 'MMM d');

    this.empAttendenceService.get_Attendence_info_based_On_date(this.userUniqueid, this.datepipe.transform(info.swipetime, 'yyyy-MM-dd')).subscribe(
      data => {
        this.emp_attendence_list = data.currentattendencelistbasedonDate;
        console.log(this.emp_attendence_list, "emp swipe info list for a given day")
        this.displayclockIn_and_clockOut(this.emp_attendence_list)

      }
    );
  }

  displayclockIn_and_clockOut(empswipelist: EmployeeAttendence[]) {
    if (empswipelist.length !== 0) {
      const lastswipeType: number = empswipelist[empswipelist.length - 1].swipetype;
      const clock_In = document.getElementById('clockIN');
      const clock_Out = document.getElementById('clockOut');

      const element_has_displaynone_class = document.querySelector('.displayNone');
      element_has_displaynone_class.classList.remove('displayNone')

      console.log(lastswipeType, "lastswipe type")
      if (lastswipeType === GlobalConstants.SWIPE_IN) {
        // display static clock
        if (clock_In.classList.contains('displayNone')) {
          clock_In.classList.remove('displayNone');

        }
        else {
          clock_In.classList.add('displayNone')
        }
      } else if (lastswipeType === GlobalConstants.SWIPE_OUT) {
        // display rotating clock indication that employee has clocked in 
        if (clock_Out.classList.contains('displayNone')) {
          clock_Out.classList.remove('displayNone');

        } else {
          clock_Out.classList.add('displayNone');
        }

      }

    }
  }

  getPreview(info) {

    // const capturePreviewModal = new bootstrap.Modal(document.getElementById('capturePreview'));
    // capturePreviewModal.show();

    console.log(info);
    this.url = undefined;
    console.log("profile path : ", info.capturedPath);
    if (info.capturedPath) {
      this.approvalinboxservice.downloadFile(info.capturedPath).subscribe(
        data => {
          var downloadUrl = URL.createObjectURL(data);
          var reader = new FileReader();
          reader.readAsDataURL(data);
          reader.onload = (_event) => {
            this.url = reader.result;
          }
        },
        error => {
          console.log('Error occured in download file');
        }
      );
    }

  }

  getEmployeeByNameOrId() {
    const [name, id] = this.query.split(' / ');
    this.queryName = name;
    this.uniqueemployeeid = id; // Store the ID if it's available

    console.log("Len ", this.query);

    if (this.queryName.length <= 2) {
      this.employeeMasterLucene = [];
    }

    if (this.queryName.length >= 3) {
      this.empLeaveService.getEmployeeByNameOrUniqueId(this.queryName).subscribe(
        data => {
          console.log("Emp Data ", data);
  
          // Check if data.employeeMasterData has any entries
          if (data.employeeMasterData.length > 0) {
            let filteredEmployees = data.employeeMasterData;
  
            // Get the current date for comparison
            const currentDate = new Date();
            console.log("Current Date: ", currentDate);  // Log current date for debugging
  
            // Apply filtering logic based on the selected employee status
            if (this.selectedluceneValue === 'Active') {
              filteredEmployees = filteredEmployees.filter(emp => {
                const leavingDate = emp.leavingdateString ? new Date(emp.leavingdateString) : null;
                console.log("Leaving Date for " + emp.name + ": ", leavingDate);  // Log leaving date for debugging
                // Active employees should have leavingdate in the future or null
                return leavingDate === null || leavingDate > currentDate;
              });
            } else if (this.selectedluceneValue === 'Inactive') {
              filteredEmployees = filteredEmployees.filter(emp => {
                const leavingDate = emp.leavingdateString ? new Date(emp.leavingdateString) : null;
                console.log("Leaving Date for " + emp.name + ": ", leavingDate);  // Log leaving date for debugging
                // Inactive employees should have leavingdate in the past or null
                return leavingDate && leavingDate < currentDate;
              });
            }
  
            // If the status is 'All', no filter is applied
            if (this.selectedluceneValue === 'All') {
              filteredEmployees = data.employeeMasterData;
            }
  
            // Assign filtered data to the employeeMasterLucene
            this.employeeMasterLucene = filteredEmployees;
  
            // Log the data and leaving date of the first employee in the filtered list
            if (this.employeeMasterLucene.length > 0) {
              this.leavingdate = this.employeeMasterLucene[0].leavingdateString; // Extract the leaving date
              console.log("Filtered Employee Data: ", this.employeeMasterLucene);
              console.log("leavingdate", this.leavingdate);
              this.selectedempid = this.employeeMasterLucene[0].uniqueemployeeid;
            } else {
              console.log("No employee data found.");
            }
          } else {
            console.log("No employee data found.");
          }
        },
        error => {
          console.log("Error ", error);
        }
      );
    }
  }

  onStatusChange() {
    this.getEmployeeByNameOrId();
  }
  checkAccessForUser() {
    console.log("checkAccessForUser here")
    this.showBasedOnPermissionEmployeeStatus = this.permissionList.includes('ng.employee.search.show.employeestatus');
  }

  getsuspensionlist() {
    this.empLeaveService.getsuspensionlist().subscribe(
      (data) => {
        console.log("Data available :", data);
        this.suspension = data.employeesuspension_intermediate;
        console.log("List of all the branch available :", this.suspension);
      },
      (error) => {
        console.log(error);
      }
    );
  }

}
