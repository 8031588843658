import { Component, OnInit } from '@angular/core';
import { PfReportService } from '../services/pf-report.service';

@Component({
  selector: 'app-epf-abstract-report',
  templateUrl: './epf-abstract-report.component.html',
  styleUrls: ['./epf-abstract-report.component.css']
})
export class EpfAbstractReportComponent implements OnInit {

  constructor(private pfStatementService: PfReportService) { }

  ngOnInit(): void {
    const currentMonthValue = new Date().getMonth() + 1; // getMonth() returns 0 for January, so +1
    this.selectedMonth = this.months.find(month => month.value === currentMonthValue);

    const currentYear = new Date().getFullYear();
    // this.years.push(''); // Add empty option
    for (let year = currentYear; year >= 2018; year--) {
      this.years.push(year.toString());
    }
    this.year = currentYear.toString();
    // this.getEmployeeList();
    
  }
  months = [
    { name: 'January', value: 1 },
    { name: 'February', value: 2 },
    { name: 'March', value: 3 },
    { name: 'April', value: 4 },
    { name: 'May', value: 5 },
    { name: 'June', value: 6 },
    { name: 'July', value: 7 },
    { name: 'August', value: 8 },
    { name: 'September', value: 9 },
    { name: 'October', value: 10 },
    { name: 'November', value: 11 },
    { name: 'December', value: 12 }
  ];
  selectedMonth: { name: string, value: number } | undefined;
  years: string[] = [];
  directoryPath: string = '';
  year: any;
  selecteduniqueeName: any;
  selecteduniqueeEmpId: any;
  employeeList: any[] = [];
  query: any;
  queryName: any;
  limitPara: any;
  offset: any;
  reportData: any[] = [];
  isSearchClicked: boolean = false;



  executeReport() {
    this.isSearchClicked = true;
    this.reportData = [];
    console.log('year', this.year);
    console.log('month', this.selectedMonth);
    console.log('month', this.query);

    if (!this.year) {
        console.log('year', this.year);
        return;
    }

    if (!this.selectedMonth || !this.selectedMonth.name || !this.selectedMonth.value) {
        console.log('month', this.selectedMonth);
        return;
    }

    this.pfStatementService.getEpfabstractReport(this.year, this.selectedMonth.value.toString(), this.selectedMonth.name.toString(), this.limitPara, this.offset)
        .subscribe(
            data => {
                  console.log('Report data received:', data.statutoryReportBean);
                  this.reportData = this.reportData.concat(data.statutoryReportBean); 
             },
            (error) => {
                
                console.log("Error Occurred ", error);
            }
        );
}

alertmessages(message: string, sign: string): void {
  let alertDiv = document.createElement("div");
  alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
  <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`;
  let alertcomp = document.querySelector(".alertcomp");
  alertcomp.append(alertDiv);
  document.body.scrollTop = document.documentElement.scrollTop = 0;

  setTimeout(function () {
    alertcomp.removeChild(alertDiv);
  }, 3000);
}
clear(){
  this.selectedMonth = null; // Clear the selected month
  console.log('Selected month cleared:', this.selectedMonth);
  this.year='';

}
}
