import { Component, OnInit, ViewChild } from '@angular/core';
import { PolicyManagerServiceService } from '../services/policy-manager-service.service';
import { SessionService } from '../services';
import { PolicySubmission } from '../models/PolicySubmission';
import { NgForm } from '@angular/forms';
declare var $:any
@Component({
  selector: 'app-policy-submission',
  templateUrl: './policy-submission.component.html',
  styleUrls: ['./policy-submission.component.css']
})
export class PolicySubmissionComponent implements OnInit {

  constructor(private policyManagerServiceService: PolicyManagerServiceService, private session: SessionService,private sessionService: SessionService) {
  
   }

  ngOnInit(): void {
    console.log("INITIAL");
    this.getPolicyList();
  }
  @ViewChild('policysubmissionform', { static: false }) policysubmissionform: NgForm;

  policySubmission: PolicySubmission = new PolicySubmission();
  id: number;
  fileName: any;
  timestamp: Date;
  amount: number;
  fileType: any;
  userId: any;
  status: any;
  attchment:any;
  fileToUpload: File | null = null;
  filename: any;
  uniqueempid: string = this.session.getempid()
  policyList:any[] = [];
  fileSavedPath:any;
  selectedfile:any;
  
  submit() {
    this.policysubmissionform.form.markAllAsTouched()

    if (this.policysubmissionform.invalid) {
      return;
    }

      this.policySubmission.fileToUpload = this.fileToUpload;
      this.policySubmission.fileType = this.fileType;
      this.policySubmission.uniqueempid = this.uniqueempid;
      this.policyManagerServiceService
        .savePolicySubmission(this.fileToUpload, this.policySubmission)
        .subscribe(
          (res) => {
            console.log("response", res);
            console.log("File Type", this.fileType);
            console.log("File Uploading", this.fileToUpload);
            this.alertmessages("Successfully Saved ", "success");
            this.getPolicyList();
          },
          (error) => {
            console.log(error);
            this.alertmessages("Please try again later", "danger");
          }
        );
        this.clear();
  }

  getPolicyList() {
    this.policyManagerServiceService.getPolicySubList(this.sessionService.getloggedusername()).subscribe(
      (data) => {
        console.log("Data available :", data);
         this.policyList = data.policySubmission;
        console.log("List of all the PolicyList available :", this.policyList);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  viewdocument(path: string, filename: string) {
    console.log("view document : ", path)
    if (path === undefined) {
      path = filename;
    }
    console.log(" ")
    console.log("view document : ", path)
    this.policyManagerServiceService.viewdocument(path).subscribe(
      (response) => {
        console.log(`Our response : ${response}`)
        if (filename.includes('.pdf')) {
          var file = new Blob([response], { type: 'application/pdf' });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        } else if (filename.includes('.jpg') || filename.includes('.jpeg')) {
          var file = new Blob([response], { type: 'image/jpeg' });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        } else if (filename.toLowerCase().includes('.png')) {
          var file = new Blob([response], { type: 'image/png' });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        } else {
          console.log("Other types are not recommended!");
        }
      },
      (error) => { },
    )
  }

  myDocumentDownload(path: any, filename: any) {
    console.log("path ", path);
    this.fileSavedPath = path;
    this.filename = filename;

    console.log("The File Saved Path is", this.fileSavedPath);
    console.log("The File Saved Path is", this.filename);
    this.downloadImage();
  }

  downloadImage() {

    console.log("Download Image is called");
    this.policyManagerServiceService.downloadFile(this.fileSavedPath).subscribe(
      (data) => {
        var downloadUrl = URL.createObjectURL(data);
        var link = document.createElement("a");
        link.download = this.filename;
        link.href = downloadUrl;
        link.click();
        link.remove();
        console.log("The Url is", downloadUrl);
      },
      (error) => {
        console.log("Error occured in download file");
      }
    );
  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement("div");
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
  <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`;
    let alertcomp = document.querySelector(".alertcomp");
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }

  onFileSelected(event: any) {
    this.fileToUpload = event.target.files[0];
  }

  deletePolicy() {
    const id = this.id;
    console.log("delete expense : ", id, " ");

    this.policyManagerServiceService.deletePolicy(id).subscribe(
      (data) => {
        console.log("delete  Expense ", data);
        this.alertmessages("Successfully deleted ", "success");
        this.clear();
        this.getPolicyList();
        $('#showAlertVer').modal('toggle');
      },
      (error) => {
        this.alertmessages("Expense is in Use ", "danger");
        console.log(error);
        $('#showAlertVer').modal('toggle');
      }
    );
    this.clear();
  }

  onDeleteHandler(id) {
    // console.log("delete : ", info);
    this.id=id;
    $('#showAlertVer').modal('toggle');
  }
  closeMRQModal() {
    $('#showAlertVer').modal('toggle');

  }
  clear() {
    this.fileName= null;
    this.fileType= null;
    this.policysubmissionform.reset();
  }
}
