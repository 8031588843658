import { Component, OnInit, ViewChild } from '@angular/core';
import { ExpensemanagerService } from '../services/expensemanager.service';
import { NgForm } from '@angular/forms';
import { EmployeeType } from '../models/EmployeeType';
declare var $:any
@Component({
  selector: 'app-employee-type',
  templateUrl: './employee-type.component.html',
  styleUrls: ['./employee-type.component.css']
})
export class EmployeeTypeComponent implements OnInit {

   id: number;
   employeeTypeName: any;
   employetypecode:any;
   userId: any;
   timeStamp: Date;
  // employeeType: any[] [];
   addOrUpdate: boolean;
   EmployeeType:EmployeeType[];
   add_view_toggle: string;
   constructor(private expensemanagerService: ExpensemanagerService) { }
 //  @ViewChild('employeeTypeform', { static: false }) expense: NgForm;

  ngOnInit(): void {
    this.viewEmployeeTypeButton();
  }
  updateEmployeeType() {
    this.employeeTypeName = this.employeeTypeName.trim();
    console.log("After trim Employee type: ", this.employeeTypeName, " :  length : ", this.employeeTypeName.length)
    if (this.employeeTypeName != undefined && this.employeeTypeName.length > 0) {

      const updatedField = {
        id: this.id,
        employeeTypeName: this.employeeTypeName,
        employetypecode: this.employetypecode
      };

      this.expensemanagerService.editUpdate_employeeType(updatedField).subscribe(
        data => {
          console.log("added Employee Type ", data)
          this.alertmessages("Successfully Updated ", "success")
          this.clear();
          this.viewEmployeeTypeButton();
        //  this.getemployeeType();
        }, error => 
          {

            if (error.status === 422 && error.error && error.error.status === "Duplicate") {
              this.alertmessages("Employee Type code already exists", "danger");
          } else {
          this.alertmessages("Please try again later", "danger")
          console.log(error);
        }
      }
      );

     // this.clear();
    }
  }

  viewEmployeeTypeButton() {
    // clicked view job 
    this.clear();
    console.log("clicked view job ");
    this.addOrUpdate = true;
    this.add_view_toggle = 'viewEmployeeType';
    this.getEmployeeType();

  }

  addempType(){
    this.add_view_toggle = "add_employeeType";
    this.addOrUpdate = true;
  }
  

  getEmployeeType() {
    this.expensemanagerService.getemployeeTypeList().subscribe(
      data => {
        this.EmployeeType = data.employeeType;
        console.log("List of Employee Type :", data)
        // this.employeeType = data.employeeType;
        // console.log("List of Employee Type :", this.employeeType)
      }, error => {
        console.log(error);
      }
    );
  }


  
  clear() {
    this.employeeTypeName = "";
    this.employetypecode = "";

  //   this.addOrUpdate = true;
  //  // this.expense.reset();
  //   this.getEmployeeType();
  }
  saveEmployeeType() {
    console.log("clicked save");
    console.log("employeeTypeName",this.employeeTypeName);
    console.log("employetypecode",this.employetypecode);
    this.employeeTypeName = this.employeeTypeName.trim();
    if ((this.employetypecode != null && this.employetypecode != undefined && this.employetypecode.length > 0) && (this.employeeTypeName != null || this.employeeTypeName != undefined))
      {
      console.log("Add Expense TYpe: ", this.employeeTypeName, " :  length : ", this.employeeTypeName.length)
      this.expensemanagerService.insert_employeeType(this.employeeTypeName,this.employetypecode).subscribe(
        data => {
          console.log("added Employee Type ", data)
          this.alertmessages("Successfully Saved ", "success")
          this.clear();
          this.viewEmployeeTypeButton();
        //  this.searchEmployeeType();
        }, error => {
          console.log(error);
          //this.alertmessages("Please try again later", "danger")
        //  
        if (error.status === 422 && error.error && error.error.status === "Duplicate") {
              this.alertmessages("Employee Type code already exists", "danger");
          } else 
        {
            this.alertmessages("Please try again later", "danger");
        }
        }
      );
    }else
  {
    this.alertmessages("Please Fill all the required field", "danger");
  }
  }
  
 
  editemployeeType(info:any) {
    console.log("edit Employee Type : ", info)
    console.log("edit Employee Type : ", info.employeeTypeName)
    console.log("edit Employee Type code: ", info.employetypecode)
    this.employeeTypeName = info.employeeTypeName;
    this.employetypecode = info.employetypecode;
   this.id = info.id;
    this.add_view_toggle = 'add_employeeType';
    this.addOrUpdate = false;
  }

 
  deleteEmployeetype() {
    const id = this.id;
    this.expensemanagerService.delete_employeeType(id).subscribe(
      data => {
        console.log("delete  Employee Type ", data)
        this.alertmessages("Successfully deleted ", "success")
        this.clear();
        this.getEmployeeType();
        $('#showAlertVer').modal('toggle');
      }, error => {
        $('#showAlertVer').modal('toggle');
        this.alertmessages("Employee Type is in Use ", "danger")
        console.log(error);
      }
    );

    //this.clear();
  }
  onDeleteHandler(id,employetype) {
    this.id=id;
    this.employeeTypeName = employetype;
    $('#showAlertVer').modal('toggle');
  }

  closeMRQModal() {
    $('#showAlertVer').modal('toggle');

  }
  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }
}
