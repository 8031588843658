import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DepartmentInfo } from '../models/departmentInfo';
import { AlertService } from '../services';
import { CommonService } from '../services/common.service';
declare var $:any
@Component({
  selector: 'app-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.css']
})
export class DepartmentComponent implements OnInit {
  name: string;
  departmentid: number;
  departmentId:number;
  employe: DepartmentInfo[];
  list: DepartmentInfo[];
  limitPara: any;
  offset: any;
  isAdding: boolean = true;
  addOrUpdate: boolean = true;

  constructor(private commonservice: CommonService, private router: Router, private alertservice: AlertService) { }

  ngOnInit() {
    this.searchDepartment();
    this.limitPara = 5;
    this.offset = 0;
    (<HTMLInputElement>document.getElementById('textType')).focus()
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/temp', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }

  reFresh() {
    this.name = "";
    this.searchDepartment();
    this.addOrUpdate = true;
    // this.redirectTo('/departmentetails');
  }

  selectDepartment(e: any) {
    //console.log("NAme ",e);
    //console.log("NAme ",e.target.value);
    this.name = e.target.value;
  }

  saveDepartment() {
    if (!this.name) {
      this.alertmessages("Please enter Department Name", "danger");
      return;
    }
    this.commonservice.getdeparment(this.name.trim()).subscribe(
      data => {
        this.employe = data.departmentsaved;
        this.searchDepartment();
        this.reFresh();
        console.log("save" + this.employe);
        if (this.employe) {
          this.alertmessages("Successfully Updated", "success");
          return;
        }

      },
      error => {
        const response = error.error;
        if (response.errorMessages != null && response.errorMessages.length !== 0) {
          this.alertservice.error(response.errorMessages[0]['key']);
        }
      });
  }
  editdepartment(id, info) {
    console.log("edit group : ", info)
    this.name = info;
    this.departmentId = id;

    this.addOrUpdate = false;
  }
  updateDepartment() {
    this.addOrUpdate = true;
    console.log("After trim Group: ", this.name);
    console.log("After departmentId: ", this.departmentId);
    this.name = this.name.trim();
    console.log("After trim Group: ", this.name, " :  length : ", this.name.length)
    if (this.name != undefined && this.name.length > 0) {

      const updatedField = {
        departmentId: this.departmentId,
        name: this.name
      };

      this.commonservice.editUpdate_department(updatedField).subscribe(
        data => {
          console.log("added group ", data)
          this.alertmessages("Successfully Updated ", "success")
          this.reFresh();
           this.searchDepartment();

        }, error => {
          this.alertmessages("Please try again later", "danger")
          this.reFresh();
          console.log(error);
        }
      );
      this.reFresh();
      //this.clear();
    }

  }
  searchDepartment() {
    // if (!this.name) {
    this.commonservice.getSearchdeparment1().subscribe(
      data => {
        this.list = data;
      },
      error => {
        const response = error.error;
        if (response.errorMessages != null && response.errorMessages.length !== 0) {
          this.alertservice.error(response.errorMessages[0]['key']);
        }
      });
    // }
  }

  onScroll() {
    this.offset = this.offset + this.limitPara;
    this.searchDepartment();
  }

  @HostListener('scroll', ['$event'])
  scrolled() {
    this.onScroll();
  }

  limitpara() {
    this.limitPara = 0;
  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    // (<HTMLButtonElement>document.getElementById('btndisabled')).disabled = true;
    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/departmentetails']);
      });


    }, 3000);
  }

  onAddOrSaveClick() {
    if (this.isAdding) {
      // Code for "Add" functionality
      console.log("Adding data to temporary storage...");

      // Toggle to prepare for "Save" on the next click
      this.isAdding = false;
    } else {
      // Code for "Save" functionality
      this.searchDepartment();
      this.isAdding = true;

    }

  }

  onDeleteHandler(id,name) {
    // console.log("delete : ", info);
    this.departmentId=id;
    this.name = name;
    $('#showAlertVer').modal('toggle');
  }

  closeMRQModal() {
    $('#showAlertVer').modal('toggle');

  }

  deletedepartment() {
    //console.log("delete Expense Type : ", id, " ", expenseName)
    const id = this.departmentId;
    this.commonservice.delete_department(id).subscribe(
      data => {
        console.log("delete Department name ", data)
        this.alertmessages("Successfully deleted ", "success")
       // this.clear();
       this.reFresh();
        this.searchDepartment();
        $('#showAlertVer').modal('toggle');
      }, error => {
        $('#showAlertVer').modal('toggle');
        this.alertmessages(" Department name is in Use ", "danger")
        console.log(error);
      }
    );

    //this.clear();
  }
}
